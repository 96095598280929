import gql from 'graphql-tag';

export const SUBMIT_TRANFER_REQUEST = gql`
  mutation submitInventoryTransfer(
    $statusHeader: String
    $orderId: String
    $orderExternalId: String
    $accountId: String
    $salesRepId: String
    $receivingRepId: String
    $fromSubInventoryCode: String
    $toSubInventoryCode: String
    $salesOrganization: String
    $requestedFrom: String
    $lineItems: [UpdateTransferLineItems]
  ) {
    submitInventoryTransfer(
      statusHeader: $statusHeader
      orderId: $orderId
      orderExternalId: $orderExternalId
      accountId: $accountId
      salesRepId: $salesRepId
      receivingRepId: $receivingRepId
      fromSubInventoryCode: $fromSubInventoryCode
      toSubInventoryCode: $toSubInventoryCode
      salesOrganization: $salesOrganization
      requestedFrom: $requestedFrom
      lineItems: $lineItems
    ) {
      message
      externalId
    }
  }
`;

export default SUBMIT_TRANFER_REQUEST;
