import gql from 'graphql-tag';

/** submit usage sheet */
const SUBMIT_USAGE_SHEET = gql`
  mutation submitUsageSheet(
    $caseId: String
    $caseExternalId: String
    $accountId: String
    $branchId: String
    $procedureTypeIds: String
    $surgeonId: String
    $salesRepId: String
    $shipToId: String
    $billToId: String
    $eventDateTime: Date
    $poNumber: String
    $patientId: String
    $isPrimaryApproval: Boolean
    $submittedByPersona: String
    $approvalType: String
    $lineItems: [SubmitUsageSheetLineItems]
    $usageType: String
    $isUserOnHold: Boolean
  ) {
    submitUsageSheet(
      caseId: $caseId
      caseExternalId: $caseExternalId
      accountId: $accountId
      branchId: $branchId
      procedureTypeIds: $procedureTypeIds
      surgeonId: $surgeonId
      salesRepId: $salesRepId
      shipToId: $shipToId
      billToId: $billToId
      eventDateTime: $eventDateTime
      poNumber: $poNumber
      patientId: $patientId
      isPrimaryApproval: $isPrimaryApproval
      submittedByPersona: $submittedByPersona
      approvalType: $approvalType
      lineItems: $lineItems
      usageType: $usageType
      isUserOnHold: $isUserOnHold
    ) {
      message
      externalId
    }
  }
`;

export default SUBMIT_USAGE_SHEET;
