/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Spinner from '@salesforce/design-system-react/components/spinner';
import moment from 'moment';
import Input from '@salesforce/design-system-react/components/input';
import { format } from 'date-fns';
import { GET_SHIP_TO_ADDRESSES } from '../../../../../graphql/getShipToAddresses';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import { GET_PRIMARY_ACCOUNT } from '../../../../../graphql/getPrimaryAccount';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import { INVENTORY_SERVICE_LEVEL_OPTIONS, NOTIFY_TYPE } from '../../../../../util/constants';
import { getUTCDateTime } from '../../../../../util/utilityFunctions';
import DatePicker from '../../../../Shared/DatePicker';
import './index.scss';
import CartComponent from '../Parts/CartComponent';
import { getUserInfo } from '../../../../../store/ducks/userInfo';

interface Props {
  usageDetails?: any;
  viewOnly: boolean;
  isAssignUser: boolean;
  refetch: () => void;
  count: number;
  replenishmentConfig?: any;
  type: string;
  selectedIndex?: number;
}

interface DropdownSelect {
  id?: string;
  label?: string;
  value: string;
}

const UsageReplenishment: React.FC<Props> = ({
  usageDetails,
  refetch,
  count,
  viewOnly,
  isAssignUser,
  replenishmentConfig,
  type,
  selectedIndex,
}) => {
  const [selectedServiceLevel, setSelectedServiceLevel] = useState<DropdownSelect[]>();
  const [shipToAddressData, setShipToAddressData] = useState<any>();
  const [shipToAddressDataRepStock, setShipToAddressDataRepStock] = useState<any>();
  const { open, notification, openSnackBar } = useSnackBar();
  const [shipToAddressDataCustomer, setShipToAddressDataCustomer] = useState<any>();
  const [shipToAddress, setShipToAddress] = useState<any>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [isPartSelected, setIsPartSelected] = useState(true);
  const [selectedParts, setSelectedParts] = useState<any>([]);
  const [parts, setParts] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [requestedDated, setRequestedDated] = useState<any>(null);
  const userInfo = useSelector(getUserInfo);
  const [createUpdateUsageLineItems, { loading }] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);
  const [getCustomerShipToAddressItems, { data: customershipToAddressesItems }] = useLazyQuery(
    GET_SHIP_TO_ADDRESSES
  );
  const [getRepstockShipToAddressItems, { data: repstockshipToAddressesItems1 }] = useLazyQuery(
    GET_SHIP_TO_ADDRESSES
  );
  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const primaryAccountData = primaryAccountItems?.getPrimaryAccount;
  useEffect(() => {
    if (selectedIndex === 1) {
      getPrimaryAccountItems({
        variables: {
          salesRepId: usageDetails?.salesRepId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);
  const history = useHistory();

  const handleSave = (): void => {
    const payload: Array<any> = [];
    const updatedItems = [...parts];
    updatedItems.forEach((product: any, index: number): void => {
      let lotNumber =
        (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber;
      if (product.iscatalog) {
        lotNumber = product.lotNumber;
      }
      payload.push({
        sfid: product.sfid,
        externalId: product.externalId,
        productId: product.productId,
        isLotNumber: product.isLotNumber,
        productNumber: product.productNumber,
        quantity: product.quantity.toString(),
        wasted: product.wasted || false,
        iscatalog: product.iscatalog,
        isLotValueUpdated: product?.isLotValueUpdated || false,
        sourceLocation: product.sourceLocation
          ? typeof product.sourceLocation === 'string'
            ? product.sourceLocation
            : product?.sourceLocation[0]?.value
          : null,
        lotNumber,
        serialNumber: (index + 1).toString(),
        productFamily: product.productFamily,
        actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2) || '',
        usgTotalAmount: parseFloat((product.usgPrice * product.actualQuantity).toString()).toFixed(
          2
        ),
        isSelected: product.isSelected,
        shipToId: product.shipToId,
        needByDate: product.needByDate || null,
        serviceLevel: product.serviceLevel,
        subInventoryCode: product.caseUsageSubInventoryCode,
        priceOverride: product.priceOverride ? product.priceOverride : null,
        deleted: product?.deleted || false,
      });
    });
    const mutation = {
      caseId: usageDetails.caseId,
      caseExternalId: usageDetails.caseExternalId,
      salesRepId: usageDetails.salesRepId,
      formType: 'Update',
      lineItems: payload,
    };
    setLoader(true);
    createUpdateUsageLineItems({
      variables: mutation,
    })
      .then(() => {
        refetch();
        setLoader(false);
      })
      .catch((res: any) => {
        setLoader(false);
        if (res && res.message) {
          openSnackBar(NOTIFY_TYPE.ERROR, res.message);
          setLoader(false);
        } else {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      });
  };

  const optionsWithLabelFormatted = (arr: any): any[] =>
    arr &&
    arr.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ShipToAddress') {
        return {
          ...elem,
          label: `${
            elem.address1 && elem.name && elem.state && elem.postalcode
              ? `${elem.isPrimaryAddress ? '*' : ''} ${elem.name} - ${
                  elem.address1
                } ${elem.address2 || ''}, ${elem.state}, ${elem.postalcode}`
              : `${elem.name}`
          }`,
          value: `${
            elem.address1 && elem.name && elem.state && elem.postalcode
              ? `${elem.isPrimaryAddress ? '*' : ''} ${elem.name} - ${
                  elem.address1
                } ${elem.address2 || ''}, ${elem.state}, ${elem.postalcode}`
              : `${elem.name}`
          }`,
        };
      }
      return { ...elem, label: `${elem.number ? elem.number : ''} ${elem.value}` };
    });
  useEffect(() => {
    if (usageDetails?.lineItems) {
      if (usageDetails && usageDetails?.accountId) {
        getCustomerShipToAddressItems({
          variables: {
            accountId: usageDetails?.accountId || '',
          },
        });
        if (
          customershipToAddressesItems &&
          customershipToAddressesItems.getShipToAddresses.length > 0
        ) {
          const shipToAddressDatacustomer = optionsWithLabelFormatted(
            customershipToAddressesItems.getShipToAddresses
          );
          setShipToAddressDataCustomer(shipToAddressDatacustomer);
        }
      }
    }
  }, [usageDetails, refetch, getCustomerShipToAddressItems, customershipToAddressesItems]);
  useEffect(() => {
    if (usageDetails?.lineItems) {
      setCheckAll(!viewOnly);
      if (viewOnly) {
        setCheckAll(viewOnly);
      }
      // eslint-disable-next-line no-unused-expressions
      usageDetails?.lineItems.forEach((item: any) => {
        if (item.needByDate) {
          setRequestedDated(
            moment(item.needByDate)
              .utc()
              .format('MM/DD/YYYY')
          );
        }
        if (item.serviceLevel) {
          setSelectedServiceLevel([
            {
              label: item.serviceLevel,
              value: item.serviceLevel,
            },
          ]);
        }
        if (item.isSelected === true) {
          setIsPartSelected(false);
        }
        if (
          ((item?.isBioTissuePart && item?.sourceLocation === 'Customer') ||
            !item?.isBioTissuePart ||
            item?.sourceLocation === '' ||
            item?.sourceLocation === null) &&
          (item.isSelected === false || item.isSelected === null)
        ) {
          setCheckAll(false);
        }
      });
      setParts(usageDetails?.lineItems);
      const isExists = usageDetails?.lineItems?.findIndex((obj: any) => obj.isSelected === true);
      if (isExists === -1) {
        setIsPartSelected(true);
      } else {
        setIsPartSelected(false);
      }
      const isCheckAll = usageDetails?.lineItems?.filter(
        (obj: any) =>
          (obj?.isBioTissuePart && obj?.sourceLocation === 'Customer') ||
          !obj?.isBioTissuePart ||
          obj?.sourceLocation === '' ||
          obj?.sourceLocation === null
      );
      if (isCheckAll.length === 0) {
        setCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageDetails, refetch]);

  useEffect(() => {
    const accountNumbers: any = [];
    // eslint-disable-next-line no-unused-expressions
    parts?.forEach((part: any) => {
      if (part?.sourceLocation === 'Repstock') {
        accountNumbers.push(
          part.caseUsageSubInventoryCode || (primaryAccountData && primaryAccountData[0].number)
        );
      }
    });
    if (primaryAccountData && primaryAccountData.length > 0) {
      if (accountNumbers) {
        getRepstockShipToAddressItems({
          variables: {
            accountIds: accountNumbers,
            accountId: primaryAccountData[0].id || '',
            userId: usageDetails?.salesRepId,
          },
        });
      }
      if (
        repstockshipToAddressesItems1 &&
        repstockshipToAddressesItems1.getShipToAddresses.length > 0
      ) {
        const shipToAddressDatarepstock = optionsWithLabelFormatted(
          repstockshipToAddressesItems1.getShipToAddresses
        );
        setShipToAddressDataRepStock(shipToAddressDatarepstock);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRepstockShipToAddressItems, primaryAccountData, repstockshipToAddressesItems1]);

  const getShipToAddress = (shiptoId: any, sourceLocation: any, part: any) => {
    let shipToaddress: any = [];
    const filterShipToAddress = shipToAddressDataRepStock?.filter(
      (item: any) =>
        part.caseUsageSubInventoryCode === item.name ||
        (part.iscatalog === true && primaryAccountData && primaryAccountData[0].name === item.name)
    );
    if (shiptoId && Object.keys(shiptoId).length > 0) {
      if (sourceLocation === 'Repstock') {
        shipToaddress = [filterShipToAddress?.find((obj: any) => obj.id === shiptoId)];
      }
      if (sourceLocation === 'Customer') {
        shipToaddress = [shipToAddressDataCustomer?.find((obj: any) => obj.id === shiptoId)];
      }
    }
    return shipToaddress;
  };

  const defaultAddress = (part: any) => {
    const address: any[] = [];
    if (part.externalId) {
      const filterShipToAddress = shipToAddressDataRepStock?.filter(
        (item: any) =>
          part.caseUsageSubInventoryCode === item.name ||
          (part.iscatalog === true && primaryAccountData[0].name === item.name)
      );
      if (
        part.sourceLocation === 'Repstock' &&
        filterShipToAddress &&
        filterShipToAddress?.length === 1
      ) {
        // eslint-disable-next-line array-callback-return
        parts.map((lineItem: any) => {
          if (part.externalId === lineItem.externalId) {
            lineItem.shipToId = filterShipToAddress[0].id;
          }
        });
        setParts(parts);
        return optionsWithLabelFormatted([filterShipToAddress[0]]);
      }
      if (part.sourceLocation === 'Customer' && shipToAddressDataCustomer) {
        let shipToIndex: any;
        // eslint-disable-next-line array-callback-return
        parts.map((lineItem: any) => {
          if (part.externalId === lineItem.externalId && shipToAddressDataCustomer) {
            shipToIndex = shipToAddressDataCustomer?.findIndex(
              (item: any) => item.id === usageDetails.shippingId
            );
            lineItem.shipToId = shipToAddressDataCustomer[shipToIndex].id;
          }
        });
        setParts(parts);
        return optionsWithLabelFormatted([shipToAddressDataCustomer[shipToIndex]]);
      }
    }
    return address;
  };
  const updateValues = (updatedValue: any, upDatedKey: any, updatePart: any) => {
    let objIndex: any = [];
    if (checkAll && upDatedKey !== 'shipToId') {
      parts.forEach((part: any) => {
        part[upDatedKey] = updatedValue;
      });
      const result = parts.filter((part: any) => {
        return (
          part.isSelected === true &&
          (part.serviceLevel === null || part.needByDate === null || part.shipToId === null)
        );
      });
    } else {
      // eslint-disable-next-line no-lonely-if
      if (updatePart && Object.keys(updatePart).length > 0) {
        objIndex = parts.findIndex((obj: any) => obj.externalId === updatePart.externalId);
        parts[objIndex][upDatedKey] = updatedValue;
      } else {
        // eslint-disable-next-line array-callback-return
        parts.map((part: any) => {
          if (part.isSelected === true) {
            part[upDatedKey] = updatedValue;
          }
        });
      }
    }
    setParts(parts);
    handleSave();
    if (parts.length > 0) {
      usageDetails.lineItems = parts;
    }
  };
  const handleSelect = (data: any, name: any, selectedpart: any) => {
    if (data.selection || name === 'needByDateFromDate') {
      switch (name) {
        case 'shipToId':
          if (data?.selection[0]) {
            updateValues(data.selection[0].id, 'shipToId', selectedpart);
            setShipToAddress(data.selection);
          }
          break;
        case 'serviceLevel':
          updateValues(data.selection[0].value, 'serviceLevel', {});
          setSelectedServiceLevel(data.selection);
          break;
        case 'needByDateFromDate':
          if (data) {
            updateValues(moment(data).format('MM/DD/YYYY'), 'needByDate', {});
            setRequestedDated(moment(data).format('MM/DD/YYYY'));
          }
          break;
        default:
      }
    }
  };
  const handleCheckboxChangeForAll = (event: any) => {
    const updatParts = parts.map((item: any) => {
      if (event.target.checked === true) {
        item.isSelected = true;
        item.needByDate = requestedDated;
        item.serviceLevel = selectedServiceLevel ? selectedServiceLevel[0]?.value : '';
        if (item.shipToId === null || item.shipToId === '') {
          const shipToId = defaultAddress(item);
          item.shipToId = shipToId ? shipToId[0]?.id : '';
        }
      } else {
        item.isSelected = false;
      }
      return { ...item };
    });
    setParts(updatParts);
    handleSave();
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      setSelectedParts(updatParts);
    } else if (selectedParts?.length === parts?.length) {
      setSelectedParts([]);
    }
  };

  const handleLink = (externalId: any, iRtype: any) => {
    history.push('/inventoryRequestDetails', {
      isEventInventoryFlow: false,
      externalId,
      type: iRtype || 'Open',
    });
  };

  const handleLinkClick = (externalId: any, iRtype: any) => {
    if (externalId) {
      handleLink(externalId, iRtype);
    }
  };

  const handleCheckboxChange = (event: any, part: any) => {
    const updatedParts = parts;
    updatedParts.map((item: any) => {
      if (item.externalId === part.externalId) {
        item.isSelected = event.target.checked;
        if (event.target.checked) {
          item.needByDate = requestedDated;
          item.serviceLevel = selectedServiceLevel ? selectedServiceLevel[0]?.value : '';
          if (item.shipToId === null || item.shipToId) {
            const shipToId = defaultAddress(item);
            item.shipToId = shipToId ? shipToId[0]?.id : '';
          }
        }
      }
      if (item.isSelected === true) {
        setIsPartSelected(false);
      }
      return item;
    });
    setParts(updatedParts);
    handleSave();
  };
  const isPartChecked = (lineItem: any) => {
    if (viewOnly && lineItem?.irCaseID) {
      const isExists = selectedParts?.findIndex(
        (obj: any) => obj.externalId === lineItem.externalId
      );
      if (isExists === -1) {
        selectedParts.push(lineItem);
        setSelectedParts(selectedParts);
      }

      return true;
    }
    return !!lineItem?.isSelected;
  };
  return (
    <>
      <div className="parts-table-container marginAdjust" />
      <IconSettings iconPath="/icons">
        {loader && <Spinner size="medium" variant="base" />}
        <div className="header-container">
          <div className="marginAdjust widthHalf">
            <div className="slds-col slds-align_absolute-center justifyLeft">
              {requestedDated ? (
                <DatePicker
                  label="Requested Delivery Date"
                  required
                  placeholder="MM/DD/YYYY"
                  handleChangeDate={(data: any): void => {
                    handleSelect(data, 'needByDateFromDate', []);
                  }}
                  disabled={viewOnly || isAssignUser || isPartSelected}
                  value={requestedDated || null}
                />
              ) : (
                <DatePicker
                  label="Requested Delivery Date "
                  required
                  placeholder="MM/DD/YYYY"
                  handleChangeDate={(data: any): void => {
                    handleSelect(data, 'needByDateFromDate', []);
                  }}
                  disabled={viewOnly || isAssignUser || isPartSelected}
                  value={
                    (requestedDated &&
                      moment(requestedDated)
                        ?.utc()
                        .format('MM/DD/YYYY')) ||
                    null
                  }
                />
              )}
            </div>
          </div>
          <div className="marginAdjust widthHalf">
            <div className="slds-col slds-align_absolute-center justifyRight">
              <Combobox
                id="serviceLevel"
                required
                disabled={viewOnly || isAssignUser || isPartSelected}
                singleInputDisabled={viewOnly || isAssignUser || isPartSelected}
                labels={{
                  label: `Service Level`,
                  placeholder: 'Service Level',
                  noOptionsFound: 'No data found',
                }}
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'serviceLevel', []),
                }}
                placeholder="Service Level"
                options={INVENTORY_SERVICE_LEVEL_OPTIONS || []}
                selection={
                  viewOnly && selectedParts.length > 0 && selectedParts[0].irCaseID
                    ? [
                        {
                          label: selectedParts[0]?.serviceLevel,
                          value: selectedParts[0]?.serviceLevel,
                        },
                      ]
                    : selectedServiceLevel || []
                }
                variant="readonly"
              />
            </div>
          </div>
        </div>
        <PageHeader
          className="slds-page-header-usage slds-page-header__name-title-inventory slds-m-around_small slds-m-bottom_none"
          //   onRenderActions={actions}
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <span style={{ marginRight: '10px' }}>
                <CartComponent count={count} color="white" textColor="black" />
              </span>
              <span style={{ fontSize: '18px' }}>Replenishment</span>
            </>
          }
          variant="record-home"
          id="header"
        />
        <div className="parts-table-container">
          <div className="parts-table-header bill-replenished-only-cols-configured">
            <span style={{ width: '40px' }}>
              {parts && (
                <div className="slds-col slds-size_12ß-of-12">
                  <Checkbox
                    assistiveText={{
                      label: 'Select/Unselect All',
                    }}
                    disabled={viewOnly || isAssignUser}
                    key="mark_all"
                    id="mark_all"
                    onChange={(_event: any): void => {
                      handleCheckboxChangeForAll(_event);
                    }}
                    checked={checkAll}
                  />
                </div>
              )}
            </span>
            <span className="textAlignLeft" style={{ marginLeft: 15 }}>
              Part
            </span>
            <span className="textAlign">Qty</span>
            <span className="textAlign">Source Location</span>
            <span className="textAlign">Ship To</span>
            <span className="widthAdjust textAlign">IR</span>
          </div>

          {parts &&
            // eslint-disable-next-line array-callback-return, consistent-return
            parts.map((part: any, index: any) => {
              if (
                (part?.isBioTissuePart && part?.sourceLocation === 'Customer') ||
                !part?.isBioTissuePart ||
                part?.sourceLocation === '' ||
                part?.sourceLocation === null
              ) {
                return (
                  <div className="parts-table-header bill-replenished-only-cols-configured fontWieght">
                    <span style={{ width: '40px' }}>
                      <div className="slds-col slds-size_12ß-of-12">
                        <Checkbox
                          assistiveText={{
                            label: 'Select/Unselect All',
                          }}
                          disabled={viewOnly || isAssignUser}
                          onChange={(_event: any): void => {
                            handleCheckboxChange(_event, part);
                          }}
                          checked={isPartChecked(part)}
                        />
                      </div>
                    </span>
                    <span className="textAlignLeft" style={{ marginLeft: 15 }}>
                      {`${part.productNumber} - ${part.productdescription}`}
                    </span>
                    {checkAll || isPartChecked(part) ? (
                      <>
                        <span className="textAlign">
                          <p>{part.quantity}</p>
                        </span>
                        <span className="textAlign">
                          <p>{part.sourceLocation}</p>
                        </span>
                        <span style={{ padding: '0px 2px' }}>
                          <Combobox
                            name="shipTo"
                            disabled={viewOnly || isAssignUser}
                            labels={{
                              label: '',
                              placeholder: 'Select Ship To',
                              noOptionsFound: 'No data found',
                            }}
                            events={{
                              onSelect: (event: any, data: any): void => {
                                handleSelect(data, 'shipToId', part);
                              },
                            }}
                            placeholder="Select Ship To"
                            options={
                              // eslint-disable-next-line no-nested-ternary
                              part.sourceLocation
                                ? part.sourceLocation === 'Repstock'
                                  ? shipToAddressDataRepStock?.filter(
                                      (item: any) =>
                                        part.caseUsageSubInventoryCode === item.name ||
                                        (part.iscatalog === true &&
                                          primaryAccountData &&
                                          primaryAccountData[0].name === item.name)
                                    ) || []
                                  : shipToAddressDataCustomer || []
                                : []
                            }
                            // selection={
                            //   shipToAddressData && part.shipToId === null
                            //     ? defaultAddress(part)
                            //     : getShipToAddress(part.shipToId) || []
                            // }
                            selection={
                              !part.shipToId
                                ? defaultAddress(part)
                                : getShipToAddress(part.shipToId, part.sourceLocation, part) || []
                            }
                            variant="readonly"
                          />
                        </span>
                        <span className="widthAdjust marginAuto">
                          <p className="textAlign" style={{ marginLeft: '5px' }}>
                            {userInfo?.personas?.indexOf('CSR') === -1 ? (
                              <span
                                role="button"
                                tabIndex={0}
                                className="slds-button"
                                onClick={
                                  () =>
                                    handleLink(
                                      part.inventorySfid || part.ircaseExternalId,
                                      part.inventorySfid ? 'Processed' : 'Open'
                                    )
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                onKeyDown={
                                  () =>
                                    handleLinkClick(
                                      part.inventorySfid || part.ircaseExternalId,
                                      part.inventorySfid ? 'Processed' : 'Open'
                                    )
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {part.irCaseID || ''}
                              </span>
                            ) : (
                              // eslint-disable-next-line react/jsx-one-expression-per-line
                              <span> {part.irCaseID || ''} </span>
                            )}
                          </p>
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <p />
                        </span>
                        <span>
                          <p />
                        </span>
                        <span />
                        <span>
                          <p />
                        </span>
                      </>
                    )}
                  </div>
                );
              }
            })}
        </div>
      </IconSettings>
    </>
  );
};

export default React.memo(UsageReplenishment);
