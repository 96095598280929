/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable */
import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import {
  INVENTORY_SHIPPING,
  INVENTORY_SHIPPING_SM,
  PERSONA_CONDITION,
} from '../../../util/constants';
import { getEventIconInner, getSvgIcon } from '../../../util/utilityFunctions';
import { GET_SAS_URI } from '../../../graphql/getSasUri';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { GET_SHIP_METHODS } from '../../../graphql/getShipMethods';
import { formatShipMethodsLabel } from '../../../util/utilityFunctions';

interface Props extends RouteComponentProps {
  inventoryReturnDetails: InventoryReturnDetails;
  viewOnly: boolean;
  setShipMethodValue: (item: string) => void;
  handleEditClick: () => void;
  handleBackClick: () => void;
  handleRequestClick?: any;
  type: string;
  irHeaderCofig?: any;
  userInfo?: any;
  parentEventStatus?: string;
  handleInventoryActionDropdownSelect?: any;
}
interface File {
  imageurl: string;
  name: string;
}
interface DropdownSelect {
  label: string;
  value: string;
}
interface InventoryReturnDetails {
  caseNumber?: string;
  status: string;
  type: string;
  salesRepName: string;
  shipmentDate: string;
  trackingNumber: string;
  territoryNumber: string;
  serviceLevel?: string;
  serviceLevelJustification?: string;
  shipMethod?: string;
  eventId?: string;
  eventStatus?: string;
  salesRep?: string;
  accountName?: string;
  accountNumber?: string;
  needByDate?: string;
  comments?: any;
  shippingAddessLine1?: string;
  shippingAddessLine2?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingPostalcode?: string;
  erpOrderNumber?: string;
  failureReason?: string;
  parentEventId?: any;
  parentEventType?: any;
  parentEventSubType?: any;
  parentCaseExternalId?: any;
  showBackorderdWarning?: boolean;
  showRedWarningIcon?: boolean;
  visualIndicator?: string;
  attachments?: any;
  dockAvailable?: any;
  deliveryContractPhone?: any;
  deliveryContractName?: any;
  deliveryContractInstructions?: any;
  deliveryContractEmail?: any;
  liftGateNeeded?: any;
  isShippingDetails?: any;
  shortTruckOnly?: any;
  parentEventStartDate?: any;
  thresholdQuantityIndicator?: boolean;
  salesRepNameIns?: string;
  salesRepEmailIns?: string;
  salesRepPhoneIns?: string;
  shippingInstructions?: string;
  createdDate?: any;
  parentOrderid?: any;
  shippingAddressNumber?: string;
  lineItems?: any;
}

const InventoryQueueHeader: FC<Props> = ({
  history,
  inventoryReturnDetails,
  viewOnly,
  setShipMethodValue,
  type,
  userInfo,
  handleEditClick,
  handleBackClick,
  handleRequestClick,
  irHeaderCofig,
  parentEventStatus,
  handleInventoryActionDropdownSelect,
}) => {
  const {
    caseNumber,
    territoryNumber,
    trackingNumber,
    salesRepName,
    shipmentDate,
    serviceLevel,
    serviceLevelJustification,
    status,
    shipMethod,
    eventId,
    eventStatus,
    salesRep,
    accountName,
    accountNumber,
    needByDate,
    comments,
    shippingAddessLine1,
    shippingAddessLine2,
    shippingCity,
    shippingState,
    shippingPostalcode,
    erpOrderNumber,
    failureReason,
    parentEventId,
    parentEventType,
    parentEventSubType,
    parentCaseExternalId,
    showRedWarningIcon,
    showBackorderdWarning,
    visualIndicator,
    attachments,
    dockAvailable,
    deliveryContractPhone,
    deliveryContractName,
    deliveryContractInstructions,
    deliveryContractEmail,
    liftGateNeeded,
    isShippingDetails,
    shortTruckOnly,
    parentEventStartDate,
    thresholdQuantityIndicator,
    salesRepNameIns,
    salesRepEmailIns,
    salesRepPhoneIns,
    parentOrderid,
    shippingInstructions,
    createdDate,
    shippingAddressNumber,
    lineItems,
  } = inventoryReturnDetails;
  const [shipMethodOptions, setShipMethodOptions] = useState<DropdownSelect[]>();
  const [shipMethodSelected, setShipMethodSelected] = useState<DropdownSelect[]>();
  const [isLoading, setLoader] = useState(false);
  const [getShipMethods, { data: shipMethods }] = useLazyQuery(GET_SHIP_METHODS, {
    fetchPolicy: 'no-cache',
  });

  const isOps =
    userInfo &&
    (userInfo?.personas === 'Branch Op' ||
      userInfo?.personas === '3PL Ops' ||
      userInfo?.personas === 'CSR;Branch Op' ||
      userInfo?.personas === 'Agency OPS;Agency CSR' ||
      userInfo?.personas === 'Agency OPS' ||
      userInfo?.personas === 'CSR;Branch Op;Business Admin' ||
      userInfo?.personas?.indexOf('Branch Op') > -1 ||
      userInfo?.personas?.indexOf('Agency OPS') > -1);
  const iconThreshold = getSvgIcon('threshold');
  useEffect(() => {
    /*const inventoryShipping =
         INVENTORY_SHIPPING.find((item: any) => item.serviceLevel === serviceLevel);
    if (inventoryShipping && inventoryShipping.shipMethodOptions) {
      const options = inventoryShipping.shipMethodOptions.map((item: any) => {
        return {
          label: item,
          value: item,
          disabled: type !== 'Review' && (type !== 'Failed')
        };
      });
      options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
      setShipMethodOptions(options);
    }*/
    setShipMethodOptions(formatShipMethodsLabel(shipMethods))
  }, [shipMethods]);
  useEffect(() => {
    if (shipMethod) {
      setShipMethodSelected([{ label: shipMethod, value: shipMethod }]);
    }
    
  }, [shipMethod, shipMethods]);

  useEffect(() => {
    getShipMethods({
      variables: {},
    });
  }, []);

  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };
  const headerActions = (): ReactNode => {
    const filterRule: any =
      // eslint-disable-next-line no-unused-expressions
      userInfo?.businessRules?.filter((br: any) => {
        if (br.rules === 'Clone Inventory Request') {
          return br.isRequired;
        }
      });

    const isClone = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
    const dropDown = [
      {
        disabled: !!(
          (visualIndicator && visualIndicator?.toLowerCase() === 'requested' && type !== 'Review') ||
          (visualIndicator && visualIndicator?.toLowerCase() === 'cancelled') ||
          (visualIndicator && visualIndicator?.toLowerCase() === 'approved' && type !== 'Review') ||
          (visualIndicator && visualIndicator?.toLowerCase() === '3pl review' && type !== 'Review') ||
          (parentEventStatus && parentEventStatus?.toLowerCase() === 'completed') ||
          (parentEventStatus && parentEventStatus?.toLowerCase() === 'cancelled') ||
          type === 'Processed' ||
          type === 'Assign' || viewOnly ||
          (
            type === 'Failed' &&
              !(
                userInfo &&
                (userInfo?.personas === 'Branch Op' ||
                  userInfo?.personas === '3PL Ops' ||
                  userInfo?.personas === 'CSR;Branch Op' ||
                  userInfo?.personas === 'Agency OPS;Agency CSR' ||
                  userInfo?.personas === 'Agency OPS' ||
                  userInfo?.personas?.toLowerCase()?.includes('branch op'))
              ))
        ),
        label: 'Edit',
        value: 'edit',
      },
      {
        disabled: !!(
          (visualIndicator && visualIndicator?.toLowerCase() === 'requested' && type !== 'Review') ||
          (visualIndicator && visualIndicator?.toLowerCase() === 'cancelled') ||
          (visualIndicator && visualIndicator?.toLowerCase() === 'approved' && type !== 'Review') ||
          (visualIndicator && visualIndicator?.toLowerCase() === '3pl review' && type !== 'Review') ||
          (parentEventStatus && parentEventStatus?.toLowerCase() === 'completed') ||
          (parentEventStatus && parentEventStatus?.toLowerCase() === 'cancelled') ||
          type === 'Processed' ||
          type === 'Assign' || viewOnly ||
          type === 'Failed' &&  !(
              userInfo &&
              (userInfo?.personas === 'Branch Op' ||
                userInfo?.personas === '3PL Ops' ||
                userInfo?.personas === 'CSR;Branch Op' ||
                userInfo?.personas === 'Agency OPS;Agency CSR' ||
                userInfo?.personas === 'Agency OPS' ||
                userInfo?.personas?.toLowerCase()?.includes('branch op'))
            )
        ),
        label: 'Submit',
        value: type === 'Failed' ? 'Resubmit' : 'submit',
        disabledReason: lineItems && lineItems?.length === 0 ? 'missing_line_items' : 'nothing',
      },
      {
        disabled: !!(
          (
            (visualIndicator && visualIndicator?.toLowerCase() === 'requested') ||
            (visualIndicator && visualIndicator?.toLowerCase() === 'requested') ||
            (visualIndicator && visualIndicator?.toLowerCase() === 'cancelled') ||
            (visualIndicator && visualIndicator?.toLowerCase() === 'failed') ||
            (visualIndicator &&
              visualIndicator?.toLowerCase() === 'approved' &&
              type !== 'Review') ||
            (visualIndicator && visualIndicator?.toLowerCase() === '3pl review') ||
            (parentEventStatus && parentEventStatus?.toLowerCase() === 'completed') ||
            (parentEventStatus && parentEventStatus?.toLowerCase() === 'cancelled') ||
            type === 'Processed'
          ) //||
          // (type === 'Failed' &&
          //   !(
          //     userInfo &&
          //     (userInfo.personas === 'Branch Op' || userInfo.personas === '3PL Ops')
          // )
          // )
        ),
        label: 'Cancel',
        value: 'cancel_request',
      },
      { label: 'Clone', value: 'clone', disabled: !isClone || type !== 'Review' },
      {
        label: 'Make Favorite',
        value: 'make_favorite',
        disabled: false,
      },
    ];
    // dropDown.push({
    //   label: 'Discrepancy', value: 'discrepancy',
    //   disabled: false
    // })
    return (
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Dropdown
            style={{ width: '10rem', marginLeft: '10px', borderRadius: '0.25rem' }}
            iconCategory="utility"
            iconSize="small"
            align="right"
            iconName="down"
            iconPosition="right"
            label="Actions"
            // disabled={(type === 'Failed') && !(userInfo && userInfo.personas === 'Branch Op')}
            options={dropDown}
            onSelect={(value: string): void => handleInventoryActionDropdownSelect(value)}
          />
          <DisplayRecordIndicator recordInfo={inventoryReturnDetails} />
        </ButtonGroup>
      </PageHeaderControl>
    );
  };

  const handleSelectDropdownItem = (data: any): void => {
    setShipMethodSelected(data.selection || []);
    setShipMethodValue(data.selection ? data.selection[0]?.label : '');
  };

  const parenticonPath = getEventIconInner(parentEventType);
  const handleChangeRequest = (): void => {
    handleRequestClick(parentEventType, parentCaseExternalId, parentEventId, parentOrderid);
  };

  const getConfiguration = (key: string) => {
    if (irHeaderCofig) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < irHeaderCofig.length; i++) {
        if (irHeaderCofig[i].mappingKey === key && irHeaderCofig[i].isShow) {
          return true;
        }
      }
    }
    return false;
  };

  const iconBackorder = getSvgIcon('backorder');
  const inventoryeRequestStatus =
    status === 'Failed' && visualIndicator === 'Failed' ? 'Approved' : status;

  return (
    <IconSettings iconPath="/icons">
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleBackClick}
        />
      </div>
      <PageHeader
        className="transferdetail_header slds-m-around_small slds-m-bottom_none"
        icon={
          <Icon
            assistiveText={{ label: 'Opportunity' }}
            category="standard"
            name="assignment"
            style={{ backgroundColor: '#FFB500' }}
          />
        }
        label="Inventory Requests"
        onRenderActions={headerActions}
        // onRenderControls={headerControls}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">
              {type === 'Processed' || type === 'Failed' ? caseNumber : eventId || 'TBD'}
            </span>
            <span className="slds-col slds-m-left_large">
              <div style={{ display: 'flex' }}>
                <EventStatusBadge
                  status={
                    type === 'Processed' || type === 'Failed'
                      ? visualIndicator === 'Approved'
                        ? visualIndicator
                        : inventoryeRequestStatus
                      : eventStatus || 'TBD'
                  }
                />
                {thresholdQuantityIndicator && type === 'Review' && (
                  <div style={{ marginLeft: '5px' }}>
                    <img
                      className="slds-tooltip-trigger"
                      title="Threshold Exceed"
                      src={iconThreshold}
                      alt="threshold"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  </div>
                )}
                {showRedWarningIcon && (
                  <div style={{ marginLeft: '5px' }}>
                    <Icon
                      title="Rejected"
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ color: '#b83c27' }}
                      className="slds-icon-text-error"
                    />
                  </div>
                )}
                {showBackorderdWarning && (
                  <img
                    src={iconBackorder}
                    alt="backorder"
                    style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                  />
                )}
                {thresholdQuantityIndicator && type !== 'Review' && (
                  <div style={{ marginLeft: '5px' }}>
                    <img
                      className="slds-tooltip-trigger"
                      title="Threshold Exceed"
                      src={iconThreshold}
                      alt="threshold"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  </div>
                )}
              </div>
            </span>
          </div>
        }
        truncate
        variant="object-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none', zIndex: 'auto' }}
      >
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div className="slds-text-title" style={{ fontWeight: 'bold' }} title="Sales Rep">
            Sales Rep
          </div>
          <div className="slds-tooltip-trigger">
            <div title="welcome">{salesRep || '--'}</div>
            <span />
          </div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            style={{ fontWeight: 'bold' }}
            title="Account"
          >
            Account
          </div>
          <div className="slds-truncate" title="accountName">
            {`${accountNumber}-${accountName}`}
          </div>
        </li>

        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            style={{ fontWeight: 'bold' }}
            title="Ship To"
          >
            Ship To
          </div>
          <div
            title={`${shippingAddressNumber ? `${shippingAddressNumber} -` : ''}
              ${shippingAddessLine1 || ''}, ${shippingCity || ''}, ${shippingState ||
              ''}, ${shippingPostalcode || ''}`}
          >
            {`${shippingAddressNumber ? `${shippingAddressNumber} -` : ''}
            ${shippingAddessLine1 || ''}, ${shippingCity || ''}, ${shippingState ||
              ''}, ${shippingPostalcode || ''}`}
          </div>
        </li>

        {getConfiguration('requestedReceivalDate') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Requested Delivery Date"
              style={{ fontWeight: 'bold' }}
            >
              Requested Delivery Date
            </div>
            <p className="slds-line-clamp">
              {needByDate
                ? moment(needByDate)
                    .utc()
                    .format('MM/DD/YYYY')
                : '--'}
            </p>
          </li>
        )}
      </ul>
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none', zIndex: 'auto' }}
      >
        {/* <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            style={{ fontWeight: 'bold' }}
            title="ERP Order Number"
          >
            Event
          </div>
          {parentEventId ? (
            <div title={parentEventId}>
              <img src={parenticonPath} alt="company" />
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventSubType || parentEventType}
              </span>
              <span
                role="button"
                tabIndex={0}
                className="slds-button"
                onClick={handleChangeRequest}
                onKeyDown={handleChangeRequest}
                style={{ cursor: 'pointer' }}
              >
                {parentEventId}
              </span>
            </div>
          ) : (
            '--'
          )}
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Event Start Date"
            style={{ fontWeight: 'bold' }}
          >
            Event Start Date
          </div>
          <p className="slds-line-clamp">
            {parentEventStartDate
              ? moment(parentEventStartDate)
                  .utc()
                  .format('MM/DD/YYYY')
              : '--'}
          </p>
        </li> */}
        {/* <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Event Start Date"
            style={{ fontWeight: 'bold' }}
          >
            Event Start Date
          </div>
          <p className="slds-line-clamp">
            {parentEventStartDate
              ? moment(parentEventStartDate)
                  .utc()
                  .format('MM/DD/YYYY')
              : '--'}
          </p>
        </li> */}
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            style={{ fontWeight: 'bold' }}
            title="Ship Date"
          >
            Service Level
          </div>
          <div title={serviceLevel || '--'}>{serviceLevel || '--'}</div>
        </li>
        {serviceLevel === 'First AM' ? (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Ship Level Justification"
            >
              Service Level Justification
            </div>
            {serviceLevelJustification ? (
              <div title="Service Level Justification">{serviceLevelJustification}</div>
            ) : (
              '--'
            )}
          </li>
        ) : (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Comment"
            >
              Ship Method
            </div>
            <div>
              {type !== 'Processed' && isOps ? (
                <Combobox
                  id="combobox-readonly-single"
                  disabled={
                    (type !== 'Review' && type !== 'Failed') || (viewOnly && !isOps) || viewOnly
                  }
                  events={{
                    onSelect: (_event: any, data: any): void => handleSelectDropdownItem(data),
                  }}
                  labels={{
                    placeholder: 'Select',
                  }}
                  options={shipMethodOptions || []}
                  selection={shipMethodSelected}
                  variant="readonly"
                />
              ) : (
                shipMethodSelected?.[0]?.value || '--'
              )}
            </div>
          </li>
        )}
        {type === 'Processed' && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="ERP Order Number"
            >
              ERP Order Number
            </div>
            <div title={erpOrderNumber}>{erpOrderNumber}</div>
          </li>
        )}
      </ul>
      {serviceLevel === 'First AM' && (
        <ul
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none', zIndex: 'auto' }}
        >
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Comment"
            >
              Ship Method
            </div>
            <div>
              {type !== 'Processed' && isOps ? (
                <Combobox
                  id="combobox-readonly-single"
                  disabled={
                    type === 'Processed' ||
                    type === 'Assign' ||
                    viewOnly ||
                    (viewOnly &&
                      type === 'Failed' &&
                        !(
                          userInfo &&
                          (userInfo?.personas?.toLowerCase().includes('branch op') ||
                            userInfo.personas === '3PL Ops' ||
                            userInfo.personas === 'CSR;Branch Op' ||
                            userInfo.personas === 'Agency OPS;Agency CSR' ||
                            userInfo.personas === 'Agency OPS' ||
                            userInfo.personas === '3PL Ops' ||
                            userInfo.personas === 'CSR;Branch Op' ||
                            userInfo.personas === 'Agency OPS;Agency CSR' ||
                            userInfo.personas === 'Agency OPS')
                        ))
                  }
                  events={{
                    onSelect: (_event: any, data: any): void => handleSelectDropdownItem(data),
                  }}
                  labels={{
                    placeholder: 'Select',
                  }}
                  options={shipMethodOptions || []}
                  selection={shipMethodSelected}
                  variant="readonly"
                />
              ) : (
                shipMethodSelected?.[0]?.value || '--'
              )}
            </div>
          </li>
        </ul>
      )}
      {/* <ul
        style={{ borderBottom: 'none', zIndex: 0 }}
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
      >
         {type === 'Processed' && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="ERP Order Number"
            >
              ERP Order Number
            </div>
            <div title={erpOrderNumber}>{erpOrderNumber}</div>
          </li>
        )}
      </ul> */}
      <ul
        style={{ borderBottom: 'none', zIndex: 0 }}
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
      >
        {getConfiguration('Attachments') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Attachment"
              style={{ fontWeight: 'bold' }}
            >
              Attachments
            </div>
            {attachments?.length > 0 ? (
              attachments?.map((item: any) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(): void => handleAttachmentsView(item)}
                    aria-hidden="true"
                  >
                    {`${item.name}`}
                  </div>
                );
              })
            ) : (
              <div title="">--</div>
            )}
          </li>
        )}

        {getConfiguration('comments') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Comment"
              style={{ fontWeight: 'bold' }}
            >
              Header Comments
            </div>
            {comments && comments.length
              ? comments.filter((item: { type: string }) => item?.type === 'Internal')?.[0]
                  ?.value || '--'
              : '--'}
          </li>
        )}

        {type === 'Failed' && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Failure Reason"
            >
              Failure Reason
            </div>
            <div title={failureReason}>{failureReason}</div>
          </li>
        )}
      </ul>
      <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none borderBottonNone">
        {getConfiguration('specificShipingDetails') && (
          <li className="slds-page-header__detail">
            <div
              className="slds-text-title slds-truncate"
              title="Specific Shipping Details"
              style={{ fontWeight: 'bold' }}
            >
              Specific Shipping Details
            </div>
            {isShippingDetails ? (
              <div style={{ fontSize: '16px' }} title="Specific Shipping Details">
                <span>
                  {` Sales Rep Name: ${
                    salesRepNameIns && salesRepNameIns !== 'null' ? salesRepNameIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Contact Phone: ${
                    salesRepPhoneIns && salesRepPhoneIns !== 'null' ? salesRepPhoneIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Email: ${
                    salesRepEmailIns && salesRepEmailIns !== 'null' ? salesRepEmailIns : ''
                  }`}
                </span>
                <span>{` | Lift Gate Needed: ${liftGateNeeded ? 'Yes' : 'No'}`}</span>
                <span>{` | Dock Available: ${dockAvailable ? 'Yes' : 'No'}`}</span>
                <span>{` | Short Truck Only: ${shortTruckOnly ? 'Yes' : 'No'}`}</span>
                <span>{` | Delivery Contact Name: ${deliveryContractName || ''}`}</span>
                <span>{` | Delivery Contact Phone: ${deliveryContractPhone || ''}`}</span>
                {deliveryContractEmail !== `` && (
                  <span>{` | Delivery Contact Email: ${deliveryContractEmail || ''}`}</span>
                )}
                {deliveryContractInstructions !== `` && (
                  <span>{` | Delivery Instructions: ${deliveryContractInstructions || ''}`}</span>
                )}
              </div>
            ) : (
              <div style={{ fontSize: '16px' }} title="Specific Shipping Details">
                <span>
                  {` Sales Rep Name: ${
                    salesRepNameIns && salesRepNameIns !== 'null' ? salesRepNameIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Contact Phone: ${
                    salesRepPhoneIns && salesRepPhoneIns !== 'null' ? salesRepPhoneIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Email: ${
                    salesRepEmailIns && salesRepEmailIns !== 'null' ? salesRepEmailIns : ''
                  }`}
                </span>
              </div>
            )}
          </li>
        )}
      </ul>
      <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none">
        <li className="slds-page-header__detail">
          <div
            className="slds-text-title slds-truncate"
            title="Event Id"
            style={{ fontWeight: 'bold' }}
          >
            Related Documents
          </div>
          {parentEventId ? (
            <div title={parentEventId}>
              <span>
                {parentEventType?.indexOf('Bill') === -1 && (
                  <img src={parenticonPath} alt="company" />
                )}
              </span>
              <span
                role="button"
                tabIndex={0}
                className="slds-button"
                onClick={handleChangeRequest}
                onKeyDown={handleChangeRequest}
                style={{ cursor: 'pointer' }}
              >
                {parentEventId}
              </span>
              <span> &nbsp; </span>
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventSubType
                  ? parentEventSubType
                  : parentEventType?.indexOf('Bill') !== -1
                  ? 'Bill Only'
                  : parentEventType}
                {`,`}
              </span>
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventType?.indexOf('Bill') !== -1 && createdDate
                  ? moment(createdDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : parentEventStartDate
                  ? moment(parentEventStartDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : '--'}
              </span>
            </div>
          ) : (
            '--'
          )}
        </li>
      </ul>
    </IconSettings>
  );
};

export default withRouter(InventoryQueueHeader);
