/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/**
 * Module for header on Inventory Request Detail Header Page
 * @module src/InventoryReqyestDetail/InventoryRequestDetailHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-indent */

import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { setHoldBannerStatus } from '../../../../store/ducks/holdBanner';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';
import { INVENTORY_REQUEST, holdTypes, onHoldUsers } from '../../../../util/constants';
import { getEventIcon, getEventIconInner, getSvgIcon } from '../../../../util/utilityFunctions';
import { GET_SAS_URI } from '../../../../graphql/getSasUri';
import DisplayRecordIndicator from '../../../Shared/DisplayRecordIndicator';
import { GET_USER_HOLD } from '../../../../graphql/getUserHold';
import { GET_USER_CC_HOLD } from '../../../../graphql/getUserCCInfo';

// import ReadMore from '../../Shared/ReadMore';

interface Props {
  inventoryDetail?: any;
  isEventInventoryFlow?: boolean;
  handleInventoryActionDropdownSelect?: any;
  hasEventInventory?: boolean;
  type?: string;
  parentEventStatus?: string;
  handleRequestClick?: any;
  irHeaderCofig?: any;
}
interface File {
  imageurl: string;
  name: string;
}
const InventoryRequestDetailHeader: FC<Props> = ({
  inventoryDetail,
  isEventInventoryFlow,
  handleInventoryActionDropdownSelect,
  hasEventInventory,
  type,
  parentEventStatus,
  handleRequestClick,
  irHeaderCofig,
}) => {
  const {
    salesRep,
    // eventStatus,
    eventType,
    eventId,
    needByDate,
    serviceLevel,
    serviceLevelJustification,
    // eventSubType,
    shippingAddressNumber,
    shippingAddessLine1,
    shippingAddessLine2,
    shippingCity,
    shippingState,
    shippingPostalcode,
    accountName,
    accountNumber,
    visualIndicator,
    lineItems,
    comments,
    eventStatus,
    caseNumber,
    erpOrderNumber,
    failureReason,
    dockAvailable,
    deliveryContractPhone,
    deliveryContractName,
    deliveryContractInstructions,
    deliveryContractEmail,
    liftGateNeeded,
    isShippingDetails,
    shortTruckOnly,
    parentCaseExternalId,
    parentEventStartDate,
    parentEventId,
    parentEventType,
    parentEventSubType,
    attachments,
    thresholdQuantityIndicator,
    createdDate,
    parentOrderid,
    salesRepNameIns,
    salesRepEmailIns,
    salesRepPhoneIns,
    shipMethod,
    caseExternalId,
    shippingInstructions,
  } = inventoryDetail ? inventoryDetail : '';
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const [userOnHold, setUserOnHold] = useState(false);
  const isManualIR =
    type && eventStatus && caseExternalId
      ? type === 'Open' && eventStatus === 'Approved' && caseExternalId
      : false;
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: inventoryDetail?.salesRepId || userInfo?.id,
    },
  });
  const { data: accountHoldFlag } = useQuery(GET_USER_CC_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: inventoryDetail?.salesRepId || userInfo?.id,
      accountId: accountNumber,
      name: salesRep,
    },
  });
  const isHoldUser = !!onHoldUsers?.find((rec: any) => rec?.value === userInfo?.personas);
  const handleNavigateToEventList = (): void => {
    window.history.back();
  };
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };
  const iconThreshold = getSvgIcon('threshold');
  let sortedComments;
  if (comments && comments.length > 0) {
    sortedComments = comments.sort((a: { postTime: any }, b: { postTime: any }) => {
      return a.postTime < b.postTime ? -1 : 1;
    });
  }

  /** checking if any lineItem is in Rejected status so we can disable the Cancel Request button in dropdown */
  let filtereredItems: any;
  if (type === 'Processed' || type === 'Failed') {
    filtereredItems = lineItems?.filter(
      (o: { status: string }) => o.status.toLowerCase() === 'rejected'
    );
  } else {
    filtereredItems = lineItems?.filter(
      (o: { lineVisualIndicator: string }) => o.lineVisualIndicator.toLowerCase() === 'approved'
    );
  }
  const lineItemApproved = filtereredItems?.length > 0;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getConfiguration = (key: string) => {
    if (irHeaderCofig) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < irHeaderCofig.length; i++) {
        if (irHeaderCofig[i].mappingKey === key && irHeaderCofig[i].isShow) {
          return true;
        }
      }
    }
    return false;
  };
  useEffect(() => {
    if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold'] ||
            (e === holdTypes[0]['CC Hold'] &&
              accountHoldFlag?.getCycleCountHoldInfo?.length > 0 &&
              accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold) ||
            e === holdTypes[0]['Custom Hold']
        )?.length > 0
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) => e === holdTypes[0]['Trial Hold']
        )?.length > 0 &&
        parentEventType === 'Trial'
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo &&
        holddata?.getUserHoldInfo?.length > 0 &&
        accountHoldFlag?.getCycleCountHoldInfo?.length > 0
      ) {
        if (
          holddata?.getUserHoldInfo[0]?.onHold === false &&
          accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold === false
        ) {
          dispatch(setHoldBannerStatus(false));
        } else if (
          holddata?.getUserHoldInfo[0]?.onHoldReason.length === 1 &&
          holddata?.getUserHoldInfo[0]?.onHoldReason.includes('Cycle Count Hold') &&
          accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold === false
        ) {
          dispatch(setHoldBannerStatus(false));
        }
      }
    } else {
      dispatch(setHoldBannerStatus(false));
    }
  }, [accountHoldFlag, dispatch, holddata, parentEventType]);

  const actions = (): ReactNode => {
    const filterRule: any =
      // eslint-disable-next-line no-unused-expressions
      userInfo?.businessRules?.filter((br: any) => {
        if (br.rules === 'Clone Inventory Request') {
          return br.isRequired;
        }
      });
    const isClone = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
    const dropDown = [
      {
        disabled: !!(
          (eventStatus && eventStatus.toLowerCase() === 'requested' && lineItemApproved) ||
          (visualIndicator && visualIndicator.toLowerCase() === 'requested') ||
          (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
          (visualIndicator && visualIndicator.toLowerCase() === 'approved') ||
          (visualIndicator && visualIndicator.toLowerCase() === '3pl review') ||
          (parentEventStatus && parentEventStatus.toLowerCase() === 'completed') ||
          (parentEventStatus && parentEventStatus.toLowerCase() === 'cancelled') ||
          type === 'Processed' ||
          isManualIR ||
          (type === 'Failed' &&
            !(
              userInfo &&
              (userInfo.personas === 'Branch Op' ||
                userInfo.personas === '3PL Ops' ||
                userInfo.personas.toLowerCase().includes('branch op'))
            ))
        ),
        label: 'Edit',
        value: 'edit',
      },
      {
        disabled: !!(
          (userOnHold && isHoldUser) ||
          (visualIndicator && visualIndicator.toLowerCase() === 'requested') ||
          (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
          (visualIndicator && visualIndicator.toLowerCase() === 'approved') ||
          (visualIndicator && visualIndicator.toLowerCase() === '3pl review') ||
          (parentEventStatus && parentEventStatus.toLowerCase() === 'completed') ||
          (parentEventStatus && parentEventStatus.toLowerCase() === 'cancelled') ||
          type === 'Processed' ||
          isManualIR ||
          (type === 'Failed' &&
            !(
              userInfo &&
              (userInfo.personas === 'Branch Op' ||
                userInfo.personas === '3PL Ops' ||
                userInfo.personas.toLowerCase().includes('branch op'))
            ))
        ),
        label: 'Submit',
        value: 'submit',
        disabledReason: lineItems && lineItems?.length === 0 ? 'missing_line_items' : 'nothing',
      },
      {
        disabled: !!(
          // (lineItemRejected) ||
          (
            (eventStatus && eventStatus.toLowerCase() === 'requested' && lineItemApproved) ||
            (visualIndicator && visualIndicator.toLowerCase() === 'requested') ||
            (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
            (visualIndicator && visualIndicator.toLowerCase() === 'approved') ||
            (visualIndicator && visualIndicator.toLowerCase() === '3pl review') ||
            (parentEventStatus && parentEventStatus.toLowerCase() === 'completed') ||
            (parentEventStatus && parentEventStatus.toLowerCase() === 'cancelled') ||
            type === 'Processed' ||
            isManualIR ||
            (type === 'Failed' &&
              !(
                userInfo &&
                (userInfo.personas === 'Branch Op' ||
                  userInfo.personas === '3PL Ops' ||
                  userInfo.personas.toLowerCase().includes('branch op'))
              ))
          )
        ),
        label: 'Cancel',
        value: 'cancel_request',
      },
      { label: 'Clone', value: 'clone', disabled: !isClone || isManualIR },
      {
        label: 'Make Favorite',
        value: 'make_favorite',
        disabled: false,
      },
    ];
    // dropDown.push({
    //   label: 'Discrepancy',
    //   value: 'discrepancy',
    //   disabled: false,
    // });
    return (
      <PageHeaderControl>
        <ButtonGroup
          className="slds-align-middle"
          variant="list"
          id="button-group-page-header-actions"
        >
          <Dropdown
            style={{ width: '10rem', marginLeft: '10px', borderRadius: '0.25rem' }}
            iconCategory="utility"
            iconSize="small"
            align="right"
            iconName="down"
            iconPosition="right"
            label="Actions"
            // disabled={(type === 'Failed') && !(userInfo && userInfo.personas === 'Branch Op')}
            options={dropDown}
            onSelect={(value: string): void => handleInventoryActionDropdownSelect(value)}
          />
          <DisplayRecordIndicator recordInfo={inventoryDetail} />
        </ButtonGroup>
      </PageHeaderControl>
    );
  };

  const iconPath = getEventIcon(eventType);
  const parenticonPath = getEventIconInner(parentEventType);
  const handleChangeRequest = (): void => {
    handleRequestClick(parentEventType, parentCaseExternalId, parentEventId, parentOrderid);
  };

  return (
    <IconSettings iconPath="/icons">
      {!isEventInventoryFlow && !hasEventInventory && (
        <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
          <Button
            style={{ color: 'black' }}
            iconCategory="utility"
            iconName="back"
            iconPosition="left"
            label="Back"
            onClick={handleNavigateToEventList}
          />
        </div>
      )}
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        label={eventType === INVENTORY_REQUEST ? 'Request Inventory' : ''}
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">
              {type === 'Processed' || type === 'Failed' ? caseNumber : eventId || 'TBD'}
            </span>
            <span className="slds-col slds-m-left_large">
              <div style={{ display: 'flex' }} title="Event Status">
                <EventStatusBadge
                  status={
                    visualIndicator === 'Rejected' || visualIndicator === 'Approved'
                      ? eventStatus
                      : visualIndicator
                  }
                />
                {eventStatus === 'Requested' && visualIndicator === 'Rejected' && (
                  <div style={{ marginLeft: '5px' }}>
                    <Icon
                      title="Rejected"
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ color: '#b83c27' }}
                      className="slds-icon-text-error"
                    />
                  </div>
                )}
                {thresholdQuantityIndicator && (
                  <div style={{ marginLeft: '5px' }}>
                    <img
                      className="slds-tooltip-trigger"
                      title="Threshold Exceed"
                      src={iconThreshold}
                      alt="threshold"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  </div>
                )}
              </div>
            </span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {getConfiguration('salesRep') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div className="slds-text-title" title="Sales Rep" style={{ fontWeight: 'bold' }}>
              Sales Rep
            </div>
            <div className="slds-tooltip-trigger">
              <div title={salesRep}>{salesRep}</div>
              <span />
            </div>
          </li>
        )}

        {getConfiguration('primaryAccount') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Account"
              style={{ fontWeight: 'bold' }}
            >
              Account
            </div>
            <div className="slds-truncate" title={`${accountNumber}-${accountName}`}>
              {`${accountNumber}-${accountName}`}
            </div>
          </li>
        )}
        {getConfiguration('shipTo') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Ship To"
              style={{ fontWeight: 'bold' }}
            >
              Ship To
            </div>
            <div
              title={`${shippingAddressNumber || ''} - ${shippingAddessLine1 || ''}, ${
                shippingAddessLine2 ? `${shippingAddessLine2},` : ''
              } ${shippingCity}, ${shippingState}, ${shippingPostalcode}`}
            >
              {`${shippingAddressNumber || ''} ${
                shippingAddressNumber ? '-' : ''
              } ${shippingAddessLine1 || ''}, ${
                shippingAddessLine2 ? `${shippingAddessLine2},` : ''
              } ${shippingCity}, ${shippingState}, ${shippingPostalcode}`}
            </div>
          </li>
        )}
        {getConfiguration('requestedReceivalDate') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Requested Delivery Date"
              style={{ fontWeight: 'bold' }}
            >
              Requested Delivery Date
            </div>
            <p className="slds-line-clamp">
              {needByDate
                ? moment(needByDate)
                    .utc()
                    .format('MM/DD/YYYY')
                : '--'}
            </p>
          </li>
        )}
      </ul>
      <ul
        style={{ borderBottom: 'none' }}
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
      >
        {/* <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Event Start Date"
            style={{ fontWeight: 'bold' }}
          >
            Event Start Date
          </div>
          <p className="slds-line-clamp">
            {parentEventStartDate
              ? moment(parentEventStartDate)
                  .utc()
                  .format('MM/DD/YYYY')
              : '--'}
          </p>
        </li> */}
        {getConfiguration('serviceLevel') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Service Level"
              style={{ fontWeight: 'bold' }}
            >
              Service Level
            </div>
            <div title={serviceLevel}>{serviceLevel || '--'}</div>
          </li>
        )}
        {getConfiguration('serviceLevel') && serviceLevel === 'First AM' ? (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Ship Level Justification"
            >
              Service Level Justification
            </div>
            {serviceLevelJustification ? (
              <div title="Service Level Justification">{serviceLevelJustification}</div>
            ) : (
              '--'
            )}
          </li>
        ) : (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Comment"
            >
              Ship Method
            </div>
            <div title={shipMethod}>{shipMethod || '--'}</div>
          </li>
        )}
        {type === 'Processed' && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="ERP Order Number"
              style={{ fontWeight: 'bold' }}
            >
              ERP Order Number
            </div>
            <div title={erpOrderNumber}>{erpOrderNumber}</div>
          </li>
        )}
        {type === 'Failed' && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Failure Reason"
              style={{ fontWeight: 'bold' }}
            >
              Failure Reason
            </div>
            <div title={failureReason}>{failureReason}</div>
          </li>
        )}
        {/* <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            style={{ fontWeight: 'bold' }}
            title="Comment"
          >
            Ship Method
          </div>
          <div title={shipMethod}>{shipMethod || '--'}</div>
        </li> */}
        {/* {type === 'Failed' && ( */}
        {/*  )} */}
      </ul>
      {getConfiguration('serviceLevel') && serviceLevel === 'First AM' && (
        <ul
          style={{ borderBottom: 'none' }}
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        >
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              style={{ fontWeight: 'bold' }}
              title="Comment"
            >
              Ship Method
            </div>
            <div title={shipMethod}>{shipMethod || '--'}</div>
          </li>
        </ul>
      )}
      <ul
        style={{ borderBottom: 'none' }}
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
      >
        {getConfiguration('Attachments') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Attachment"
              style={{ fontWeight: 'bold' }}
            >
              Attachments
            </div>
            {attachments?.length > 0 ? (
              attachments?.map((item: any) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(): void => handleAttachmentsView(item)}
                    aria-hidden="true"
                  >
                    {`${item.name}`}
                  </div>
                );
              })
            ) : (
              <div title="">--</div>
            )}
          </li>
        )}
        {getConfiguration('comments') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Header Comments"
              style={{ fontWeight: 'bold' }}
            >
              Header Comments
            </div>
            {sortedComments && sortedComments.length
              ? sortedComments.filter((item: { type: string }) => item?.type === 'Internal')?.[0]
                  ?.value || '--'
              : '--'}
          </li>
        )}
      </ul>
      <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none borderBottonNone">
        {getConfiguration('specificShipingDetails') && (
          <li className="slds-page-header__detail">
            <div
              className="slds-text-title slds-truncate"
              title="Specific Shipping Details"
              style={{ fontWeight: 'bold' }}
            >
              Specific Shipping Details
            </div>
            {isShippingDetails ? (
              <div style={{ fontSize: '16px' }} title="Specific Shipping Details">
                <span>
                  {` Sales Rep Name: ${
                    salesRepNameIns && salesRepNameIns !== 'null' ? salesRepNameIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Contact Phone: ${
                    salesRepPhoneIns && salesRepPhoneIns !== 'null' ? salesRepPhoneIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Email: ${
                    salesRepEmailIns && salesRepEmailIns !== 'null' ? salesRepEmailIns : ''
                  }`}
                </span>
                <span>{` | Lift Gate Needed: ${liftGateNeeded ? 'Yes' : 'No'}`}</span>
                <span>{` | Dock Available: ${dockAvailable ? 'Yes' : 'No'}`}</span>
                <span>{` | Short Truck Only: ${shortTruckOnly ? 'Yes' : 'No'}`}</span>
                <span>{` | Delivery Contact Name: ${deliveryContractName || ''}`}</span>
                <span>{` | Delivery Contact Phone: ${deliveryContractPhone || ''}`}</span>
                {deliveryContractEmail !== `` && (
                  <span>{` | Delivery Contact Email: ${deliveryContractEmail || ''}`}</span>
                )}
                {deliveryContractInstructions !== `` && (
                  <span>{` | Delivery Instructions: ${deliveryContractInstructions || ''}`}</span>
                )}
              </div>
            ) : (
              <div style={{ fontSize: '16px' }} title="Specific Shipping Details">
                <span>
                  {` Sales Rep Name: ${
                    salesRepNameIns && salesRepNameIns !== 'null' ? salesRepNameIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Contact Phone: ${
                    salesRepPhoneIns && salesRepPhoneIns !== 'null' ? salesRepPhoneIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Email: ${
                    salesRepEmailIns && salesRepEmailIns !== 'null' ? salesRepEmailIns : ''
                  }`}
                </span>
              </div>
            )}
          </li>
        )}
      </ul>
      <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none">
        <li className="slds-page-header__detail">
          <div
            className="slds-text-title slds-truncate"
            title="Event Id"
            style={{ fontWeight: 'bold' }}
          >
            Related Documents
          </div>
          {parentEventId ? (
            <div title={parentEventId}>
              <span>
                {parentEventType.indexOf('Bill') === -1 && (
                  <img src={parenticonPath} alt="company" />
                )}
              </span>
              <span
                role="button"
                tabIndex={0}
                className="slds-button"
                onClick={handleChangeRequest}
                onKeyDown={handleChangeRequest}
                style={{ cursor: 'pointer' }}
              >
                {parentEventId}
              </span>
              <span> &nbsp; </span>
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventSubType
                  ? parentEventSubType
                  : parentEventType.indexOf('Bill') !== -1
                  ? 'Bill Only'
                  : parentEventType}

                {`,`}
              </span>
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventType.indexOf('Bill') !== -1 && createdDate
                  ? moment(createdDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : parentEventStartDate
                  ? moment(parentEventStartDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : '--'}
              </span>
            </div>
          ) : (
            '--'
          )}
        </li>
      </ul>
    </IconSettings>
  );
};

/** Custom Header on Inventory Request Detail Header  Page **/
export default InventoryRequestDetailHeader;
