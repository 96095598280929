/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*
 * Module for header on Surgical Detail Page
 * @module src/SurgicalDetail/SurgicalDetailHeader
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import CartComponent from '../InventoryRequestHeader/inventoryCart';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import SnackBar from '../../../../Shared/SnackBar';
import { MAX_QUANTITY, NOTIFY_TYPE } from '../../../../../util/constants';
import { GET_TRANSFER_DETAILS } from '../../../../../graphql/getInventoryTransferDetails';
import { UPDATE_TRANSFER_INVENTORY } from '../../../../../graphql/mutations/updateTransferInventory';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../../graphql/getSubInventoryWithLotDetails';

import './index.scss';

interface Props extends RouteComponentProps {
  isOpen?: boolean;
  handleClose: () => void;
  inventoryDetail?: any;
  InventoryTotalcount?: any;
  handleSelectedLineItems: (item: any, count: number) => void;
  refetchTransferDetails: () => void;
  selectedItemsValue?: any;
  history: any;
}

interface Item {
  hospital?: string;
  id: string;
  owned?: string;
  value: string;
  subInventoryCode?: string;
}

interface CommentItem {
  id?: string;
  sfid?: string;
  createdDate?: string;
  userName?: string;
  comment: string;
  postTime: string;
  commentType: string;
  commentExternalId?: string;
  userId: string | null;
}

interface LineItem {
  id: string;
  externalId: string;
  partNumber?: string;
  lotNumber: string;
  productId: string;
  quantity: string;
  priceBookEntryId: string;
  unitPrice: string;
  status: string;
  approvalStatus: string;
  salesUnitOfMeasure: string;
  productNumber: string;
  isLotNumber: boolean;
  subInventoryCode: string;
}

interface DetailItem {
  accountId: string;
  accountName: string;
  accountNumber: string;
  comments: CommentItem[];
  createdDate: string | null;
  effectiveDate: string;
  externalId: string;
  fromSubInventoryCode: string;
  id: string | null;
  lineItems: LineItem[];
  orderNumber: string | null;
  priceBook2Id: string;
  receivingRepId: string;
  receivingRepName: string;
  recordTypeId: string;
  salesRepId: string;
  salesRepName: string;
  status: string;
  toSubInventoryCode: string;
  type: string;
}

export interface ItemPrivate {
  salesUnitOfMeasure: string;
  approvalStatus?: string;
  priceBookEntryId?: string;
  unitPrice?: string;
  externalId?: string;
  id?: string;
  quantity: string;
  availableqty: number | null | undefined;
  expirationDate: string;
  lotNumber: string;
  onhandqty: number | null;
  productDescription: string | null;
  productId: string;
  productNumber: string | null;
  subInventoryName: string | null;
}

export type ItemDetails = ItemPrivate;

const TransferEditLineItems: FC<Props> = ({
  isOpen,
  handleClose,
  inventoryDetail,
  InventoryTotalcount,
  handleSelectedLineItems,
  refetchTransferDetails,
  selectedItemsValue,
}) => {
  const [count, setCount] = useState(InventoryTotalcount);
  const [updateTransfer] = useMutation(UPDATE_TRANSFER_INVENTORY);
  const { open, notification, openSnackBar } = useSnackBar();
  const [inventoryList, setInventoryList] = useState<any>([]);
  const [salesRep, setSalesRep] = useState<Item | null>();
  const [recSalesRep, setRecSalesRep] = useState<Item | null>();
  // const [pickedItems, setPickedItems] = useState<ItemPrivate[]>([]);
  const [pickedItems, setPickedItems] = useState<ItemPrivate[]>([]);
  const [transferDetails, setTransferDetails] = useState<DetailItem>();

  const [getInvetoryListItems, { data: inventoryItems }] = useLazyQuery(
    GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS
  );

  useEffect(() => {
    if (inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length > 0) {
      let totalCount = 0;
      inventoryDetail.lineItems.forEach((list: any) => {
        /* eslint-disable no-param-reassign */
        list.maxQuantity = list.lotNumber
          ? Number(list.availableQuantity) + Number(list.quantity)
          : MAX_QUANTITY;
        totalCount += Number(list.quantity);
      });
      setInventoryList(inventoryDetail.lineItems);
      setCount(totalCount);
    }
  }, [inventoryItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const [getTransferDetails, { data: transferDetail, loading }] = useLazyQuery(
    GET_TRANSFER_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  useEffect(() => {
    if (!inventoryDetail && !inventoryDetail?.externalId) {
      return;
    }
    // getSalesRepsItems();
    getTransferDetails({
      variables: {
        id: inventoryDetail?.externalId,
      },
    });
  }, [inventoryDetail, getTransferDetails]);

  useEffect(() => {
    const getInventoryTransferDetails =
      transferDetail && transferDetail?.getInventoryTransferDetails;
    if (getInventoryTransferDetails) {
      setTransferDetails(transferDetail.getInventoryTransferDetails);
      setSalesRep({
        id: getInventoryTransferDetails.salesRepId,
        value: getInventoryTransferDetails.salesRepName,
      });
      setRecSalesRep({
        id: getInventoryTransferDetails.receivingRepId,
        value: getInventoryTransferDetails.receivingRepName,
      });
      const lineItems = getInventoryTransferDetails?.lineItems || [];
      setPickedItems(lineItems);
      // setInventoryCount(inventoryCountHanlde(lineItems));
    }
    // setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferDetail]);

  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...inventoryList];
    updatedList.forEach((element: any) => {
      if (product.lotNumber === element.lotNumber) {
        /* eslint-disable no-param-reassign */
        element.quantity =
          data.number < element.maxQuantity ? String(data.number) : element.maxQuantity;
        element.deleted = !!(event === 'delete');
        // setCount(count + 1);
      }
    });
    setInventoryList(updatedList);
    let totalCount = 0;
    // eslint-disable-next-line no-return-assign
    updatedList.filter((list: any) => (totalCount += Number(list.quantity)));
    setCount(totalCount);
  };

  const handleSaveCall = (selectedLineItemInventoryList: any): void => {
    const updatedPickedItems = selectedLineItemInventoryList.map(
      (element: any): LineItem => {
        return {
          id: element?.id || '',
          externalId: element.externalId || '',
          lotNumber: element.lotNumber || '',
          productId: element.productId || '',
          quantity: String(element.counterValue) || '',
          priceBookEntryId: element.priceBookEntryId || '',
          unitPrice: element?.unitPrice || '',
          status: element.status,
          approvalStatus: element.approvalStatus || '',
          subInventoryCode: element?.subInventoryCode || '',
          salesUnitOfMeasure: element.salesUnitOfMeasure || '',
          productNumber: element.productNumber,
          isLotNumber: element.item.isLotNumber,
        };
      }
    );
    const updatedDetails = {
      orderId: inventoryDetail.id || '', // null
      orderExternalId: inventoryDetail.externalId || '',
      accountId: inventoryDetail.accountId || '',
      requestedFrom: 'SalesRep',
      salesRepId: inventoryDetail?.salesRepId,
      receivingRepId: inventoryDetail?.receivingRepId,
      salesOrganization: inventoryDetail?.salesOrganization || 'F48',
      fromSubInventoryCode: inventoryDetail.fromSubInventoryCode || '',
      toSubInventoryCode: inventoryDetail.toSubInventoryCode || '',
      status: inventoryDetail.status,
      lineItems: updatedPickedItems || [],
    };
    updateTransfer({
      variables: updatedDetails,
    })
      .then(response => {
        if (
          response &&
          response?.data &&
          response?.data?.updateTransferInventory &&
          response?.data?.updateTransferInventory?.message &&
          response?.data?.updateTransferInventory?.message === 'success'
        ) {
          handleClose();
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
          setTimeout(() => {
            refetchTransferDetails();
          }, 500);
        } else {
          console.log('We had a trouble saving data to salesforce. Please try again later');
        }
      })
      .catch(error => {
        setTimeout(() => {
          if (error && error.message) {
            //    setLoader(false);
            openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
          } else {
            openSnackBar(
              NOTIFY_TYPE.ERROR,
              'Error: We had a trouble saving data to salesforce. Please try again later'
            );
          }
        }, 1000);
      });
  };

  const onAddClick = (): void => {
    const selctedItems: Array<any> = [];
    inventoryList
      .filter((list: any) => list.quantity)
      .forEach((product: any): void => {
        if (product.quantity) {
          selctedItems.push({
            id: product.id,
            externalId: product.externalId,
            counterValue: Number(product.quantity),
            expirationDate: product.expirationDate,
            lotNumber: product.lotNumber,
            priceBookEntryId: product.priceBookEntryId,
            productDescription: product.productDescription,
            productId: product.productId,
            subInventoryCode: product?.subInventoryCode || '',
            productNumber: product.productNumber,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            subInventoryName: product.subInventoryName,
            status: product.status,
            unitPrice: product.unitPrice,
            approvalStatus: product.approvalStatus,
          });
        }
      });
    setInventoryList(selctedItems);
    selectedItemsValue = selctedItems;
    handleSelectedLineItems(selctedItems, count);
    handleSaveCall(selctedItems);
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        <Modal
          isOpen={isOpen}
          footer={[
            <Button label="Cancel" onClick={handleClose} />,
            <Button label="Update" variant="brand" onClick={onAddClick} />,
          ]}
          onRequestClose={handleClose}
        >
          <div style={{ display: 'grid', padding: '0.75rem', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Edit Parts</div>
            <div style={{ textAlign: 'end' }}>
              <CartComponent count={count} color="black" textColor="white" />
            </div>
          </div>
          <div style={{ padding: '0rem 0.75rem 0rem 0.75rem' }}>
            <div>
              <section className="slds-p-around_large-1">
                {inventoryList &&
                  inventoryList.map((product: any) =>
                    !product.deleted ? (
                      <div
                        style={{
                          padding: '5px',
                          borderBottom: '1px solid #545857',
                          height: '3rem',
                          display: 'grid',
                          gridTemplateColumns: '4fr 1.25fr 0.25fr',
                        }}
                      >
                        <div style={{ marginTop: '5px' }}>
                          {`${product.productNumber} ${product.productDescription}`}
                        </div>
                        <div>
                          <Input
                            id="counter-input-3"
                            minValue={0}
                            maxValue={Number(product.maxQuantity)}
                            onChange={(
                              event: any,
                              data: { value: string; number: number }
                            ): void => {
                              handleCount(event, data, product);
                            }}
                            value={Number(product.quantity)}
                            variant="counter"
                            disabled={
                              product.approvalStatus === 'Approved' ||
                              product.approvalStatus === 'Requested'
                            }
                          />
                        </div>
                        <div style={{ margin: '5px' }}>
                          <Button
                            iconCategory="utility"
                            iconName="delete"
                            iconSize="large"
                            iconVariant="bare"
                            variant="icon"
                            onClick={(): void =>
                              handleCount('delete', { value: '0', number: 0 }, product)
                            }
                            disabled={
                              product.approvalStatus === 'Approved' ||
                              product.approvalStatus === 'Requested'
                            }
                          />
                        </div>
                      </div>
                    ) : null
                  )}
              </section>
            </div>
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Custom Header on Inventory Add Items Page **/
export default withRouter(TransferEditLineItems);
