/**
 * Graphql mutation to create event
 * @module src/graphql/createEvent
 */
import gql from 'graphql-tag';

/** creating event */

interface Comment {
  comment: string;
  postTime: string;
  commentType: string;
}
const CREATE_EVENT = gql`
  mutation createEvent(
    $division: String
    $branchId: String!
    $accountId: String!
    $eventType: String!
    $procedureTypeIds: String
    $procedureDetailIds: [String]
    $procedureDetailNames: String
    $navigation: Boolean
    $robotics: Boolean
    $revision: Boolean
    $salesRepId: String!
    $surgeonId: String
    $eventSubType: String
    $eventName: String
    $eventAddress: String
    $coveringRepIds: [String]
    $coveringRepNames: [String]
    $eventDateTime: String
    $eventStartDateTime: String
    $eventEndDateTime: String
    $shipToId: String
    $billToId: String
    $deliveryService: String
    $customerPo: String
    $organizedBy: String
    $numberOfParticipants: String
    $trailContractSigned: Boolean
    $eventShippingLocation: String
    $status: String!
    $specialInstruction: String
    $eventDuration: String
    $shipToAddress: String
    $eventDescription: String
    $locationDescription: String
    $street1: String
    $street2: String
    $city: String
    $state: String
    $zipCode: String
    $opportunity: String
    $comments: [Comment]
  ) {
    createEvent(
      division: $division
      branchId: $branchId
      accountId: $accountId
      surgeonId: $surgeonId
      eventType: $eventType
      eventSubType: $eventSubType
      eventName: $eventName
      eventAddress: $eventAddress
      procedureTypeIds: $procedureTypeIds
      procedureDetailIds: $procedureDetailIds
      procedureDetailNames: $procedureDetailNames
      navigation: $navigation
      robotics: $robotics
      revision: $revision
      salesRepId: $salesRepId
      coveringRepIds: $coveringRepIds
      coveringRepNames: $coveringRepNames
      eventDateTime: $eventDateTime
      eventStartDateTime: $eventStartDateTime
      eventEndDateTime: $eventEndDateTime
      shipToId: $shipToId
      billToId: $billToId
      deliveryService: $deliveryService
      customerPo: $customerPo
      organizedBy: $organizedBy
      numberOfParticipants: $numberOfParticipants
      trailContractSigned: $trailContractSigned
      eventShippingLocation: $eventShippingLocation
      status: $status
      specialInstruction: $specialInstruction
      eventDuration: $eventDuration
      shipToAddress: $shipToAddress
      eventDescription: $eventDescription
      locationDescription: $locationDescription
      street1: $street1
      street2: $street2
      city: $city
      state: $state
      zipCode: $zipCode
      opportunity: $opportunity
      comments: $comments
    ) {
      message
      externalId
      sfid
    }
  }
`;

export default CREATE_EVENT;
