/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
// import Input from '@salesforce/design-system-react/components/input';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CartComponent from './CartComponent';
import './index.scss';
import AddPartsModal from './AddPartsModal';
// import LineItems from './LineItems';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import PartsTabularDetails from './PartsTabularDetails';
import { GET_USER_TERRITORIES } from '../../../../../graphql/getUserTerritories';
import { GET_PARTS_WITH_LOT_DETAILS } from '../../../../../graphql/getPartWithLotDetails';
import { GetActualQty, getDateDifferenceInDays } from '../../../../../util/utilityFunctions';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import { MAX_QUANTITY, USAGE_TYPE, NOTIFY_TYPE } from '../../../../../util/constants';
import RESUBMIT_ORDER_DETAILS from '../../../../../graphql/mutations/resubmitOrderDetails';
// import EventStatusBadge from '../../../Shared/EventStatusBadge';
import SnackBar from '../../../../Shared/SnackBar';
import FilterTagsForModal from '../../../../Shared/FilterTagsForModal';
import { getUserInfo } from '../../../../../store/ducks/userInfo';

interface Props {
  usageDetails?: any;
  viewOnly: boolean;
  isAssignUser: boolean;
  refetch: () => void;
  count: number;
  isERPOrder: boolean;
  isERPOrderFailed: boolean;
  updatedPrice: any;
  orderDetails: any;
  partsDetailsConfig?: any;
  type: string;
  screenLoading?: boolean;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const UsageParts: FC<Props> = ({
  usageDetails,
  refetch,
  count,
  viewOnly,
  isAssignUser,
  isERPOrder,
  isERPOrderFailed,
  updatedPrice,
  orderDetails,
  partsDetailsConfig,
  screenLoading,
  type,
}) => {
  const userInfo = useSelector(getUserInfo);
  const [items, setItems] = useState<any[]>([]);
  const [isLoaded, setLoading] = useState(false);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const [isDelete, setIsDelete] = useState({ isDelete: false, payloadLength: 0 });
  const [getPartsLotDetails, { data: partsLotDetails, loading: partsLoader }] = useLazyQuery(
    GET_PARTS_WITH_LOT_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [loader, setLoader] = useState(true);
  // const [searchHeader, setSearchHeader] = useState('Catalog');
  const [createUpdateUsageLineItems, { loading }] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);
  const [resubmitOrderLineItems] = useMutation(RESUBMIT_ORDER_DETAILS);
  // const [sortedLineItems, setSortedLineItems] = useState([]);
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [territory, setTerritory] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [openSaveConfirmModal, setOpenSaveConfirmModal] = useState(false);
  const [
    getUserTerritoriesItems,
    { data: userTerritoriesItems },
  ] = useLazyQuery(GET_USER_TERRITORIES, { fetchPolicy: 'no-cache' });
  let enabledPartsTabs: any[] = [];
  if (partsDetailsConfig) {
    partsDetailsConfig.forEach((item: any) => {
      if (item.isShow === true) {
        enabledPartsTabs = [...enabledPartsTabs, item.mappingKey];
      }
    });
  }

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    const duplicateLots: any[] = [];
    array.forEach((item: any) => {
      const records = array.filter((x: any) => x?.lotNumber === item?.lotNumber);
      if (records?.length === 1) {
        newArray.push(item);
      } else if (records?.length > 1 && duplicateLots.indexOf(item?.lotNumber) === -1) {
        const subInventoryTypes = ['IT', 'IH', 'E'];
        for (const type of subInventoryTypes) {
          const foundInventory = records.find(
            (ele: any) =>
              ele?.caseUsageSubInventoryCode?.startsWith(type) ||
              ele?.subInventoryCode?.startsWith(type)
          );
          if (foundInventory && Number(foundInventory.availableqty) > 0) {
            newArray.push(foundInventory);
            duplicateLots.push(item.lotNumber);
            break;
          }
        }
      }
      return null;
    });

    return newArray;
  };

  useEffect(() => {
    if (usageDetails.salesRepId) {
      getUserTerritoriesItems({
        variables: {
          userId: usageDetails.salesRepId,
        },
      });
    }
  }, [getUserTerritoriesItems, usageDetails]);

  useEffect(() => {
    if (userTerritoriesItems?.getUserTerritories) {
      const userTerritoriesItemsList = userTerritoriesItems?.getUserTerritories;
      if (userTerritoriesItemsList.length > 0) {
        setTerritory(userTerritoriesItemsList[0].value);
      }
    }
  }, [userTerritoriesItems]);

  useEffect(() => {
    const { lineItems } = usageDetails;
    // set the unique id
    const itemsWithUnqId = lineItems.map((i: any) => {
      const maxQuantity = i.lotNumber
        ? Number(i.availableQuantity) + Number(i.quantity)
        : MAX_QUANTITY;
      return {
        ...i,
        uniqueId: isERPOrder ? i.caseUsageExternalId || i.sfid || i.externalId : i.externalId,
        originalQty: i.quantity,
        maxQuantity,
      };
    });
    setItems(itemsWithUnqId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isERPOrder, refetch]);
  useEffect(() => {
    const { lineItems } = usageDetails;
    if (lineItems && lineItems.length > 0 && (territory || usageDetails?.accountNumber)) {
      getPartsLotDetails({
        variables: {
          productNumber: lineItems.map((i: any) => {
            return { productNumber: i.productNumber, sourceLocation: i.sourceLocation };
          }),
          subInventoryName: territory,
          accountNumber: usageDetails?.accountNumber,
          salesRepId: usageDetails?.salesRepId,
        },
      });
    }

    if (isDelete.isDelete) {
      if (usageDetails?.lineItems?.length < isDelete.payloadLength) {
        setTimeout(() => {
          refetch();
        }, 4000);
        setIsDelete({ isDelete: false, payloadLength: 0 });
      }
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageDetails, isDelete.isDelete, isDelete.payloadLength]);

  useEffect(() => {
    if (partsLotDetails?.getPartWithLotDetails) {
      const lotDetails = partsLotDetails.getPartWithLotDetails;
      const lotOptions: any = [];
      lotDetails.forEach((item: any) => {
        let dDiff = 60;
        dDiff = getDateDifferenceInDays(item.expirationDate, new Date().toString());

        const data = {
          ...item,
          label: item.lotNumber,
          value: item.lotNumber,
          expiresInDays: item.expirationDate ? dDiff : 60,
        };
        if (item.expirationDate && Number(item.availableqty) > 0) {
          const expirationDate = new Date(moment(item.expirationDate).format('MM/DD/YYYY'));
          const currentDate = new Date(moment().format('MM/DD/YYYY'));
          let surgeryDate;
          if (usageDetails?.eventDateTime) {
            surgeryDate = new Date(moment(usageDetails?.eventDateTime).format('MM/DD/YYYY'));
          }
          if (expirationDate >= currentDate) {
            lotOptions.push(data);
          } else if (expirationDate < currentDate && surgeryDate && expirationDate >= surgeryDate) {
            lotOptions.push(data);
          }
        } else if (Number(item.availableqty) > 0) {
          lotOptions.push(data);
        }
      });
      const lineItems = items.map((i: any) => {
        const isLotExist = lotOptions?.find(
          (element: any) =>
            (element.lotNumber === i.lotNumber && Number(i.quantity) > 0 && !i.iscatalog) ||
            (usageDetails?.status === 'Failed' &&
              element.lotNumber === i.lotNumber &&
              Number(i.quantity) > 0)
        );

        const alreadyAddedLot = lotDetails?.find(
          (element: any) =>
            (element.lotNumber === i.lotNumber && Number(i.quantity) > 0 && !i.iscatalog) ||
            (usageDetails?.status === 'Failed' &&
              element.lotNumber === i.lotNumber &&
              Number(i.quantity) > 0)
        );
        if (
          (alreadyAddedLot && !isLotExist && !i.iscatalog) ||
          (usageDetails?.status === 'Failed' && alreadyAddedLot && !isLotExist)
        ) {
          const dDiff = getDateDifferenceInDays(
            alreadyAddedLot.expirationDate,
            new Date().toString()
          );

          const data = {
            ...alreadyAddedLot,
            label: alreadyAddedLot.lotNumber,
            value: alreadyAddedLot.lotNumber,
            expiresInDays: alreadyAddedLot.expirationDate ? dDiff : 60,
          };
          lotOptions.push(data);
        } else if (
          (!alreadyAddedLot && i.lotNumber && i.isValidLotSerialCombination && !i.iscatalog) ||
          (!alreadyAddedLot &&
            i.lotNumber &&
            i.isValidLotSerialCombination &&
            usageDetails?.status === 'Failed')
        ) {
          lotOptions.push({
            availableqty: i.availableQuantity,
            expirationDate: i.expirationDate,
            lotNumber: i.lotNumber,
            onhandqty: i.onhandQuantity,
            productDescription: i.productdescription,
            productNumber: i.productNumber,
            sourceLocation: i.sourceLocation,
            label: i.lotNumber,
            value: i.lotNumber,
            caseUsageSubInventoryCode: i.caseUsageSubInventoryCode,
            subInventoryCode: i.caseUsageSubInventoryCode,
          });
        }
        const lotValues = removeDuplicates(
          !i.iscatalog || usageDetails?.status === 'Failed'
            ? lotOptions.filter(
                (item: any) =>
                  item?.productNumber === i?.productNumber &&
                  item?.sourceLocation === i.sourceLocation
              )
            : lotOptions.filter(
                (item: any) =>
                  item?.productNumber === i?.productNumber &&
                  Number(item.availableqty) > 0 &&
                  item?.sourceLocation === i.sourceLocation
              )
        );

        return {
          ...i,
          lotOptions: lotValues,
          selectedLot: lotValues.filter((item: any) => item.lotNumber === i.lotNumber),
        };
      });
      setItems(lineItems);
      // setTimeout(() => {
      //   setLoader(false);
      // }, 4000);
      if (loading) {
        setLoader(true);
      } else {
        setLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsLotDetails, refetch]);

  useEffect(() => {
    if (updatedPrice?.getUpdatedResolvedPrice) {
      const lineItms = items.map((i: any) => {
        const priceItem = updatedPrice.getUpdatedResolvedPrice.find(
          (price: any) => price.productNumber === i.productNumber
        );

        const actualqty = GetActualQty(
          usageDetails.diplayOrderType,
          priceItem?.sellingUOM,
          i.salesUnitOfMeasure,
          i.packContent,
          i.quantity
        );

        return {
          ...i,
          sellingUoM: priceItem?.sellingUOM,
          actualQuantity: actualqty,
          // allowOverride,
          // priceOverride: !viewOnly && !allowOverride ? priceItem?.resolvedPrice : i.priceOverride,
          // usgPrice: !viewOnly && !allowOverride ? priceItem?.resolvedPrice : i.usgPrice,
        };
      });
      setItems(lineItms || []);
      // setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPrice]);
  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
  };
  const saveErpOrderLineItems = (lines?: any): void => {
    setLoading(true);
    const payload: Array<any> = [];
    // const updatedItems = lines?.length > 0 ? lines : items;
    const updatedItems = [...items];
    updatedItems.forEach((product: any, index: number): void => {
      let previousLotNumber: any = null;
      let lotNumber =
        (product?.selectedLot && product?.selectedLot[0]?.lotNumber) || product.lotNumber;

      const caseUsageSubInventoryCode =
        product?.selectedLot && product?.selectedLot[0]?.subInventoryCode
          ? product.selectedLot[0]?.subInventoryCode
          : product?.caseUsageSubInventoryCode;

      if (product.selectedLot && product.selectedLot[0]?.lotNumber && !product.iscatalog) {
        if (product.selectedLot[0]?.lotNumber !== product.lotNumber) {
          previousLotNumber = product.lotNumber;
          product.caseUsageSubInventoryCode = product.selectedLot[0]?.subInventoryCode;
        }
      }

      let sourceLocation = '';
      if (typeof product.sourceLocation === 'string') {
        sourceLocation = product.sourceLocation;
      } else if (product.sourceLocation?.length > 0) {
        sourceLocation = product.sourceLocation[0].value;
      }

      if (product.iscatalog) {
        previousLotNumber = product.previousLotNumber;
        lotNumber = lotNumber || product.lotNumber;
      }

      payload.push({
        sfid: product.sfid,
        externalId: product?.caseUsageExternalId || product.externalId,
        productId: product.productId,
        isLotNumber: product.isLotNumber,
        productNumber: product.productNumber,
        quantity: product.quantity.toString(),
        wasted: product.wasted || false,
        lotNumber,
        previousLotNumber,
        deleted: product.quantity === '' ? true : null,
        serialNumber: (index + 1).toString(),
        caseUsageId: product.caseUsageId,
        caseUsageExternalId: product.caseUsageExternalId,
        priceBookEntryId: product.priceBookEntryId,
        salesUnitOfMeasure: product.salesUnitOfMeasure,
        deliveredDate: product.deliveredDate,
        productFamily: product.productFamily,
        isLotValueUpdated: product?.isLotValueUpdated ? product?.isLotValueUpdated : false,
        actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2) || '',
        usgTotalAmount: parseFloat((product.usgPrice * product.actualQuantity).toString()).toFixed(
          2
        ),
        sourceLocation,
        subInventoryCode: caseUsageSubInventoryCode || '',
      });
    });
    const mutation = {
      id: usageDetails.id,
      salesRepId: usageDetails?.salesRepId,
      accountId: usageDetails?.accountId,
      submitType: 'Update',
      requestedFrom:
        usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'BillOnly' : 'ShipAndBill',
      lineItems: payload,
    };
    resubmitOrderLineItems({
      variables: mutation,
    }).then(() => {
      // setLoader(false);
      refetch();
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveOrderRequestLineItems = (lines?: any): void => {
    const payload: Array<any> = [];
    const deletePayload: Array<any> = [];

    const lineItems = lines?.length > 0 ? lines : items;
    lineItems.forEach((product: any, index: number): void => {
      let previousLotNumber = null;
      let lotNumber =
        (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber;
      if (!product.quantity) {
        setIsDelete({ isDelete: true, payloadLength: lineItems.length });
      }
      if (product.selectedLot && product.selectedLot[0]?.lotNumber && !product.iscatalog) {
        if (product.selectedLot[0]?.lotNumber !== product.lotNumber) {
          previousLotNumber = product.lotNumber;
        }
      }
      if (product.iscatalog) {
        previousLotNumber = product.previousLotNumber;
        lotNumber = product.lotNumber;
      }

      if (product?.deleted) {
        deletePayload.push({
          sfid: product.sfid,
          externalId: product.externalId,
          productId: product.productId,
          isLotNumber: product.isLotNumber,
          productNumber: product.productNumber,
          quantity: product.quantity.toString(),
          iscatalog: product.iscatalog,
          isLotValueUpdated: product?.isLotValueUpdated || false,
          isValidLotSerialCombination: product.isValidLotSerialCombination,
          wasted: product.wasted || false,
          sourceLocation: product.sourceLocation
            ? typeof product.sourceLocation === 'string'
              ? product.sourceLocation
              : product?.sourceLocation[0]?.value
            : null,
          lotNumber,
          previousLotNumber,
          subInventoryCode: product?.caseUsageSubInventoryCode || '',
          serialNumber: (index + 1).toString(),
          productFamily: product.productFamily,
          actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2) || '',
          usgTotalAmount: parseFloat(
            (product.usgPrice * product.actualQuantity).toString()
          ).toFixed(2),
          priceOverride: product.priceOverride ? product.priceOverride : null,
          deleted: product?.deleted || false,
        });
      }
      payload.push({
        sfid: product.sfid,
        externalId: product.externalId,
        productId: product.productId,
        isLotNumber: product.isLotNumber,
        productNumber: product.productNumber,
        quantity: product.quantity.toString(),
        wasted: product.wasted || false,
        iscatalog: product.iscatalog,
        isLotValueUpdated: product?.isLotValueUpdated || false,
        isValidLotSerialCombination: product.isValidLotSerialCombination,
        sourceLocation: product.sourceLocation
          ? typeof product.sourceLocation === 'string'
            ? product.sourceLocation
            : product?.sourceLocation[0]?.value
          : null,
        lotNumber,
        previousLotNumber,
        subInventoryCode: product?.caseUsageSubInventoryCode || '',
        serialNumber: (index + 1).toString(),
        productFamily: product.productFamily,
        actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2) || '',
        usgTotalAmount: parseFloat((product.usgPrice * product.actualQuantity).toString()).toFixed(
          2
        ),
        priceOverride: product.priceOverride ? product.priceOverride : null,
        deleted: product?.deleted || false,
      });
    });

    if (deletePayload?.length > 0 && payload.length > 0) {
      const product = lineItems?.find((item: any) => item.isLotValueUpdated);
      if (product) {
        let previousLotNumber = null;
        let lotNumber =
          (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber;
        if (!product.quantity) {
          setIsDelete({ isDelete: true, payloadLength: lineItems.length });
        }
        if (product.selectedLot && product.selectedLot[0]?.lotNumber && !product.iscatalog) {
          if (product.selectedLot[0]?.lotNumber !== product.lotNumber) {
            previousLotNumber = product.lotNumber;
          }
        }
        if (product.iscatalog) {
          previousLotNumber = product.previousLotNumber;
          lotNumber = product.lotNumber;
        }

        deletePayload.push({
          sfid: product.sfid,
          externalId: product.externalId,
          productId: product.productId,
          isLotNumber: product.isLotNumber,
          productNumber: product.productNumber,
          quantity: product.quantity.toString(),
          iscatalog: product.iscatalog,
          isLotValueUpdated: product?.isLotValueUpdated || false,
          isValidLotSerialCombination: product.isValidLotSerialCombination,
          wasted: product.wasted || false,
          sourceLocation: product.sourceLocation
            ? typeof product.sourceLocation === 'string'
              ? product.sourceLocation
              : product?.sourceLocation[0]?.value
            : null,
          subInventoryCode: product?.caseUsageSubInventoryCode || '',
          lotNumber,
          previousLotNumber,
          serialNumber: (deletePayload.length + 1).toString(),
          productFamily: product.productFamily,
          actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2) || '',
          usgTotalAmount: parseFloat(
            (product.usgPrice * product.actualQuantity).toString()
          ).toFixed(2),
          priceOverride: product.priceOverride ? product.priceOverride : null,
          deleted: product?.deleted || false,
        });
      }
    }

    const mutation = {
      caseId: usageDetails.caseId,
      caseExternalId: usageDetails.caseExternalId,
      salesRepId: usageDetails.salesRepId,
      formType: 'Update',
      lineItems: deletePayload && deletePayload.length > 0 ? deletePayload : payload,
    };

    createUpdateUsageLineItems({
      variables: mutation,
    })
      // .then(() => {
      //   setTimeout(() => {
      //     refetch();
      //   }, 3000);
      // })
      .then((res: any) => {
        const data = res?.data?.createUpdateUsageSheetLineItems;
        if (data?.isMaxQuantityExceeded) {
          setTimeout(() => {
            openSnackBar(
              NOTIFY_TYPE.WARNING,
              'Quantity Exceeds available inventory. Please add additional lines from catalog with the quantity difference.'
            );
          }, 1500);
        }

        refetch();
        setLoader(false);
      })
      .catch(res => {
        setLoader(false);
        if (res && res.message) {
          openSnackBar(NOTIFY_TYPE.ERROR, res.message);
          setLoader(false);
        } else {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      });
  };

  useEffect(() => {
    if (
      !isLoaded &&
      items?.length > 0 &&
      usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
      type !== 'Failed' &&
      type !== 'Processed' &&
      partsLotDetails?.getPartWithLotDetails?.length > 0
    ) {
      let isLotValueUpdated = false;
      items.forEach((i: any) => {
        const product: any = items?.find(
          (item: any) =>
            item.lotNumber === i.lotNumber &&
            item.productNumber === i.productNumber &&
            item.externalId !== i.externalId &&
            item.iscatalog &&
            item.sourceLocation === i.sourceLocation &&
            partsLotDetails?.getPartWithLotDetails?.length > 0
        );

        if (
          product &&
          i.isValidLotSerialCombination &&
          Number(i.availableQuantity) > 0 &&
          !product.isValidLotSerialCombination
        ) {
          product.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
          product.isLotValueUpdated = true;
          isLotValueUpdated = true;
        } else {
          const lotOptions = partsLotDetails?.getPartWithLotDetails;
          const availableLots: any = lotOptions.filter(
            (item: any) =>
              item.lotNumber === i.lotNumber &&
              !i.isValidLotSerialCombination &&
              item.sourceLocation === i.sourceLocation &&
              i.iscatalog &&
              Number(item.availableqty) > 0 &&
              item.productNumber === i.productNumber
          );

          if (
            (!product &&
              i.iscatalog &&
              !i.isValidLotSerialCombination &&
              Number(i.availableQuantity) > 0) ||
            availableLots?.length > 0
          ) {
            // eslint-disable-next-line no-param-reassign
            i.isLotValueUpdated = true;

            let subInventoryCode = '';
            if (availableLots?.length > 1) {
              const subInventoryTypes = ['IT', 'IH', 'E'];
              for (const type of subInventoryTypes) {
                const foundInventory = availableLots.find(
                  (ele: any) =>
                    ele?.caseUsageSubInventoryCode?.startsWith(type) ||
                    ele?.subInventoryCode?.startsWith(type)
                );
                if (foundInventory) {
                  subInventoryCode = foundInventory.caseUsageSubInventoryCode;
                  break;
                }
              }
            } else if (availableLots?.length === 1) {
              subInventoryCode =
                availableLots[0].subInventoryCode || availableLots[0].caseUsageSubInventoryCode;
            }
            i.caseUsageSubInventoryCode = subInventoryCode;
            isLotValueUpdated = true;
          }
        }
      });

      if (isLotValueUpdated) {
        if (orderDetails?.status === 'Failed') {
          saveErpOrderLineItems(items);
          // saveErpOrderLineItems();
        } else {
          saveOrderRequestLineItems(items);
        }
      }
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, items, saveOrderRequestLineItems, partsLotDetails]);

  const saveParts = (): void => {
    setOpenSaveConfirmModal(false);
    setLoader(true);
    if (isERPOrder) {
      saveErpOrderLineItems();
    } else {
      saveOrderRequestLineItems();
    }
  };
  useEffect(() => {
    if (valuesUpdated) {
      setLoader(true);
      saveParts();
      setValuesUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesUpdated]);
  const handleSave = (): void => {
    const isAnyQtyZero = items.some((i: any) => !i.deleted && Number(i.quantity) === 0);
    if (isAnyQtyZero) {
      setOpenSaveConfirmModal(true);
    } else {
      saveParts();
    }
  };

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-controls">
        <Dropdown
          disabled={
            viewOnly ||
            isAssignUser ||
            (usageDetails?.status === 'Failed' &&
              usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY)
          }
          style={{ background: viewOnly ? 'lightgray' : '' }}
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="add"
          iconPosition="left"
          label="Add"
          // options={[
          //   { label: 'Catalog', value: 'catalog' },
          //   { label: 'My Sub Inventory', value: 'subInventory' },
          // ]}
          // onSelect={(option: any): void => setSearchHeader(option.label)}
          onClick={(): void => setShowSearchCatalogModal(true)}
        />
        {/* <Input
          id="search-case-id"
          placeholder="Search"
          // onChange={handleChange}
        /> */}
        <Button
          className="yellow-btn"
          label="Search"
          onClick={(): void => setShowSearchCatalogModal(true)}
          disabled={
            viewOnly ||
            isAssignUser ||
            (usageDetails?.status === 'Failed' &&
              usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY)
          }
          style={{ background: viewOnly ? 'lightgray' : '' }}
        />
      </ButtonGroup>
      {/* <Button
        className="yellow-btn"
        align="right"
        label="Save"
        onClick={handleSave}
        disabled={viewOnly}
      /> */}
    </PageHeaderControl>
  );

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <PageHeader
        className="slds-page-header-usage slds-page-header__name-title-inventory slds-m-around_small slds-m-bottom_none"
        onRenderActions={actions}
        title={
          <>
            <span style={{ marginRight: '10px' }}>
              <CartComponent count={count} color="white" textColor="black" />
            </span>
            <span style={{ fontSize: '18px' }}>Parts</span>
          </>
        }
        variant="record-home"
        id="header"
      />
      {/* <div style={{ width: '50%', margin: '10px 25%' }}>
        {loader && <Spinner size="medium" variant="base" />}
        <LineItems
          usageDetails={usageDetails}
          onSequenceChange={handleSequenceChange}
          refetch={refetch}
          fromParts
        />
      </div> */}
      <PartsTabularDetails
        usageDetails={usageDetails}
        viewOnly={viewOnly}
        isAssignUser={isAssignUser}
        items={usageDetails.lineItems.length === 0 ? [] : items}
        type={type}
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        setItems={(arrg: any) => setItems(arrg)}
        isERPOrder={isERPOrder}
        isERPOrderFailed={isERPOrderFailed}
        orderDetails={orderDetails}
        partListConfig={enabledPartsTabs}
        saveParts={setValuesUpdated}
      />
      {showSearchCatalogModal && (
        <AddPartsModal
          isOpen={showSearchCatalogModal}
          handleClose={onHandleCloseSearchCatalogModal}
          usageDetails={usageDetails}
          refetch={refetch}
          // isAssignUser={isAssignUser}
          count={count}
          territory={territory}
          isERPOrder={isERPOrder}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
          modlenData={updatedPrice?.getUpdatedResolvedPrice}
        />
      )}
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTagsForModal selectedFilter={selectedFilter} handleFilter={handleFilter} />
      )}
      {(loader || screenLoading || partsLoader) && <Spinner size="medium" variant="base" />}
      {openSaveConfirmModal && (
        <Modal
          className="delete-preset-modal default-modal_header default-modal_footer"
          isOpen={openSaveConfirmModal}
          onRequestClose={(): void => setOpenSaveConfirmModal(false)}
          ariaHideApp={false}
          footer={[
            <Button label="Yes" variant="brand" onClick={saveParts} key="save_yes" />,
            <Button
              label="No"
              onClick={(): void => setOpenSaveConfirmModal(false)}
              key="save_no"
            />,
          ]}
          heading="Warning!"
        >
          <div className="slds-text-align--center">
            <div>
              <h1 className="slds-m-around--large ">
                {'Part(s) will be removed as the quantity is 0. Are you sure you want to proceed?'}
              </h1>
            </div>
          </div>
        </Modal>
      )}
    </IconSettings>
  );
};

export default UsageParts;
