/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line react/jsx-wrap-multilines
/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Input from '@salesforce/design-system-react/components/input';
import InputField from '../../../../Shared/Input';
import {
  getSvgIcon,
  getUSDString,
  priceValidationIndicatorClass,
  getHoldIcon,
  GetActualQty,
} from '../../../../../util/utilityFunctions';
// import { GET_UPDATED_RESOLVED_PRICE } from '../../../../../graphql/getUpdatedResolvedPrice';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import RESUBMIT_ORDER_DETAILS from '../../../../../graphql/mutations/resubmitOrderDetails';
import { USAGE_TYPE, NOTIFY_TYPE, MAX_QUANTITY } from '../../../../../util/constants';
import SnackBar from '../../../../Shared/SnackBar';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';

interface Props {
  lineItem: any;
  open: boolean;
  caseId?: string;
  salesRepId?: string;
  caseExternalId?: string;
  refetch: () => void;
  handleToggleModal: () => void;
  viewOnly: boolean;
  isAssignUser: boolean;
  diplayOrderType: string;
  eventType: string;
  isERPOrder: boolean;
  orderId?: string;
  modalconfigs?: any;
  lineItems?: any;
  updatedPrice?: any;
  openPricingSnackBar?: any;
}

const PricingModal: FC<Props> = ({
  open,
  refetch,
  handleToggleModal,
  lineItem,
  viewOnly,
  isAssignUser,
  caseExternalId,
  caseId,
  salesRepId,
  diplayOrderType,
  eventType,
  isERPOrder,
  orderId,
  modalconfigs,
  lineItems,
  updatedPrice,
  openPricingSnackBar,
}) => {
  const [loader, setLoader] = useState(false);
  const [openError, setopenError] = useState(false);
  const [qty, setQty] = useState(lineItem.quantity);
  const [actualqty, setActualqty] = useState(lineItem.actualQuantity);
  const [overrideValue, setOverrideValue] = useState('');
  const [contractName, setContractName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [priceType, setPriceType] = useState('');
  const [contractPrice, setContractPrice] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [unitPriceTotal, setUnitPriceTotal] = useState('');
  const [targetPrice, setTargetPrice] = useState('');
  const [floorPrice, setFloorPrice] = useState('');
  const [listPrice, setListPrice] = useState('');
  const [listTotalPrice, setListTotalPrice] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const { notification, openSnackBar } = useSnackBar();
  const [overrideType, setOverrideType] = useState('DOLLAR');
  const [errorText, setErrorText] = useState('');
  const [openSaveConfirmModal, setOpenSaveConfirmModal] = useState(false);
  const [duplicatePart, setDuplicatePart] = useState<any>({});
  // const [getResolvedPrice, { data: updatedPrice }] = useLazyQuery(GET_UPDATED_RESOLVED_PRICE);
  const [savePriceDetails] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);
  const [resubmitOrderLineItems] = useMutation(RESUBMIT_ORDER_DETAILS);
  const validNumberRegex = /^\d*\.?\d+$/;
  const handleCount = (
    event: any,
    data: { value: string; number: number },
    content: number,
    name: string
  ): void => {
    let qtyValue = data.number > lineItem.maxQuantity ? lineItem.maxQuantity : data.number;
    const product: any = lineItems?.find(
      (item: any) =>
        item.lotNumber === lineItem.lotNumber &&
        item.productNumber === lineItem.productNumber &&
        item.externalId !== lineItem.externalId &&
        item.sourceLocation === lineItem.sourceLocation
    );

    if (
      !lineItem.isValidLotSerialCombination &&
      lineItem.iscatalog &&
      diplayOrderType === USAGE_TYPE.BILL_ONLY
    ) {
      qtyValue = data.number;
    }

    const actualQtyValue = GetActualQty(
      diplayOrderType,
      name,
      lineItem.salesUnitOfMeasure,
      lineItem.packContent,
      data.number
    );

    if (product && lineItem.isValidLotSerialCombination && !product.isValidLotSerialCombination) {
      product.isLotValueUpdated = true;
      product.caseUsageSubInventoryCode = lineItem.caseUsageSubInventoryCode;
      // product.iscatalog = product.iscatalog;
      product.sourceLocation = product.sourceLocation
        ? typeof product.sourceLocation === 'string'
          ? product.sourceLocation
          : product?.sourceLocation[0]?.value
        : null;
      // product.previousLotNumber = product.previousLotNumber;
      setDuplicatePart(product);
    }

    setQty(qtyValue);
    setActualqty(actualQtyValue);
  };
  const holdIconPath = getHoldIcon();
  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    content: number,
    name: string,
    onHold: string
  ): void => {
    if (onHold) {
      setopenError(true);
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );

      setTimeout(() => {
        setopenError(false);
      }, 5000);
    }
    handleCount(event, data, content, name);
  };

  const setInitialPrices = useCallback(() => {
    setTargetPrice(lineItem.targetPrice || '');
    setListPrice(lineItem.listPrice || '');
    setFloorPrice(lineItem.floorPrice || '');
    setPriceType(lineItem?.priceType);
  }, [lineItem]);
  // useEffect(() => {
  //   if (lineItem.productNumber) {
  //     setLoader(true);
  //     getResolvedPrice({
  //       variables: {
  //         productNumbers: [lineItem.productNumber],
  //       },
  //     });
  //   }
  // }, [getResolvedPrice, lineItem]);
  // useEffect(() => {
  //   if (updatedPrice?.getUpdatedResolvedPrice) {
  //     setLoader(false);
  //     const price = updatedPrice.getUpdatedResolvedPrice[0];
  //     setContractPrice(price.resolvedPrice || '');
  //     setContractName(price.contractName || '');
  //   }
  // }, [updatedPrice]);
  useEffect(() => {
    if (lineItem) {
      setContractPrice(lineItem.contractPrice || '');
      setContractName(lineItem.contractName || '');
      setStartDate(lineItem.contractStartDate || '');
      setEndDate(lineItem.contractEndDate || '');
      setOverrideType(lineItem.priceOverrideType || 'DOLLAR');
      setOverrideValue(lineItem.priceOverride || '');
      setUnitPrice(lineItem.priceOverride ? lineItem.usgPrice : lineItem.contractPrice);
      setInitialPrices();
    }
  }, [lineItem, setInitialPrices]);
  useEffect(() => {
    if (unitPrice) {
      const val = Number(unitPrice) * Number(actualqty);
      setUnitPriceTotal(val.toString());
    }
  }, [actualqty, qty, unitPrice]);
  useEffect(() => {
    if (listPrice) {
      const val = Number(listPrice) * Number(actualqty);
      setListTotalPrice(val.toString());
    }
  }, [actualqty, listPrice, qty]);
  useEffect(() => {
    if (unitPrice && listPrice && Number(listPrice) > 0) {
      const discOffList = (1 - Number(unitPrice) / Number(listPrice)) * 100;
      const discount = parseFloat(discOffList.toString()).toFixed(2);
      setDiscountPercentage(discount.toString());
    }
  }, [unitPrice, listPrice]);

  const handleUserInput = (inputValue: string): void => {
    if (inputValue && validNumberRegex.test(inputValue) && parseFloat(inputValue) !== 0) {
      setPriceType('User Override');
      if (overrideType === 'DOLLAR') {
        setUnitPrice(inputValue);
      } else {
        // Percentage
        // if (Number(inputValue) > 10) {
        //   setErrorText('Upto 10% Only');
        //   return;
        // }
        setErrorText('');
        const discount = Number(listPrice) * (Number(inputValue) / 100);
        const newUnitPrice = Number(listPrice) - Number(discount);
        setUnitPrice(newUnitPrice.toString());
      }
    } else {
      setUnitPrice(lineItem.contractPrice);
      setInitialPrices();
    }
    if (inputValue === '' || (validNumberRegex.test(inputValue) && parseFloat(inputValue) !== 0)) {
      setOverrideValue(inputValue);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    // Prevent the minus sign from being entered
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  const handleUserOverrideSelection = (type: string): void => {
    setOverrideType(type);
    setOverrideValue('');
    setErrorText('');
    setUnitPrice(lineItem.contractPrice);
    setInitialPrices();
  };

  const savePartsOnPricing = (): void => {
    setOpenSaveConfirmModal(false);
    const payload: any = [
      {
        sfid: lineItem.sfid,
        externalId: lineItem.externalId,
        productId: lineItem.productId,
        quantity: qty.toString(),
        actualQuantity: parseFloat(actualqty.toString()).toFixed(2),
        lotNumber: lineItem.lotNumber,
        serialNumber: lineItem.serialNumber,
        isLotNumber: lineItem.isLotNumber,
        productNumber: lineItem.productNumber,
        usgPrice: unitPrice?.toString(),
        usgTotalAmount: parseFloat(unitPriceTotal?.toString()).toFixed(2),
        overridePrice: !!overrideValue,
        priceOverride: overrideValue || null,
        deleted: qty === 0 ? true : null,
        priceOverrideType: overrideType,
        iscatalog: lineItem.iscatalog,
        isLotValueUpdated: lineItem?.isLotValueUpdated || false,
        subInventoryCode: lineItem?.caseUsageSubInventoryCode || '',
        sourceLocation: lineItem.sourceLocation
          ? typeof lineItem.sourceLocation === 'string'
            ? lineItem.sourceLocation
            : lineItem?.sourceLocation[0]?.value
          : null,
        previousLotNumber: lineItem.previousLotNumber,
        wasted: lineItem.wasted,
        isValidLotSerialCombination: lineItem.isValidLotSerialCombination,
        ...(isERPOrder && {
          priceBookEntryId: lineItem.priceBookEntryId,
          salesUnitOfMeasure: lineItem.salesUnitOfMeasure,
          deliveredDate: lineItem.deliveredDate,
          caseUsageId: lineItem.caseUsageId,
          caseUsageExternalId: lineItem.caseUsageExternalId,
        }),
      },
    ];

    if (duplicatePart && duplicatePart?.externalId) {
      payload.push({
        sfid: duplicatePart.sfid,
        externalId: duplicatePart.externalId,
        productId: duplicatePart.productId,
        quantity: duplicatePart.quantity.toString(),
        actualQuantity: parseFloat(duplicatePart.actualQuantity.toString()).toFixed(2),
        lotNumber: duplicatePart.lotNumber,
        serialNumber: duplicatePart.serialNumber,
        isLotNumber: duplicatePart.isLotNumber,
        productNumber: duplicatePart.productNumber,
        usgPrice: unitPrice,
        usgTotalAmount: parseFloat(unitPriceTotal.toString()).toFixed(2),
        overridePrice: !!overrideValue,
        priceOverride: overrideValue || null,
        deleted: false,
        priceOverrideType: overrideType,
        iscatalog: duplicatePart.iscatalog,
        subInventoryCode: duplicatePart?.caseUsageSubInventoryCode || '',
        isLotValueUpdated: true,
        wasted: duplicatePart.wasted,
        sourceLocation: duplicatePart.sourceLocation
          ? typeof duplicatePart.sourceLocation === 'string'
            ? duplicatePart.sourceLocation
            : duplicatePart?.sourceLocation[0]?.value
          : null,
        previousLotNumber: duplicatePart.previousLotNumber,
        isValidLotSerialCombination: duplicatePart.isValidLotSerialCombination,
        ...(isERPOrder && {
          priceBookEntryId: duplicatePart.priceBookEntryId,
          salesUnitOfMeasure: duplicatePart.salesUnitOfMeasure,
          deliveredDate: duplicatePart.deliveredDate,
          caseUsageId: duplicatePart.caseUsageId,
          caseUsageExternalId: duplicatePart.caseUsageExternalId,
        }),
      });
    }

    let mutation;
    let saveQuery;
    if (isERPOrder) {
      mutation = {
        id: orderId,
        salesRepId,
        submitType: 'Update',
        requestedFrom: diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'BillOnly' : 'ShipAndBill',
        lineItems: payload,
      };
      saveQuery = resubmitOrderLineItems;
    } else {
      mutation = {
        caseId,
        caseExternalId,
        salesRepId,
        formType: 'Update',
        lineItems: payload,
        tabName: 'Pricing',
      };
      saveQuery = savePriceDetails;
    }
    setLoader(true);
    saveQuery({
      variables: mutation,
    }).then((res: any) => {
      const data = res?.data?.createUpdateUsageSheetLineItems;
      if (data?.isMaxQuantityExceeded) {
        setTimeout(() => {
          openPricingSnackBar(
            NOTIFY_TYPE.WARNING,
            'Quantity Exceeds available inventory. Please add additional lines from catalog with the quantity difference.'
          );
        }, 1500);
      }
      setLoader(false);
      setErrorText('');
      setTimeout(() => {
        refetch();
      }, 2000);
      handleToggleModal();
    });
  };

  const handleSave = (): void => {
    if (!lineItem.deleted && qty === 0) {
      setOpenSaveConfirmModal(true);
    } else {
      savePartsOnPricing();
    }
  };

  const getUoM = (name: string, packContent: string): string => {
    let actualUOM = name;

    if (packContent && Number(packContent) > 1) {
      actualUOM += `/ ${packContent}`;
    }
    return actualUOM;
  };

  const getSellingUoM = (line: any): string => {
    const sellingUOMDetails = updatedPrice?.filter(
      (item: any) => item.productNumber === line.productNumber
    );

    let sellingUOM = 'PCE';
    if (sellingUOMDetails?.length > 0) {
      sellingUOM = sellingUOMDetails[0]?.sellingUOM;
    }
    return sellingUOM || 'PCE';
  };

  // Pricing modal config changes
  let enabledModalFields: any[] = [];
  let enabledPartsFields: any[] = [];
  let enabledPricingFields: any[] = [];
  if (modalconfigs?.priceDetail_tab) {
    // eslint-disable-next-line no-unused-expressions
    modalconfigs?.priceDetail_tab.forEach((item: any) => {
      if (item.isShow === true) {
        enabledModalFields = [...enabledModalFields, item.mappingKey];
      }
    });
  }
  if (modalconfigs?.parts_tab) {
    // eslint-disable-next-line no-unused-expressions
    modalconfigs?.parts_tab.forEach((item: any) => {
      if (item.isShow === true) {
        enabledPartsFields = [...enabledPartsFields, item.mappingKey];
      }
    });
  }
  if (modalconfigs?.pricing_tab) {
    // eslint-disable-next-line no-unused-expressions
    modalconfigs?.pricing_tab.forEach((item: any) => {
      if (item.isShow === true) {
        enabledPricingFields = [...enabledPricingFields, item.mappingKey];
      }
    });
  }

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={openError} notification={notification} />
      {openSaveConfirmModal && (
        <Modal
          className="delete-preset-modal default-modal_header default-modal_footer"
          isOpen={openSaveConfirmModal}
          onRequestClose={(): void => setOpenSaveConfirmModal(false)}
          ariaHideApp={false}
          footer={[
            <Button label="Yes" variant="brand" onClick={savePartsOnPricing} key="save_yes" />,
            <Button
              label="No"
              onClick={(): void => setOpenSaveConfirmModal(false)}
              key="save_no"
            />,
          ]}
          heading="Confirmation"
        >
          <div className="slds-text-align--center">
            <div>
              <h1 className="slds-m-around--large ">
                {'Part(s) will be removed as the quantity is 0. Are you sure you want to proceed?'}
              </h1>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={open}
        onRequestClose={handleToggleModal}
        footer={[
          <div className="pricing-modal-footer">
            {loader && <Spinner size="small" />}
            <Button
              className="slds-float_left slds-text-color_default"
              label="Cancel"
              key="Cancel"
              onClick={handleToggleModal}
            />
            <Button
              disabled={viewOnly || isAssignUser}
              className="yellow-btn"
              label="Save"
              onClick={handleSave}
              variant="brand"
              key="save"
            />
          </div>,
        ]}
        heading={[
          <div>
            <p className="price-header">
              <p style={{ fontWeight: 'bold' }}>Price Details</p>
              <p style={{ fontWeight: 'normal' }}>
                {`${lineItem.productNumber} ${lineItem.productdescription}`}
                {lineItem.productOnHold ? (
                  <img
                    src={holdIconPath}
                    className="logo-image"
                    alt="company"
                    style={{ marginLeft: '8px', marginRight: '8px' }}
                  />
                ) : (
                  ''
                )}
              </p>
            </p>
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade" style={{ fontSize: '16px' }}>
          <section className="price-modal">
            <div className="slds-p-around_large-1 ">
              <div className="price-modal-item">
                {enabledModalFields?.includes('contractName') && (
                  <>
                    <div className="label">Contract Name</div>
                    <div className="label bold">{contractName}</div>
                  </>
                )}
              </div>
              <div className="price-modal-item2">
                {enabledModalFields?.includes('startDate') && (
                  <>
                    <div className="label">Start Date</div>
                    <div className="label bold">
                      {startDate ? moment(startDate).format('L') : ''}
                    </div>
                  </>
                )}
                {enabledModalFields?.includes('endDate') && (
                  <>
                    <div className="label">End Date</div>
                    <div className="label bold">{endDate ? moment(endDate).format('L') : ''}</div>
                  </>
                )}
              </div>
              <div className="price-modal-item2">
                {enabledPricingFields?.includes('seiiing_uom') && (
                  <>
                    <div className="label">Selling UoM</div>
                    <div className="label bold">
                      {/* diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Each' : getUoM()*/}
                      {getSellingUoM(lineItem)}
                    </div>
                  </>
                )}
                {enabledPricingFields?.includes('actual_uom') && (
                  <>
                    <div className="label">Actual UoM</div>
                    <div className="label bold">
                      {/* diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'Each' : getUoM() */}
                      {getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)}
                    </div>
                  </>
                )}
              </div>
            </div>
            {enabledPartsFields?.includes('qty') && (
              <div className="slds-p-around_large-1 ">
                <div className="price-modal-control">
                  <div className="label qty">Qty</div>
                  <Input
                    id="counter-input"
                    minValue={0}
                    maxValue={
                      (lineItem.iscatalog && lineItem.lotValidation === 'false') ||
                      (lineItem.iscatalog && !lineItem.isValidLotSerialCombination)
                        ? MAX_QUANTITY
                        : Number(lineItem.maxQuantity)
                    }
                    onChange={(event: any, data: { value: string; number: number }): void => {
                      handleCountalert(
                        event,
                        data,
                        lineItem.packContent,
                        lineItem.sellingUoM,
                        lineItem.productOnHold
                      );
                    }}
                    value={qty}
                    variant="counter"
                  />
                </div>
              </div>
            )}
            {enabledPartsFields?.includes('actual_qty') && (
              <div className="slds-p-around_large-1 ">
                <div className="price-modal-control">
                  <div className="label qty">Actual Qty</div>
                  <div className="label bold">{parseFloat(actualqty).toFixed(2)}</div>
                </div>
              </div>
            )}
            {diplayOrderType === USAGE_TYPE.BILL_ONLY && enabledPartsFields?.includes('wasted') && (
              <div className="slds-p-around_large-1">
                <div className="price-modal-control">
                  <div className="label wasted">Wasted</div>
                  <IconSettings iconPath="/assets/icons">
                    <Checkbox
                      labels={{
                        toggleEnabled: '',
                        toggleDisabled: '',
                      }}
                      style={{ marginLeft: '25px' }}
                      variant="toggle"
                      disabled
                      // checked={showPrice}
                    />
                  </IconSettings>
                </div>
              </div>
            )}
            <div className="slds-p-around_large-1 ">
              {enabledPricingFields?.includes('contract_price') && (
                <div className="price-modal-item">
                  <div className="label">Contract Price</div>
                  <div className="label price-txt">{getUSDString(contractPrice)}</div>
                </div>
              )}
              {enabledPricingFields?.includes('override_price') && (
                <div className="override-price-container">
                  <div className="override-price-heading">Override Price</div>
                  <div className="override-type">
                    <ButtonGroup variant="list">
                      <Button
                        className={overrideType === 'DOLLAR' ? 'active-btn' : 'btn'}
                        iconCategory="utility"
                        iconSize="large"
                        variant="icon"
                        iconVariant="border-filled"
                        label={<img src={getSvgIcon('dollar')} alt="dollar" />}
                        onClick={(): void => handleUserOverrideSelection('DOLLAR')}
                        // disabled={!lineItem.allowOverride}
                      />
                      <Button
                        className={overrideType !== 'DOLLAR' ? 'active-btn' : 'btn'}
                        iconSize="large"
                        variant="icon"
                        iconCategory="utility"
                        iconVariant="border-filled"
                        label={<img src={getSvgIcon('percentage')} alt="percentage" />}
                        onClick={(): void => handleUserOverrideSelection('PERCENTAGE')}
                        // disabled={!lineItem.allowOverride}
                      />
                    </ButtonGroup>
                  </div>
                  <div className="override-price-control">
                    <InputField
                      type="number"
                      minValue="0"
                      errorText={errorText}
                      label=""
                      value={overrideValue}
                      handleChangeText={(result: string): void => {
                        handleUserInput(result);
                      }}
                      disabled={false} // {!lineItem.allowOverride}
                      name=""
                      onHnadleKeyPress={handleKeyPress}
                    />
                  </div>
                </div>
              )}
              {enabledModalFields?.includes('unitPrice') && (
                <div className="price-modal-item" style={{ marginTop: '-15px' }}>
                  <div className="label">Unit Price</div>
                  <div className={`label ${priceValidationIndicatorClass(eventType, lineItem)}`}>
                    {getUSDString(unitPrice)}
                  </div>
                </div>
              )}
              {enabledPricingFields?.includes('line_total') && (
                <div className="price-modal-item">
                  <div className="label">Line Total</div>
                  <div className="label price-txt bold">{getUSDString(unitPriceTotal)}</div>
                </div>
              )}
              {enabledPricingFields?.includes('list_discount') && (
                <div className="price-modal-item">
                  <div className="label">Discount off List</div>
                  <div className="label price-txt">{`${discountPercentage || 0} %`}</div>
                </div>
              )}
              {enabledPricingFields?.includes('override_price') && (
                <div className="price-modal-item">
                  <div className="label">Price Type</div>
                  <div className="label bold">{priceType}</div>
                </div>
              )}
            </div>
            {(enabledModalFields?.includes('targetPrice') ||
              enabledModalFields?.includes('floorPrice') ||
              enabledModalFields?.includes('listPrice')) && (
              <div className="slds-p-around_large-1 ">
                {enabledModalFields?.includes('targetPrice') && (
                  <div className="price-modal-item">
                    <div className="label">Target Price</div>
                    <div className="label">{getUSDString(targetPrice)}</div>
                  </div>
                )}
                {enabledModalFields?.includes('floorPrice') && (
                  <div className="price-modal-item">
                    <div className="label">Floor Price</div>
                    <div className="label">{getUSDString(floorPrice)}</div>
                  </div>
                )}
                {enabledModalFields?.includes('listPrice') && (
                  <>
                    <div className="price-modal-item">
                      <div className="label">List Price</div>
                      <div className="label">{getUSDString(listPrice)}</div>
                    </div>
                    <div className="price-modal-item">
                      <div className="label">List Total</div>
                      <div className="label">{getUSDString(listTotalPrice)}</div>
                    </div>
                  </>
                )}
              </div>
            )}
          </section>
        </section>
      </Modal>
    </IconSettings>
  );
};

export default PricingModal;
