/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line react/jsx-wrap-multilines
import React, { FC, useState, useEffect } from 'react';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Modal from '@salesforce/design-system-react/components/modal';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import TextArea from '../../../Shared/TextArea';
import { getUTCDateTime } from '../../../../util/utilityFunctions';
import './index.scss';
import CREATE_UPDATE_CYCLE_COUNT_COMMENT from '../../../../graphql/mutations/createUpdateCycleCountComments';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { GET_COMMENT_HISTORY } from '../../../../graphql/getCommentHistory';
import EDIT_COMMENT from '../../../../graphql/mutations/editComment';
import DELETE_COMMENT from '../../../../graphql/mutations/deleteComment';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import { NOTIFY_TYPE, PERSONA_CONDITION } from '../../../../util/constants';

interface Comments {
  id?: any;
  type?: any;
  postTime: any;
  user?: any;
  sfid: any;
  externalId: any;
}

interface Props {
  children?: FC;
  isOpen: boolean;
  toggleOpen: () => void;
  toggleOpenOnAdd: () => void;
  handleCancelButton?: () => void;
  createCommentLoading: boolean;
  comments: any;
  disabled: boolean;
}

const AddCommentsModal: FC<Props> = ({
  createCommentLoading,
  toggleOpen,
  toggleOpenOnAdd,
  isOpen,
  comments,
  disabled,
}) => {
  const [commentText, setCommentText] = useState('');
  const [createComment, { loading: createCommentLoader }] = useMutation(
    CREATE_UPDATE_CYCLE_COUNT_COMMENT
  );
  const commentsFlag = comments?.filter((i: any) => i.comments !== null);
  const userInfo = useSelector(getUserInfo);
  const [isEdit, setIsEdit] = useState('');
  const [editFieldText, setEditFieldText] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteExternalId, setDeleteExternalId] = useState('');
  const { open, notification, openSnackBar } = useSnackBar();

  const changeCommentData = (event: any): void => {
    const { value } = event.target;
    setCommentText(value);
  };

  const [commentsList, setCommentList] = useState<Comments[]>();
  const { data: customerComments, refetch: refetchCommentHistory } = useQuery(GET_COMMENT_HISTORY, {
    variables: {
      sfid: comments?.[0]?.sfid,
      caseExternalId: comments?.[0]?.externalId,
      commentType: 'Customer',
    },
  });
  const [editComment, { data: updateCommentData, loading: updateCommentLoading }] = useMutation(
    EDIT_COMMENT
  );
  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(DELETE_COMMENT);

  const handleDelete = (externalId: any): void => {
    if (externalId) {
      const obj = {
        externalId: deleteExternalId,
      };
      deleteComment({
        variables: obj,
      })
        .then(() => {
          refetchCommentHistory();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Seems like it is invalid comment.');
    }
    setDeleteConfirm(false);
  };
  // useEffect(() => {
  //   if (comments) {
  //     setCommentList(comments);
  //   }
  // }, [comments]);
  useEffect(() => {
    setCommentList(customerComments?.getCommentHistory);
  }, [customerComments, setCommentList, refetchCommentHistory]);
  const handleAdd = (list: any): void => {
    createComment({
      variables: {
        cycleCountLineExternalId: comments[0].externalId,
        cycleCountLineId: comments[0].sfid,
        comment: commentText,
        postTime: moment()
          .utc()
          .toString(),
        commentType: 'Customer',
        type: 'New',
      },
    }).then(() => {
      toggleOpenOnAdd();
    });
  };
  const handleCancelModal = () => {
    setDeleteConfirm(false);
  };
  const handleEdit = (commentData: any) => {
    if (editFieldText) {
      const obj = {
        externalId: isEdit,
        comment: editFieldText,
        postTime: getUTCDateTime(new Date()),
        // user: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
      editComment({
        variables: obj,
      })
        .then(() => {
          refetchCommentHistory();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please add your comment first');
    }
    setIsEdit('');
  };

  const formatDate = (value: any): string => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(value);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  const getTimezoneName = (formattedDate: any) => {
    const currentDate = new Date(formattedDate);
    const short = currentDate.toLocaleDateString(undefined);
    const full = currentDate.toLocaleDateString(undefined, { timeZoneName: 'long' });
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    }
    return full;
  };

  const getTimeStamp = (formattedDate: any) => {
    const currentDateTime = new Date(formattedDate);
    const hour =
      currentDateTime.getHours() === 0
        ? 12
        : currentDateTime.getHours() > 12
        ? currentDateTime.getHours() - 12
        : currentDateTime.getHours();
    const min =
      currentDateTime.getMinutes() < 10
        ? `0${currentDateTime.getMinutes()}`
        : currentDateTime.getMinutes();
    const ampm = currentDateTime.getHours() < 12 ? 'AM' : 'PM';
    const time = `${hour}:${min} ${ampm}`;

    return time;
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleOpen}
        dismissOnClickOutside={false}
        id="internal_comment_modal"
        className="internal_comment_modal"
        ariaHideApp={false}
        footer={[
          (createCommentLoader || updateCommentLoading || deleteCommentLoading) && (
            <div key="loader">
              <Spinner size="small" variant="base" assistiveText={{ label: 'Loading...' }} />
            </div>
          ),
          <Button
            label="Add"
            key="internal_save"
            variant="brand"
            className="slds-button_brand"
            disabled={commentText === '' || disabled}
            onClick={(): void => handleAdd(commentsList)}
          />,
        ]}
        heading={
          <div className="slds-float_left slds-text-title_bold filter_heading">Comments</div>
        }
      >
        <SnackBar open={open} notification={notification} />
        <div className="comments-container-background">
          <section className="slds-p-around_medium slds-theme_shade">
            {commentsFlag?.length > 0 && (
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="comments-container-cycle-count">
                  {commentsList &&
                    commentsList.map((line: any) => (
                      // item.comments
                      //   ? item.comments &&
                      //     item.comments.map((line: any) => (
                      <div className="comment-body">
                        <p className="comment-user-name">
                          {line.userName || ''}
                          {line.postTime ? (
                            <span style={{ fontWeight: 500, marginLeft: '25px' }}>
                              {moment(line.postTime).format('MM/DD/YYYY')}
                              {`${' at '}`}
                              {getTimeStamp(line.postTime)}
                              {`${' '}`}
                              {getTimezoneName(new Date())
                                .match(/\b([A-Z])/g)
                                ?.join('')}
                            </span>
                          ) : (
                            ''
                          )}
                          {userInfo?.id === line.userId && (
                            <>
                              <Button
                                assistiveText={{ icon: 'Edit Comment' }}
                                iconCategory="utility"
                                iconName="edit"
                                iconSize="small"
                                iconVariant="bare"
                                onClick={() => {
                                  setIsEdit(line.commentExternalId);
                                  setEditFieldText(line.comment);
                                }}
                                variant="icon"
                                disabled={disabled}
                              />
                              <Button
                                assistiveText={{ icon: 'Delete Comment' }}
                                iconCategory="utility"
                                iconName="delete"
                                iconSize="small"
                                iconVariant="bare"
                                onClick={() => {
                                  setDeleteConfirm(true);
                                  setDeleteExternalId(line.commentExternalId);
                                }}
                                variant="icon"
                                disabled={disabled}
                              />
                            </>
                          )}
                        </p>
                        {/* <h4 className="comment-timestamp">
                              {`${formatDate(line.postTime)} at  ${moment(line.postTime).format(
                                'LT'
                              )}`}
                            </h4> */}
                        {isEdit === line?.commentExternalId ? (
                          <>
                            <TextArea
                              label=""
                              tooltipText="Edit Comment"
                              showTooltip={false}
                              disabled={false}
                              handleChangeText={(text: string): void => setEditFieldText(text)}
                              value={editFieldText}
                              maxLength="200"
                            />
                            <Grid container justifyContent="flex-end">
                              <Grid item>
                                <Button
                                  align="right"
                                  label="Cancel"
                                  onClick={() => setIsEdit('')}
                                />
                                <Button
                                  align="right"
                                  label="Save"
                                  className="yellow-btn"
                                  onClick={() => handleEdit(line)}
                                  disabled={editFieldText === line.comment}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <p className="comments_text">
                            {`${line.comment}`}
                            {line.updatedBy ? ` - Edited` : ``}
                          </p>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <h1 className="slds-text-title_bold">Add Comment</h1>
            <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
              <textarea
                id="unique-id-1"
                rows={10}
                className="slds-textarea"
                value={commentText || ''}
                disabled={disabled}
                onChange={
                  (event: any): void => changeCommentData(event)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </div>
          </section>
        </div>
      </Modal>
      {deleteConfirm && (
        <Modal
          className="cancel-creation-modal default-modal_header default-modal_footer"
          isOpen={deleteConfirm}
          onRequestClose={handleCancelModal}
          ariaHideApp={false}
          footer={[
            <Button label="Yes" onClick={handleDelete} key="yes" />,
            <Button label="No" variant="brand" onClick={handleCancelModal} key="no" />,
          ]}
          heading="Delete Confirmation"
        >
          <div className="slds-text-align--center">
            <div>
              <h1 className="slds-m-around--large ">Are you sure to delete this comment?</h1>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddCommentsModal;
