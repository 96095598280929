/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createNotificationSettings
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_USAGE_LINE_ITEMS = gql`
  mutation createUpdateUsageSheetLineItems(
    $caseId: String
    $caseExternalId: String
    $formType: String!
    $salesRepId: String
    $tabName: String
    $lineItems: [UsageSheetLineItems]
  ) {
    createUpdateUsageSheetLineItems(
      caseId: $caseId
      caseExternalId: $caseExternalId
      formType: $formType
      salesRepId: $salesRepId
      tabName: $tabName
      lineItems: $lineItems
    ) {
      message
      externalId
      isMaxQuantityExceeded
    }
  }
`;

export default CREATE_UPDATE_USAGE_LINE_ITEMS;
