/*
 * Get Comment history GraphQL specification
 * @module src/graphql/getCommentHistory
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_COMMENT_HISTORY = gql`
  query getCommentHistory($caseExternalId: String, $commentType: String!) {
    getCommentHistory(caseExternalId: $caseExternalId, commentType: $commentType) {
      id
      comment
      createdDate
      commentType
      commentExternalId
      postTime
      userId
      sfid
      userName
      updatedBy
    }
  }
`;
