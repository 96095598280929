/**
 * Graphql mutation to delete comment
 * @module src/graphql/deleteComment
 */
import gql from 'graphql-tag';

/** editing comment */
const DELETE_COMMENT = gql`
  mutation deleteComment($sfid: String, $externalId: String) {
    deleteComment(sfid: $sfid, externalId: $externalId) {
      message
    }
  }
`;

export default DELETE_COMMENT;
