/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Module for header on InventoryReturnDetailCatalog Page
 * @module src/SurgicalDetail/InventoryReturnDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import Button from '@salesforce/design-system-react/components/button';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import { Paper } from '@material-ui/core';
import Icon from '@salesforce/design-system-react/components/icon';
// import Tooltip from '@salesforce/design-system-react/components/tooltip';
import './index.scss';
import InventoryReturnAddItems from '../InventoryReturnAddItems';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import InventoryRequestTable from '../InventoryRequestTable';
import FilterTagsForModal from '../../../Shared/FilterTagsForModal';
import { INVENTORY_RETURN_DROPDOWN } from '../../../../util/constants';
import CartComponent from '../../../Shared/CartComponent';
import { getSvgIcon } from '../../../../util/utilityFunctions';

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

interface Props {
  inventoryReturnDetail: any;
  refetchInventoryReturn?: () => void;
  changeSelectedTab: (value: number) => void;
  refetch: () => void;
  visualIndicator: string;
  type: string;
  externalId: string;
  hasEventInventory?: any;
  isOrder?: boolean;
  detailsConfig?: any;
  detailsViewconfigpickup?: any;
  returnType?: any;
  viewOnly?: boolean;
}

const InventoryReturnDetailCatalog: FC<Props> = ({
  inventoryReturnDetail,
  refetchInventoryReturn,
  changeSelectedTab,
  refetch,
  visualIndicator,
  hasEventInventory,
  type,
  externalId,
  isOrder,
  detailsConfig,
  detailsViewconfigpickup,
  returnType,
  viewOnly,
}) => {
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [editCatalog, setEditCatalog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const catalogInd = getSvgIcon('catalog');

  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    setEditCatalog(false);
    if (refetchInventoryReturn) {
      refetchInventoryReturn();
    }
  };

  const quantityCount = inventoryReturnDetail?.lineItems.reduce(
    (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
    0
  );
  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
    changeSelectedTab(value);
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getEnabledTabs = (key: string) => {
    let enabledTabs: any[] = [];
    if (detailsConfig && detailsConfig[key]) {
      detailsConfig[key].forEach((item: any) => {
        if (item.isShow === true) {
          enabledTabs = [...enabledTabs, item.mappingKey];
        }
      });
    }
    return enabledTabs;
  };
  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup className="slds-align-middle" id="button-group-page-header-actions">
        <div style={{ marginRight: '10px' }}>
          <CartComponent count={quantityCount} color="white" textColor="black" />
        </div>
        <Dropdown
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="down"
          iconPosition="right"
          label="Add"
          onSelect={(selectedOption: any): void => {
            const { value } = selectedOption;
            if (value === 'search') {
              setShowSearchCatalogModal(true);
            }
          }}
          disabled={
            inventoryReturnDetail?.status.toLowerCase() === 'approved' ||
            inventoryReturnDetail?.status.toLowerCase() === 'cancelled' ||
            inventoryReturnDetail?.status.toLowerCase() === 'completed' ||
            inventoryReturnDetail?.status.toLowerCase() === 'closed' ||
            inventoryReturnDetail?.visualIndicator.toLowerCase() === 'requested' ||
            inventoryReturnDetail?.status.toLowerCase() === 'shipped'
          }
          style={{ borderRadius: '5px' }}
          options={INVENTORY_RETURN_DROPDOWN}
        />
      </ButtonGroup>
      <Button
        label="Edit"
        disabled={
          inventoryReturnDetail?.status.toLowerCase() === 'approved' ||
          inventoryReturnDetail?.status.toLowerCase() === 'cancelled' ||
          inventoryReturnDetail?.status.toLowerCase() === 'completed' ||
          inventoryReturnDetail?.status.toLowerCase() === 'closed' ||
          inventoryReturnDetail?.visualIndicator.toLowerCase() === 'requested' ||
          inventoryReturnDetail?.status.toLowerCase() === 'shipped'
        }
        onClick={(): void => {
          setEditCatalog(true);
        }}
      />
    </PageHeaderControl>
  );

  return (
    // <IconSettings iconPath="/icons">
    //   <PageHeader
    //     className="slds-page-header-return-inventory  slds-page-header__name-title-return-inventory slds-m-around_small slds-m-bottom_none"
    //     onRenderActions={actions}
    //     title="Inventory"
    //     variant="record-home"
    //     id="header"
    //   />
    //   <div
    //     className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
    //     style={{ justifyContent: 'center' }}
    //   >
    //     <>
    //       <Paper style={{ width: '50%' }}>
    //         {inventoryReturnDetail &&
    //           inventoryReturnDetail.lineItems.map((lineItem: any) => {
    //             const { lineVisualIndicator } = lineItem;
    //             let statusToShow = lineVisualIndicator;
    //             switch (lineVisualIndicator) {
    //               case 'Approve':
    //                 statusToShow = 'Approved';
    //                 break;
    //               case 'Accept':
    //                 statusToShow = 'Approved';
    //                 break;
    //               case 'Reject':
    //                 statusToShow = 'Rejected';
    //                 break;
    //               case 'Cancel':
    //                 statusToShow = 'Cancelled';
    //                 break;
    //               case 'No Action':
    //                 statusToShow = 'New';
    //                 break;
    //               default:
    //                 statusToShow = lineVisualIndicator;
    //             }
    //             return (
    //               <div
    //                 style={{
    //                   display: 'grid',
    //                   gridTemplateColumns: '1fr auto 1fr',
    //                   borderBottom: '1px solid lightgray',
    //                 }}
    //               >
    //                 <div style={{ padding: '10px' }}>
    //                   {lineItem.productNumber}
    //                   {'-'}
    //                   {lineItem.productDescription}
    //                   <br />
    //                   {`Lot#-${lineItem.lotNumber || '--'}`}
    //                 </div>
    //                 <div style={{ padding: '10px' }}>
    //                   <EventStatusBadge status={statusToShow} />
    //                 </div>
    //                 <div
    //                   style={{
    //                     padding: '10px',
    //                     textAlign: 'end',
    //                   }}
    //                 >
    //                   {lineItem.quantity}
    //                 </div>
    //               </div>
    //             );
    //           })}
    //       </Paper>
    //     </>
    //   </div>
    //   {showSearchCatalogModal && (
    //     <InventoryReturnAddItems
    //       isOpen={showSearchCatalogModal}
    //       handleClose={onHandleCloseSearchCatalogModal}
    //       inventoryReturnDetail={inventoryReturnDetail}
    //       selectedFilter={selectedFilter}
    //       handleFilter={handleFilter}
    //       quantityCount={quantityCount}
    //     />
    //   )}
    //   {selectedFilter && selectedFilter.filterValues && (
    //     <FilterTagsForModal selectedFilter={selectedFilter} handleFilter={handleFilter} />
    //   )}
    //   {editCatalog && (
    //     // <InventoryRequestEditAddItems
    //     //   isOpen={editCatalog}
    //     //   handleClose={onHandleCloseInventoryEditAddModal}
    //     //   inventoryDetail={inventoryDetail}
    //     //   isEventInventoryFlow={isEventInventoryFlow}
    //     // />
    //     <InventoryReturnAddItems
    //       isOpen={editCatalog}
    //       handleClose={onHandleCloseSearchCatalogModal}
    //       inventoryReturnDetail={inventoryReturnDetail}
    //       isEdit={editCatalog}
    //       selectedFilter={selectedFilter}
    //       handleFilter={handleFilter}
    //       quantityCount={quantityCount}
    //     />
    //   )}
    // </IconSettings>
    <div style={{ padding: '16px' }}>
      <IconSettings iconPath="/icons">
        <Tabs
          variant="scoped"
          id="usage-tabs-scoped-returns"
          onSelect={handleTabSelect}
          defaultSelectedIndex={0}
          selectedIndex={selectedTabIndex}
        >
          <TabsPanel label="Returning">
            <InventoryRequestTable
              refetch={refetch}
              inventoryDetail={inventoryReturnDetail}
              // isEventInventoryFlow={isEventInventoryFlow}
              visualIndicator={visualIndicator}
              type="Returning"
              hasEventInventory={hasEventInventory}
              // eventInventoryExternalId={eventInventoryExternalId}
              externalId={externalId}
              isOrder={isOrder}
              detailsViewConfig={getEnabledTabs('ReturningTab')}
              returnType={returnType}
              viewOnly={viewOnly}
            />
          </TabsPanel>
          <TabsPanel label="Received">
            <InventoryRequestTable
              refetch={refetch}
              inventoryDetail={inventoryReturnDetail}
              // isEventInventoryFlow={isEventInventoryFlow}
              visualIndicator={visualIndicator}
              type="Received"
              hasEventInventory={hasEventInventory}
              // eventInventoryExternalId={eventInventoryExternalId}
              externalId={externalId}
              isOrder={isOrder}
              detailsViewConfig={getEnabledTabs('ReceivedTab')}
              returnType={returnType}
              viewOnly={viewOnly}
            />
          </TabsPanel>
          <TabsPanel
            disabled
            onClick={(e: any) => {
              e.preventDefault();
            }}
            style={{
              cursor: 'default',
            }}
            label={
              <div
                style={{
                  display: 'inline-block',
                  cursor: 'default',
                  color: '#000000',
                }}
              >
                <span style={{ marginLeft: '30px', marginRight: '5px' }}>
                  <img
                    src={catalogInd}
                    className="logo-image"
                    alt="company"
                    style={{ marginLeft: '8px', marginRight: '8px' }}
                  />
                  Catalog
                </span>
                {returnType === 'Failed' && (
                  <span style={{ marginLeft: '30px' }}>
                    <Icon
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ fill: '#D2042D', marginRight: '5px' }}
                    />
                    Error
                  </span>
                )}
              </div>
            }
          />
        </Tabs>
      </IconSettings>
    </div>
  );
};

/** Custom Header on InventoryReturnDetailCatalog Page **/
export default InventoryReturnDetailCatalog;
