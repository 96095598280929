/**
 * Graphql mutation to edit comment
 * @module src/graphql/editComment
 */
import gql from 'graphql-tag';

/** editing comment */
const EDIT_COMMENT = gql`
  mutation editComment($sfid: String, $externalId: String, $comment: String, $postTime: String!) {
    editComment(sfid: $sfid, externalId: $externalId, comment: $comment, postTime: $postTime) {
      message
    }
  }
`;

export default EDIT_COMMENT;
