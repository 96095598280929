import gql from 'graphql-tag';

const SUBMIT_INVENTORY_DETAILS = gql`
  mutation reviewInventoryRequest(
    $caseId: String
    $caseExternalId: String
    $parentEventType: String
    $parentEventSubType: String
    $accountId: String
    $branchId: String
    $salesRepId: String
    $shipToId: String
    $serviceLevel: String
    $needByDate: Date
    $shipMethod: String
    $lineItems: [ReviewInventoryRequestLineItems]
    $comments: [Comment]
  ) {
    reviewInventoryRequest(
      caseId: $caseId
      caseExternalId: $caseExternalId
      parentEventType: $parentEventType
      parentEventSubType: $parentEventSubType
      accountId: $accountId
      branchId: $branchId
      salesRepId: $salesRepId
      shipToId: $shipToId
      serviceLevel: $serviceLevel
      needByDate: $needByDate
      shipMethod: $shipMethod
      lineItems: $lineItems
      comments: $comments
    ) {
      message
    }
  }
`;

export default SUBMIT_INVENTORY_DETAILS;
