/**
 * Graphql mutation to create update usage
 * @module src/graphql/createEditUsage
 */
import gql from 'graphql-tag';

interface UsageParts {
  caseId: string;
  caseExternalId: string;
  usageId: string;
  usageExternalId: string;
  partId: string;
  kitId: string;
  procedureId: string;
  quantity: string;
  wasted: boolean;
  replenish: boolean;
  lotNo: string;
  unitNo: string;
  serialNo: string;
  expirationDate: string;
  isLotControlled: boolean;
  replenishAddress: string;
  replenishOtherLocation: string;
  workflowAction: string;
}

/** create update usage */
const CREATE_UPDATE_USAGE = gql`
  mutation createUpdateUsage(
    $eventId: String
    $eventExternalId: String
    $caseId: String
    $caseExternalId: String
    $accountId: String
    $branchId: String
    $salesRepId: String
    $shipToId: String
    $shipMethod: String
    $serviceLevel: String
    $serviceLevelJustification: String
    $needByDate: String
    $requestedFrom: String!
    $liftGateNeeded: Boolean
    $dockAvailable: Boolean
    $shortTruckOnly: Boolean
    $deliveryContractName: String
    $deliveryContractPhone: String
    $deliveryContractEmail: String
    $deliveryContractInstructions: String
    $isShippingDetails: Boolean
    $usageParts: [UsageParts]
    $comments: [Comment]
    $attachments: [Attachment]
  ) {
    createUpdateUsage(
      eventId: $eventId
      eventExternalId: $eventExternalId
      caseId: $caseId
      caseExternalId: $caseExternalId
      accountId: $accountId
      branchId: $branchId
      salesRepId: $salesRepId
      shipToId: $shipToId
      shipMethod: $shipMethod
      serviceLevel: $serviceLevel
      serviceLevelJustification: $serviceLevelJustification
      needByDate: $needByDate
      requestedFrom: $requestedFrom
      liftGateNeeded: $liftGateNeeded
      dockAvailable: $dockAvailable
      shortTruckOnly: $shortTruckOnly
      deliveryContractName: $deliveryContractName
      deliveryContractPhone: $deliveryContractPhone
      deliveryContractEmail: $deliveryContractEmail
      deliveryContractInstructions: $deliveryContractInstructions
      isShippingDetails: $isShippingDetails
      usageParts: $usageParts
      comments: $comments
      attachments: $attachments
    ) {
      message
      externalId
      sfid
    }
  }
`;

export default CREATE_UPDATE_USAGE;
