/**
 * Module for header on Inventory Request Detail Header Page
 * @module src/InventoryReqyestDetail/InventoryFavoriteDetailHeader
 */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';
import { getEventIcon } from '../../../../util/utilityFunctions';
// import ReadMore from '../../Shared/ReadMore';

interface Props {
  inventoryRequestFav: any;
  handleCreateNewInventoryRequest: () => void;
  handleInventoryFavoriteActionDropdownSelect: (value: string) => void;
}

const InventoryFavoriteDetailHeader: FC<Props> = ({
  handleInventoryFavoriteActionDropdownSelect,
  handleCreateNewInventoryRequest,
  inventoryRequestFav,
}) => {
  const { serviceLevel, nameOfFavorite, comments } = inventoryRequestFav;
  const handleNavigateToEventList = (): void => {
    window.history.back();
  };

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup
        className="slds-align-middle"
        variant="list"
        id="button-group-page-header-actions"
      >
        <Button
          iconCategory="utility"
          className="slds-text-color_default"
          label="Create New Inventory Request"
          onClick={(): void => handleCreateNewInventoryRequest()}
        />
        <Dropdown
          style={{ marginLeft: '10px', borderRadius: '0.25rem' }}
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="down"
          iconPosition="right"
          label="Edit"
          options={[
            {
              label: 'Edit',
              value: 'edit',
            },
            {
              label: 'Save As',
              value: 'save_as',
            },
            {
              label: 'Delete Favorite',
              value: 'delete_favorite',
            },
          ]}
          onSelect={(value: string): void => handleInventoryFavoriteActionDropdownSelect(value)}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );

  const iconPath = getEventIcon('Inventory');

  return (
    <IconSettings iconPath="/icons">
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        label="Favorite Name"
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">{nameOfFavorite}</span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div className="slds-text-title" title="Sales Rep" style={{ fontWeight: 'bold' }}>
            Service Level
          </div>
          <div className="slds-tooltip-trigger">
            <div title="serviceLevel">{serviceLevel}</div>
            <span />
          </div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Comment"
            style={{ fontWeight: 'bold' }}
          >
            Header Comments
          </div>
          {comments && comments.length
            ? comments.filter(
                (item: { commentType: string }) => item?.commentType === 'Internal'
              )?.[0]?.comment || '--'
            : '--'}
        </li>
      </ul>
    </IconSettings>
  );
};

/** Custom Header on Inventory Request Detail Header Page **/
export default InventoryFavoriteDetailHeader;
