/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Module for header on InventoryRequestDetailCatalog Page
 * @module src/SurgicalDetail/InventoryRequestDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Icon from '@salesforce/design-system-react/components/icon';
import './index.scss';
import InventoryRequestTable from '../InventoryRequestTable';
import InventoryRequestShippimgTab from '../InventoryRequestShippingTab';
import { getSvgIcon } from '../../../../util/utilityFunctions';

interface Props {
  inventoryDetail?: any;
  isEventInventoryFlow?: boolean;
  refetch: () => void;
  refetchOnAdd?: () => void;
  visualIndicator: string;
  viewOnly?: boolean;
  type: string;
  parentEventStatus?: string;
  hasEventInventory?: any;
  eventInventoryExternalId?: any;
  externalId?: any;
  changeSelectedTab: (value: number) => void;
  tabConfig?: any;
  handleLineItemActions?: any;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryRequestDetailCatalog: FC<Props> = ({
  refetch,
  inventoryDetail,
  isEventInventoryFlow,
  visualIndicator,
  viewOnly,
  type,
  hasEventInventory,
  eventInventoryExternalId,
  externalId,
  changeSelectedTab,
  tabConfig,
  handleLineItemActions,
  refetchOnAdd,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
    changeSelectedTab(value);
  };
  const trialPartInd = getSvgIcon('trialPart');

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getEnabledTabs = (key: string) => {
    let enabledTabs: any[] = [];
    if (tabConfig && tabConfig[key]) {
      tabConfig[key].forEach((item: any) => {
        if (item.isShow === true) {
          enabledTabs = [...enabledTabs, item.mappingKey];
        }
      });

      if (key === 'shipping_tab') {
        tabConfig.inventory_tab.forEach((item: any) => {
          if (
            item.isShow === true &&
            (item.mappingKey === 'actualUom' || item.mappingKey === 'actualQty')
          ) {
            enabledTabs = [...enabledTabs, item.mappingKey];
          }
        });
      }
    }
    return enabledTabs;
  };

  return (
    <div style={{ padding: '16px' }}>
      <IconSettings iconPath="/icons">
        <Tabs
          variant="scoped"
          id="usage-tabs-scoped-returns"
          onSelect={handleTabSelect}
          defaultSelectedIndex={0}
          selectedIndex={selectedTabIndex}
        >
          <TabsPanel label="Inventory">
            <InventoryRequestTable
              refetch={refetch}
              inventoryDetail={inventoryDetail}
              isEventInventoryFlow={isEventInventoryFlow}
              visualIndicator={visualIndicator}
              type={type}
              hasEventInventory={hasEventInventory}
              viewOnly={viewOnly}
              eventInventoryExternalId={eventInventoryExternalId}
              externalId={externalId}
              handleLineItemActions={handleLineItemActions}
              tabConfig={getEnabledTabs('inventory_tab')}
              refetchOnAdd={refetchOnAdd}
            />
          </TabsPanel>
          <TabsPanel label="Shipping">
            <InventoryRequestShippimgTab
              externalId={externalId}
              tabConfig={getEnabledTabs('shipping_tab')}
            />
          </TabsPanel>
          <TabsPanel
            disabled
            onClick={(e: any) => {
              e.preventDefault();
            }}
            style={{
              cursor: 'default',
            }}
            label={
              <div
                style={{
                  display: 'inline-block',
                  cursor: 'default',
                  color: '#000000',
                  fontSize: '15px',
                }}
              >
                <span style={{ marginLeft: '30px', marginRight: '5px' }}>
                  <img
                    src={trialPartInd}
                    className="logo-image"
                    alt="trial part"
                    style={{ marginLeft: '8px', marginRight: '8px' }}
                  />
                  {'Received ≤ 365 days'}
                </span>
                {type === 'Failed' && (
                  <span style={{ marginLeft: '30px' }}>
                    <Icon
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ fill: '#D2042D', marginRight: '5px' }}
                    />
                    {'Error'}
                  </span>
                )}
              </div>
            }
          />
          {/* <TabsPanel label="Dispostition" />*/}
        </Tabs>
      </IconSettings>
    </div>
  );
};

/** Custom Header on InventoryRequestDetailCatalog Page **/
export default InventoryRequestDetailCatalog;
