/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { INVENTORY_SHIPPING } from '../../../util/constants';
import { getEventIcon, getEventIconInner } from '../../../util/utilityFunctions';
import { GET_SAS_URI } from '../../../graphql/getSasUri';
import { GET_SHIP_TO_ADDRESSES } from '../../../graphql/getShipToAddresses';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';

interface Props extends RouteComponentProps {
  inventoryReturnDetails: InventoryReturnDetails;
  handleRequestClick?: any;
  viewOnly: boolean;
  setShipMethodValue: (item: string) => void;
  handleEditClick: () => void;
  submitIRDetails: () => void;
}

interface File {
  imageurl: string;
  name: string;
}

interface DropdownSelect {
  label: string;
  value: string;
}
interface InventoryReturnDetails {
  orderNumber: string;
  status: string;
  type: string;
  salesRepName: string;
  shipmentDate: string;
  submittedDate: string;
  trackingNumber: string;
  territoryNumber: string;
  serviceLevel?: string;
  shipMethod?: string;
  attachments?: any;
  returnReason?: any;
  accountName?: any;
  accountNumber?: any;
  shippingLabelAttachments?: any;
  isshippingLabelRequired?: any;
  returnType?: any;
  visualIndicator?: any;
  liftGateNeeded?: any;
  dockAvailable?: any;
  shortTruckOnly?: any;
  onsiteContractEmail?: any;
  onsiteContractInstructions?: any;
  onsiteContractName?: any;
  onsiteContractPhone?: any;
  shipToId?: any;
  comments?: any;
  accountId?: any;
  parentEventId?: any;
  parentEventType?: any;
  parentCaseExternalId?: any;
  parentEventSubType?: any;
  shippingAddessLine1: any;
  shippingAddessLine2: any;
  shippingAddressNumber: any;
  shippingCity: any;
  shippingPostalcode: any;
  shippingState: any;
  salesRepNameIns?: any;
  salesRepEmailIns?: any;
  salesRepPhoneIns?: any;
  shippingInstructions?: any;
}

const InventoryQueueHeader: FC<Props> = ({
  history,
  inventoryReturnDetails,
  handleRequestClick,
  viewOnly,
  setShipMethodValue,
  handleEditClick,
  submitIRDetails,
}) => {
  const {
    serviceLevel,
    shipMethod,
    status,
    type = 'Inventory Return',
    orderNumber,
    accountId,
    salesRepName,
    accountName,
    accountNumber,
    visualIndicator,
    // trackingNumber,
    shipmentDate,
    // returnReason,
    attachments,
    // territoryNumber,
    liftGateNeeded,
    dockAvailable,
    shortTruckOnly,
    onsiteContractEmail,
    onsiteContractInstructions,
    onsiteContractName,
    onsiteContractPhone,
    returnType,
    isshippingLabelRequired,
    shippingLabelAttachments,
    comments,
    shipToId,
    shippingAddessLine1,
    shippingAddessLine2,
    shippingAddressNumber,
    shippingCity,
    shippingPostalcode,
    shippingState,
    salesRepNameIns,
    salesRepEmailIns,
    salesRepPhoneIns,
    parentEventId,
    parentEventType,
    parentCaseExternalId,
    parentEventSubType,
    shippingInstructions,
  } = inventoryReturnDetails;
  const showReturnType = returnType;
  const [shipMethodOptions, setShipMethodOptions] = useState<DropdownSelect[]>();
  const [shipMethodSelected, setShipMethodSelected] = useState<DropdownSelect[]>();
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const parenticonPath = getEventIconInner(parentEventType);
  const [getShipToAddressItems, { data: shipToAddressesItems }] = useLazyQuery(
    GET_SHIP_TO_ADDRESSES,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY
  useEffect(() => {
    const inventoryShipping = INVENTORY_SHIPPING.find(
      (item: any) => item.serviceLevel === serviceLevel
    );
    if (inventoryShipping && inventoryShipping.shipMethodOptions) {
      const options = inventoryShipping.shipMethodOptions.map((item: any) => {
        return {
          label: item,
          value: item,
        };
      });
      setShipMethodOptions(options);
    }
  }, [serviceLevel]);
  useEffect(() => {
    if (shipMethod) {
      setShipMethodSelected([{ label: shipMethod, value: shipMethod }]);
    }
  }, [shipMethod]);
  useEffect(() => {
    getShipToAddressItems({
      variables: {
        accountId,
      },
    });
  }, [getShipToAddressItems, accountId]);
  const handleChangeRequest = (): void => {
    handleRequestClick(parentEventType, parentCaseExternalId, parentEventId);
  };

  const handleNavigateToEventList = (): void => {
    window.history.back();
  };

  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };
  const shipTo =
    shipToAddressesItems &&
    shipToAddressesItems.getShipToAddresses.filter((e: any) => e.id === shipToId);

  const dropdown = [
    {
      disabled: viewOnly || status === 'Completed' || visualIndicator === 'Completed',
      label: 'Edit',
      value: 'edit',
    },
    {
      disabled: viewOnly || status === 'Completed' || visualIndicator === 'Completed',
      label: 'Submit',
      value: 'submit',
    },
    {
      disabled: true,
      label: 'Cancel',
      value: 'cancel',
    },
  ];

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <Dropdown
          style={{ width: '6rem', marginLeft: '10px', borderRadius: '0.25rem' }}
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="down"
          iconPosition="right"
          label="Actions"
          options={dropdown}
          onSelect={(option: any): void => {
            if (option.value === 'edit' && !option.disabled) {
              handleEditClick();
            } else if (option.value === 'submit' && !option.disabled) {
              submitIRDetails();
            }
          }}
        />
      </PageHeaderControl>
      <PageHeaderControl>
        <DisplayRecordIndicator recordInfo={inventoryReturnDetails} />
      </PageHeaderControl>
    </>
  );

  const handleSelectDropdownItem = (data: any): void => {
    setShipMethodSelected(data.selection || []);
    setShipMethodValue(data.selection ? data.selection[0]?.label : '');
  };
  const iconPath = getEventIcon(type);
  return (
    <IconSettings iconPath="/icons">
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        label={type === 'Inventory Return' ? 'Standard Return' : type}
        onRenderActions={headerActions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">{orderNumber}</span>
            <span className="slds-col slds-m-left_large">
              <div style={{ display: 'flex' }}>
                <EventStatusBadge
                  status={
                    visualIndicator === 'Rejected' || visualIndicator === 'Approved'
                      ? status
                      : visualIndicator
                  }
                />
                {visualIndicator === 'Rejected' && (
                  <div style={{ marginLeft: '5px' }}>
                    <Icon
                      title="Rejected"
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ color: '#b83c27' }}
                      className="slds-icon-text-error"
                    />
                  </div>
                )}
              </div>
            </span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div className="slds-text-title" title="Sales Rep" style={{ fontWeight: 'bold' }}>
            Sales Rep
          </div>
          <div className="slds-tooltip-trigger">
            <div title={salesRepName}>{salesRepName}</div>
            <span />
          </div>
        </li>
        {showReturnType === 'Pickup Return' ? (
          <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Account"
              style={{ fontWeight: 'bold' }}
            >
              Account
            </div>
            <div className="" title="--">
              {`${accountNumber} - ${accountName}`}
            </div>
          </li>
        ) : (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Expected Return Date"
              style={{ fontWeight: 'bold' }}
            >
              Expected Return Date
            </div>
            <p className="slds-line-clamp">
              {shipmentDate
                ? moment(shipmentDate)
                    .utc()
                    .format('MM/DD/YYYY')
                : '--'}
            </p>
          </li>
        )}
        {
          <li className="slds-page-header__detail">
            <div
              // className="slds-text-title"
              title="Ship Date"
              style={{ fontWeight: 'bold' }}
            >
              Ship To
            </div>
            <div style={{ width: '100%', fontSize: '16px' }} title="--">
              {shippingAddessLine1
                ? `${
                    shippingAddressNumber ? `${shippingAddressNumber} -` : ''
                  } ${shippingAddessLine1 || ''}, ${shippingAddessLine2 || ''}, ${shippingCity ||
                    ''}, ${shippingState || ''}, ${shippingPostalcode || ''}, ${
                    !shippingAddressNumber ? 'US' : ''
                  }`
                : '--'}
            </div>
          </li>
        }
      </ul>
      <ul
        style={{ borderBottom: 'none' }}
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
      >
        {showReturnType === 'Pickup Return' ? (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Requested Pickup Date"
              style={{ fontWeight: 'bold' }}
            >
              Requested Pickup Date
            </div>
            <p className="slds-line-clamp">
              {shipmentDate
                ? moment(shipmentDate)
                    .utc()
                    .format('MM/DD/YYYY')
                : '--'}
            </p>
          </li>
        ) : (
          <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Request Shipping Label"
              style={{ fontWeight: 'bold' }}
            >
              Request Shipping Label
            </div>
            <div className="" title="--">
              {isshippingLabelRequired ? 'Yes' : 'No'}
            </div>
          </li>
        )}
        {showReturnType !== 'Pickup Return' && isshippingLabelRequired && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Shipping Label"
              style={{ fontWeight: 'bold' }}
            >
              Shipping Label
            </div>
            {showReturnType !== 'Pickup Return' &&
            shippingLabelAttachments &&
            shippingLabelAttachments.length > 0 ? (
              shippingLabelAttachments.map((item: any) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(): void => handleAttachmentsView(item)}
                    aria-hidden="true"
                  >
                    {`${item.name}`}
                  </div>
                );
              })
            ) : (
              <div title="">--</div>
            )}
          </li>
        )}
      </ul>
      {showReturnType === 'Pickup Return' && (
        <ul
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none' }}
        >
          <li className="slds-page-header__detail">
            <div
              className="slds-text-title slds-truncate"
              title="Pickup Details"
              style={{ fontWeight: 'bold' }}
            >
              Pickup Details
            </div>
            {
              <div style={{ fontSize: '16px' }} title="Specific Shipping Details">
                <span>
                  {` Sales Rep Name: ${
                    salesRepNameIns && salesRepNameIns !== 'null' ? salesRepNameIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Contact Phone: ${
                    salesRepPhoneIns && salesRepPhoneIns !== 'null' ? salesRepPhoneIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Email: ${
                    salesRepEmailIns && salesRepEmailIns !== 'null' ? salesRepEmailIns : ''
                  }`}
                </span>
                <span>{` | Lift Gate Needed: ${liftGateNeeded ? 'Yes' : 'No'}`}</span>
                <span>{` | Dock Available: ${dockAvailable ? 'Yes' : 'No'}`}</span>
                <span>{` | Short Truck Only: ${shortTruckOnly ? 'Yes' : 'No'}`}</span>
                <span>{` | Onsite Contact Name: ${onsiteContractName}`}</span>
                <span>{` | Onsite Contact Phone: ${onsiteContractPhone}`}</span>
                {onsiteContractEmail && (
                  <span>{` | Onsite Contact Email: ${onsiteContractEmail}`}</span>
                )}
                {onsiteContractInstructions && (
                  <span>{` | Onsite Instructions: ${onsiteContractInstructions}`}</span>
                )}
              </div>
            }
          </li>
        </ul>
      )}
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Attachment"
            style={{ fontWeight: 'bold' }}
          >
            Attachment
          </div>
          {attachments.length > 0 ? (
            attachments.map((item: any) => {
              return (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={(): void => handleAttachmentsView(item)}
                  aria-hidden="true"
                >
                  {`${item.name}`}
                </div>
              );
            })
          ) : (
            <div title="">--</div>
          )}
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Header Comments"
            style={{ fontWeight: 'bold' }}
          >
            Header Comments
          </div>
          {comments && comments.length
            ? comments.filter((item: { type: string }) => item?.type === 'Internal')?.[0]?.value ||
              '--'
            : '--'}
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Comment"
            style={{ fontWeight: 'bold' }}
          >
            Related Documents
          </div>
          {parentEventId ? (
            <div title={parentEventId}>
              <img src={parenticonPath} alt="company" />
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventType === 'Demo' && parentEventSubType === 'Demo'
                  ? 'Standard Demo'
                  : parentEventSubType || parentEventType}
              </span>
              <span
                role="button"
                tabIndex={0}
                className="slds-button"
                onClick={handleChangeRequest}
                onKeyDown={handleChangeRequest}
                style={{ cursor: 'pointer' }}
              >
                {parentEventId}
              </span>
            </div>
          ) : (
            '--'
          )}
        </li>
      </ul>
    </IconSettings>
  );
};

export default withRouter(InventoryQueueHeader);
