/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-template */
/* eslint-disable no-self-assign */
/**
 * FormEngine shared component.
 * @module src/components/Shared/FormEngine
 */
import React, { FC, useCallback, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import * as Yup from 'yup';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { isBefore, format, isEqual, isSameDay } from 'date-fns';
import moment from 'moment';
import { EVENT_STATUS, FormInputTypes, REGION, USAGE_TYPE } from '../../../util/constants';
import { getDateDifferenceInDays, getHoldIcon } from '../../../util/utilityFunctions';
import { getUserId } from '../../../store/ducks/userId';
import RenderDynamicFields from './RenderDynamicFields';
import { GET_SHIP_TO_ADDRESSES } from '../../../graphql/getShipToAddresses';
import { GET_USER_E_ACCOUNT } from '../../../graphql/getUserEAccount';
import { getUserInfo } from '../../../store/ducks/userInfo';
// Constants used for fields in json
const FIELD = 'field';
const SECTION = 'section';

const eAccountAddresses: any[] = [];

interface VariableType {
  [key: string]: string;
}

const VARIABLES_TYPE: VariableType = {
  franchise: 'division',
  country: 'branchId',
  account: 'accountId',
  surgeon: 'contactId',
  salesRep: 'salesRepId',
  primaryAccount: 'accountId',
};

interface FunctionMap {
  [key: string]: Function;
}

interface WorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface Props {
  division: string;
  formItems: Form;
  apiData: any;
  selectSalesRep?: any;
  useLazyQueryObject: FunctionMap;
  handleSave: (result: any) => void;
  toggleOpen: () => void;
  isOpen: boolean;
  createEventLoading?: boolean;
  createRequest?: boolean;
  showCSRFields?: WorkflowPermission | null;
  showSurgeonFields?: WorkflowPermission | null;
  formValues?: any;
  accountChecked?: any;
  selectedTab?: any;
  handleRadioButtonChange?: any;
  handleToggleButtonChange?: any;
  handleTabChange?: any;
  partsData?: any;
  locationChecked?: any;
  handleClickedButton?: (buttonType: string) => void;
  eventStatus?: string;
  showCreateInventoryButton?: WorkflowPermission | null;
  showCreateOpenButton?: WorkflowPermission | null;
  handleCancelButton?: () => void;
  handleCallback?: any;
  isEventInventoryButton?: boolean;
  handleUploadFiles?:
    | ((value: string, label: string) => Record<string, any> | undefined)
    | undefined;
  handleDeleteAttachments?: ((value: any, label: string) => any | undefined) | undefined;
  uploadedFiles?: File[];
  uploadedShippingLabelFiles?: File[];
  modalHeading?: string;
  handleAccountSelection?: any;
  handleRecievingRepSelection?: any;
  handleAccountIdSelection?: any;
  handleSalesRepSelection?: any;
  eventUsage?: any;
  warning?: boolean;
  partsIssue?: string;
  formType?: string;
  handleShippingToggleButton?: any;
  handleServiceLevelJustification?: any;
  isEdit?: any;
  isIR?: boolean;
  isUserOpsCsr?: boolean;
  type?: boolean;
}

interface FormFieldOption {
  value?: string;
  name?: string;
  label: string;
  id?: string;
}

interface File {
  id?: string;
  name: string;
  imageurl: string;
}

/** Exporting FormField type interface */
interface FormField {
  kind: typeof FIELD;
  name: string;
  fieldLabel: string;
  fieldType: string;
  required: boolean;
  optionsStatic?: FormFieldOption[];
  optionsPlaceHolder?: string;
  optionsDynamic?: string;
  optionsDependentField?: string[];
  optionsDynamicDependent?: string;
  optionsAutoSelected: boolean;
  characterLimit?: number;
  showTooltip?: boolean;
  permissionSets?: string[];
  showOnWeb?: boolean;
  disabled?: boolean;
  showAddButton?: boolean;
  addButtonText?: string;
  keypadType?: 'number-pad' | 'default';
  minValue?: string;
  maxValue?: string;
  allowBackDate?: boolean;
  date?: boolean;
  isShow?: boolean;
  time?: boolean;
  fields?: string;
  isFailed?: boolean;
}

interface ShipToAddress {
  address1?: string;
  address2?: string;
  city?: string;
  id?: string;
  isPrimaryAddress?: boolean;
  isPrimaryAccount?: boolean;
  name?: string;
  label?: string;
  value?: string;
  postalcode?: string;
  state?: string;
  __typename?: string;
}

interface FormSection {
  kind: typeof SECTION;
  fields: FormField[];
}

/** Exporting Form type interface */
export type Form = (FormField | FormSection)[];

interface FormInitialValues {
  [key: string]: any;
}

// TODO. formik methods have types defined as 'any', need to do a bit research.
const FormEngine: FC<Props> = ({
  uploadedFiles,
  uploadedShippingLabelFiles,
  formItems,
  apiData,
  useLazyQueryObject,
  handleSave,
  createEventLoading,
  showCSRFields,
  showSurgeonFields,
  formValues,
  handleClickedButton,
  handleRadioButtonChange,
  handleToggleButtonChange,
  handleTabChange,
  accountChecked,
  locationChecked,
  eventStatus = '',
  selectedTab,
  showCreateOpenButton,
  handleCancelButton,
  division,
  handleCallback,
  handleUploadFiles,
  handleDeleteAttachments,
  isEventInventoryButton = false,
  modalHeading,
  handleAccountSelection,
  handleRecievingRepSelection,
  handleAccountIdSelection,
  handleSalesRepSelection,
  partsData,
  eventUsage,
  warning,
  partsIssue,
  formType,
  handleShippingToggleButton,
  handleServiceLevelJustification,
  isEdit,
  createRequest,
  isUserOpsCsr,
  type,
  isIR,
}: Props) => {
  const client = useApolloClient();
  const [showAlert, setShowAlert] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [editShipTo, setEditShipTo] = useState(false);
  const [editBillTo, setEditBillTo] = useState(false);
  const [accountChange, setAccountChange] = useState(false);
  const [ShipToFlag, setShipToFlagChange] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [apiDataState, setApiDataState] = useState(apiData);
  const [showTimeAlert, setShowTimeAlert] = useState(false);
  const [selectTab, setSelectedTab] = useState('');
  const [checkedItems, setChecked] = useState<any[]>([]);
  const [partsList, setPartsList] = useState<any[]>([]);
  const [addedSunInvPartLocal, setAddedSunInvPartLocal] = useState<any>([]);
  const USER_ID = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [currentSalesRep, setCurrentSalesRep] = useState('');
  const [repStockAddress, setRepStockAddress] = useState<any>([]);
  const [flag, setFlag] = useState(false);
  let setFieldValueTemp: any;
  const isEditEvent = eventStatus !== '';
  const [
    getShipToAddressItems,
    { data: shipToAddressesItems, loading: loadingShipToApi },
  ] = useLazyQuery(GET_SHIP_TO_ADDRESSES, {
    fetchPolicy: 'no-cache',
  });
  const shipToAddresses = shipToAddressesItems?.getShipToAddresses;
  const [getUserEAccount, { data: userEAccount }] = useLazyQuery(GET_USER_E_ACCOUNT, {
    fetchPolicy: 'no-cache',
  });
  const [getUserEAccountForSalesRep, { data: userEAccountForSalesRep }] = useLazyQuery(
    GET_USER_E_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (!accountChange) {
      setEditShipTo(false);
      setEditBillTo(false);
    }
  }, [formValues]);
  // handling field disable
  const handleFieldDisable = useCallback(
    (optionsDependentField: string[] | undefined, values: any) => {
      if (optionsDependentField) {
        return optionsDependentField.some(
          item => !values[`${item}`] || (values[`${item}`] && values[`${item}`].length === 0)
        );
      }
      return false;
    },
    []
  );

  useEffect(() => {
    const repStockAccount = apiDataState?.getAccounts?.find(
      (account: any) => account.isRepStockAccount === true
    );
    getShipToAddressItems({
      variables: {
        accountId: repStockAccount?.id,
      },
    });
  }, [apiDataState, modalHeading]);

  const handlefailedTabFieldDisable = useCallback((isFailed: boolean | undefined) => {
    if (isFailed && isUserOpsCsr && type) {
      return isFailed;
    }
    if (formType === 'Failed') {
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    if (!accountChange) {
      setEditShipTo(false);
      setEditBillTo(false);
    }
  }, [formValues]);

  const getFormInitialValues = useCallback(() => {
    const initialValuesSchema: FormInitialValues = {}; // initial values
    let schema: any = {}; // validation schema
    formItems.forEach(element => {
      if (element.kind === SECTION) {
        element.fields.forEach((fieldItem: FormField) => {
          if (
            showCSRFields &&
            fieldItem.permissionSets &&
            fieldItem.permissionSets.includes('show_csr_fields') &&
            fieldItem.isShow
          ) {
            const fieldName = fieldItem.name;
            if (fieldItem.fieldType === FormInputTypes.TOGGLE_SWITCH) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? false : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.DATE_PICKER) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? null : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.TIME_PICKER) {
              const date = new Date();
              date.setHours(8);
              date.setMinutes(0);
              initialValuesSchema[fieldName] =
                formValues === undefined ? date : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.ATTACHMENTS) {
              initialValuesSchema[fieldName] = uploadedFiles?.length ? [] : uploadedFiles?.[0];
            } else {
              initialValuesSchema[fieldName] =
                formValues === undefined ? '' : formValues[`${fieldName}`];
            }
            if (
              fieldItem.required &&
              fieldItem.fieldType !== 'radio' &&
              fieldItem.fieldType !== 'static'
            ) {
              schema = { ...schema, [fieldName]: Yup.string().required() };
            }
          } else if (
            showSurgeonFields &&
            fieldItem.permissionSets &&
            fieldItem.permissionSets.includes('show_surgeon_fields') &&
            fieldItem?.isShow
          ) {
            const fieldName = fieldItem.name;
            if (fieldItem.fieldType === FormInputTypes.TOGGLE_SWITCH) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? false : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.DATE_PICKER) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? null : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.TIME_PICKER) {
              const date = new Date();
              date.setHours(8);
              date.setMinutes(0);
              initialValuesSchema[fieldName] =
                formValues === undefined ? date : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.ATTACHMENTS) {
              initialValuesSchema[fieldName] = uploadedFiles?.length ? [] : uploadedFiles?.[0];
            } else {
              initialValuesSchema[fieldName] =
                formValues === undefined ? '' : formValues[`${fieldName}`];
            }
            if (
              fieldItem.required &&
              fieldItem.fieldType !== 'radio' &&
              fieldItem.fieldType !== 'static'
            ) {
              schema = { ...schema, [fieldName]: Yup.string().required() };
            }
          } else if (
            fieldItem.isShow &&
            ((Array.isArray(fieldItem.permissionSets) && !fieldItem.permissionSets.length) ||
              fieldItem.permissionSets === null)
          ) {
            const fieldName = fieldItem.name;
            if (fieldItem.fieldType === FormInputTypes.TOGGLE_SWITCH) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? false : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.DATE_PICKER) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? null : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.TEXT_AREA) {
              initialValuesSchema[fieldName] =
                formValues === undefined ? null : formValues[`${fieldName}`];
            }
            // else if (
            //   fieldItem.fieldType === FormInputTypes.FILTER_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER
            // ) {
            //   initialValuesSchema[fieldName] =
            //     formValues === undefined ? null : formValues[`${fieldName}`];
            // }
            else if (fieldItem.fieldType === FormInputTypes.TIME_PICKER) {
              const date = new Date();
              date.setHours(8);
              date.setMinutes(0);
              initialValuesSchema[fieldName] =
                formValues === undefined ? date : formValues[`${fieldName}`];
            } else if (fieldItem.fieldType === FormInputTypes.ATTACHMENTS) {
              initialValuesSchema[fieldName] = uploadedFiles?.length ? [] : uploadedFiles?.[0];
            } else {
              initialValuesSchema[fieldName] =
                formValues === undefined ? '' : formValues[`${fieldName}`];
            }

            if (
              fieldItem.required &&
              fieldItem.fieldType !== 'radio' &&
              fieldItem.fieldType !== 'static'
            ) {
              schema = { ...schema, [fieldName]: Yup.string().required() };
            }
          }
        });
      }
    });
    const validationSchema = Yup.object().shape(schema);
    return { initialValuesSchema, validationSchema };
  }, [formItems, formValues, showCSRFields, showSurgeonFields]);

  // This fucntion is created to get dynamic query parameters for api calling
  const getQueryParameters = useCallback(
    (values: any, searchText: string, dependentElements?: string[]) => {
      let variables: any = {
        userId: USER_ID,
        region: REGION, // TODO later it will changed to logged in userId
        searchText,
      };

      if (dependentElements) {
        dependentElements.forEach((element: string) => {
          const key = VARIABLES_TYPE[element];
          if (values[element] && values[element].length > 0) {
            const variableValues = { [key]: values[element][0].id };
            variables = { ...variables, ...variableValues };
          }
        });
      }
      return variables;
    },
    [USER_ID]
  );
  const [selectedAccount, setSelectedAccount] = useState<any>();
  const handleSelectAccount = useCallback(
    (accoutValue, name) => {
      setSelectedAccount(accoutValue[0]);
      if (handleAccountSelection) {
        handleAccountSelection(accoutValue, name);
      }
    },
    [handleAccountSelection]
  );
  const holdIconPath = getHoldIcon();
  const handleSelectRecievingRep = useCallback(
    accoutValue => {
      if (handleRecievingRepSelection) {
        handleRecievingRepSelection(accoutValue);
      }
    },
    [handleRecievingRepSelection]
  );

  const handleSelectSalesRep = useCallback(
    accoutValue => {
      setCurrentSalesRep(accoutValue?.[0]?.id);
      if (handleSalesRepSelection) {
        handleSalesRepSelection(accoutValue);
      }
    },
    [handleSalesRepSelection]
  );

  // Getting picker's selected values and saving it in formik values
  // Checking validation based on dependent values
  // const handleSelectSalesRep = useCallback(
  //   salesRepValue => {
  //     if (salesRepValue && selectSalesRep) {
  //       selectSalesRep(salesRepValue);
  //     }
  //   },
  //   [selectSalesRep]
  // );

  const formatPhoneNumber = (input: any, inputType: any) => {
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 11);
    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size === 0) {
      input = input;
    } else if (size < 2 && inputType !== 'deleteContentBackward') {
      input = input.substring(0, 1) + '-';
    } else if (size < 4 && inputType !== 'deleteContentBackward') {
      input = input.substring(0, 1) + '-' + input.substring(1, 4);
    } else if (size < 7 && inputType !== 'deleteContentBackward') {
      input = input.substring(0, 1) + '-' + input.substring(1, 4) + '-' + input.substring(4, 6);
    } else if (inputType !== 'deleteContentBackward') {
      input =
        input.substring(0, 1) +
        '-' +
        input.substring(1, 4) +
        '-' +
        input.substring(4, 7) +
        '-' +
        input.substring(7, 11);
    }
    return input;
  };
  const handleFormValues = useCallback(
    (
      setFieldValue: any,
      name: string,
      result: any,
      values: any,
      optionsDynamic = '',
      inputType: any,
      clearValue?: boolean
    ) => {
      setShowAlert(false);
      setShowTimeAlert(false);
      setDisableSave(false);
      setFieldValue(name, result); // saving in formik values
      if (
        name === 'specificShipingDetails' ||
        name === 'locationToggle' ||
        name === 'eventLocation'
      ) {
        handleRadioButtonChange(result, name);
      }
      if (name === 'tabNavigator') {
        handleTabChange(result);
        setSelectedTab(result?.label);
      }
      if (name === 'liftGateNeeded' || name === 'dockAvailable' || name === 'serviceLevel') {
        if (name === 'liftGateNeeded' && result === true) {
          setFieldValue('dockAvailable', false);
        }
        if (name === 'dockAvailable' && result === true) {
          setFieldValue('liftGateNeeded', false);
        }
        if (name === 'serviceLevel') {
          setFlag(true);
          if (result[0]?.value === '3PL Delivery') {
            handleShippingToggleButton('Yes');
          }
          if (result[0]?.value === 'First AM' || result[0]?.label === 'First AM') {
            handleServiceLevelJustification(true);
          } else {
            handleServiceLevelJustification(false);
            setFieldValue('serviceLevelJustification', '');
          }
        }
      }
      if (name === 'account' || name === 'primaryAccount') {
        setShipToFlagChange(true);
      }
      if (
        name === 'shippingLabelAttachments' ||
        name === 'Attachments' ||
        name === 'isshippingLabelRequired'
      ) {
        setShipToFlagChange(false);
      }

      if (name === 'isshippingLabelRequired') {
        handleToggleButtonChange(result, name);
      }
      // eslint-disable-next-line prefer-object-spread
      const valuesObjCopy = Object.assign({}, values);
      Object.keys(values).forEach(key => {
        if (key === name) {
          valuesObjCopy[key] = result; // Creating values obje copy and setting the values
        }
      });
      const {
        endDate,
        startDate,
        endTime,
        startTime,
        eventDate,
        account,
        deliveryContractPhone,
        onsiteContractPhone,
        deliveryContractEmail,
        onsiteContractEmail,
        zipCode,
        opportunity,
      } = valuesObjCopy;
      if (deliveryContractPhone) {
        const reg = /^\s*(?:\+?(\d{1,3}))[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        const formatedPhone = formatPhoneNumber(deliveryContractPhone, inputType);
        setFieldValue('deliveryContractPhone', formatedPhone);
        if (reg.test(formatedPhone) === false) {
          setShowTimeAlert(true);
          setAlertMessage('Please Enter Valid Phone Number');
        }
      }
      if (onsiteContractPhone) {
        const reg = /^\s*(?:\+?(\d{1,3}))[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        const formatedPhoneOnsite = formatPhoneNumber(onsiteContractPhone, inputType);
        if (inputType === undefined) {
          setTimeout(() => {
            setFieldValue('onsiteContractPhone', formatedPhoneOnsite);
          }, 0);
        } else {
          setFieldValue('onsiteContractPhone', formatedPhoneOnsite);
        }
        if (reg.test(formatedPhoneOnsite) === false) {
          setShowTimeAlert(true);
          setAlertMessage('Please Enter Valid Phone Number');
        }
      }

      if (deliveryContractEmail) {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(deliveryContractEmail) === false) {
          setShowTimeAlert(true);
          setAlertMessage('Please Enter Valid Email');
        }
      }
      if (onsiteContractEmail) {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(onsiteContractEmail) === false) {
          setShowTimeAlert(true);
          setAlertMessage('Please Enter Valid Email');
        }
      }
      if (zipCode) {
        const reg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        if (reg.test(zipCode) === false) {
          setShowTimeAlert(true);
          setAlertMessage('Please Enter Valid Zip Code');
        }
      }
      if (
        name === 'primaryAccount' ||
        name === 'sourceAccount' ||
        name === 'destinationAccount' ||
        name === 'account'
      ) {
        handleSelectAccount(result, name);
        setFlag(false);
      }
      if (name === 'sourceAccount') {
        if (!valuesObjCopy?.recievingRep) {
          setFieldValue('destinationAccount', []);
        }
      }
      if (name === 'recievingRep') {
        setFieldValue('destinationAccount', []);
        handleSelectRecievingRep(result);
      }
      if (name === 'salesRep') {
        setFieldValue('recievingRep', []);
        if (result?.length === 0) {
          setFieldValue('sourceAccount', []);
          setFieldValue('destinationAccount', []);
        }
        handleSelectRecievingRep([]);
        handleSelectSalesRep(result);
        if (formValues && formValues?.coveringRep?.length === 1) {
          setTimeout(() => {
            setFieldValue('coveringRep', formValues.coveringRep);
          }, 500);
        }
      }

      if (name === 'account') {
        if (editShipTo || editBillTo) {
          setEditShipTo(true);
          setEditBillTo(true);
          setAccountChange(true);
          setFieldValue('billTo', []);
          setFieldValue('shipTo', []);
        }

        if (handleAccountIdSelection) {
          handleAccountIdSelection(result);
        }
        if (
          formValues &&
          formValues?.opportunity?.length === 1 &&
          formValues?.opportunity[0]?.id !== null
        ) {
          setFieldValue('opportunity', formValues?.opportunity);
        } else {
          setTimeout(() => {
            setFieldValue('opportunity', []);
          }, 1000);
        }
      }

      if (name === 'tbaAccount') {
        handleRadioButtonChange(result, name);
        setFieldValue('account', []);
      }
      if (name === 'eventDate' && eventDate) {
        const isSelectedDateToday =
          new Date().getDate() === new Date(eventDate).getDate() &&
          new Date().getMonth() === new Date(eventDate).getMonth() &&
          new Date().getFullYear() === new Date(eventDate).getFullYear();
        if (isSelectedDateToday) {
          const currentTime = format(new Date(), 'HH:mm');
          const selecetdTime = format(new Date(eventDate), 'HH:mm');

          if (selecetdTime < currentTime) {
            setShowTimeAlert(true);
            setAlertMessage('Event time cannot be in past time');
          }
        }
      }

      if (endDate && startDate) {
        const updateStartDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
        const updateEndDate = typeof endDate === 'string' ? new Date(endDate) : endDate;
        const selectedStartTime = format(new Date(startDate), 'HH:mm');
        const selectedEndTime = format(new Date(endDate), 'HH:mm');

        const formattedStartDate = new Date(moment(updateStartDate).format('YYYY-MM-DD'));
        const formattedEndDate = new Date(moment(updateEndDate).format('YYYY-MM-DD'));
        const sameDayCheck = isSameDay(formattedEndDate, formattedStartDate);

        const isBeforeDate = isBefore(formattedEndDate, formattedStartDate);
        if (isBeforeDate || (sameDayCheck && selectedStartTime > selectedEndTime)) {
          setShowAlert(true);
          if (isBeforeDate) setAlertMessage('Event Start Date cannot be after the Event End Date');
          else setAlertMessage('Event Start Date/Time cannot be after the Event End Date/Time');
        }

        // if (isEqual(formattedStartDate, formattedEndDate)) {
        //   const formattedEndTime = format(new Date(endDate), 'HH:mm');
        //   const formattedStartTime = format(new Date(startDate), 'HH:mm');

        //   if (formattedEndTime < formattedStartTime) {
        //     setShowAlert(true);
        //     setAlertMessage('End time cannot be less than or equal to start time');
        //   }
        // }
      } else {
        if (startDate && !(new Date(eventDate) > new Date(startDate))) {
          const selectedStartTime = format(new Date(startDate), 'HH:mm');
          const currentTime = format(new Date(), 'HH:mm');

          const isSelectedDateToday =
            new Date().getDate() === new Date(startDate).getDate() &&
            new Date().getMonth() === new Date(startDate).getMonth() &&
            new Date().getFullYear() === new Date(startDate).getFullYear();
          if (isSelectedDateToday) {
            if (currentTime > selectedStartTime) {
              setShowAlert(true);
              setAlertMessage('Event start time cannot be in past time');
            }
          }
        }
        if (endDate) {
          const selectedEndTime = format(new Date(endDate), 'HH:mm');
          const currentTime = format(new Date(), 'HH:mm');
          const isSelectedDateToday =
            new Date().getDate() === new Date(endDate).getDate() &&
            new Date().getMonth() === new Date(endDate).getMonth() &&
            new Date().getFullYear() === new Date(endDate).getFullYear();
          if (isSelectedDateToday) {
            if (currentTime > selectedEndTime) {
              setShowAlert(true);
              setAlertMessage('Event start time cannot be in past time');
            }
          }
        }
      }

      if (account && account.length === 0) {
        setDisableSave(true);
      }

      if (result && result.length === 0) {
        // eslint-disable-next-line prefer-object-spread
        const apiDataStateCopy = Object.assign({}, apiDataState);
        if (optionsDynamic) {
          if (clearValue) {
            apiDataStateCopy[optionsDynamic] = [];
          }

          formItems.forEach(formItem => {
            // Checking item kind isection
            if (formItem.kind === SECTION) {
              formItem.fields.forEach(element => {
                if (
                  element.optionsDependentField &&
                  element.optionsDependentField.includes(name) &&
                  element.optionsDynamic
                ) {
                  apiDataStateCopy[element.optionsDynamic] = [];
                }
              });
            }
          });
          setApiDataState(apiDataStateCopy);
        }
      }

      formItems.forEach(formItem => {
        // Checking item kind isection
        if (formItem.kind === SECTION) {
          formItem.fields.forEach(element => {
            if (element.optionsDependentField && element.optionsDependentField.includes(name)) {
              if (element.fieldType === 'datePicker') {
                setFieldValue(element.name, null);
              }
              setFieldValue(element.name, []);

              const callApiService = element.optionsDependentField.some(field => {
                return !valuesObjCopy[field] || valuesObjCopy[field].length === 0;
              });

              if (result && result.length > 0) {
                // Call api's for those whose dependent condition fullfilled
                const filterTypeQuery =
                  element.fieldType === FormInputTypes.FILTER_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER;
                if (!callApiService && element.optionsDynamic) {
                  const variables = getQueryParameters(
                    valuesObjCopy,
                    '',
                    element.optionsDependentField
                  );

                  const method = useLazyQueryObject[element.optionsDynamic];
                  if (method) {
                    if (element.optionsDynamic === 'getShipToAddresses') {
                      variables.type = 'Shipping';
                    }
                    if (element.optionsDynamic === 'getBillToAddresses') {
                      variables.type = 'Billing';
                    }
                    // Dynamic Api calling using unique key
                    method({
                      variables,
                    });
                  }
                }
              }
            }
          });
        }
      });
    },
    [apiDataState, formItems, getQueryParameters, useLazyQueryObject]
  );

  const callApiEditEvent = useCallback(() => {
    const valuesObjCopy = { ...formValues };

    Object.keys(formValues).forEach(key => {
      const result = valuesObjCopy[key]; // Creating values obje copy and setting the values

      formItems.forEach(formItem => {
        // Checking item kind isection
        if (formItem.kind === SECTION) {
          formItem.fields.forEach(element => {
            if (element.optionsDependentField && element.optionsDependentField.includes(key)) {
              const callApiService = element.optionsDependentField.some(field => {
                return !valuesObjCopy[field] || valuesObjCopy[field].length === 0;
              });

              if (result && result.length > 0) {
                // Call api's for those whose dependent condition fullfilled
                const filterTypeQuery =
                  element.fieldType === FormInputTypes.FILTER_SINGLE_DYNAMIC_AUTO_COMPLETE_PICKER;
                if (!callApiService && element.optionsDynamic) {
                  const variables = getQueryParameters(
                    valuesObjCopy,
                    '',
                    element.optionsDependentField
                  );
                  const method = useLazyQueryObject[element.optionsDynamic];

                  if (method) {
                    // Dynamic Api calling using unique key
                    method({
                      variables,
                    });
                  }
                }
              }
            }
          });
        }
      });
    });
  }, [formItems, formValues, useLazyQueryObject, getQueryParameters]);

  useEffect(() => {
    if (formValues) {
      callApiEditEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedLineItems, setLineItems] = useState<any[]>([]);

  // handling form result
  const handleFormSubmit = useCallback(
    (result: any) => {
      if (checkedItems && checkedItems.length > 0) {
        const checkedLineItems: Array<any> = [];
        // eslint-disable-next-line array-callback-return, consistent-return
        const finalLineItems = addedSunInvPartLocal.map((item: any) => {
          if (checkedItems.includes(item.id)) {
            checkedLineItems.push({
              ...item,
              actualPackContent: item?.packContent ? item?.packContent : '1',
              quantity: item?.counterValue ? item?.counterValue.toString() : item?.actualQuantity,
            });
          }
        });
        result.lineItems = checkedLineItems;
        handleSave(result);
      } else {
        handleSave(result);
      }
    },
    [handleSave]
  );

  const handleSaveButtonCLick = (handleSubmit: any, buttonType: string) => {
    setDisableSave(true);
    if (handleClickedButton) {
      handleClickedButton(buttonType);
    }

    handleSubmit();
  };

  // Code for parts tab actions(start)//

  const handleButtonToggle = (handleSubmit: any, buttonType: string) => {
    if (buttonType === 'next') {
      const nextTab = { label: 'Parts', value: 'parts' };
      handleTabChange(nextTab);
      setSelectedTab('Parts');
    }
  };

  const getPartsListFormatted = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any, index: number) => {
        return {
          ...item,
          id: index,
          counterValue:
            Number(item?.quantity) || Number(item?.actualQuantity - item?.dispositionQuantity),
        };
      });
      return list;
    }
    return [];
  }, []);

  useEffect(() => {
    if (partsData?.getSubInventoryWithLotDetails) {
      const list = getPartsListFormatted(partsData?.getSubInventoryWithLotDetails);
      setPartsList(list);
    }
  }, [getPartsListFormatted, partsData]);

  const handleCheckboxChange = (id: any, event: any): void => {
    if (event.target.checked) {
      if (checkedItems.length === 0 || !checkedItems.includes(id)) checkedItems.push(id);
    } else if (checkedItems.indexOf(id) !== -1) {
      checkedItems.splice(checkedItems.indexOf(id), 1);
    }
    const finalLineItems = partsList.map((item: any, index: number) => {
      if (item.id === id) {
        const sunInvIndex = addedSunInvPartLocal?.findIndex(
          (e: any) => e?.lotNumber === item?.lotNumber && e.productNumber === item?.productNumber
        );
        if (sunInvIndex > -1) {
          addedSunInvPartLocal[sunInvIndex] = partsList[index];
        } else {
          addedSunInvPartLocal.push(partsList[index]);
        }
        if (formType === USAGE_TYPE.INVENTORY_RETURN) {
          return {
            ...item,
            counterValue: 0,
            isChecked: event.target.checked,
          };
        }
        return {
          ...item,
          counterValue: item?.counterValue || item?.actualQuantity,
          isChecked: event.target.checked,
        };
      }
      return {
        ...item,
        counterValue: item?.counterValue || item?.actualQuantity,
        isChecked: item.isChecked,
      };
    });
    setPartsList(finalLineItems);
    setChecked(checkedItems);
    setAddedSunInvPartLocal(addedSunInvPartLocal);
  };

  const handleCheckboxChangeForAll = (event: any): void => {
    const finalLineItems = partsList.map((item: any, index: number) => {
      if (event.target.checked) {
        if (
          item?.isDisposition &&
          Number(item?.actualQuantity) > Number(item?.dispositionQuantity)
        ) {
          checkedItems.push(item?.id);
          setChecked(checkedItems);
          const sunInvIndex = addedSunInvPartLocal?.findIndex(
            (e: any) => e?.lotNumber === item?.lotNumber && e?.productNumber === item?.productNumber
          );
          if (sunInvIndex > -1) {
            addedSunInvPartLocal[sunInvIndex] = partsList[index];
          } else {
            addedSunInvPartLocal.push(partsList[index]);
          }
        }
        if (formType === USAGE_TYPE.INVENTORY_RETURN) {
          return {
            ...item,
            counterValue: 0,
            isChecked: true,
          };
        }
        return {
          ...item,
          counterValue: item?.counterValue || item?.actualQuantity,
          isChecked: true,
        };
      }
      setChecked([]);
      return {
        ...item,
        counterValue: item?.counterValue || item?.actualQuantity,
        isChecked: false,
      };
    });
    setPartsList(finalLineItems);
  };

  const handleSubInvCount = (
    event: any,
    data: { value: string; number: number },
    subInv: any
  ): void => {
    const subInvWithLotInfo = [...partsList];
    subInvWithLotInfo.forEach((element: any, index: number) => {
      if (
        element.productDescription === subInv.productDescription &&
        element.lotNumber === subInv.lotNumber
      ) {
        element.changed = true;
        element.counterValue =
          data.number < subInv.actualQuantity ? data.number : subInv.actualQuantity;
        const sunInvIndex = addedSunInvPartLocal.findIndex(
          (e: any) => e.lotNumber === subInv.lotNumber && e.productNumber === subInv.productNumber
        );
        if (sunInvIndex > -1) {
          addedSunInvPartLocal[sunInvIndex] = subInvWithLotInfo[index];
        } else {
          addedSunInvPartLocal.push(subInvWithLotInfo[index]);
        }
      }
    });
    setPartsList(subInvWithLotInfo);
    setAddedSunInvPartLocal(addedSunInvPartLocal);
  };

  const [salesRepAddress, setSalesRepAddress] = useState<any>();
  const [asrRsmAddress, setAsrRsmAddress] = useState<any>();

  const getSalesRepAccountAddress = async (): Promise<void> => {
    if (currentSalesRep) {
      const userEAccountResponse = await client.query({
        query: GET_USER_E_ACCOUNT,
        variables: {
          userId: currentSalesRep,
        },
        fetchPolicy: 'no-cache',
      });
      const accountAddress = await client.query({
        query: GET_SHIP_TO_ADDRESSES,
        variables: {
          accountId: userEAccountResponse?.data?.getUserEAccount[0]?.id,
        },
        fetchPolicy: 'no-cache',
      });
      setSalesRepAddress(accountAddress?.data?.getShipToAddresses);
    }
  };

  const getAsrRsmAccountAddress = async (): Promise<void> => {
    if (
      userInfo &&
      (userInfo?.personas?.toLowerCase()?.includes('sales manager') ||
        userInfo?.personas?.toLowerCase()?.includes('sales associate'))
    ) {
      const userEAccountResponse = await client.query({
        query: GET_USER_E_ACCOUNT,
        variables: {
          userId: USER_ID,
        },
        fetchPolicy: 'no-cache',
      });
      const accountAddressForArsRsm = await client.query({
        query: GET_SHIP_TO_ADDRESSES,
        variables: {
          accountId: userEAccountResponse?.data?.getUserEAccount[0]?.id,
        },
        fetchPolicy: 'no-cache',
      });
      setAsrRsmAddress(accountAddressForArsRsm?.data?.getShipToAddresses);
    }
  };

  useEffect(() => {
    getSalesRepAccountAddress();
    getAsrRsmAccountAddress();
  }, [currentSalesRep]);

  const getDecoratedAddress = (shipToAddress: ShipToAddress[]): ShipToAddress[] => {
    return shipToAddress?.map((item: ShipToAddress) => {
      const address = `${item?.name || ''} -  ${item?.address1 || ''} ${item?.address2 ||
        ''}, ${item?.city || ''}, ${item?.state || ''}, ${item?.postalcode || ''}`;
      return {
        id: item.id,
        value: address,
        label: address,
        isPrimaryAddress: !!item?.isPrimaryAddress,
      };
    });
  };

  const getSanitizedArray = (array: ShipToAddress[]): ShipToAddress[] => {
    const cleanedArray = array?.filter(
      (item: ShipToAddress) => item !== undefined && item !== null
    );
    return cleanedArray?.filter(
      (item: ShipToAddress, index: number, self: ShipToAddress[]) =>
        index === self.findIndex((element: any) => element.id === item.id)
    );
  };

  const getDynamicShipToAddress = (prevShipTo: ShipToAddress[]): any => {
    if (prevShipTo !== undefined) {
      let asrRsmAddressFound = false;
      let salesRepAddressFound = false;
      for (let prevShipToIndex = 0; prevShipToIndex < prevShipTo?.length; prevShipToIndex++) {
        for (
          let asrRsmAddressIndex = 0;
          asrRsmAddressIndex < asrRsmAddress?.length;
          asrRsmAddressIndex++
        ) {
          if (prevShipTo?.[prevShipToIndex]?.id !== asrRsmAddress?.[asrRsmAddressIndex]?.id)
            asrRsmAddressFound = true;
        }
        for (
          let salesRepAddressIndex = 0;
          salesRepAddressIndex < salesRepAddress?.length;
          salesRepAddressIndex++
        ) {
          if (prevShipTo?.[prevShipToIndex]?.id !== salesRepAddress?.[salesRepAddressIndex]?.id)
            salesRepAddressFound = true;
        }
      }
      if (!prevShipTo?.length) {
        if (asrRsmAddress) asrRsmAddressFound = true;
        if (salesRepAddress) salesRepAddressFound = true;
      }
      if (asrRsmAddressFound) {
        const newShipTo = getDecoratedAddress(asrRsmAddress);
        prevShipTo.push(...newShipTo);
      }
      if (salesRepAddressFound) {
        const newShipTo = getDecoratedAddress(salesRepAddress);
        prevShipTo.push(...newShipTo);
      }
      return getSanitizedArray(prevShipTo);
    }
  };

  useEffect(() => {
    if (apiData && !flag) {
      if (!window.location.href.includes('Orders'))
        getDynamicShipToAddress(apiData?.getShipToAddresses);
      setApiDataState(apiData);
    }
  }, [apiData]);

  useEffect(() => {
    if (shipToAddresses && shipToAddresses.length) {
      const updatedAddress = getDecoratedAddress(shipToAddresses);
      setRepStockAddress(updatedAddress);
    }
  }, [shipToAddresses]);

  useEffect(() => {
    setFlag(false);
  }, []);

  // Rendering form fields type
  const renderFormElement = (
    fieldItem: FormField,
    key: number,
    handleChange: any, // TODO Formik Methods
    setFieldValue: any,
    values: any
  ) => {
    const {
      optionsDynamic,
      optionsStatic,
      name,
      optionsDependentField,
      permissionSets,
      isShow,
      fieldType,
    } = fieldItem;
    // Checking form element 'fields' and rendering based on fields type
    const itemsData =
      optionsStatic || (apiDataState && optionsDynamic ? apiDataState[optionsDynamic] : null);
    const showAddMenu = !!(name === 'surgeon' && !showSurgeonFields);
    setFieldValueTemp = setFieldValue;
    if (fieldItem.name === 'shipTo' && repStockAddress) {
      let addRepsShipTo = true;
      for (let i = 0; i < itemsData.length; i++) {
        for (let j = 0; j < repStockAddress.length; j++) {
          if (
            itemsData[i].id === repStockAddress[j].id &&
            repStockAddress[j].label?.startsWith('E')
          ) {
            addRepsShipTo = false;
            break;
          }
        }
      }
      const checkRepShipTo =
        itemsData && itemsData.length > 0
          ? itemsData.filter((el: any) => el?.label?.startsWith('E'))
          : [];
      if (
        addRepsShipTo &&
        repStockAddress.length > 0 &&
        values?.primaryAccount &&
        values?.primaryAccount?.length > 0 &&
        checkRepShipTo &&
        checkRepShipTo.length === 0
      ) {
        const checkRepStock = itemsData?.filter((item: any) => item.id === repStockAddress?.[0].id);
        if (checkRepStock.length === 0) {
          itemsData.push(...repStockAddress);
          if (!values?.shipTo?.length || ShipToFlag) setFieldValue('shipTo', itemsData);
        }
      }
    }
    const fetchItems = (searchText: string): string => {
      if (optionsDynamic) {
        const method = useLazyQueryObject[optionsDynamic];
        if (method) {
          // Reusable function to get the api variables
          const variables = getQueryParameters(values, searchText, optionsDependentField);
          method({
            variables,
          });
        }
      }
      return searchText;
    };
    if (isShow && showCSRFields && permissionSets && permissionSets.includes('show_csr_fields')) {
      return (
        <RenderDynamicFields
          division={division}
          fieldItem={fieldItem}
          itemsData={itemsData}
          FormInputTypes={FormInputTypes}
          values={values}
          setFieldValue={setFieldValue}
          handleFormValues={handleFormValues}
          handleFieldDisable={handleFieldDisable}
          showAddMenu={showAddMenu}
          fetchItems={fetchItems}
          key={key}
          handleRadioButtonChange={handleRadioButtonChange}
          accountChecked={accountChecked}
          selectedTab={selectedTab}
          locationChecked={locationChecked}
          eventStatus={eventStatus}
          handleUploadFiles={handleUploadFiles}
          handleDeleteAttachments={handleDeleteAttachments}
          uploadedFiles={uploadedFiles}
          uploadedShippingLabelFiles={uploadedShippingLabelFiles}
          modalHeading={modalHeading}
          eventUsage={eventUsage}
          formType={formType}
          partsListCheck={partsList}
          handlefailedTabFieldDisable={handlefailedTabFieldDisable}
        />
      );
    }
    if (
      isShow &&
      showSurgeonFields &&
      permissionSets &&
      permissionSets.includes('show_surgeon_fields')
    ) {
      return (
        <RenderDynamicFields
          division={division}
          fieldItem={fieldItem}
          itemsData={itemsData}
          FormInputTypes={FormInputTypes}
          values={values}
          setFieldValue={setFieldValue}
          handleFormValues={handleFormValues}
          handleFieldDisable={handleFieldDisable}
          showAddMenu={showAddMenu}
          fetchItems={fetchItems}
          key={key}
          handleRadioButtonChange={handleRadioButtonChange}
          accountChecked={accountChecked}
          selectedTab={selectedTab}
          locationChecked={locationChecked}
          eventStatus={eventStatus}
          handleUploadFiles={handleUploadFiles}
          handleDeleteAttachments={handleDeleteAttachments}
          uploadedFiles={uploadedFiles}
          uploadedShippingLabelFiles={uploadedShippingLabelFiles}
          modalHeading={modalHeading}
          eventUsage={eventUsage}
          formType={formType}
          partsListCheck={partsList}
          handlefailedTabFieldDisable={handlefailedTabFieldDisable}
        />
      );
    }
    if (
      (isShow && Array.isArray(fieldItem.permissionSets) && !fieldItem.permissionSets.length) ||
      fieldItem.permissionSets === null
    ) {
      return (
        <RenderDynamicFields
          division={division}
          fieldItem={fieldItem}
          itemsData={itemsData}
          FormInputTypes={FormInputTypes}
          values={values}
          setFieldValue={setFieldValue}
          handleFormValues={handleFormValues}
          handleFieldDisable={handleFieldDisable}
          showAddMenu={showAddMenu}
          fetchItems={fetchItems}
          key={key}
          handleRadioButtonChange={handleRadioButtonChange}
          accountChecked={accountChecked}
          selectedTab={selectedTab}
          locationChecked={locationChecked}
          eventStatus={eventStatus}
          handleCallback={handleCallback}
          handleUploadFiles={handleUploadFiles}
          handleDeleteAttachments={handleDeleteAttachments}
          uploadedFiles={uploadedFiles}
          uploadedShippingLabelFiles={uploadedShippingLabelFiles}
          modalHeading={modalHeading}
          eventUsage={eventUsage}
          formType={formType}
          partsListCheck={partsList}
          handlefailedTabFieldDisable={handlefailedTabFieldDisable}
        />
      );
    }
    return null;
  };
  // TODO. For now initial values are static, will make them a reduce function once validation story is started.
  const { initialValuesSchema, validationSchema } = getFormInitialValues();
  let validateFormTemp: any;

  useEffect(() => {
    if (validateFormTemp) {
      validateFormTemp();
    }
  }, [accountChecked, locationChecked]);

  useEffect(() => {
    if (setFieldValueTemp) {
      setTimeout(() => {
        setFieldValueTemp('Attachments', uploadedFiles);
      }, 0);
    }
  }, [uploadedFiles]);

  return (
    <Formik
      initialValues={formValues || initialValuesSchema}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      // validateOnMount
    >
      {({ handleChange, setFieldValue, values, handleSubmit, isValid, errors, validateForm }) => {
        validateFormTemp = validateForm;
        const partsTabFlag =
          formType === USAGE_TYPE.BILL_ONLY &&
          partsList &&
          partsList.filter((e: any) => Number(e?.actualQuantity) > Number(e?.dispositionQuantity))
            ?.length === 0;
        const formBOFlag = formType === USAGE_TYPE.BILL_ONLY;
        return (
          <div>
            {!isEdit && createEventLoading && (
              <Spinner
                size="large"
                variant="base"
                assistiveText={{
                  label: 'Loading...',
                }}
                containerStyle={{ top: 50, zIndex: 2 }}
              />
            )}
            {createRequest && (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    left: '30%',
                    position: 'absolute',
                    zIndex: 4,
                    backgroundColor: '#E5E4E2',
                    padding: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Your data is being saved. Please wait.
                </div>
                <Spinner
                  size="large"
                  variant="base"
                  assistiveText={{
                    label: 'Loading...',
                  }}
                  containerStyle={{ top: 50, zIndex: 2 }}
                />
              </>
            )}
            <div className={showAlert || showTimeAlert ? 'customAlert' : ''}>
              {(showAlert || showTimeAlert) && (
                <Pill
                  labels={{
                    label: alertMessage,
                  }}
                  hasError
                  icon={
                    <Icon
                      title="Error"
                      category="utility"
                      name="warning"
                      className="slds-icon-text-error"
                    />
                  }
                />
              )}
            </div>

            <div className="form-section">
              {selectedTab && selectedTab === 'Parts' && warning && (
                <div
                  style={{
                    width: '100%',
                    padding: '10px',
                    backgroundColor: 'rgba(255, 181, 0, 0.2980392156862745)',
                  }}
                >
                  <div style={{ display: 'inline-block' }}>
                    <Icon
                      category="utility"
                      name="info"
                      size="x-small"
                      style={{ fill: '#000', margin: '12px' }}
                    />
                    The available quantity has changed. Your request exceeds the available quantity.
                  </div>
                </div>
              )}
              {((selectedTab && selectedTab === 'Parts') || (selectTab && selectTab === 'Parts')) &&
              partsData
                ? formItems.map((element: any, elementIndex) => {
                    const results = element.fields && element.fields.filter((e: any) => e.isShow);
                    const ifTabNav =
                      element.fields &&
                      element.fields.filter(
                        (e: any) => e?.isShow === true && e?.fieldType === 'tabNavigator'
                      );
                    // Checking form element 'kind' and rendering based on the type
                    switch (element.kind) {
                      case FIELD: {
                        return renderFormElement(
                          element,
                          elementIndex,
                          handleChange,
                          setFieldValue,
                          values
                        );
                      }
                      case SECTION: {
                        return (
                          ifTabNav &&
                          ifTabNav.length > 0 &&
                          partsData && (
                            <div className="slds-card slds-p-around_medium" key={elementIndex}>
                              {element.fields.map(
                                (fieldItem: FormField, fieldItemIndex: number) => {
                                  // Checking form element 'fields' and rendering based on fields type
                                  if (
                                    fieldItem?.isShow === true &&
                                    fieldItem?.fieldType === 'tabNavigator'
                                  ) {
                                    return renderFormElement(
                                      fieldItem,
                                      fieldItemIndex,
                                      handleChange,
                                      setFieldValue,
                                      values
                                    );
                                  }
                                  return null;
                                }
                              )}
                              <div
                                style={{
                                  width: '100%',
                                  margin: 'auto',
                                  float: 'right',
                                  marginRight: '5px',
                                }}
                              >
                                {/* <div style={{ display: 'inline-block' }}>Expiration Date:</div> */}
                                <div style={{ display: 'inline-block' }}>
                                  <span style={{ marginLeft: '40px' }}>
                                    <Icon
                                      // assistiveText={{ label: 'Announcement' }}
                                      category="utility"
                                      name="notification"
                                      size="x-small"
                                      style={{ fill: '#000084', marginRight: '5px' }}
                                    />
                                    Expiring in 90 days
                                  </span>
                                  <span style={{ marginLeft: '10px' }}>
                                    <Icon
                                      // assistiveText={{ label: 'Announcement' }}
                                      category="utility"
                                      name="clock"
                                      size="x-small"
                                      style={{ fill: '#FFAE42', marginRight: '5px' }}
                                    />
                                    30 days
                                  </span>
                                  <span style={{ marginLeft: '10px' }}>
                                    <Icon
                                      // assistiveText={{ label: 'Announcement' }}
                                      category="utility"
                                      name="warning"
                                      size="x-small"
                                      style={{ fill: '#D2042D', marginRight: '5px' }}
                                    />
                                    Expired
                                  </span>
                                  <span style={{ marginLeft: '10px', marginRight: '5px' }}>
                                    <img
                                      src={holdIconPath}
                                      className="logo-image"
                                      alt="company"
                                      style={{ marginLeft: '8px', marginRight: '8px' }}
                                    />
                                    On Hold
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div className="slds-col slds-size_12ß-of-12">
                                  <Checkbox
                                    assistiveText={{
                                      label: 'Select/Unselect All',
                                    }}
                                    key="mark_all"
                                    id="mark_all"
                                    onChange={(_event: any): void => {
                                      handleCheckboxChangeForAll(_event);
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: '100%',
                                    borderBottom: '2px solid #000000',
                                    marginTop: '10px',
                                  }}
                                />
                                <section className="slds-p-around_large-1">
                                  {partsList &&
                                    partsList?.length > 0 &&
                                    partsList.map((subInv: any, index: number) => {
                                      let icon: any;
                                      let color: any;
                                      const quanityDisp =
                                        subInv?.isDisposition &&
                                        Number(subInv?.actualQuantity) >
                                          Number(subInv?.dispositionQuantity)
                                          ? subInv?.actualQuantity - subInv?.dispositionQuantity
                                          : 0;

                                      if (subInv.isexpire90days) {
                                        icon = 'notification';
                                        color = '#000084';
                                      }
                                      if (subInv.isexpire30days) {
                                        icon = 'clock';
                                        color = '#FFAE42';
                                      }
                                      if (subInv.isexpired) {
                                        icon = 'warning';
                                        color = '#D2042D';
                                      }
                                      if (quanityDisp > 0) {
                                        return (
                                          <div
                                            className={
                                              partsIssue?.includes(subInv?.productNumber)
                                                ? 'inventory-return-lot-info-name-warning'
                                                : 'inventory-return-lot-info-name'
                                            }
                                          >
                                            <div style={{ marginTop: '5px', display: 'flex' }}>
                                              <span>
                                                <Checkbox
                                                  assistiveText={{
                                                    label: 'Surgery',
                                                  }}
                                                  key={subInv.id}
                                                  id={subInv.id}
                                                  checked={subInv.isChecked}
                                                  onChange={(_event: any): void => {
                                                    handleCheckboxChange(subInv.id, _event);
                                                  }}
                                                />
                                              </span>
                                              {`${subInv.productNumber} ${subInv.productDescription}`}
                                              {subInv.productOnHold ? (
                                                <img
                                                  src={holdIconPath}
                                                  className="logo-image"
                                                  alt="company"
                                                  style={{ marginLeft: '8px', marginRight: '8px' }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                            <div
                                              className="inventory-return-lot-info"
                                              style={{
                                                color:
                                                  Number(subInv.actualQuantity) <= 0
                                                    ? 'lightgray'
                                                    : '',
                                                flexDirection: 'column',
                                              }}
                                            >
                                              <span>
                                                Lot #/Serial # - {subInv.lotNumber || '--'} <br />
                                                Expiration Date:{' '}
                                                {subInv.expirationDate
                                                  ? moment(subInv.expirationDate).format(
                                                      'MM/DD/YYYY'
                                                    )
                                                  : '--'}
                                                {subInv.expirationDate && icon && (
                                                  <Icon
                                                    // assistiveText={{ label: 'Announcement' }}
                                                    category="utility"
                                                    name={icon}
                                                    size="x-small"
                                                    style={{ fill: color, marginLeft: '15px' }}
                                                  />
                                                )}
                                              </span>
                                              {subInv.isChecked && (
                                                <Input
                                                  id="counter-input-3"
                                                  minValue={0}
                                                  maxValue={Number(quanityDisp)}
                                                  onChange={(
                                                    event: any,
                                                    data: { value: string; number: number }
                                                  ): void => {
                                                    handleSubInvCount(event, data, subInv);
                                                  }}
                                                  value={subInv.counterValue}
                                                  variant="counter"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                </section>
                              </div>
                            </div>
                          )
                        );
                      }
                      default: {
                        return <div key={`${elementIndex}`} />;
                      }
                    }
                  })
                : formItems.map((element: any, elementIndex) => {
                    // Checking form element 'kind' and rendering based on the type
                    const results = element.fields && element.fields.filter((e: any) => e.isShow);
                    switch (element.kind) {
                      case FIELD: {
                        return renderFormElement(
                          element,
                          elementIndex,
                          handleChange,
                          setFieldValue,
                          values
                        );
                      }
                      case SECTION: {
                        return (
                          results &&
                          results.length > 0 && (
                            <div className="slds-card slds-p-around_medium" key={elementIndex}>
                              {element.fields.map(
                                (fieldItem: FormField, fieldItemIndex: number) => {
                                  // Checking form element 'fields' and rendering based on fields type
                                  if (fieldItem?.isShow === true) {
                                    return renderFormElement(
                                      fieldItem,
                                      fieldItemIndex,
                                      handleChange,
                                      setFieldValue,
                                      values
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                          )
                        );
                      }
                      default: {
                        return <div key={`${elementIndex}`} />;
                      }
                    }
                  })}
              {((selectedTab && selectedTab === 'Parts') || (selectTab && selectTab === 'Parts')) &&
                !partsData && (
                  <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
                )}
            </div>
            <div className="fixed-footer-bottom">
              <Button
                className="slds-float_left slds-text-color_default"
                // label={isEditEvent ? 'Discard Changes' : 'Cancel'}
                label="Cancel"
                onClick={handleCancelButton}
                key="cancel"
              />
              {showCreateOpenButton && !isEditEvent ? (
                <Button
                  label="Create & Open"
                  disabled={!(isValid && !showAlert && !showTimeAlert && !disableSave)}
                  onClick={() => handleSaveButtonCLick(handleSubmit, 'createInventory')}
                />
              ) : (
                !isEditEvent &&
                isEventInventoryButton && (
                  <Button
                    label="Save & Request Inventory"
                    disabled={!(isValid && !showAlert && !showTimeAlert && !disableSave)}
                    onClick={() => handleSaveButtonCLick(handleSubmit, 'createInventory')}
                  />
                )
              )}
              {(createEventLoading || createRequest) && (
                <div>
                  <Spinner
                    size="small"
                    variant="base"
                    assistiveText={{
                      label: 'Loading...',
                    }}
                  />
                </div>
              )}
              {eventUsage &&
                (selectedTab && selectedTab === 'Details' ? (
                  <Button
                    label={partsTabFlag ? 'Save' : 'Next'}
                    onClick={
                      partsTabFlag
                        ? () => handleSaveButtonCLick(handleSubmit, 'save')
                        : () => handleButtonToggle(handleSubmit, 'next')
                    }
                    key="Next"
                    className="yellow-btn"
                    disabled={!(isValid && !showAlert && !disableSave && !showTimeAlert)}
                  />
                ) : (
                  <Button
                    label="Save"
                    onClick={() => handleSaveButtonCLick(handleSubmit, 'save')}
                    key="Save"
                    className="yellow-btn"
                    disabled={
                      !(
                        isValid &&
                        !showAlert &&
                        !disableSave &&
                        !showTimeAlert &&
                        ((!formBOFlag && checkedItems && checkedItems.length > 0) || formBOFlag)
                      )
                    }
                  />
                ))}
              {!eventUsage && (
                <Button
                  label="Save"
                  onClick={() => handleSaveButtonCLick(handleSubmit, 'save')}
                  key="Save"
                  className="yellow-btn"
                  disabled={!(isValid && !showAlert && !disableSave && !showTimeAlert)}
                />
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

/** Exporting FormEngine component */
export default FormEngine;
