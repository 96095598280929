/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

/**
 * React component for Inventory Request.
 * @module src/components/InventoryRequest/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import FormEngine, { Form } from '../../Shared/FormEngine';
import { getSchemeJson } from '../../../util/FormJson';
import CREATE_INVENTORY_REQUEST from '../../../graphql/mutations/createInventoryRequest';
import { GET_SALES_REPS } from '../../../graphql/getSalesReps';
import { GET_SHIP_TO_ADDRESSES } from '../../../graphql/getShipToAddresses';
import { GET_PRIMARY_ACCOUNT } from '../../../graphql/getPrimaryAccount';
import { GET_EVENT_DETAILS } from '../../../graphql/getEventDetails';
import CREATE_UPDATE_USAGE from '../../../graphql/mutations/createdUpdateUsage';
import RESUBMIT_ORDER_DETAILS from '../../../graphql/mutations/resubmitOrderDetails';
import { GET_SAS_URI } from '../../../graphql/getSasUri';
import DELETE_ATTACHMENT from '../../../graphql/mutations/deleteAttachment';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import {
  NOTIFY_TYPE,
  PERSONAS_SALES_RESP,
  SERVICELEVELIR,
  SERVICELEVELIRSM,
} from '../../../util/constants';
import useBlobFileService, { BlobFile } from '../../../util/customHooks/useBlobFileService';
import SnackBar from '../../Shared/SnackBar';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { getUserId } from '../../../store/ducks/userId';
import CREATE_INVENTORY_REQUEST_AFTER_SFID from '../../../graphql/mutations/createInventoryRequestAfterSfid';
import CREATE_UPDATE_USAGE_AFTER_SFID from '../../../graphql/mutations/createdUpdateUsageAfterSfid';
import { GET_SERVICE_LEVELS } from '../../../graphql/getServiceLevels';
import PartsConfirmModal from '../InventoryRequestPartsConfirm';

interface VariableType {
  [key: string]: string;
}
interface File {
  manual?: boolean;
  id?: string;
  name: string;
  imageurl: string;
}

interface ItemPrivate {
  salesUnitOfMeasure: string;
  approvalStatus?: string;
  priceBookEntryId?: string;
  unitPrice?: string;
  externalId?: string;
  fromSubInventoryCode?: string;
  id?: string;
  quantity: string;
  availableqty: number | null | undefined;
  expirationDate: string;
  lotNumber: string;
  onhandqty: number | null;
  productdescription: string | null;
  productId: string;
  productNumber: string | null;
  subInventoryName: string | null;
  lineVisualIndicator?: string | null | undefined;
  productNo?: string | null;
  usageId?: string | null | undefined;
  usageExternalId?: string | null | undefined;
  workflowAction?: string | null | undefined;
}

interface ShipToAddress {
  address1?: string;
  address2?: string;
  city?: string;
  id?: string;
  isPrimaryAddress?: boolean;
  isPrimaryAccount?: boolean;
  name?: string;
  label?: string;
  value?: string;
  postalcode?: string;
  state?: string;
  number?: string;
  __typename?: string;
}

interface Props extends RouteComponentProps {
  children?: FC;
  isOpen: boolean;
  isClone?: boolean;
  toggleOpen: () => void;
  formData: any;
  eventType: string;
  history: any;
  handleCancelButton?: () => void;
  refetchEventDetails?: () => void;
  flowType?: boolean;
  eventExternalId?: string;
  isEventInvClone?: boolean;
  inventoryDetail?: any;
  refetch?: () => void;
  allLineItemsClone?: any;
  inventoryRequestFav?: any;
  type?: string;
  editDetails?: any;
  eventDetailInfo?: any;
  otherAddress?: any;
  locationDesc?: any;
  selectedSalesRepData?: any;
  isFavorite?: boolean;
}

const EVENTS_KEY: VariableType = {
  country: 'branchId',
  account: 'accountId',
  surgeon: 'surgeonId',
  salesRep: 'salesRepId',
  shipTo: 'shipToId',
  needByDate: 'needByDate',
  serviceLevel: 'serviceLevel',
  comments: 'comments',
};

const EVENTS_KEY_FAILED: VariableType = {
  // country: 'branchId',
  account: 'accountId',
  surgeon: 'surgeonId',
  salesRep: 'salesRepId',
  shipTo: 'shippingId',
  needByDate: 'needByDate',
  serviceLevel: 'serviceLevel',
  comments: 'comments',
};

const InventoryRequest: FC<Props> = ({
  flowType,
  eventExternalId,
  isOpen,
  toggleOpen,
  formData,
  eventType,
  history,
  handleCancelButton,
  inventoryDetail,
  refetchEventDetails,
  refetch,
  isEventInvClone,
  allLineItemsClone,
  isClone,
  inventoryRequestFav,
  type,
  editDetails,
  eventDetailInfo,
  otherAddress,
  locationDesc,
  selectedSalesRepData,
  isFavorite,
}) => {
  const [accountChecked, setAccountChecked] = useState('No');
  const { open, notification, openSnackBar } = useSnackBar();
  const [otherAddressData, setOtherAddressData] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [resultdata, setResultData] = useState([]);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const [selectedSalesRep, setSelectedSalesRep] = useState();
  const [getSalesRepsItems, { data: salesRepsItems }] = useLazyQuery(GET_SALES_REPS); // USELAZYQUERY
  const salesRepsData = salesRepsItems?.getSalesReps;
  // const { data: salesRepsItems } = useQuery(GET_SALES_REPS);
  // const salesRepsData = salesRepsItems?.getSalesReps;
  const userId = useSelector(getUserId);
  const [accountSelected, setAccountSelected] = useState<any>();
  const userInfo = useSelector(getUserInfo);
  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY
  const primaryAccountData = primaryAccountItems?.getPrimaryAccount;
  const [serviceLevelJustification, setServiceLevelJustification] = useState<any>({
    isNeeded: false,
  });

  const [getServiceLevels, { data: serviceLevels }] = useLazyQuery(GET_SERVICE_LEVELS, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY

  const [
    getShipToAddressItems,
    { data: shipToAddressesItems, loading: loadingShipToApi },
  ] = useLazyQuery(GET_SHIP_TO_ADDRESSES, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const shipToAddresses = shipToAddressesItems?.getShipToAddresses;

  const [createInventoryRequest, { data: createInventoryRequestData }] = useMutation(
    CREATE_INVENTORY_REQUEST
  );
  const [
    createInventoryRequestAfterSfid,
    { data: createInventoryRequestAfterSfidData, loading: createEventAfterSfidLoading },
  ] = useMutation(CREATE_INVENTORY_REQUEST_AFTER_SFID);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const { data: eventDetailData } = useQuery(GET_EVENT_DETAILS, {
    variables: { externalId: eventExternalId || inventoryDetail?.caseExternalId },
  });
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const [loading, setLoading] = useState(false);
  const { uploadFileToBlobStorage } = useBlobFileService(sasUriData?.getSasUri);
  const [uploadedFiles, setUploadFiles] = useState<File[]>([]);
  const [createUpdateUsage, { data: eventUsageMutation }] = useMutation(CREATE_UPDATE_USAGE);
  const [createUpdateUsageAfterSfid, { data: eventUsageMutationAfterSfid }] = useMutation(
    CREATE_UPDATE_USAGE_AFTER_SFID
  );

  useEffect(() => {
    const userPresona = userInfo?.personas;
    const autoSelectedSalesRep =
      userPresona === 'Sales Rep' ? selectedSalesRep || userInfo?.id : selectedSalesRep;
    if (autoSelectedSalesRep) {
      getPrimaryAccountItems({
        variables: {
          salesRepId: selectedSalesRep || userInfo?.id,
        },
      });
    }
    getServiceLevels({
      variables: { businessUnit: userInfo?.businessunit, onlyApplicable: true },
    });
  }, [getPrimaryAccountItems, getServiceLevels, userInfo, selectedSalesRep]);
  const fetchItems = useCallback(
    str => {
      getSalesRepsItems();
    },
    [getSalesRepsItems]
  );

  useEffect(() => {
    fetchItems('');
  }, [fetchItems]);

  useEffect(() => {
    if (
      createInventoryRequestAfterSfidData &&
      createInventoryRequestAfterSfidData.createInventoryRequestAfterSfid &&
      createInventoryRequestAfterSfidData.createInventoryRequestAfterSfid.message === 'success'
    ) {
      setLoading(false);
      const { externalId } = createInventoryRequestAfterSfidData.createInventoryRequestAfterSfid;
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory Request successfully created.');
      setTimeout(() => {
        history.push('/inventoryRequestDetails', {
          isEventInventoryFlow: false,
          externalId,
          type: 'Open',
        });
      }, 2000);
      // history.push('/inventoryRequestDetails', {
      //   isEventInventoryFlow: false,
      //   externalId,
      // });
      toggleOpen();
    } else if (
      eventUsageMutation &&
      eventUsageMutation.createUpdateUsage &&
      eventUsageMutation.createUpdateUsage.message === 'success' &&
      inventoryDetail &&
      !isClone
    ) {
      // const { externalId } = eventExternalId;
      // history.push('/surgicalDetails', {
      //   externalId: eventExternalId,
      // });
      setLoading(false);
      const { externalId } = eventUsageMutation.createUpdateUsage;
      // if (refetchEventDetails) {
      //   refetchEventDetails();
      // }
      setLoading(false);
      if (externalId) {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory Request successfully created.');
        const { pathname } = window.location;
        if (pathname !== '/InventoryQueueRequestDetails') {
          setTimeout(() => {
            history.push('/inventoryRequestDetails', {
              isEventInventoryFlow: false,
              externalId,
              type: 'Open',
            });
          }, 1000);
        }
        /* condition to refresh the inventory detail screen to refetch the updated inventory detail */
        if (inventoryDetail) {
          if (refetch) {
            refetch();
          }
        }
      } else {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory successfully updated');
        setTimeout(() => {
          history.push('/surgicalDetails', {
            externalId: eventExternalId,
            showInventory: true,
            showInventoryTab: true,
            enableInventoryTab: true,
            isEventInventoryFlow: true,
          });
        }, 1000);
      }
      toggleOpen();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    createInventoryRequestAfterSfidData,
    createInventoryRequestData,
    eventUsageMutation,
    eventType,
    history,
  ]);

  useEffect(() => {
    if (
      eventUsageMutationAfterSfid &&
      eventUsageMutationAfterSfid.createUpdateUsageAfterSfid &&
      eventUsageMutationAfterSfid.createUpdateUsageAfterSfid.message === 'success'
    ) {
      const { externalId } = eventUsageMutationAfterSfid.createUpdateUsageAfterSfid;
      setLoading(false);
      if (externalId) {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory Request successfully created.');
        const { pathname } = window.location;
        if (pathname !== '/InventoryQueueRequestDetails') {
          setTimeout(() => {
            history.push('/inventoryRequestDetails', {
              isEventInventoryFlow: false,
              externalId,
              type: 'Open',
            });
          }, 1000);
        }
        /* condition to refresh the inventory detail screen to refetch the updated inventory detail */
        if (inventoryDetail) {
          if (refetch) {
            refetch();
          }
        }
      }
      toggleOpen();
    }
  }, [eventUsageMutationAfterSfid, eventType, history]);

  const handleRadioButtonChange = (event: any): void => {
    setAccountChecked(event.target.value);
  };

  const handleShippingToggleButton = (value: string): void => {
    setAccountChecked(value);
  };

  const handleServiceLevelJustification = (value: boolean): void => {
    if (value !== serviceLevelJustification.isNeeded) {
      setServiceLevelJustification({ ...serviceLevelJustification, isNeeded: value });
    }
  };
  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array?.filter((item: any) => {
      const i = newArray?.findIndex(x => x?.number === (item?.number || item?.name));
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };

  const formJson = useCallback(() => {
    try {
      const dataObject = formData && formData.getFormConfigurations;
      const formIdentifierToFormName: Record<string, string> = {
        requestInventory: 'requestInventory',
        inventoryRequestWithShipping: 'inventoryRequestWithShipping',
      };
      const payloadObject: Form = dataObject[formIdentifierToFormName[eventType]];
      const result: any =
        accountChecked === 'No'
          ? dataObject[formIdentifierToFormName.requestInventory]
          : dataObject[formIdentifierToFormName.inventoryRequestWithShipping];

      const checkLD = result[1]?.fields.filter((i: any) => i.name === 'locationDescription');
      if (checkLD.length > 0) {
        result[1]?.fields?.splice(2, 1);
      }
      if (flowType && editDetails) {
        const objectName: any = {
          fieldLabel: 'Location Description',
          fieldType: 'input',
          kind: 'field',
          name: 'locationDescription',
          optionsAutoSelected: false,
          optionsDependentField: [],
          optionsDynamic: editDetails.locationDescription,
          optionsPlaceHolder: '',
          permissionSets: [],
          required: false,
          disabled: true,
        };
        const checkLD = result[1]?.fields.filter((i: any) => i.name === 'locationDescription');
        if (checkLD.length === 0) {
          result[1]?.fields?.splice(2, 0, objectName);
        }
      }
      if (flowType && !editDetails && locationDesc) {
        const objectName: any = {
          fieldLabel: 'Location Description',
          fieldType: 'input',
          kind: 'field',
          name: 'locationDescription',
          optionsAutoSelected: false,
          optionsDependentField: [],
          optionsDynamic: locationDesc,
          optionsPlaceHolder: '',
          permissionSets: [],
          required: false,
          disabled: true,
        };
        const checkLD = result[1]?.fields.filter((i: any) => i.name === 'locationDescription');
        if (checkLD.length === 0) {
          result[1]?.fields?.splice(2, 0, objectName);
        }
      }

      const serviceLevelJustificationField = {
        kind: 'field',
        name: 'serviceLevelJustification',
        fieldLabel: 'Service Level Justification',
        fieldType: 'comments',
        required: serviceLevelJustification.isNeeded,
        optionsAutoSelected: false,
        isShow: serviceLevelJustification.isNeeded,
        characterLimit: 32000,
        optionsPlaceHolder: 'Justify the reason for the choosen service level.',
        showOnWeb: true,
        permissionSets: [],
      };
      const serviceLevelIndex = result[1]?.fields?.findIndex(
        (field: any) => field.name === 'serviceLevel'
      );
      const slJustificationIndex = result[1]?.fields?.findIndex(
        (field: any) => field.name === 'serviceLevelJustification'
      );
      if (serviceLevelIndex >= 0) {
        if (slJustificationIndex >= 0) {
          result[1]?.fields?.splice(slJustificationIndex, 1);
        }
        result[1]?.fields?.splice(serviceLevelIndex + 1, 0, serviceLevelJustificationField);
      }

      return result;
    } catch (error) {
      // If the query errors out, need to return something
      console.log(`Form Json Error - Error Caught: ${error}`);
      return getSchemeJson(eventType);
    }
  }, [formData, eventType, accountChecked, serviceLevelJustification, editDetails, locationDesc]);

  const getOptionsWithLabel = (addressData: any): [] => {
    if (addressData) {
      return (
        addressData &&
        addressData.map((item: any) => {
          if (item.__typename === 'Account') {
            return {
              ...item,
              label: item.isRepStockAccount
                ? `* ${item.number} ${item.value}`
                : `${item.number} ${item.value}`,
              number: item.isRepStockAccount ? `* ${item.number}` : `${item.number}`,
            };
          } else {
            if (item.isOtherAddress) {
              return {
                ...item,
                label: `${item?.number ? `${item?.number} -` : ''} ${item.address1 || ''}, ${
                  item.address2 ? `${item.address2},` : ''
                } ${item.city || ''}, ${item.state || ''}, ${item.postalcode || ''}`,
              };
            } else {
              return {
                ...item,
                label: `${item.isPrimaryAddress ? '*' : ''} ${item.name || ''} - ${item.address1 ||
                  ''} ${item.address2 || ''}, ${item.city || ''}, ${item.state ||
                  ''}, ${item.postalcode || ''}`,
              };
            }
          }
        })
      );
    }
    return [];
  };
  useEffect(() => {
    if (inventoryDetail) {
      if (inventoryDetail.attachments && inventoryDetail.attachments.length > 0) {
        // eslint-disable-next-line prefer-const
        let result: any = [];
        inventoryDetail.attachments.map((item: any) => {
          const fileToUpload = {
            id: item.id,
            name: item.name,
            imageurl: item.imageurl,
          };
          result.push(fileToUpload);
        });
        setUploadFiles(result);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [inventoryDetail, setUploadFiles]);

  useEffect(() => {
    if (shipToAddresses && editDetails) {
      const dataArray = shipToAddresses;
      const completeAddress = {
        address1: editDetails?.street1,
        address2: editDetails?.street2 || null,
        city: editDetails.city,
        id: editDetails?.shippingId,
        isPrimaryAddress: null,
        name: editDetails?.name,
        postalcode: editDetails.zipCode ? editDetails.zipCode : 'US',
        state: editDetails.state,
        isOtherAddress: true,
        __typename: 'ShipToAddress',
      };
      if (completeAddress?.address1 && completeAddress?.city && completeAddress?.state) {
        dataArray.unshift(completeAddress);
      }
      setOtherAddressData(dataArray);
    } else if (
      shipToAddresses &&
      inventoryDetail &&
      accountSelected &&
      accountSelected?.length > 0 &&
      inventoryDetail?.accountId === accountSelected[0]?.id
    ) {
      const dataArray = shipToAddresses;
      const primaryAccount = shipToAddresses.filter(
        (item: any) => item.isPrimaryAccount && inventoryDetail?.accountId === item.id
      );
      const completeAddress = {
        number: inventoryDetail?.shippingAddressNumber,
        address1: inventoryDetail?.shippingAddessLine1 || null,
        address2: inventoryDetail?.shippingAddessLine2 || null,
        city: inventoryDetail?.shippingCity,
        id: inventoryDetail?.shippingId,
        isPrimaryAddress: null,
        name: inventoryDetail?.name,
        postalcode: inventoryDetail?.shippingPostalcode
          ? inventoryDetail?.shippingPostalcode
          : 'US',
        state: inventoryDetail?.shippingState,
        isOtherAddress: true,
        isPrimaryAccount: primaryAccount?.[0]?.isPrimaryAccount,
        __typename: 'ShipToAddress',
      };
      if (completeAddress?.address1 && completeAddress?.city && completeAddress?.state) {
        dataArray.unshift(completeAddress);
      }
      setOtherAddressData(removeDuplicates(dataArray));
    } else if (shipToAddresses && otherAddress) {
      const dataArray = shipToAddresses;
      const completeAddress = {
        address1: otherAddress?.address1,
        address2: otherAddress?.address2 || '',
        city: otherAddress.city,
        id: otherAddress?.shippingId,
        isPrimaryAddress: null,
        name: otherAddress?.name,
        postalcode: otherAddress.zipCode ? otherAddress.zipCode : 'US',
        state: otherAddress.state,
        isOtherAddress: true,
        __typename: 'ShipToAddress',
      };
      if (completeAddress?.address1 && completeAddress?.city && completeAddress?.state) {
        dataArray.unshift(completeAddress);
      }
      setOtherAddressData(dataArray);
    } else {
      setOtherAddressData(shipToAddresses);
    }
    if (accountSelected?.length === 0) {
      setOtherAddressData('');
    }
  }, [shipToAddresses, otherAddress, editDetails, inventoryDetail, accountSelected]);

  const getDefaultShipToSelection = (prevShipTo: ShipToAddress[]): ShipToAddress[] => {
    //  const criteria = (record: ShipToAddress) => record?.name === accountSelected?.[0]?.number;
    const criteria = (record: ShipToAddress) => record?.id === accountSelected?.[0]?.id;
    const defaultShipTo = prevShipTo?.find(criteria);
    if (defaultShipTo) {
      if (defaultShipTo.isPrimaryAccount) {
        defaultShipTo.label = `* ${defaultShipTo.label}`;
        defaultShipTo.name = `* ${defaultShipTo.name}`;
        if (inventoryDetail) {
          defaultShipTo.number = `* ${defaultShipTo.number}`;
        }
      }
      prevShipTo = prevShipTo?.filter(record => !criteria(record));
      prevShipTo.unshift(defaultShipTo);
    }
    return prevShipTo;
  };

  const apiDataObj = {
    getSalesReps: salesRepsData,
    getAccounts: getOptionsWithLabel(primaryAccountData),
    getShipToAddresses: getDefaultShipToSelection(getOptionsWithLabel(otherAddressData)),
    getServiceLevel: serviceLevels?.getServiceLevels?.map((sl: any) => {
      return { label: sl.name, value: sl.code };
    }),
  };

  const lazyQueryObject = {
    getAccounts: getPrimaryAccountItems,
    getShipToAddresses: getShipToAddressItems,
    getSalesReps: getSalesRepsItems,
  };

  const calculateActualQuantitity = (item: any, onHandQuantity: any) => {
    const isPackContent = item?.salesUnitOfMeasure === 'PK' || item?.salesUnitOfMeasure === 'BX';
    if (!isPackContent) {
      return onHandQuantity;
    }
    const packOfContentCount = item?.packContent > 0 ? item?.packContent || 1 : 1;
    if (item?.productFamily === 'SPTSIMPLANTS') {
      return onHandQuantity * packOfContentCount;
    }
    return onHandQuantity;
  };

  const getLineItemFormated = (lineItems: any): any => {
    const finalLineItemObject: {
      productId: any;
      quantity: any;
      productNumber: any;
      usageId?: any;
      usageExternalId?: any;
      actualQuantity?: any;
      baseorCapital?: any;
      workflowAction?: any;
    }[] = [];

    lineItems.map(
      (lineItem: {
        baseorCapital: any;
        productId: any;
        quantity: any;
        productNo: any;
        usageId: any;
        usageExternalId: any;
        actualQuantity?: any;
        salesUnitOfMeasure?: any;
        packContent?: any;
        workflowAction?: any;
      }) => {
        if (isClone) {
          finalLineItemObject.push({
            baseorCapital: lineItem?.baseorCapital,
            productId: lineItem.productId,
            quantity: lineItem.quantity,
            productNumber: lineItem.productNo,
            workflowAction: lineItem.workflowAction || null,
            actualQuantity:
              Number(lineItem.actualQuantity) > 0
                ? lineItem.actualQuantity
                : calculateActualQuantitity(lineItem, lineItem.quantity),
          });
        } else {
          finalLineItemObject.push({
            productId: lineItem.productId,
            quantity: lineItem.quantity,
            productNumber: lineItem.productNo,
            usageId: lineItem.usageId,
            usageExternalId: lineItem.usageExternalId,
            baseorCapital: lineItem?.baseorCapital,
            workflowAction: lineItem.workflowAction || null,
            actualQuantity:
              Number(lineItem.actualQuantity) > 0
                ? lineItem.actualQuantity
                : calculateActualQuantitity(lineItem, lineItem.quantity),
          });
        }
      }
    );

    return finalLineItemObject;
  };

  const handleSaveRequest = (result: any): void => {
    let mutation: any = {};
    mutation = {
      ...mutation,
      // eventType,
    }; // TODO Added static value for status as discuss with SFDC team

    if (flowType) {
      if (
        eventDetailData &&
        eventDetailData.getEventDetails &&
        eventDetailData.getEventDetails.caseId &&
        eventExternalId
      ) {
        mutation = {
          ...mutation,
          eventId: eventDetailData.getEventDetails.caseId,
          eventExternalId,
          requestedFrom: 'Event',
          branchId: eventDetailData?.getEventDetails?.branchId,
        };
      } else {
        mutation = { ...mutation, requestedFrom: 'Event', eventExternalId };
      }
    }

    Object.keys(EVENTS_KEY).forEach(key => {
      if (result[key]) {
        const serverKey = EVENTS_KEY[key];
        if (Array.isArray(result[key]) && result[key].length > 0) {
          mutation = { ...mutation, [serverKey]: result[key][0].id };
        } else if (typeof result[key] === 'string' || typeof result[key] === 'boolean') {
          mutation = { ...mutation, [serverKey]: result[key] };
        }
      }
    });

    if (result.country) {
      mutation = { ...mutation, branchId: result.country[0].id };
    }

    if (result.salesRep) {
      if (result.salesRep[0].branchId || eventDetailData?.getEventDetails?.branchId) {
        mutation = {
          ...mutation,
          branchId: result.salesRep[0].branchId || eventDetailData?.getEventDetails?.branchId,
        };
      } else {
        mutation = { ...mutation };
      }
    }

    // AccountId
    if (result?.primaryAccount) {
      if (result?.primaryAccount[0]?.id) {
        let accountafterstar = result?.primaryAccount[0]?.number?.replace('*', '');
        accountafterstar = accountafterstar?.trim();
        mutation = {
          ...mutation,
          accountId: result?.primaryAccount[0]?.id,
          fromSubInventoryCode: accountafterstar || '',
        };
      } else {
        mutation = { ...mutation };
      }
    }

    if (result.shipTo) {
      if (result.shipTo[0].id) {
        mutation = { ...mutation, shipToId: result.shipTo[0].id };
      } else {
        mutation = {
          ...mutation,
          shipToId:
            result.shipTo[0].id?.length > 0 ? result.shipTo[0].id : inventoryDetail?.shippingId,
        };
      }
    }

    if (result.requestedReceivalDate) {
      const dt = new Date(result.requestedReceivalDate);
      mutation = {
        ...mutation,
        // needByDate: `${moment(result.requestedReceivalDate).format('YYYY-MM-DD')}`,
        needByDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`,
      };
    }

    if (result.serviceLevel) {
      if (result.serviceLevel[0].value) {
        mutation = { ...mutation, serviceLevel: result.serviceLevel[0].value };
      } else {
        mutation = { ...mutation };
      }
    }

    if (
      result.serviceLevelJustification ||
      (inventoryDetail && inventoryDetail.serviceLevelJustification)
    ) {
      mutation = { ...mutation, serviceLevelJustification: result.serviceLevelJustification ?? '' };
    }

    if (uploadedFiles.length > 0) {
      const uploadAttachments = uploadedFiles.map((item: File) => {
        return {
          imageurl: item.imageurl,
          name: item.name,
          id: item.id,
        };
      });
      const attachments = [...uploadAttachments];
      mutation = {
        ...mutation,
        attachments,
      };
    }
    if (result.comments || result.comments === '') {
      mutation = {
        ...mutation,

        comments: [
          {
            comment: result.comments,
            postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
            commentType: 'Internal',
            externalId:
              inventoryDetail &&
              inventoryDetail.comments[0] &&
              inventoryDetail.comments?.find((item: any) => item.type === 'Internal')?.externalId,
          },
        ],
      };
    }
    if (accountChecked) {
      mutation = { ...mutation, isShippingDetails: accountChecked === 'Yes' };
    }
    if (result.dockAvailable && accountChecked === 'Yes') {
      mutation = { ...mutation, dockAvailable: result.dockAvailable };
    }
    if (result.liftGateNeeded && accountChecked === 'Yes') {
      mutation = { ...mutation, liftGateNeeded: result.liftGateNeeded };
    }
    if (result.shortTruckOnly && accountChecked === 'Yes') {
      mutation = { ...mutation, shortTruckOnly: result.shortTruckOnly };
    }
    if (result.deliveryContractEmail && accountChecked === 'Yes') {
      mutation = { ...mutation, deliveryContractEmail: result.deliveryContractEmail };
    }
    if (result.deliveryContractInstructions && accountChecked === 'Yes') {
      mutation = { ...mutation, deliveryContractInstructions: result.deliveryContractInstructions };
    }
    if (result.deliveryContractName && accountChecked === 'Yes') {
      mutation = { ...mutation, deliveryContractName: result.deliveryContractName };
    }
    if (result.deliveryContractPhone && accountChecked === 'Yes') {
      mutation = { ...mutation, deliveryContractPhone: result.deliveryContractPhone };
    }

    if (mutation && flowType) {
      setLoading(true);
      createUpdateUsage({
        variables: mutation,
      })
        .then(response => {
          mutation = {
            ...mutation,
            caseSFID: response?.data?.createUpdateUsage?.sfid,
          };
          const recursiveCallApiUpdate = () => {
            createUpdateUsageAfterSfid({
              variables: mutation,
            }).catch(error => {
              if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                recursiveCallApiUpdate();
              } else {
                openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
              }
            });
          };
          recursiveCallApiUpdate();
        })
        .catch(error => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          // eslint-disable-next-line no-console
          console.log(
            error,
            ' - We had a trouble updating the Inventory request line Items. Please try again later'
          ); // TODO change when some operation needs to be run on error.
        })
        .catch(error => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Failed to save Inventory Request. Please try again.');
        });
    } else if (mutation && isEventInvClone) {
      let clonedLineItems: ItemPrivate[] = [];
      allLineItemsClone.map((element: ItemPrivate): any => {
        const isPickedItemFound = clonedLineItems.some(
          pickedItem =>
            pickedItem.productId === element.productId &&
            (pickedItem.productNo || pickedItem.productNumber) ===
              (element.productNo || element.productNumber)
        );
        if (isPickedItemFound) {
          clonedLineItems = clonedLineItems.map((e2: ItemPrivate) => {
            if (
              e2.productId === element.productId &&
              (e2.productNo || e2.productNumber) === (element.productNo || element.productNumber)
            ) {
              const addQuantity =
                parseInt(e2?.quantity || '0', 10) + parseInt(element?.quantity || '0', 10) || 0;
              return {
                ...e2,
                quantity: addQuantity.toString(),
              };
            }
            return e2;
          });
        } else {
          clonedLineItems.push(element);
        }
      });

      const updatedpickedItems =
        clonedLineItems.map((kit: ItemPrivate) => {
          const kitQuantity = kit?.quantity || '';
          return {
            quantity: kitQuantity.toString(),
            productId: kit.productId,
            productNumber: kit?.productNo || kit?.productNumber,
          };
        }) || [];
      mutation = {
        ...mutation,
        requestedFrom: 'Event',
        eventId: eventDetailData?.getEventDetails?.caseId,
        eventType: 'Inventory',
        eventExternalId,
      };

      if (mutation) {
        setLoading(true);
        createUpdateUsage({
          variables: mutation,
        })
          .then(createUpdateResponseHeader => {
            if (createUpdateResponseHeader.data.createUpdateUsage.message === 'success') {
              mutation = {
                ...mutation,
                caseSFID: createUpdateResponseHeader?.data?.createUpdateUsage?.sfid,
              };
              const recursiveCallApiUpdate = () => {
                createUpdateUsageAfterSfid({
                  variables: mutation,
                })
                  .then(createUpdateResponse => {
                    if (
                      createUpdateResponse.data.createUpdateUsageAfterSfid.message === 'success' &&
                      createUpdateResponseHeader.data.createUpdateUsageAfterSfid.externalId
                    ) {
                      const variablesParts = {
                        requestedFrom: 'Inventory',
                        eventExternalId,
                        usageParts: updatedpickedItems || [],
                        eventId: '',
                        caseExternalId:
                          createUpdateResponseHeader.data.createUpdateUsageAfterSfid.externalId ||
                          '',
                      };
                      setLoading(true);
                      createUpdateUsageAfterSfid({
                        variables: variablesParts,
                      })
                        .then(createUpdateResponse => {
                          if (
                            createUpdateResponse.data.createUpdateUsageAfterSfid.message ===
                            'success'
                          ) {
                            history.push('/surgicalDetails', {
                              eventType,
                              externalId: eventExternalId,
                              showInventory: true,
                              showInventoryTab: true,
                              enableInventoryTab: true,
                              isEventInventoryFlow: true,
                              // showInventoryTab,
                              // enableInventoryTab,
                            });
                          } else {
                            console.log(
                              'We had a trouble saving data to salesforce. Please try again later'
                            );
                            setLoading(false);
                            openSnackBar(
                              NOTIFY_TYPE.ERROR,
                              'Something went wrong. Please try again.'
                            );
                          }
                        })
                        .catch((error: any) => {
                          console.log(
                            error,
                            ' - We had a trouble saving data to salesforce. Please try again later'
                          );
                          setLoading(false);
                          openSnackBar(
                            NOTIFY_TYPE.ERROR,
                            'We are facing connectivity issues with Salesforce. Your data has been saved. Please check after some time.'
                          );
                        });
                    }
                  })
                  .catch(error => {
                    if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                      recursiveCallApiUpdate();
                    } else {
                      openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                    }
                  });
              };
              recursiveCallApiUpdate();
            } else {
              console.log('We had a trouble saving data to salesforce. Please try again later');
            }
          })
          .catch((error: any) => {
            console.log(
              error,
              ' - We had a trouble saving data to salesforce. Please try again later'
            );
            setLoading(false);
            openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          });
      }
    } else if (mutation && inventoryDetail && !isClone) {
      mutation = {
        ...mutation,
        caseId: inventoryDetail && inventoryDetail.caseId,
        caseExternalId: inventoryDetail && inventoryDetail.caseExternalId,
        requestedFrom: 'Inventory',
        usageParts: getLineItemFormated(inventoryDetail.lineItems),
        // inventoryDetail.lineItems,
      };
      if (accountChecked === 'No') {
        mutation = {
          ...mutation,
          deliveryContractName: '',
        };
      }
      // delete mutation.comments;
      setLoading(true);
      createUpdateUsage({
        variables: mutation,
      }).catch(error => {
        setLoading(false);
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        // eslint-disable-next-line no-console
        console.log(
          error,
          ' - We had a trouble updating the Inventory request line Items. Please try again later'
        ); // TODO change when some operation needs to be run on error.
      });
    } else if (mutation && isClone) {
      // delete mutation.comments;
      if (new Date(mutation.needByDate) < new Date()) {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Please enter a valid delivery date.');
        return;
      }
      setLoading(true);
      createInventoryRequest({
        variables: mutation,
      })
        .then(response => {
          mutation = {
            ...mutation,
            caseSFID: response?.data?.createInventoryRequest?.sfid,
          };
          const recursiveCallApi = () => {
            createInventoryRequestAfterSfid({
              variables: mutation,
            })
              .then(response => {
                if (response.data !== null) {
                  if (
                    response &&
                    response.data &&
                    response.data.createInventoryRequestAfterSfid &&
                    response.data.createInventoryRequestAfterSfid.message === 'success'
                  ) {
                    const finalObject = {
                      caseExternalId:
                        response &&
                        response.data &&
                        response.data.createInventoryRequestAfterSfid &&
                        response.data.createInventoryRequestAfterSfid.externalId,
                      requestedFrom: 'Inventory',
                      usageParts: getLineItemFormated(inventoryDetail.lineItems),
                    };
                    setLoading(true);
                    createUpdateUsage({
                      variables: finalObject,
                    })
                      .then(response => {
                        mutation = {
                          ...mutation,
                          caseSFID: response?.data?.createUpdateUsage?.sfid,
                        };
                        const recursiveCallApiUpdate = () => {
                          createUpdateUsageAfterSfid({
                            variables: mutation,
                          }).catch(error => {
                            if (
                              error.message
                                .toLowerCase()
                                .includes('salesforce and heroku sync issue')
                            ) {
                              setTimeout(() => {
                                recursiveCallApiUpdate();
                              }, 2000);
                            } else {
                              openSnackBar(
                                NOTIFY_TYPE.ERROR,
                                'Something went wrong. Please try again.'
                              );
                            }
                          });
                        };
                        recursiveCallApiUpdate();
                      })
                      .catch(error => {
                        setLoading(false);
                        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                        // eslint-disable-next-line no-console
                        console.log(
                          error,
                          ' - We had a trouble updating the Inventory request line Items. Please try again later'
                        ); // TODO change when some operation needs to be run on error.
                      });
                  }
                }
              })
              .catch(error => {
                if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                  recursiveCallApi();
                } else {
                  openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                }
              });
          };

          recursiveCallApi();
        })
        .catch(error => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          // eslint-disable-next-line no-console
          console.log(
            error,
            ' - We had a trouble creating the Inventory request Please try again later'
          ); // TODO change when some operation needs to be run on error.
        });
    } else if (mutation && inventoryRequestFav) {
      setLoading(true);
      createInventoryRequest({
        variables: mutation,
      })
        .then(response => {
          mutation = {
            ...mutation,
            caseSFID: response?.data?.createInventoryRequest?.sfid,
          };
          const recursiveCallApi = () => {
            createInventoryRequestAfterSfid({
              variables: mutation,
            })
              .then(response => {
                if (response.data !== null) {
                  if (
                    response &&
                    response.data &&
                    response.data.createInventoryRequestAfterSfid &&
                    response.data.createInventoryRequestAfterSfid.message === 'success'
                  ) {
                    const finalObject = {
                      caseExternalId:
                        response &&
                        response.data &&
                        response.data.createInventoryRequestAfterSfid &&
                        response.data.createInventoryRequestAfterSfid.externalId,
                      requestedFrom: 'Inventory',
                      usageParts: inventoryRequestFav.lineItems.map((i: any): any => {
                        return {
                          productId: i.part.id,
                          quantity: i.quantity,
                          productNumber: i.part.productNumber,
                        };
                      }),
                    };
                    setLoading(true);
                    createUpdateUsage({
                      variables: finalObject,
                    })
                      .then(response => {
                        mutation = {
                          ...mutation,
                          caseSFID: response?.data?.createUpdateUsage?.sfid,
                        };
                        const recursiveCallApiUpdate = () => {
                          createUpdateUsageAfterSfid({
                            variables: mutation,
                          }).catch(error => {
                            if (
                              error.message
                                .toLowerCase()
                                .includes('salesforce and heroku sync issue')
                            ) {
                              setTimeout(() => {
                                recursiveCallApiUpdate();
                              }, 2000);
                            } else {
                              openSnackBar(
                                NOTIFY_TYPE.ERROR,
                                'Something went wrong. Please try again.'
                              );
                            }
                          });
                        };
                        recursiveCallApiUpdate();
                      })
                      .catch(error => {
                        setLoading(false);
                        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                        // eslint-disable-next-line no-console
                        console.log(
                          error,
                          ' - We had a trouble updating the Inventory request line Items. Please try again later'
                        ); // TODO change when some operation needs to be run on error.
                      });
                  }
                }
              })
              .catch(error => {
                if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                  recursiveCallApi();
                } else {
                  openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                }
              });
          };

          recursiveCallApi();
        })
        .catch(error => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          // eslint-disable-next-line no-console
          console.log(
            error,
            ' - We had a trouble creating the Inventory request Please try again later'
          ); // TODO change when some operation needs to be run on error.
        });
    } else if (mutation) {
      setLoading(true);
      createInventoryRequest({
        variables: mutation,
      })
        .then(response => {
          mutation = {
            ...mutation,
            caseSFID: response?.data?.createInventoryRequest?.sfid,
          };
          const recursiveCallApi = () => {
            createInventoryRequestAfterSfid({
              variables: mutation,
            }).catch(error => {
              if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                recursiveCallApi();
              } else {
                openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
              }
            });
          };

          recursiveCallApi();
        })
        .catch(error => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          // eslint-disable-next-line no-console
          console.log(
            error,
            ' - We had a trouble creating the Inventory request Please try again later'
          ); // TODO change when some operation needs to be run on error.
        });
    }
  };
  const handleUploadFiles = async (event: any): Promise<void> => {
    const { files } = event;
    const file = files[0];
    setLoading(true);
    const response: BlobFile = await uploadFileToBlobStorage(file);
    if (response) {
      const fileToUpload = {
        manual: true,
        name: response?.blobName || file.name,
        imageurl: response?.imageurl.split('?')[0] || '',
      };
      setUploadFiles([...uploadedFiles, fileToUpload]);
      setLoading(false);
    }
  };
  const handleDeleteAttachments = (file: any): void => {
    if (file.manual) {
      const filteredData = uploadedFiles.filter(
        (item: any) => JSON.stringify(item) !== JSON.stringify(file)
      );
      setUploadFiles(filteredData);
    } else {
      deleteAttachment({ variables: { id: file?.id } }).then(() => {
        const filteredData = uploadedFiles.filter(
          (item: any) => JSON.stringify(item) !== JSON.stringify(file)
        );
        setUploadFiles(filteredData);
      });
    }
  };
  const handleAccountSelection = useCallback(
    accountSelectedValue => {
      setAccountSelected(accountSelectedValue);
    },
    [setAccountSelected]
  );

  const handleSaveConfirm = (result: any) => {
    setShowConfirmation(false);
    const uploadAttachments = uploadedFiles.map((item: File) => {
      return {
        id: item.id,
        imageurl: item.imageurl,
        name: item.name,
      };
    });
    const attachments = [...uploadAttachments];
    if (type === 'Failed') {
      let mutation: any = {};
      mutation = {
        ...mutation,
      }; // TODO Added static value for status as discuss with SFDC team\
      Object.keys(EVENTS_KEY_FAILED).forEach(key => {
        if (result[key]) {
          const serverKey = EVENTS_KEY_FAILED[key];
          if (Array.isArray(result[key]) && result[key].length > 0) {
            mutation = { ...mutation, [serverKey]: result[key][0].id };
          } else if (typeof result[key] === 'string' || typeof result[key] === 'boolean') {
            mutation = { ...mutation, [serverKey]: result[key] };
          }
        }
      });
      if (inventoryDetail && inventoryDetail.id) {
        mutation = { ...mutation, id: inventoryDetail.id };
      }
      // AccountId
      if (result?.primaryAccount) {
        if (result?.primaryAccount[0]?.id) {
          let accountafterstar = result?.primaryAccount[0]?.number?.replace('*', '');
          accountafterstar = accountafterstar?.trim();
          mutation = {
            ...mutation,
            accountId: result?.primaryAccount[0]?.id,
            fromSubInventoryCode: accountafterstar || '',
          };
        } else {
          mutation = { ...mutation };
        }
      }

      if (result.shipTo) {
        if (result.shipTo[0].id) {
          mutation = { ...mutation, shippingId: result.shipTo[0].id };
        } else {
          mutation = { ...mutation };
        }
      }
      if (accountChecked) {
        mutation = { ...mutation, isShippingDetails: accountChecked === 'Yes' };
      }
      if (result.dockAvailable && accountChecked === 'Yes') {
        mutation = { ...mutation, dockAvailable: result.dockAvailable };
      }
      if (result.liftGateNeeded && accountChecked === 'Yes') {
        mutation = { ...mutation, liftGateNeeded: result.liftGateNeeded };
      }
      if (result.shortTruckOnly && accountChecked === 'Yes') {
        mutation = { ...mutation, shortTruckOnly: result.shortTruckOnly };
      }
      if (result.deliveryContractEmail && accountChecked === 'Yes') {
        mutation = { ...mutation, deliveryContractEmail: result.deliveryContractEmail };
      }
      if (result.deliveryContractInstructions && accountChecked === 'Yes') {
        mutation = {
          ...mutation,
          deliveryContractInstructions: result.deliveryContractInstructions,
        };
      }
      if (result.deliveryContractName && accountChecked === 'Yes') {
        mutation = { ...mutation, deliveryContractName: result.deliveryContractName };
      }
      if (result.deliveryContractPhone && accountChecked === 'Yes') {
        mutation = { ...mutation, deliveryContractPhone: result.deliveryContractPhone };
      }
      if (accountChecked === 'No') {
        mutation = {
          ...mutation,
          deliveryContractName: '',
        };
      }
      if (result.requestedReceivalDate) {
        const dt = new Date(result.requestedReceivalDate);
        mutation = {
          ...mutation,
          // needByDate: `${moment(result.requestedReceivalDate).format('YYYY-MM-DD')}`,
          needByDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`,
        };
      }

      if (result.serviceLevel) {
        if (result.serviceLevel[0].value) {
          mutation = { ...mutation, serviceLevel: result.serviceLevel[0].value };
        } else {
          mutation = { ...mutation };
        }
      }

      if (
        result.serviceLevelJustification ||
        (inventoryDetail && inventoryDetail.serviceLevelJustification)
      ) {
        mutation = {
          ...mutation,
          serviceLevelJustification: result.serviceLevelJustification ?? '',
        };
      }

      if (result.comments) {
        mutation = {
          ...mutation,
          comments: [
            {
              comment: result.comments,
              postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
              commentType: 'Internal',
              externalId:
                inventoryDetail &&
                inventoryDetail.comments[0] &&
                inventoryDetail.comments?.find((item: any) => item.type === 'Internal')?.externalId,
            },
          ],
        };
      }
      mutation = {
        ...mutation,
        requestedFrom: 'InventoryRequest',
        submitType: 'Update',
      };
      if (attachments && attachments.length > 0) {
        mutation = { ...mutation, attachments };
      }
      setLoading(true);
      reSubmitInventory({
        variables: mutation,
      }).then((res: any) => {
        // setLoader(false);
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          openSnackBar(NOTIFY_TYPE.SUCCESS, `Inventory Request has been Updated successfully.`);
          setTimeout(() => {
            toggleOpen();
          }, 500);
          if (refetch) {
            refetch();
          }
        } else {
          openSnackBar(NOTIFY_TYPE.ERROR, `Failed to submit Inventory Request.`);
        }
      });
    } else {
      handleSaveRequest(result);
    }
    // handleSaveRequest(result);
  };

  const handleSave = (result: any): void => {
    if ((inventoryDetail && isClone && eventType) || inventoryDetail || isFavorite) {
      setShowConfirmation(true);
      setResultData(result);
    } else {
      handleSaveConfirm(result);
    }
  };
  const handleChange = (event: any): void => {
    // eslint-disable-next-line no-console
    if (handleUploadFiles) {
      handleUploadFiles(event.target);
    }
  };

  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };

  const getInternalComment = (details: any) => {
    const internalComm: any = details?.comments?.filter((item: any) => item.type === 'Internal');
    return internalComm?.[0]?.value || '';
  };

  const getFormFieldValues = useCallback(() => {
    if (editDetails) {
      return {
        salesRep: [
          {
            id: editDetails.salesRepId ? editDetails.salesRepId : '',
            label: editDetails.salesRep ? editDetails.salesRep : '',
            mobilephone: salesRepsData ? salesRepsData[0]?.mobilephone : '',
          },
        ],
        primaryAccount: [
          {
            id: editDetails?.accountId ? editDetails.accountId : '',
            label:
              editDetails?.accountNumber && editDetails.accountName
                ? `${editDetails.accountNumber} ${editDetails.accountName}`
                : '',
          },
        ],
        shipTo: [
          {
            id: editDetails.shippingId,
            label: `${editDetails?.shippingAddressNumber ||
              ''} -  ${editDetails?.shippingAddessLine1 || ''} ${editDetails?.shippingAddessLine2 ||
              ''}, ${editDetails?.shippingCity || ''}, ${editDetails?.shippingState ||
              ''}, ${editDetails?.shippingPostalcode || ''}`,
          },
        ],
        liftGateNeeded: true,
      };
    }
    if (eventDetailInfo && selectedSalesRepData) {
      return {
        salesRep: [
          {
            id: selectedSalesRepData[0].id ? selectedSalesRepData[0].id : '',
            label: selectedSalesRepData[0].label ? selectedSalesRepData[0].label : '',
            mobilephone: selectedSalesRepData[0].mobilephone
              ? selectedSalesRepData[0].mobilephone
              : null,
          },
        ],
        primaryAccount: [
          {
            id: eventDetailInfo[0].id ? eventDetailInfo[0].id : '',
            label:
              eventDetailInfo[0]?.number && eventDetailInfo[0]?.value
                ? `${eventDetailInfo[0].number} ${eventDetailInfo[0].value}`
                : '',
          },
        ],
      };
    }
    if (inventoryDetail) {
      return {
        country: [
          {
            id: inventoryDetail.branchId,
          },
        ],
        salesRep: [
          {
            id: inventoryDetail.salesRepId,
            label: inventoryDetail.salesRep,
            mobilephone: salesRepsData ? salesRepsData[0]?.mobilephone : '',
          },
        ],
        primaryAccount: [
          {
            id: inventoryDetail.accountId,
            label: `${inventoryDetail.accountNumber}-${inventoryDetail.accountName}`,
          },
        ],
        Attachments: [
          {
            id:
              inventoryDetail.attachments.length > 0 ? inventoryDetail.attachments[0].imageurl : '',
            label:
              inventoryDetail.attachments.length > 0 ? inventoryDetail.attachments[0].name : '',
          },
        ],
        dockAvailable: inventoryDetail.dockAvailable,
        isShippingDetails: inventoryDetail.isShippingDetails,
        liftGateNeeded:
          inventoryDetail.isShippingDetails === false ? true : inventoryDetail.liftGateNeeded,
        shortTruckOnly: inventoryDetail.shortTruckOnly,
        deliveryContractEmail: inventoryDetail.deliveryContractEmail,
        deliveryContractInstructions: inventoryDetail.deliveryContractInstructions,
        deliveryContractName:
          inventoryDetail.isShippingDetails === false
            ? inventoryDetail.salesRep
            : inventoryDetail.deliveryContractName,
        deliveryContractPhone:
          // eslint-disable-next-line no-nested-ternary
          inventoryDetail.isShippingDetails === false
            ? salesRepsData
              ? salesRepsData[0]?.mobilephone
              : ''
            : inventoryDetail.deliveryContractPhone,
        requestedReceivalDate: inventoryDetail.needByDate
          ? moment(inventoryDetail.needByDate)
              .utc()
              .format('MM/DD/YYYY')
          : '',
        serviceLevel: [{ id: inventoryDetail.serviceLevel, label: inventoryDetail.serviceLevel }],
        serviceLevelJustification: inventoryDetail.serviceLevelJustification,
        coveringRep: inventoryDetail.salesTeams,
        shipTo: [
          {
            id: inventoryDetail.shippingId,
            number: inventoryDetail.shippingAddressNumber,
            label: `${inventoryDetail.shippingAddressNumber || ''} ${
              inventoryDetail.shippingAddressNumber ? '-' : ''
            } ${inventoryDetail.shippingAddessLine1},  ${inventoryDetail.shippingCity || ''}, ${
              inventoryDetail.shippingState
            }, ${inventoryDetail.shippingPostalcode}`,
          },
        ],
        ...(inventoryDetail.comments &&
          inventoryDetail.comments.length && {
            comments: getInternalComment(inventoryDetail),
          }),
      };
    }
    if (inventoryRequestFav) {
      return {
        serviceLevel: [
          { id: inventoryRequestFav.serviceLevel, label: inventoryRequestFav.serviceLevel },
        ],
        ...(inventoryRequestFav.comments &&
          inventoryRequestFav.comments.map((data: any) => data.comment).toString().length && {
            comments: inventoryRequestFav.comments.map((data: any) => data.comment).toString(),
          }),
      };
    }

    return {
      franchise: [],
      deliveryContractName: null,
      deliveryContractPhone: null,
      liftGateNeeded: true,
    };
  }, [salesRepsData, selectedSalesRepData, eventDetailInfo]);

  const handleSalesRepSelection = useCallback(
    salesRepValue => {
      if (salesRepValue && salesRepValue.length > 0) {
        setSelectedSalesRep(salesRepValue[0].id);
        getPrimaryAccountItems({
          variables: {
            salesRepId: salesRepValue[0].id,
          },
        });
      }
    },
    [setSelectedSalesRep]
  );

  useEffect(() => {
    if (inventoryDetail) {
      const value = !inventoryDetail.isShippingDetails ? 'No' : 'Yes';
      setAccountChecked(value);
      handleServiceLevelJustification(inventoryDetail.serviceLevel === '1st AM');
    }
  }, [inventoryDetail]);

  const getHeading = (): string => {
    let finalHeading;
    if (inventoryDetail && isClone && eventType) {
      finalHeading = `Clone - ${eventType}` || '--';
    } else if (inventoryDetail) {
      finalHeading = `Edit - ${eventType}` || '--';
    } else {
      finalHeading = eventType;
    }
    return finalHeading;
  };
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <Modal
        className="form-engine-modal"
        isOpen={isOpen}
        onRequestClose={createEventAfterSfidLoading ? '' : handleCancelButton}
        ariaHideApp
        heading={<div className="slds-float_left slds-text-title_bold">{getHeading()}</div>}
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_medium slds-theme_shade" key="form">
          <FormEngine
            formItems={formJson()}
            division="EU"
            formType={type}
            type
            isUserOpsCsr
            selectedTab="Failed"
            apiData={apiDataObj}
            useLazyQueryObject={lazyQueryObject}
            isEdit={inventoryDetail}
            handleSave={handleSave}
            toggleOpen={toggleOpen}
            formValues={getFormFieldValues()}
            isOpen={isOpen}
            handleCancelButton={handleCancelButton}
            createEventLoading={loading || loadingShipToApi}
            createRequest={createEventAfterSfidLoading}
            handleUploadFiles={handleUploadFiles}
            handleDeleteAttachments={handleDeleteAttachments}
            handleRadioButtonChange={handleRadioButtonChange}
            accountChecked={accountChecked}
            handleAccountSelection={handleAccountSelection}
            handleSalesRepSelection={handleSalesRepSelection}
            uploadedFiles={uploadedFiles}
            modalHeading={getHeading()}
            handleShippingToggleButton={handleShippingToggleButton}
            handleServiceLevelJustification={handleServiceLevelJustification}
          />
        </section>
      </Modal>

      {showConfirmation && (
        <PartsConfirmModal
          confirmModalVisible
          handleCancel={() => {
            setShowConfirmation(false);
          }}
          handleConfirm={() => handleSaveConfirm(resultdata)}
        />
      )}
    </IconSettings>
  );
};

/** React component for Inventory Request. */
export default withRouter(InventoryRequest);
