/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if*/
/**
 * React component for Inventory Return.
 * @module src/components/InventoryReturn/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import FormEngine, { Form } from '../../../Shared/FormEngine';

import { getSchemeJson } from '../../../../util/FormJson';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import { GET_SHIP_TO_ADDRESSES } from '../../../../graphql/getShipToAddresses';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { getUserInfo } from '../../../../store/ducks/userInfo';

import CREATE_RETURN_INVENTORY from '../../../../graphql/mutations/createReturnInventory';
import UPDATE_ATTACHMENT from '../../../../graphql/mutations/inventoryReturnAttachments';
import DELETE_ATTACHMENT from '../../../../graphql/mutations/deleteAttachment';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import useBlobFileService, { BlobFile } from '../../../../util/customHooks/useBlobFileService';
import { GET_SAS_URI } from '../../../../graphql/getSasUri';
import { GET_WORKFLOW_PERMISSION } from '../../../../graphql/workflowPermissions';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../graphql/getSubInventoryWithLotDetails';
import CREATE_RETURN_INVENTORY_AFTER_SFID from '../../../../graphql/mutations/createReturnInventoryAfterSfid';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import { NOTIFY_TYPE, OPSCSRUSERS, USAGE_TYPE } from '../../../../util/constants';
import SnackBar from '../../../Shared/SnackBar';

interface Comments {
  id?: string;
  value: string;
  type?: string;
  postTime: string;
  user?: string;
  externalId?: string;
}

interface File {
  manual?: boolean;
  id?: string;
  name: string;
  imageurl: string;
}

interface LineItems {
  approvalStatus: string;
  availableQuantity: string;
  deliveredDate: string;
  expirationDate: string;
  externalId: string;
  id: string;
  isLineSubmitted: boolean;
  lotNumber: string;
  onhandQuantity: string;
  priceBookEntryId: string;
  productDescription: string;
  productId: string;
  productNumber: string;
  quantity: string;
  salesUnitOfMeasure: string;
  status: string;
  unitPrice: string;
}
interface InventoryReturn {
  shippingCity: any;
  shippingAddressNumber: string;
  sfid: string;
  id?: string;
  externalId: string;
  attachments: any;
  shippingLabelAttachments: any;
  accountId: string;
  accountName: string;
  accountNumber: string;
  assignedOPSId: string;
  assignedOPSName: string;
  comments: [Comments];
  createdDate: string;
  effectiveDate: string;
  fromSubInventoryCode: string;
  lineItems: [LineItems];
  orderExternalId: string;
  orderId: string;
  orderNumber: string;
  priceBook2Id: string;
  recordTypeId: string;
  returnReason: string;
  salesOrganization: string;
  salesRepId: string;
  salesRepName: string;
  salesRep?: string;
  shipmentDate: string;
  shippingMethod: string;
  status: string;
  toSubInventoryCode: string;
  trackingNumber: string;
  type: string;
  territoryNumber: string;
  shipToId: string;
  shippingAddessLine1: string;
  shippingAddessLine2: string;
  shippingState: string;
  shippingPostalcode: string;
  onsiteContractPhone: string;
  onsiteContractEmail: string;
  onsiteContractInstructions: string;
  liftGateNeeded: boolean;
  dockAvailable: boolean;
  shortTruckOnly: boolean;
  isshippingLabelRequired: boolean;
  onsiteContractName: string;
  caseExternalId?: string;
  caseId?: string;
  shippingId?: string;
  billingId?: string;
  serviceLevel?: string;
  shipMethod?: string;
  receivingRepId?: string;
  needByDate?: string;
}
interface ShipToAddress {
  address1?: string;
  address2?: string;
  city?: string;
  id?: string;
  isPrimaryAddress?: boolean;
  isPrimaryAccount?: boolean;
  name?: string;
  label?: string;
  value?: string;
  postalcode?: string;
  state?: string;
  number?: string;
  __typename?: string;
}
interface Props extends RouteComponentProps {
  children?: FC;
  isOpen: boolean;
  toggleOpen: () => void;
  formData: any;
  eventType: string;
  type?: string;
  history: any;
  handleCancelButton?: () => void;
  refetchInventoryReturn?: () => void;
  inventoryReturnDetail?: InventoryReturn;
  returnsType?: string;
  flowType?: string;
  eventExternalId?: string;
  eventUsage?: boolean;
  editDetails?: any;
  orderLoader?: () => void;
}

const InventoryReturn: FC<Props> = ({
  isOpen,
  toggleOpen,
  formData,
  eventType,
  history,
  inventoryReturnDetail,
  handleCancelButton,
  refetchInventoryReturn,
  type,
  returnsType,
  flowType,
  eventExternalId,
  eventUsage,
  editDetails,
  orderLoader,
}) => {
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const [salesRepId, setSalesRepId] = useState('');
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [reSubmitInventory, { loading }] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [selectedTab, setSelectedTab] = useState('Details');
  const [accountSelected, setAccountSelected] = useState<any>('');
  const [apiloading, setLoader] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadedFiles, setUploadFiles] = useState<File[]>([]);
  const [otherAddressData, setOtherAddressData] = useState('');
  const [uploadedShippingLabelFiles, setUploadShippingLabelFiles] = useState<File[]>([]);
  const [updateTransferAttachment] = useMutation(UPDATE_ATTACHMENT);
  const { uploadFileToBlobStorage } = useBlobFileService(sasUriData?.getSasUri);
  const [getSalesRepsItems, { data: salesRepsItems }] = useLazyQuery(GET_SALES_REPS); // USELAZYQUERY
  const salesRepsData = salesRepsItems?.getSalesReps;
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const [userTerritories, setUserTerritories] = useState();
  const [usersubInventoryCode, setUsersubInventoryCode] = useState();
  const isUserOpsCsr = OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas)
    ? true
    : false;
  const [
    createInventoryReturn,
    { data: createInventoryReturnData, loading: createInventoryReturnLoading },
  ] = useMutation(CREATE_RETURN_INVENTORY);
  const [
    createInventoryReturnAfterSfid,
    { data: createInventoryReturnDataAfterSfid, loading: createInventoryReturnLoadingAfterSfid },
  ] = useMutation(CREATE_RETURN_INVENTORY_AFTER_SFID);
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const { data: isBizAdminUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_business_admin_user',
    },
  });

  const { data: isOpsUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_ops_user',
    },
  });

  const { data: isRSMUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_rsm_user',
    },
  });

  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY
  const primaryAccountData = primaryAccountItems?.getPrimaryAccount;
  const [
    getShipToAddressItems,
    { data: shipToAddressesItems, loading: loadingShipToApi },
  ] = useLazyQuery(GET_SHIP_TO_ADDRESSES, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const shipToAddresses = shipToAddressesItems?.getShipToAddresses;

  const [getUserTerritoriesItems, { data: userTerritoriesItems }] = useLazyQuery(
    GET_USER_TERRITORIES,
    {
      variables: {
        userId: isBizAdminUser || isRSMUser ? salesRepId : userInfo?.id,
      },
    }
  ); // USELAZYQUERY

  const userTerritoriesItemsList = userTerritoriesItems?.getUserTerritories;

  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  useEffect(() => {
    getTerritories({
      variables: {
        userId: userInfo?.id,
      },
    });
  }, [getTerritories, userInfo]);

  useEffect(() => {
    getTerritories();
    if (territoryData?.getUserTerritories) {
      const userTerritoriesList = territoryData?.getUserTerritories;
      if (userTerritoriesList && userTerritoriesList.length > 0) {
        setUserTerritories(userTerritoriesList[0]?.value);
        setUsersubInventoryCode(userTerritoriesList[0]?.subInventoryCode);
      }
    }
  }, [getTerritories, territoryData, userTerritoriesItemsList]);

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array?.filter((item: any) => {
      const i = newArray?.findIndex(x => x?.number === (item?.number || item?.name));
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };

  useEffect(() => {
    // if (shipToAddresses && otherAddress) {
    //   const dataArray = shipToAddresses;
    //   dataArray.unshift(otherAddress);
    //   setOtherAddressData(dataArray);
    // } else
    if (shipToAddresses && editDetails) {
      const dataArray = shipToAddresses;
      const completeAddress = {
        address1: editDetails?.street1,
        address2: editDetails?.street2 || null,
        city: editDetails.city,
        id: editDetails?.shipToId,
        isPrimaryAddress: null,
        name: editDetails.zipCode,
        postalcode: 'US',
        state: editDetails.state,
        isOtherAddress: true,
        __typename: 'ShipToAddress',
      };
      if (
        completeAddress?.address1 &&
        completeAddress?.city &&
        completeAddress?.name &&
        completeAddress?.state
      ) {
        dataArray.unshift(completeAddress);
      }
      setOtherAddressData(dataArray);
    }
    if (
      shipToAddresses &&
      inventoryReturnDetail &&
      inventoryReturnDetail?.shippingAddressNumber !== null &&
      inventoryReturnDetail?.accountNumber &&
      accountSelected?.length > 0 &&
      inventoryReturnDetail?.accountNumber === accountSelected[0]?.number
    ) {
      const dataArray = shipToAddresses;
      const primaryAccount = shipToAddresses.filter(
        (item: any) => item.isPrimaryAccount && inventoryReturnDetail?.accountId === item.id
      );
      const completeAddress = {
        number: inventoryReturnDetail?.shippingAddressNumber,
        address1: inventoryReturnDetail?.shippingAddessLine1,
        address2: inventoryReturnDetail?.shippingAddessLine2 || null,
        city: inventoryReturnDetail?.shippingCity,
        id: inventoryReturnDetail?.shipToId,
        isPrimaryAddress: null,
        name: inventoryReturnDetail.shippingPostalcode,
        postalcode: 'US',
        state: inventoryReturnDetail.shippingState,
        isOtherAddress: inventoryReturnDetail?.shippingAddressNumber !== null ? true : false,
        isPrimaryAccount: primaryAccount?.[0]?.isPrimaryAccount,
        __typename: 'ShipToAddress',
      };
      if (
        completeAddress?.address1 &&
        completeAddress?.city &&
        completeAddress?.name &&
        completeAddress?.state
      ) {
        dataArray.unshift(completeAddress);
      }
      setOtherAddressData(removeDuplicates(dataArray));
    } else {
      setOtherAddressData(shipToAddresses);
    }
    if (accountSelected.length === 0) {
      setOtherAddressData('');
    }
  }, [shipToAddresses, editDetails, inventoryReturnDetail, accountSelected]);

  const getOptionsWithLabel = (addressData: any): [] => {
    if (addressData) {
      return (
        addressData &&
        addressData.map((item: any) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item.__typename === 'Account') {
            return {
              ...item,
              label: item.isRepStockAccount
                ? `* ${item.number} ${item.value}`
                : `${item.number} ${item.value}`,
              number: `${item.number}`,
            };
          } else {
            if (item?.isOtherAddress) {
              return {
                ...item,
                label: `${item?.number ? `${item?.number}-` : ''} ${item.address1 ||
                  ''}, ${item.address2 || ''}, ${item.city || ''}, ${item.state ||
                  ''}, ${item.name || ''}, ${item.postalcode}`,
              };
            } else {
              return {
                ...item,
                label: `${item.isPrimaryAddress ? '*' : ''} ${item.name} - ${
                  item.address1
                } ${item.address2 || ''}, ${item?.city}, ${item?.state}, ${item.postalcode}`,
              };
            }
          }
        })
      );
    }
    return [];
  };

  useEffect(() => {
    const userPresona = userInfo?.personas;
    const autoSelectedSalesRep =
      userPresona === 'Sales Rep' ? salesRepId || userInfo?.id : salesRepId;
    if (autoSelectedSalesRep) {
      getPrimaryAccountItems({
        variables: {
          salesRepId: salesRepId || userInfo?.id,
        },
      });
    }
  }, [getPrimaryAccountItems, salesRepId, userInfo]);

  useEffect(() => {
    if (editDetails?.caseExternalId) {
      getSubInventoryWithLotDetails({
        variables: {
          eventExternalId: editDetails?.caseExternalId,
          subInventoryName: userTerritories,
          subInventoryCode: editDetails?.accountNumber ? editDetails?.accountNumber : '',
        },
      });
    }
  }, [getSubInventoryWithLotDetails, eventExternalId, editDetails, userTerritories]);

  useEffect(() => {
    if (inventoryReturnDetail) {
      if (inventoryReturnDetail.attachments && inventoryReturnDetail.attachments.length > 0) {
        // eslint-disable-next-line prefer-const
        let result: any = [];
        inventoryReturnDetail.attachments.map((item: any) => {
          const fileToUpload = {
            id: item.id,
            name: item.name,
            imageurl: item.imageurl,
          };
          result.push(fileToUpload);
        });
        setUploadFiles(result);
      }
      if (
        inventoryReturnDetail.shippingLabelAttachments &&
        inventoryReturnDetail.shippingLabelAttachments.length > 0
      ) {
        // eslint-disable-next-line prefer-const
        let result: any = [];
        inventoryReturnDetail.shippingLabelAttachments.map((item: any) => {
          const fileToUpload = {
            id: item.id,
            name: item.name,
            imageurl: item.imageurl,
          };
          result.push(fileToUpload);
        });
        setUploadShippingLabelFiles(result);
      }
      if (inventoryReturnDetail && inventoryReturnDetail.isshippingLabelRequired) {
        setShippingLabel(inventoryReturnDetail.isshippingLabelRequired);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [inventoryReturnDetail, setUploadFiles, setUploadShippingLabelFiles, setShippingLabel]);

  useEffect(() => {
    if (
      createInventoryReturnDataAfterSfid &&
      createInventoryReturnDataAfterSfid.returnInventoryAfterSfid &&
      createInventoryReturnDataAfterSfid.returnInventoryAfterSfid.message === 'success'
    ) {
      const { externalId } = createInventoryReturnDataAfterSfid.returnInventoryAfterSfid;

      if ((type && type === 'Review') || type === 'Failed') {
        if (refetchInventoryReturn) {
          if (flowType === 'Details') {
            setSelectedTab('Details');
          }
          refetchInventoryReturn();
        }
      } else {
        history.push('/inventoryReturnDetails', {
          externalId,
        });
        if (inventoryReturnDetail) {
          if (refetchInventoryReturn) {
            refetchInventoryReturn();
          }
        }
      }
      toggleOpen();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [createInventoryReturnData, createInventoryReturnDataAfterSfid, eventType, history]);

  useEffect(() => {
    if (
      inventoryReturnDetail &&
      createInventoryReturnData &&
      createInventoryReturnData.returnInventory &&
      createInventoryReturnData.returnInventory.message === 'success'
    ) {
      const { externalId } = createInventoryReturnData.returnInventory;

      if ((type && type === 'Review') || type === 'Failed') {
        if (refetchInventoryReturn) {
          if (flowType === 'Details') {
            setSelectedTab('Details');
          }
          refetchInventoryReturn();
        }
      } else {
        history.push('/inventoryReturnDetails', {
          externalId,
        });
        if (inventoryReturnDetail) {
          if (refetchInventoryReturn) {
            refetchInventoryReturn();
          }
        }
      }
      toggleOpen();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [createInventoryReturnData, eventType, history]);

  const toggleOpenRequest = (): void => {
    if (inventoryReturnDetail) {
      if (refetchInventoryReturn) {
        refetchInventoryReturn();
      }
    }
    toggleOpen();
  };

  const fetchItems = useCallback(() => {
    getSalesRepsItems();
    if (salesRepId) {
      getUserTerritoriesItems();
    }
  }, [getSalesRepsItems, salesRepId, getUserTerritoriesItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleCallback = (result: string | any[]): void => {
    if (result && result.length) {
      setSalesRepId(result[0].id);
    }
  };

  const formJson = useCallback(() => {
    try {
      const dataObject = formData && formData.getFormConfigurations;
      const formIdentifierToFormName: Record<string, string> = {
        'Standard Return': 'standardReturn',
        'Pickup Return': 'pickupReturn',
        'Inventory Return': 'standardReturn',
      };
      const payloadObject: any =
        dataObject[formIdentifierToFormName[returnsType || 'Standard Return']];
      const formJsonData = returnsType && payloadObject;

      if ((returnsType === 'Standard Return' || returnsType === 'Inventory Return') && isOpsUser) {
        if (shippingLabel) {
          const checkField =
            formJsonData[1].fields &&
            formJsonData[1].fields.filter((i: any) => i.name === 'shippingLabelAttachments');
          if (checkField.length > 0) {
            formJsonData[1]?.fields?.splice(6, 1);
          }
          const objectName: any = {
            kind: 'field',
            name: 'shippingLabelAttachments',
            fieldLabel: 'Shipping Label',
            fieldType: 'Attachments',
            required: false,
            isShow: true,
            optionsAutoSelected: false,
            optionsDependentField: [],
            permissionSets: [],
          };
          const checkLD = formJsonData[1]?.fields.filter(
            (i: any) => i.name === 'shippingLabelAttachments'
          );
          if (checkLD.length === 0) {
            formJsonData[1]?.fields?.splice(6, 0, objectName);
          }
        } else {
          const checkField =
            formJsonData[1].fields &&
            formJsonData[1].fields.filter((i: any) => i.name === 'shippingLabelAttachments');
          if (checkField.length > 0) {
            formJsonData[1]?.fields?.splice(6, 1);
          }
        }
      }
      if (flowType && flowType === 'events') {
        formJsonData[1].fields[0].optionsStatic = [
          { value: 'details', label: 'Details' },
          { value: 'parts', label: 'Parts' },
        ];
      } else {
        formJsonData[1].fields[0].optionsStatic = [{ value: 'details', label: 'Details' }];
      }
      return formJsonData;
    } catch (error) {
      // If the query errors out, need to return something
      console.log(`Form Json Error - Error Caught: ${error}`);
      return getSchemeJson(eventType);
    }
  }, [formData, returnsType, isOpsUser, shippingLabel]);

  const getDefaultShipToSelection = (prevShipTo: ShipToAddress[]): ShipToAddress[] => {
    // const criteria = (record: ShipToAddress) => record?.name === accountSelected?.[0]?.number;
    const criteria = (record: ShipToAddress) => record?.id === accountSelected?.[0]?.id;
    const defaultShipTo = prevShipTo?.find(criteria);
    if (defaultShipTo) {
      if (defaultShipTo.isPrimaryAccount) {
        defaultShipTo.label = `* ${defaultShipTo.label}`;
        defaultShipTo.name = `* ${defaultShipTo.name}`;
        if (inventoryReturnDetail) {
          defaultShipTo.number = `* ${defaultShipTo.number}`;
        }
      }
      // eslint-disable-next-line no-param-reassign
      prevShipTo = prevShipTo?.filter(record => !criteria(record));
      prevShipTo.unshift(defaultShipTo);
    }
    return prevShipTo;
  };

  const apiDataObj = {
    getSalesReps: salesRepsData,
    getTerritory: userTerritoriesItemsList,
    getAccounts: getOptionsWithLabel(primaryAccountData),
    getShipMethod: [{ id: 'FedEx', value: 'FedEx' }],
    // getShipToAddresses: getOptionsWithLabel(otherAddressData),
    getShipToAddresses: getDefaultShipToSelection(getOptionsWithLabel(otherAddressData)),
    getTracking: inventoryReturnDetail?.trackingNumber || '',
    getReturnReason: [
      { id: 'Expired', value: 'Expired', label: 'Expired' },
      { id: 'No Longer Needed', value: 'No Longer Needed', label: 'No Longer Needed' },
      { id: 'No Longer Sterile', value: 'No Longer Sterile', label: 'No Longer Sterile' },
      { id: 'Not on Sub Inventory', value: 'Not on Sub Inventory', label: 'Not on Sub Inventory' },
    ],
  };

  const lazyQueryObject = {
    getTerritory: getUserTerritoriesItems,
    getAccounts: getPrimaryAccountItems,
    getShipToAddresses: getShipToAddressItems,
  };

  const handleUploadFiles = async (event: any, label: any): Promise<void> => {
    const { files } = event;
    const file = files[0];
    setUploadInProgress(true);
    const response: BlobFile = await uploadFileToBlobStorage(file);
    if (response) {
      const fileToUpload = {
        manual: true,
        name: response?.blobName || file.name,
        imageurl: response?.imageurl.split('?')[0] || '',
      };
      if (label === 'Attachments') {
        setUploadFiles([...uploadedFiles, fileToUpload]);
      } else {
        setUploadShippingLabelFiles([...uploadedShippingLabelFiles, fileToUpload]);
      }
      setUploadInProgress(false);
    }
  };

  const handleDeleteAttachments = (file: any, label: any): void => {
    if (label === 'Attachments') {
      if (file.manual) {
        const filteredData = uploadedFiles.filter(
          (item: any) => JSON.stringify(item) !== JSON.stringify(file)
        );
        setUploadFiles(filteredData);
      } else {
        deleteAttachment({ variables: { id: file?.id } }).then(() => {
          const filteredData = uploadedFiles.filter(
            (item: any) => JSON.stringify(item) !== JSON.stringify(file)
          );
          setUploadFiles(filteredData);
        });
      }
    } else {
      if (file.manual) {
        const filteredData = uploadedShippingLabelFiles.filter(
          (item: any) => JSON.stringify(item) !== JSON.stringify(file)
        );
        setUploadShippingLabelFiles(filteredData);
      } else {
        deleteAttachment({ variables: { id: file?.id } }).then(() => {
          const filteredData = uploadedShippingLabelFiles.filter(
            (item: any) => JSON.stringify(item) !== JSON.stringify(file)
          );
          setUploadShippingLabelFiles(filteredData);
        });
      }
    }
  };

  const formatLineItemToSave = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    const sourceLocation = editDetails?.accountNumber
      ? editDetails.accountNumber.startsWith('E') ||
        editDetails.accountNumber.startsWith('IH') ||
        editDetails.accountNumber.startsWith('IT')
        ? 'Repstock'
        : 'Customer'
      : 'Repstock';

    lineItems.map(
      (item: {
        lotNumber: any;
        productId: any;
        productNumber: any;
        quantity: any;
        expirationDate: any;
        isLotNumber: any;
        sourceLocation: any;
        partAddedFrom: any;
        subInventoryCode: any;
      }): any => {
        finalLineItems.push({
          productId: item?.productId,
          productNumber: item?.productNumber,
          quantity: item?.quantity,
          lotNumber: item?.lotNumber,
          isLotNumber: item?.isLotNumber,
          sourceLocation: flowType && flowType === 'events' ? sourceLocation : item?.sourceLocation,
          partAddedFrom: flowType && flowType === 'events' ? sourceLocation : item?.sourceLocation,
          subInventoryCode: item?.subInventoryCode,
        });
      }
    );
    return finalLineItems;
  };

  const handleSave = (result: any): void => {
    const uploadAttachments = uploadedFiles.map((item: File) => {
      return {
        imageurl: item.imageurl,
        name: item.name,
      };
    });

    const uploadShippingLabelAttachments = uploadedShippingLabelFiles.map((item: File) => {
      return {
        id: item.id,
        imageurl: item.imageurl,
        name: item.name,
      };
    });

    const attachments = [...uploadAttachments];
    const shippingLabelAttachments = [...uploadShippingLabelAttachments];

    let mutation: any = {};
    let attachmentMutation: any = {};
    mutation = {
      ...mutation,
      eventId: editDetails?.caseId ? editDetails?.caseId : '',
      eventExternalId: flowType && flowType === 'events' ? editDetails?.caseExternalId : '',
      formType: inventoryReturnDetail ? 'Update' : 'New',
      returnType:
        returnsType === 'Standard Return' || returnsType === 'Inventory Return'
          ? 'Inventory Return'
          : 'Pickup Return',
      isshippingLabelRequired: result.isshippingLabelRequired,
    };

    // if (result.country) {
    //   mutation = { ...mutation, branchId: result.country[0].id };
    // }
    if (inventoryReturnDetail) {
      mutation = {
        ...mutation,
        orderExternalId:
          inventoryReturnDetail.orderExternalId || inventoryReturnDetail.caseExternalId,
        orderId: inventoryReturnDetail.orderId || inventoryReturnDetail.caseId,
      };
      attachmentMutation = {
        orderExternalId:
          inventoryReturnDetail.orderExternalId || inventoryReturnDetail.caseExternalId,
        orderId: inventoryReturnDetail.orderId || inventoryReturnDetail.caseId,
      };
    }

    if (result.salesRep) {
      if (result.salesRep && result.salesRep.length) {
        if (type === 'Failed' && inventoryReturnDetail) {
          mutation = {
            ...mutation,
            branchId: result.salesRep[0].branchId,
            // accountId: result.salesRep[0].hospital,
            salesRepId: result.salesRep[0].id,
          };
        } else {
          mutation = {
            ...mutation,
            branchId: result.salesRep[0].branchId,
            // accountId: result.salesRep[0].hospital,
            salesRepId: result.salesRep[0].id,
            salesOrganization: result.salesRep[0].salesOrganization,
          };
        }
      } else {
        mutation = { ...mutation };
      }
    }

    if (result.ShipDate) {
      const dt = new Date(result.ShipDate);
      mutation = {
        ...mutation,
        // shipDate: `${moment(result.ShipDate).format('YYYY-MM-DD')}`,
        shipDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`,
      };
    }
    if (result.requestedPickupDate) {
      const dt = new Date(result.requestedPickupDate);
      mutation = {
        ...mutation,
        // shipDate: `${moment(result.ShipDate).format('YYYY-MM-DD')}`,
        shipDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`,
      };
    }

    if (result.expectedReturnDate && returnsType !== 'Pickup Return') {
      const dt = new Date(result.expectedReturnDate);
      mutation = {
        ...mutation,
        // shipDate: `${moment(result.ShipDate).format('YYYY-MM-DD')}`,
        shipDate: `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`,
      };
    }

    if (result.Tracking) {
      if (Array.isArray(result.Tracking)) {
        mutation = { ...mutation, trackingId: result.Tracking[0].id };
      } else {
        mutation = { ...mutation, trackingId: result.Tracking };
      }
    }

    if (result.primaryAccount) {
      mutation = {
        ...mutation,
        accountId: result.primaryAccount[0].id,
        fromSubInventoryCode: result.primaryAccount[0].number,
        // fromSubInventoryCode:
        //   userInfo?.businessunit === 'Sports Medicine'
        //     ? usersubInventoryCode
        //     : result.primaryAccount[0].number,
      };
    }

    // if (result.shipTo) {
    //   mutation = { ...mutation, shipToId: result.shipTo[0].id };
    // }
    if (result.shipTo) {
      if (result.shipTo[0].id) {
        mutation = { ...mutation, shipToId: result.shipTo[0].id };
      } else {
        mutation = { ...mutation };
      }
    }
    if (result.dockAvailable) {
      mutation = { ...mutation, dockAvailable: result.dockAvailable };
    }

    if (result.liftGateNeeded) {
      mutation = { ...mutation, liftGateNeeded: result.liftGateNeeded };
    }
    if (result.shortTruckOnly) {
      mutation = { ...mutation, shortTruckOnly: result.shortTruckOnly };
    }
    if (result.onsiteContractEmail) {
      mutation = { ...mutation, onsiteContractEmail: result.onsiteContractEmail };
    }
    if (result.onsiteContractInstructions) {
      mutation = { ...mutation, onsiteContractInstructions: result.onsiteContractInstructions };
    }
    if (result.onsiteContractName) {
      mutation = { ...mutation, onsiteContractName: result.onsiteContractName };
    }
    if (result.onsiteContractPhone) {
      mutation = { ...mutation, onsiteContractPhone: result.onsiteContractPhone };
    }
    if (result.ReturnReason) {
      mutation = { ...mutation, returnReason: result.ReturnReason[0].id };
    }

    // if (result.Territory) {
    //   mutation = {
    //     ...mutation,
    //     fromSubInventoryCode: result.Territory[0].subInventoryCode
    //       ? result.Territory[0].subInventoryCode
    //       : result.Territory[0].id,
    //   };
    // }
    if (result.comments || result?.comments === '') {
      if (result.externalId) {
        mutation = {
          ...mutation,
          comments: {
            externalId: result.externalId,
            comment: result.comments,
            postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
            commentType: 'Internal',
          },
        };
      } else {
        mutation = {
          ...mutation,
          comments: {
            comment: result.comments,
            postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
            commentType: 'Internal',
          },
        };
      }
    }

    if (attachments) {
      mutation = { ...mutation, attachments };
    }

    if (shippingLabelAttachments) {
      mutation = { ...mutation, shippingLabelAttachments };
    }

    if (result?.lineItems && eventUsage) {
      mutation = { ...mutation, lineItems: formatLineItemToSave(result?.lineItems) };
    }
    if (inventoryReturnDetail) {
      if (attachments && attachments.length > 0) {
        const results = attachments.filter(
          (e: any) =>
            !inventoryReturnDetail.attachments.some((e2: any) => e.imageurl === e2.imageurl)
        );
        attachmentMutation = { ...attachmentMutation, attachments: results };
      }
      updateTransferAttachment({
        variables: attachmentMutation,
      }).then(response => {
        if (
          response &&
          response?.data &&
          response?.data?.inventoryReturnAttachments &&
          response?.data?.inventoryReturnAttachments?.message === 'success' &&
          response?.data?.inventoryReturnAttachments?.externalId !== ''
        ) {
          // setSaveButton(false);
        } else {
          console.log('We had a trouble saving data to salesforce. Please try again later');
        }
      });
    }
    if (mutation) {
      if (type === 'Failed' && inventoryReturnDetail) {
        mutation = {
          ...mutation,
          id: inventoryReturnDetail.id,
          caseId: inventoryReturnDetail.caseId,
          caseExternalId: inventoryReturnDetail.caseExternalId,
          submitType: 'Update',
        };
        reSubmitInventory({
          variables: { ...mutation, attachments: [] },
        })
          .then((res: any) => {
            if (res && res.data && res.data.resubmitOrderDetails?.message === 'success') {
              if (refetchInventoryReturn) {
                if (flowType === 'Details') {
                  setSelectedTab('Details');
                }
                refetchInventoryReturn();
              }
              toggleOpen();
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log('We had a trouble saving data to salesforce. Please try again later');
            // eslint-disable-next-line no-console
            console.log(error); // TODO change when some operation needs to be run on error.
          });
        if (orderLoader) orderLoader();
      } else {
        createInventoryReturn({
          variables: mutation,
        })
          .then(response => {
            setLoader(true);
            if (mutation.formType === 'New') {
              mutation = {
                ...mutation,
                caseSFID: response?.data?.returnInventory?.sfid,
              };
              const recursiveCallApiUpdate = () => {
                createInventoryReturnAfterSfid({
                  variables: mutation,
                })
                  .then(res => {
                    if (res?.data !== null) {
                      setLoader(false);
                    }
                  })
                  .catch(error => {
                    if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                      recursiveCallApiUpdate();
                    } else {
                      setLoader(false);
                      openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                    }
                  });
              };
              recursiveCallApiUpdate();
            }
          })
          .catch((error: any) => {
            if (error) {
              openSnackBar(NOTIFY_TYPE.ERROR, 'Failed to create inventory return.');
            }
          });
      }
    }
  };

  const handleAccountSelection = useCallback(
    accountSelectedValue => {
      setAccountSelected(accountSelectedValue);
    },
    [setAccountSelected]
  );

  const handleToggleButtonChange = useCallback(
    shippingLabelValue => {
      setShippingLabel(shippingLabelValue);
    },
    [setShippingLabel]
  );

  const getInternalComment = (details: any) => {
    const internalComm: any = details?.comments?.filter((item: any) => item.type === 'Internal');
    return internalComm;
  };

  const getFormFieldValues = (): any => {
    if (editDetails) {
      return {
        salesRep: [
          {
            id: editDetails.salesRepId,
            label: editDetails.salesRepName || editDetails.salesRep,
            hospital: editDetails.accountId,
            salesOrganization: editDetails.salesOrganization,
            mobilephone:
              salesRepsData && salesRepsData?.length > 0 ? salesRepsData[0]?.mobilephone : '',
          },
        ],
        primaryAccount: [
          {
            id: editDetails.accountId,
            label: `${editDetails.accountNumber}-${editDetails.accountName}`,
            subInventoryCode: editDetails.accountNumber,
            number: editDetails.accountNumber,
          },
        ],
        liftGateNeeded: true,
      };
    }
    if (inventoryReturnDetail) {
      return {
        salesRep: [
          {
            id: inventoryReturnDetail.salesRepId,
            label: inventoryReturnDetail.salesRepName || inventoryReturnDetail.salesRep,
            hospital: inventoryReturnDetail.accountId,
            salesOrganization: inventoryReturnDetail.salesOrganization,
          },
        ],
        primaryAccount: [
          {
            id: inventoryReturnDetail.accountId,
            label: `${inventoryReturnDetail.accountNumber}-${inventoryReturnDetail.accountName}`,
            subInventoryCode: inventoryReturnDetail.accountNumber,
            number: inventoryReturnDetail.accountNumber,
          },
        ],
        onsiteContractName: inventoryReturnDetail.onsiteContractName,
        onsiteContractPhone: inventoryReturnDetail.onsiteContractPhone,
        onsiteContractEmail: inventoryReturnDetail.onsiteContractEmail,
        onsiteContractInstructions: inventoryReturnDetail.onsiteContractInstructions,
        liftGateNeeded: inventoryReturnDetail.liftGateNeeded,
        dockAvailable: inventoryReturnDetail.dockAvailable,
        isshippingLabelRequired: inventoryReturnDetail.isshippingLabelRequired,
        shortTruckOnly: inventoryReturnDetail.shortTruckOnly,
        requestedPickupDate: inventoryReturnDetail.shipmentDate
          ? moment(inventoryReturnDetail.shipmentDate)
              .utc()
              .format('MM/DD/YYYY')
          : null,

        expectedReturnDate: inventoryReturnDetail.shipmentDate
          ? moment(inventoryReturnDetail.shipmentDate)
              .utc()
              .format('MM/DD/YYYY')
          : null,

        shipTo: [
          {
            id: inventoryReturnDetail.shipToId,
            // label: inventoryReturnDetail.shipToId,
            label: `${inventoryReturnDetail.shippingAddressNumber} - ${
              inventoryReturnDetail.shippingAddessLine1
            } ${inventoryReturnDetail.shippingAddessLine2 ||
              ''}, ${inventoryReturnDetail?.shippingCity || ''}, ${
              inventoryReturnDetail.shippingState
            }, ${inventoryReturnDetail.shippingPostalcode}`,
          },
        ],
        // Tracking: [
        //   {
        //     id: inventoryReturnDetail.trackingNumber,
        //     label: inventoryReturnDetail.trackingNumber,
        //   },
        // ],
        Attachments: [
          {
            id:
              inventoryReturnDetail.attachments.length > 0
                ? inventoryReturnDetail.attachments[0].imageurl
                : '',
            label:
              inventoryReturnDetail.attachments.length > 0
                ? inventoryReturnDetail.attachments[0].name
                : '',
          },
        ],
        shippingLabelAttachments: [
          {
            id:
              inventoryReturnDetail.shippingLabelAttachments.length > 0
                ? inventoryReturnDetail.shippingLabelAttachments[0].imageurl
                : '',
            label:
              inventoryReturnDetail.shippingLabelAttachments.length > 0
                ? inventoryReturnDetail.shippingLabelAttachments[0].name
                : '',
          },
        ],
        // ReturnReason: [
        //   {
        //     id: inventoryReturnDetail.returnReason,
        //     label: inventoryReturnDetail.returnReason,
        //   },
        // ],
        ...(inventoryReturnDetail.comments &&
          inventoryReturnDetail.comments.length && {
            comments: getInternalComment(inventoryReturnDetail)?.[0]?.value,
            externalId: getInternalComment(inventoryReturnDetail)?.[0]?.externalId,
          }),
      };
    }
    if (returnsType === 'Pickup Return') {
      return {
        liftGateNeeded: true,
      };
    }
    return {
      onsiteContractName: null,
      onsiteContractPhone: null,
      franchise: [],
    };
  };

  const handleTabChange = (event: any): void => {
    setSelectedTab(event.label);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab('Details');
    }
  }, [isOpen]);

  const getHeading = (): any => {
    let finalHeading;
    if (inventoryReturnDetail) {
      finalHeading =
        `Edit - ${returnsType === 'Inventory Return' ? 'Standard Return' : returnsType}` || '--';
    } else {
      finalHeading = returnsType;
    }
    return finalHeading;
  };

  // eslint-disable-next-line no-return-assign
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <Modal
        className="form-engine-modal"
        isOpen={isOpen}
        onRequestClose={apiloading ? '' : handleCancelButton}
        ariaHideApp
        heading={
          <div className="slds-float_left slds-text-title_bold">
            {inventoryReturnDetail
              ? `Edit - ${returnsType === 'Inventory Return' ? 'Standard Return' : returnsType}` ||
                '--'
              : returnsType}
          </div>
        }
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_medium slds-theme_shade" key="form">
          <FormEngine
            formItems={formJson()}
            formType={USAGE_TYPE.INVENTORY_RETURN}
            division="EU"
            apiData={apiDataObj}
            useLazyQueryObject={lazyQueryObject}
            handleSave={handleSave}
            toggleOpen={toggleOpen}
            formValues={getFormFieldValues()}
            isOpen={isOpen}
            handleCallback={handleCallback}
            handleCancelButton={handleCancelButton}
            createEventLoading={
              createInventoryReturnLoading || uploadInProgress || loading || loadingShipToApi
            }
            createRequest={apiloading}
            handleUploadFiles={handleUploadFiles}
            handleDeleteAttachments={handleDeleteAttachments}
            uploadedFiles={uploadedFiles}
            uploadedShippingLabelFiles={uploadedShippingLabelFiles}
            selectedTab={selectedTab}
            handleAccountSelection={handleAccountSelection}
            handleTabChange={handleTabChange}
            handleToggleButtonChange={handleToggleButtonChange}
            partsData={subInventoryWithLotDetails ? subInventoryWithLotDetails : []}
            eventUsage={eventUsage}
            isEdit={inventoryReturnDetail}
            isUserOpsCsr={isUserOpsCsr}
            type={
              // eslint-disable-next-line no-cond-assign, no-param-reassign
              inventoryReturnDetail && inventoryReturnDetail?.status === 'Failed' ? true : false
            }
            modalHeading={getHeading()}
          />
        </section>
      </Modal>
    </IconSettings>
  );
};

/** React component for Inventory Return. */
export default withRouter(InventoryReturn);
