/**
 * Module for showing Surgery Details.
 * @module src/EventsCalendar/EventsCalendarDay
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_EVENT_DETAILS } from '../../graphql/getEventDetails';
import SurgicalDetailHeader from './SurgicalDetailHeader';
import SurgicalDetailTabs from './SurgicalDetailTabs';
import CancelEvent from './SurgicalDetailCancelEvent';
import CloneEvent from './SurgicalDetailCloneEvent';
import CANCEL_EVENT from '../../graphql/mutations/cancelEvent';
import { GET_FORM_CONFIGURATIONS } from '../../graphql/getFormConfigurations';
import { EVENT_DETAIL_TABS, ORDER_TYPE } from '../../util/constants';
import { GET_EVENT_INVENTORY } from '../../graphql/getEventInventory';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../graphql/workflowPermissions';
import { GET_COMMENT_HISTORY } from '../../graphql/getCommentHistory';
import { GET_INVENTORY_REQUEST_DETAILS } from '../../graphql/getInventoryDetails';
import { setHoldBannerStatus } from '../../store/ducks/holdBanner';
// import { GET_TRANSLATIONS } from '../../graphql/getTranslations';
import Comments from '../Inventory/InventoryRequestDetail/Comments';

const SurgicalDetail: FC<RouteComponentProps> = ({ location }) => {
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cloneEventModalVisible, setCloneEventModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(true);
  const [tabSelectedIndex, setTabSelectedIndex] = useState<number | null>(0);
  const [commentsSelectedTabIndex, setCommentsSelectedTabIndex] = useState(0);
  const [eventInventoryExternalId, setEventInventoryExternalId] = useState('');
  const [hasEventInventory, setHasEventInventory] = useState(false);

  const { state } = location;
  const dispatch = useDispatch();
  const { externalId, id, showInventory, showInventoryTab, enableInventoryTab } = state;
  const [getWorkflowPermissions] = useLazyQuery(GET_WORKFLOW_PERMISSIONS);
  const { data, loading, refetch: refetchEventDetails } = useQuery(GET_EVENT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
      externalId,
    },
  });
  const [getFormConfigurations] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [
    getCommentHistory,
    { loading: commentsLoading, data: commentHistory, refetch: refetchCommentHistory },
  ] = useLazyQuery(GET_COMMENT_HISTORY, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    getWorkflowPermissions();
  }, [getWorkflowPermissions]);

  const eventDetail = data?.getEventDetails;

  const handleCloseCloneEventModal = (): void => {
    setCloneEventModalVisible(false);
  };

  useEffect(() => {
    dispatch(setHoldBannerStatus(false));
  }, [dispatch]);

  useEffect(() => {
    if (
      !eventInventoryExternalId &&
      eventDetail &&
      eventDetail.eventInventory &&
      eventDetail.eventInventory.length
    ) {
      setEventInventoryExternalId(
        eventDetail && eventDetail.eventInventory && eventDetail.eventInventory[0].externalId
      );
      setHasEventInventory(!hasEventInventory);
    }
    if (eventDetail && eventDetail.eventInventory && eventDetail.eventInventory.length) {
      setEventInventoryExternalId(
        eventDetail && eventDetail.eventInventory && eventDetail.eventInventory[0].externalId
      );
      // setHasEventInventory(!hasEventInventory);
    }
    if (eventDetail && eventDetail.eventInventory && !eventDetail.eventInventory.length) {
      setHasEventInventory(false);
    }
  }, [eventDetail, eventInventoryExternalId, hasEventInventory]);

  const eventStatus = eventDetail ? eventDetail.eventStatus : '';
  const eventType = eventDetail ? eventDetail.eventType : '';
  const caseSfid = eventDetail ? eventDetail.caseId : '';
  const caseExternalId = eventDetail ? eventDetail.caseExternalId : '';
  const eventCancelReason = eventDetail ? eventDetail.cancelReason : null;
  const eventCancelComment = eventDetail ? eventDetail.cancelComment : null;

  const { data: showEventInventoryTab } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventType,
      action: 'show_event_inventory_tab',
    },
  });

  const { data: enableEventInventoryTab } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventStatus,
      action: 'enable_event_inventory_tab',
    },
  });

  const { data: showEventUsageTab } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventType,
      action: 'show_event_usage_tab',
    },
  });

  const { data: enableEventUsageTab } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventStatus,
      action: 'enable_event_usage_tab',
    },
  });

  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'show_surgeon_fields',
    },
  });

  const enableTab = enableInventoryTab || enableEventInventoryTab || hasEventInventory;
  const showTab = showInventoryTab || showEventInventoryTab || hasEventInventory;
  /*
  Will need to use this section later when utilizing i18next because users can direct
  immediately to any routed page. This means that I'd need to import the translations, 
  check if the resource bundle exists, and set it for it does not.
  */
  // const { data: translationData } = useQuery(GET_TRANSLATIONS, {
  //   variables: {
  //     translationKey: 'Translation-en_US',
  //   },
  // });

  // useEffect(() => {
  //   if (showInventory) {
  //     if (!enableTab || !showTab) {
  //       setTabSelectedIndex(0);
  //     } else {
  //       setTabSelectedIndex(1);
  //     }
  //   } else {
  //     setTabSelectedIndex(0);
  //   }
  // }, [enableTab, showTab, showInventory]);

  const handleEditModalVisible = (): void => {
    setIsEditModalVisible(false);
  };

  // const [
  //   getEventInventory,
  //   { data: eventInventoryData, loading: eventInventoryLoading, refetch: refetchEventInventory },
  // ] = useLazyQuery(GET_EVENT_INVENTORY, {
  //   fetchPolicy: 'no-cache',
  //   notifyOnNetworkStatusChange: true,
  // });
  const [
    getEventInventory,
    { data: eventInventoryData, loading: eventInventoryLoading, refetch: refetchEventInventory },
  ] = useLazyQuery(GET_INVENTORY_REQUEST_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      externalId: eventInventoryExternalId,
    },
  });

  const [setCancelEvent, { loading: cancelEventLoading, data: cancelEventData }] = useMutation(
    CANCEL_EVENT
  );

  const handleCancelEventModal = useCallback((): void => {
    setCancelModalVisible(!cancelModalVisible);
  }, [cancelModalVisible]);

  const handleCloneEventModal = useCallback((): void => {
    setIsEditModalVisible(true);
    setCloneEventModalVisible(!cloneEventModalVisible);
  }, [cloneEventModalVisible]);

  useEffect(() => {
    if (eventInventoryExternalId) {
      getEventInventory({
        variables: {
          externalId: eventInventoryExternalId,
        },
      });
    }
  }, [getEventInventory, eventInventoryExternalId]);

  useEffect(() => {
    if (
      cancelEventData &&
      cancelEventData.cancelEvent &&
      cancelEventData.cancelEvent.message === 'success'
    ) {
      if (refetchEventDetails) {
        refetchEventDetails();
      }
      handleCancelEventModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelEventData, refetchEventDetails]);

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  useEffect(() => {
    // Call inventory API
    if (showTab) {
      setTabSelectedIndex(0);
      getEventInventory({
        variables: {
          externalId: eventInventoryExternalId,
        },
      });
    }
  }, [eventInventoryExternalId, getEventInventory, showTab]);

  const cancelEvent = (cancelReason: string): void => {
    const mutation = {
      sfid: id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      externalId,
      reason: cancelReason || '',
    };
    setCancelEvent({
      variables: mutation,
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error); // TODO change when some operation needs to be run on error.
    });
  };

  const handleTabSelect = (value: number): void => {
    setTabSelectedIndex(value);
    // changeSelectedTab(value);
  };

  // const handleTabSelect = (value: number): void => {
  //   setTabSelectedIndex(value);
  //   let tabName = '';
  //   switch (value) {
  //     case 0:
  //       if (showTab) {
  //         tabName = 'Inventory';
  //       } else if (eventDetail?.orderType === ORDER_TYPE.SJC_SM_BILL_ONLY) {
  //         // means usage created
  //         tabName = 'Usage';
  //       } else {
  //         tabName = '';
  //       }
  //       break;
  //     case 1:
  //       tabName = showTab && eventDetail?.orderType === ORDER_TYPE.SJC_SM_BILL_ONLY ? 'Usage' : '';
  //       break;
  //     default:
  //       tabName = '';
  //   }
  //   setCommentsSelectedTabIndex(0);
  //   // if (value === EVENT_DETAIL_TABS.INVENTORY) {
  //   if (tabName === 'Inventory') {
  //     // Call inventory API
  //     getEventInventory({
  //       variables: {
  //         externalId: eventInventoryExternalId,
  //       },
  //     });
  //     // } else if (value === EVENT_DETAIL_TABS.RELATED) {
  //   } else if (tabName === 'Related') {
  //     // Check is it related tab then call internal comment API
  //     if (showSurgeonData && getCommentHistory) {
  //       // Call customer comment API
  //       getCommentHistory({
  //         variables: {
  //           sfid: caseSfid,
  //           caseExternalId,
  //           commentType: 'Customer',
  //         },
  //       });
  //     } else if (getCommentHistory) {
  //       // Call internal comment API
  //       getCommentHistory({
  //         variables: {
  //           sfid: caseSfid,
  //           caseExternalId,
  //           commentType: 'Internal',
  //         },
  //       });
  //     }
  //   }
  // };

  const { data: showSurgeonFields } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'show_surgeon_fields',
    },
  });

  const handleCommentsTabClick = (index: number): void => {
    setCommentsSelectedTabIndex(index);
  };

  const eventId = eventDetail ? eventDetail.eventId : '';
  return (
    <IconSettings iconPath="/icons">
      {loading ? (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      ) : (
        <>
          {eventDetail && (
            <SurgicalDetailHeader
              eventDetail={eventDetail && eventDetail}
              eventType={eventType}
              showCancelEventModal={handleCancelEventModal}
              showCloneEventModal={handleCloneEventModal}
              commentHistory={commentHistory}
              refetchEventDetails={refetchEventDetails}
              eventCancelReason={eventCancelReason}
              eventCancelComment={eventCancelComment}
              hasEventInventory={hasEventInventory}
              calendarView={state.calendarView}
              calendarDate={state.calendarDate}
            />
          )}
          <SurgicalDetailTabs
            eventDetail={eventDetail && eventDetail}
            eventType={eventType}
            handleTabSelect={handleTabSelect}
            eventInventoryData={eventInventoryData}
            eventInventoryLoading={eventInventoryLoading}
            refetchEventInventory={refetchEventInventory}
            showEventInventoryTab={showTab}
            enableEventInventoryTab={enableTab}
            tabSelectedIndex={tabSelectedIndex}
            getCommentHistory={getCommentHistory}
            refetchCommentHistory={refetchCommentHistory}
            commentsLoading={commentsLoading}
            commentHistory={commentHistory}
            showEventUsageTab={showEventUsageTab}
            enableEventUsageTab={enableEventUsageTab}
            refetchEventDetails={refetchEventDetails}
            commentsSelectedTabIndex={commentsSelectedTabIndex}
            handleCommentsTabClick={handleCommentsTabClick}
            eventInventoryExternalId={eventInventoryExternalId}
            hasEventInventory={hasEventInventory}
          />
          <Comments
            inventoryReturnDetails={eventDetail}
            // setUserEnteredComment={setUserEnteredComment}
            viewOnly={false}
            type={eventType}
          />
          {cancelModalVisible && (
            <CancelEvent
              cancelModalVisible={cancelModalVisible}
              handleCancelEventModal={handleCancelEventModal}
              eventType={eventType}
              eventId={eventId}
              cancelEvent={cancelEvent}
              cancelEventLoading={cancelEventLoading}
            />
          )}
          {cloneEventModalVisible && (
            <CloneEvent
              cloneEventModalVisible={cloneEventModalVisible}
              handleCloneEventModal={handleCloneEventModal}
              eventDetail={eventDetail}
              eventInventoryData={eventInventoryData}
              handleEditModalVisible={handleEditModalVisible}
              editModalVisible={isEditModalVisible}
              handleCloseCloneEventModal={handleCloseCloneEventModal}
              showSurgeonData={showSurgeonData}
            />
          )}
        </>
      )}
    </IconSettings>
  );
};

/** Surgery Detail Page**/
export default withRouter(SurgicalDetail);
