/**
 * Module for header on Inventory Request Detail Header Page
 * @module src/CycleCountDetail/CycleCountDetailHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-indent */

import React, { FC, ReactNode } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';
import { CYCLE_COUNT, SPORTS_MED } from '../../../../util/constants';
import { getEventIcon, getSvgIcon } from '../../../../util/utilityFunctions';
import DisplayRecordIndicator from '../../../Shared/DisplayRecordIndicator';

// import ReadMore from '../../Shared/ReadMore';

interface Props {
  cycleCountDetails?: any;
  handleActionDropdownSelect?: any;
  refetch?: () => void;
  opsActionType?: any;
}

const CycleCountDetailHeader: FC<Props> = ({
  cycleCountDetails,
  handleActionDropdownSelect,
  refetch,
  opsActionType,
}) => {
  const {
    caseNumber,
    countType,
    endDate,
    eventType,
    isInventoryHold,
    inventoryLocation,
    startDate,
    territory,
    status,
    salesRepDetails,
    salesRep,
    opsDetails,
    comments,
    asrOrRsmDetails,
  } = cycleCountDetails;
  const userInfo = useSelector(getUserInfo);
  const handleNavigateToEventList = (): void => {
    window.history.back();
  };
  const inprogress = getSvgIcon('inprogress');
  const submitted = getSvgIcon('submit');

  let disableAction = false;

  if (cycleCountDetails && userInfo?.personas !== 'Branch Op') {
    const startDateValue = moment(new Date(cycleCountDetails.startDate)).format('MM/DD/YYYY');
    const endDateValue = moment(new Date(cycleCountDetails.endDate)).format('MM/DD/YYYY');
    const currentDate = moment(new Date()).format('MM/DD/YYYY');

    if (status.toLowerCase() === 'in progress' && currentDate > endDateValue) {
      disableAction = true;
    }
  }
  const actions = (): ReactNode => (
    <>
      {userInfo && userInfo?.personas?.toLowerCase().includes('branch op') ? (
        <PageHeaderControl>
          <Dropdown
            iconCategory="utility"
            iconSize="small"
            align="right"
            iconName="down"
            iconPosition="right"
            label="Actions"
            // disabled={(type === 'Failed') && !(userInfo && userInfo.personas === 'Branch Op')}
            options={
              status.toLowerCase() === 'submitted' &&
              opsActionType === 'Assign' &&
              userInfo?.personas?.toLowerCase().includes('branch op')
                ? [
                    {
                      label: 'Complete',
                      value: 'complete',
                      disabled: true,
                    },
                    {
                      label: 'Audit Complete',
                      value: 'audit_complete',
                      disabled: true,
                    },
                    // { label: 'Order Details', value: 'order_details' },
                    {
                      label: 'Cancel',
                      value: 'cancel',
                      disabled: true,
                    },
                    {
                      label: 'Edit',
                      value: 'edit',
                      disabled: true,
                    },
                    {
                      label: 'Export',
                      value: 'export',
                      disabled: true,
                    },
                  ]
                : [
                    {
                      label: 'Complete',
                      value: 'complete',
                      disabled: !!(
                        status.toLowerCase() === 'completed' || status.toLowerCase() === 'cancelled'
                      ),
                    },
                    {
                      label: 'Audit Complete',
                      value: 'audit_complete',
                      disabled: !!(
                        status.toLowerCase() === 'completed' || status.toLowerCase() === 'cancelled'
                      ),
                    },
                    // { label: 'Order Details', value: 'order_details' },
                    {
                      label: 'Cancel',
                      value: 'cancel',
                      disabled: !!(
                        status.toLowerCase() === 'completed' ||
                        status.toLowerCase() === 'cancelled' ||
                        status.toLowerCase() === 'submitted'
                      ),
                    },
                    {
                      label: 'Edit',
                      value: 'edit',
                      disabled: !!(
                        status.toLowerCase() === 'completed' ||
                        status.toLowerCase() === 'cancelled' ||
                        (status.toLowerCase() === 'submitted' &&
                          !userInfo?.personas?.toLowerCase().includes('branch op'))
                      ),
                    },
                    {
                      label: 'Export',
                      value: 'export',
                      disabled: !!(status.toLowerCase() === 'cancelled'),
                    },
                  ]
            }
            onSelect={(value: string): void => handleActionDropdownSelect(value)}
          />
        </PageHeaderControl>
      ) : (
        <PageHeaderControl>
          <Dropdown
            iconCategory="utility"
            iconSize="small"
            align="right"
            iconName="down"
            iconPosition="right"
            label="Actions"
            // disabled={(type === 'Failed') && !(userInfo && userInfo.personas === 'Branch Op')}
            options={[
              {
                label: 'Edit',
                value: 'edit',
                disabled: !(status.toLowerCase() === 'in progress') || disableAction,
              },
              {
                label: 'Submit Count',
                value: 'submit',
                disabled:
                  !(status.toLowerCase() === 'in progress') ||
                  (cycleCountDetails && cycleCountDetails?.lineItems?.length === 0) ||
                  disableAction,
              },
            ]}
            onSelect={(value: string): void => handleActionDropdownSelect(value)}
          />
        </PageHeaderControl>
      )}

      <PageHeaderControl>
        <DisplayRecordIndicator recordInfo={cycleCountDetails} />
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon(CYCLE_COUNT);
  const opsSubmitAction = !!salesRepDetails?.find((rec: any) => rec?.isSubmittedByOPS);
  const asrSubmitAction = !!asrOrRsmDetails?.find((rec: any) => rec?.isSubmittedByAsrOrRsm);

  return (
    <IconSettings iconPath="/icons">
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        // label="Cycle Count"
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <div className="slds-col event_id">
              Cycle Count
              {/* {userInfo?.businessunit !== SPORTS_MED && ( */}
              <div className="slds-col event_id_caseId" title={caseNumber}>
                {caseNumber || 'TBD'}
              </div>
              {/* )} */}
            </div>
            <span className="slds-col slds-m-left_large">
              <div style={{ display: 'flex' }} title="Cycle Count Status">
                <EventStatusBadge status={status} />
              </div>
            </span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {/* {userInfo?.businessunit === SPORTS_MED && (
          <>
            <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
              <div className="slds-text-title" title="Sales Rep" style={{ fontWeight: 'bold' }}>
                ID
              </div>
              <div className="slds-tooltip-trigger">
                <div title={caseNumber}>{caseNumber || 'TBD'}</div>
              </div>
            </li>
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Start Date"
                style={{ fontWeight: 'bold' }}
              >
                Start Date
              </div>
              <p className="slds-line-clamp">
                {startDate
                  ? moment(startDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : '--'}
              </p>
            </li>

            <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="End Date"
                style={{ fontWeight: 'bold' }}
              >
                End Date
              </div>
              <p className="slds-line-clamp">
                {endDate
                  ? moment(endDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : '--'}
              </p>
            </li>
          </>
        )} */}
        {/* {userInfo?.businessunit !== SPORTS_MED && ( */}
        <>
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div className="slds-text-title" title="Account" style={{ fontWeight: 'bold' }}>
              Account
            </div>
            <div className="slds-tooltip-trigger">
              <div title={territory}>{`${inventoryLocation || ''}-${territory || ''}`}</div>
            </div>
          </li>
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Start Date"
              style={{ fontWeight: 'bold' }}
            >
              Sales Rep
            </div>
            {/* {salesRepDetails &&
              salesRepDetails.map((item: any) => {
                return (
                  <div>
                    {`${item.salesRepName}`}
                    {`${item.salesRepName} - ${
                      item.salesRepStatus === 'Submitted' && item.isSubmittedByOPS
                        ? 'Submitted on Behalf Of'
                        : item.salesRepStatus
                    }`}
                    {item.salesRepStatus === 'Submitted' && item.isSubmittedByOPS && (
                      <Icon
                        category="utility"
                        name="info"
                        size="x-small"
                        style={{ fill: '#000', marginBottom: '3px', marginLeft: '5px' }}
                      />
                    )}
                  </div>
                );
              })} */}
            {salesRep &&
              salesRep?.map((item: any) => {
                return <div>{`${item?.split('-')[0]}`}</div>;
              })}
          </li>
        </>
        {/* )} */}
        <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Count Type"
            style={{ fontWeight: 'bold' }}
          >
            Count Type
          </div>
          <div title={countType}>{countType}</div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Inventory Hold"
            style={{ fontWeight: 'bold' }}
          >
            Inventory Hold
          </div>
          <div title={isInventoryHold ? `Yes` : 'No'}>{isInventoryHold ? `Yes` : 'No'}</div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_1.5-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Reason Code"
            style={{ fontWeight: 'bold' }}
          >
            Reason Code
          </div>
          {'No'}
        </li>
      </ul>
      {/* {userInfo?.businessunit === SPORTS_MED && (
        <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none">
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Territory"
              style={{ fontWeight: 'bold' }}
            >
              Territory
            </div>
            <div title={territory}>{territory}</div>
          </li> */}
      {/* <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Counters"
              style={{ fontWeight: 'bold' }}
            >
              {`Counters`}
            </div>
            {salesRepDetails &&
              salesRepDetails.map((item: any) => {
                return (
                  <div>
                    {`${item.salesRepName} - ${
                      item.salesRepStatus === 'Submitted' && item.isSubmittedByOPS
                        ? 'Submitted on Behalf Of'
                        : item.salesRepStatus
                    }`}
                    {item.salesRepStatus === 'Submitted' && item.isSubmittedByOPS && (
                      <Icon
                        category="utility"
                        name="info"
                        size="x-small"
                        style={{ fill: '#000', marginBottom: '3px', marginLeft: '5px' }}
                      />
                    )}
                  </div>
                );
              })}
          </li> */}
      {/* <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Inventory Location"
              style={{ fontWeight: 'bold' }}
            >
              Inventory Location
            </div>
            <div title={inventoryLocation || ''}>{inventoryLocation || ''}</div>
          </li>
        </ul> */}
      {/* )} */}
      {/* {userInfo?.businessunit !== SPORTS_MED && ( */}
      <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none">
        <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Start Date"
            style={{ fontWeight: 'bold' }}
          >
            Start Date
          </div>
          <p className="slds-line-clamp">
            {startDate
              ? moment(startDate)
                  .utc()
                  .format('MM/DD/YYYY')
              : '--'}
          </p>
        </li>

        <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="End Date"
            style={{ fontWeight: 'bold' }}
          >
            End Date
          </div>
          <p className="slds-line-clamp">
            {endDate
              ? moment(endDate)
                  .utc()
                  .format('MM/DD/YYYY')
              : '--'}
          </p>
        </li>

        <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Counters"
            style={{ fontWeight: 'bold' }}
          >
            {`Counters`}
          </div>
          {salesRepDetails &&
            !(status.toLowerCase() === 'submitted' || status.toLowerCase() === 'completed') &&
            salesRepDetails.map((item: any) => {
              return (
                <div>
                  {`${item?.salesRepName}`}
                  {item?.salesRepStatus?.toLowerCase() === 'submitted' && (
                    <img
                      className="slds-tooltip-trigger"
                      src={submitted}
                      alt="Submitted"
                      title="Submitted"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  )}
                  {(item.salesRepStatus === 'In Progress' ||
                    item.salesRepStatus === 'Scheduled') && (
                    <img
                      className="slds-tooltip-trigger"
                      src={inprogress}
                      title="In Progress"
                      alt="In Progress"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  )}
                </div>
              );
            })}
          {salesRepDetails &&
            (status.toLowerCase() === 'submitted' || status.toLowerCase() === 'completed') &&
            !asrSubmitAction &&
            salesRepDetails.map((item: any) => {
              return (
                <div>
                  {`${item?.salesRepName}`}
                  {item?.salesRepStatus?.toLowerCase() === 'submitted' && (
                    <img
                      className="slds-tooltip-trigger"
                      src={submitted}
                      alt="Submitted"
                      title="Submitted"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  )}
                  {(item.salesRepStatus === 'In Progress' ||
                    item.salesRepStatus === 'Scheduled') && (
                    <img
                      className="slds-tooltip-trigger"
                      src={inprogress}
                      title="In Progress"
                      alt="In Progress"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  )}
                </div>
              );
            })}
          {asrSubmitAction &&
            (status.toLowerCase() === 'submitted' || status.toLowerCase() === 'completed') &&
            asrOrRsmDetails.map((item: any) => {
              return (
                <div>
                  {`${item?.asrOrRsmUserName}`}
                  {item?.asrOrRsmStatus?.toLowerCase() === 'submitted' && (
                    <img
                      className="slds-tooltip-trigger"
                      src={submitted}
                      alt="Submitted"
                      title="Submitted"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  )}
                  {(item.asrOrRsmStatus === 'In Progress' ||
                    item.asrOrRsmStatus === 'Scheduled') && (
                    <img
                      className="slds-tooltip-trigger"
                      src={inprogress}
                      title="In Progress"
                      alt="In Progress"
                      style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                    />
                  )}
                </div>
              );
            })}
          {opsDetails &&
            (status.toLowerCase() === 'submitted' || status.toLowerCase() === 'completed') &&
            opsDetails?.map((item: any) => {
              return (
                <div>
                  {`${item?.opsUserName}`}
                  <img
                    className="slds-tooltip-trigger"
                    src={submitted}
                    alt="Submitted"
                    title="Submitted"
                    style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                  />
                </div>
              );
            })}
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Header Comments"
            style={{ fontWeight: 'bold' }}
          >
            {`Header Comments`}
          </div>
          {comments && comments.length
            ? comments.filter((item: { type: string }) => item?.type === 'Internal')?.[0]?.value ||
              '--'
            : '--'}
        </li>
      </ul>
      {/* )} */}
    </IconSettings>
  );
};

/** Custom Header on Inventory Request Detail Header Page **/
export default CycleCountDetailHeader;
