/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/**
 * Module for showing Inventory Details.
 * @module src/InventoryRequest/InventoryReturnDetail
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useSelector, useDispatch } from 'react-redux';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../graphql/getDetailsViewConfigurations';
import InventoryReturnDetailCatalog from './InventoryReturnDetailCatalog';
import './index.scss';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';

import InventoryReturnDetailHeader from './InventoryReturnDetailHeader';
import { GET_INVENTORY_RETURN_DETAILS } from '../../../graphql/getInventoryReturnDetails';
import SUBMIT_INVENTORY_RETURN from '../../../graphql/mutations/submitInventoryReturn';
import RESUBMIT_ORDER_DETAILS from '../../../graphql/mutations/resubmitOrderDetails';
import CANCEL_INVENTORY_RETURNS from '../../../graphql/mutations/cancelInventoryReturns';
import CancelReturnModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import CancelReturnInventory from '../InventoryReturnCancel';
import InventoryReturn from '../InventoryReturns/InventoryReturn';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { NonSalesRep, NOTIFY_TYPE, OPS_ACTION_TYPE, OPSCSRUSERS } from '../../../util/constants';
import { getUserInfo, setUserInfo } from '../../../store/ducks/userInfo';
import { getUserId } from '../../../store/ducks/userId';
import { GET_USER_INFO } from '../../../graphql/getUserInfoData';
import { GET_ORDER_DETAILS } from '../../../graphql/getOrderDetails';
import Comments from '../InventoryRequestDetail/Comments';

interface LineItem {
  lotValidation?: any;
  partAddedFrom: any;
  sourceLocation: any;
  iscatalog: boolean;
  isLotNumber: any;
  id: string;
  externalId: string;
  lotNumber: string;
  productId: string;
  quantity: string;
  priceBookEntryId: string;
  unitPrice: string;
  salesUnitOfMeasure: string;
  status: string;
  deliveredDate: string;
  workflowAction: string;
  type: string;
}

export interface Props extends RouteComponentProps {
  children?: FC;
}

const InventoryReturnDetail: FC<Props> = ({ location, history }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const userId = useSelector(getUserId);
  const { state } = location;
  const { open, notification, openSnackBar } = useSnackBar();
  const { externalId, type, orderId, caseExternalId } = state;
  const [openInventoryReturnCancelModal, setOpenInventoryReturnCancelModal] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [reprocessQueue, setReprocessQueue] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [selectedTabIndex, changeSelectedTab] = useState(0);
  const [orderLoading, setOrderLoading] = useState(false);
  const isUserOps =
    userInfo?.personas?.toLowerCase().includes('branch op') ||
    userInfo?.personas?.includes('3PL Ops');
  const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  const { data, refetch: refetchInventoryReturnDetails, loading } = useQuery(
    GET_INVENTORY_RETURN_DETAILS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: externalId || caseExternalId,
      },
    }
  );
  let checkLotNotAvailable = false;
  const {
    data: getOrderDetails,
    refetch: refetchProcessedEventDetails,
    loading: processedLoading,
  } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: orderId,
    },
  });

  const { data: userInfoData } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);
  const [
    setSubmitReturnInventory,
    { loading: submitReturnInventoryLoading, data: submitReturnInventoryData },
  ] = useMutation(SUBMIT_INVENTORY_RETURN);

  const [reSubmitOrder] = useMutation(RESUBMIT_ORDER_DETAILS);

  const [
    setCancelReturnInventory,
    { data: cancelReturnInventoryData, loading: cancelReturnInventoryLoading },
  ] = useMutation(CANCEL_INVENTORY_RETURNS);

  const isNotSR = !!NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas);

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  useEffect(() => {
    try {
      dispatch(setUserInfo(userInfoData ? userInfoData.getUserInfo[0] : {}));
    } catch (err) {
      // do nothing
    }
  }, [dispatch, userInfoData]);

  useEffect(() => {
    if (state) {
      const { externalId: caseExternalId, opsAction } = state;
      if (caseExternalId) {
        if (opsAction) {
          setReprocessQueue(true);
          if (opsAction && opsAction === OPS_ACTION_TYPE.ASSIGN) {
            setViewOnly(true);
          }
        }
      } else {
        setReprocessQueue(false);
      }
    }
  }, [history, state]);

  useEffect(() => {
    if (
      submitReturnInventoryData &&
      submitReturnInventoryData.submitInventoryReturn &&
      submitReturnInventoryData.submitInventoryReturn.message === 'success'
    ) {
      if (refetchInventoryReturnDetails && type === 'Return') {
        refetchInventoryReturnDetails();
      }
      if (type === 'Completed' || type === 'Failed') {
        refetchProcessedEventDetails();
      }
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory Return successfully submitted.');
      setTimeout(() => {
        history.push('/Inventory/Returns');
      }, 1000);
    }

    if (
      cancelReturnInventoryData &&
      cancelReturnInventoryData.cancelInventoryReturns &&
      cancelReturnInventoryData.cancelInventoryReturns.message === 'success'
    ) {
      setTimeout(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory return successfully cancelled.');
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submitReturnInventoryData,
    refetchInventoryReturnDetails,
    cancelReturnInventoryData,
    refetchProcessedEventDetails,
  ]);

  /* useEffect(() => {
    if (
      submitReturnInventoryData &&
      submitReturnInventoryData.submitInventoryReturn &&
      submitReturnInventoryData.submitInventoryReturn.message === 'success' &&
      inventoryReturnDetail &&
      inventoryReturnDetail.status === 'Completed'
    ) {
      history.push('/inventory/Returns/completed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitReturnInventoryData, inventoryReturnDetail]);
 */

  const refetchInventoryReturn = (): void => {
    if (type === 'Completed' || type === 'Failed') {
      if ((type === 'Completed' && orderId) || type === 'Failed') {
        refetchProcessedEventDetails();
      } else {
        refetchInventoryReturnDetails();
      }
    } else {
      refetchInventoryReturnDetails();
    }
    // setTimeout(() => {
    //   refetchInventoryReturnDetails();
    // }, 3000);
  };

  useEffect(() => {
    setOrderLoading(false);
  }, [getOrderDetails]);

  let inventoryReturnDetail: any;
  // const inventoryReturnDetail = data && data?.getInventoryReturnDetails;
  if (type === 'Completed' || type === 'Failed') {
    if ((type === 'Completed' && orderId) || type === 'Failed') {
      inventoryReturnDetail = getOrderDetails?.getOrderDetails;
    } else {
      inventoryReturnDetail = data?.getInventoryReturnDetails;
    }
  } else {
    inventoryReturnDetail = data?.getInventoryReturnDetails;
  }

  const returnType = inventoryReturnDetail?.returnType || inventoryReturnDetail?.erpOrderType;
  const detailsViewconfig =
    returnType === 'Pickup Return'
      ? getDetailsViewConfigurations?.getDetailsViewConfigurations?.pickupReturnDetail
      : getDetailsViewConfigurations?.getDetailsViewConfigurations?.StandardReturnDetail;

  const orderLoader = (): void => {
    const returnType = inventoryReturnDetail?.returnType || inventoryReturnDetail?.erpOrderType;
    if (returnType === 'Pickup Return') {
      setOrderLoading(true);
    }
  };

  const formatLineItemToSubmit = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map((item: LineItem): any => {
      if (
        ((!item?.lotNumber && item?.iscatalog) || (!item?.sourceLocation && item?.iscatalog)) &&
        item?.lotValidation === 'true'
      ) {
        checkLotNotAvailable = true;
      }
      finalLineItems.push({
        id: item?.id,
        externalId: item.externalId,
        lotNumber: item.lotNumber,
        isLotNumber: item?.isLotNumber,
        productId: item.productId,
        quantity: item.quantity,
        priceBookEntryId: item.priceBookEntryId,
        unitPrice: item.unitPrice,
        salesUnitOfMeasure: item.salesUnitOfMeasure,
        deliveredDate: item.deliveredDate,
        status: item.status,
        workflowAction: item?.workflowAction ? item?.workflowAction : '',
        type: item?.type ? item?.type : '',
        partAddedFrom: item.iscatalog ? 'Catalog' : item?.sourceLocation,
      });
    });
    return finalLineItems;
  };

  const formatLineItemToReSubmit = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map((item: any): any => {
      if (
        ((!item?.lotNumber && item?.iscatalog) || (!item?.sourceLocation && item?.iscatalog)) &&
        item?.lotValidation === 'true'
      ) {
        checkLotNotAvailable = true;
      }
      finalLineItems.push({
        id: item?.id,
        caseUsageId: item.caseUsageId,
        caseUsageExternalId: item.caseUsageExternalId,
        sfid: item.sfid,
        externalId: item.externalId,
        isLotNumber: item?.isLotNumber,
        lotNumber: item.lotNumber,
        productId: item.productId,
        productNumber: item.productNumber,
        quantity: item.quantity,
        partAddedFrom: item.iscatalog ? 'Catalog' : item?.sourceLocation,
        sourceLocation: item.sourceLocation,
      });
    });
    return finalLineItems;
  };

  // const showReturnResonWarning = (): boolean => {
  //   if (inventoryReturnDetail?.lineItems?.length > 0) {
  //     let showError = false;

  //     inventoryReturnDetail.lineItems.forEach((item: any) => {
  //       if (!item.returnReason) {
  //         showError = true;
  //       }
  //     });

  //     if (showError) {
  //       openSnackBar(NOTIFY_TYPE.ERROR, 'Please select return reason and then submit');

  //       return true;
  //     }
  //   }

  //   return false;
  // };

  const handleResubmit = (): void => {
    if (inventoryReturnDetail && inventoryReturnDetail.lineItems.length === 0) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'Return request should have at least one line item associated with the request'
      );
    } else if (inventoryReturnDetail?.isCycleCountInitiated === true) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'One or More part`s Account has cyclecount Initiated');
    } else {
      // const filterInventoryOnHold = selectedInventoryItems.filter(
      //   (item: any) => item.productOnHold
      // );
      // if (filterInventoryOnHold && filterInventoryOnHold.length > 0) {
      //   openSnackBar(
      //     NOTIFY_TYPE.ERROR,
      //     'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      //   );
      //   return;
      // }
      // const showError = showReturnResonWarning();

      // if (showError) return;

      const updatedPickedItems = formatLineItemToReSubmit(inventoryReturnDetail?.lineItems);
      if (checkLotNotAvailable) {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'One or more parts are not available. Inventory needs to be adjusted before submitting.'
        );
        return;
      }
      const {
        id,
        accountId,
        salesRepId,
        fromSubInventoryCode,
        // salesOrganization,
      } = inventoryReturnDetail;
      const updatedDetails = {
        salesRepId,
        accountId,
        fromSubInventoryCode:
          (inventoryReturnDetail && inventoryReturnDetail?.fromSubInventoryCode) || '',
        // salesOrganization,
        id: (inventoryReturnDetail && inventoryReturnDetail.id) || '',
        orderExternalId: (inventoryReturnDetail && inventoryReturnDetail.externalId) || '',
        submitType: 'Resubmit',
        requestedFrom: 'Inventory Return',
        lineItems: updatedPickedItems,
      };
      if (updatedPickedItems && updatedPickedItems.length > 0) {
        reSubmitOrder({
          variables: updatedDetails,
        })
          .then((res: any) => {
            if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
              openSnackBar(NOTIFY_TYPE.SUCCESS, `Return has been resubmitted successfully.`);
              setTimeout(() => {
                // refetch();
                if (reprocessQueue) {
                  history.push(`/Inventory/FailedRequests`);
                } else {
                  history.push(`/inventory/Returns/${type.toLowerCase()}`);
                }
              }, 1000);
            }
          })
          .catch(error => {
            if (error && error.message) {
              openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
            } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory Return error.');
          });
      } else {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'Return request should have at least one line item associated with the request.'
        );
      }
    }
  };

  const submitInventoryReturn = (): void => {
    if (inventoryReturnDetail?.isCycleCountInitiated === true) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'One or More part`s Account has cyclecount Initiated');
    } else {
      // sunshine act hold alert
      // if (userInfo?.isSubInventoryOnhold && userInfo?.onholdReason !== 'Trial Hold') {
      //   openSnackBar(
      //     NOTIFY_TYPE.ERROR,
      //     'Submit is not allowed while user is on hold. Please contact Ops.'
      //   );
      //   return;
      // }

      if (returnType === 'Inventory Return') {
        const configDetails =
          getDetailsViewConfigurations?.getDetailsViewConfigurations?.StandardReturnDetail;
        const trackingConfig = configDetails?.ReturningTab.filter((item: any) =>
          item?.mappingKey?.toLowerCase().includes('tracking')
        );

        if (trackingConfig?.[0]?.isShow && !inventoryReturnDetail?.isshippingLabelRequired) {
          const trackingDetails: any = inventoryReturnDetail?.lineItems?.filter(
            (item: any) => !item?.trackingNumber
          );
          if (trackingDetails?.length > 0) {
            openSnackBar(NOTIFY_TYPE.ERROR, 'Tracking number is required');
            return;
          }
        }
      }

      if (returnType === 'Inventory Return') {
        const configDetails =
          getDetailsViewConfigurations?.getDetailsViewConfigurations?.StandardReturnDetail;
        const trackingConfig = configDetails.ReturningTab.filter(
          (item: any) => item.mappingKey === 'Tracking'
        );

        if (trackingConfig?.[0]?.isShow && !inventoryReturnDetail?.isshippingLabelRequired) {
          const trackingDetails: any = inventoryReturnDetail.lineItems?.filter(
            (item: any) => !item.trackingNumber
          );
          if (trackingDetails?.length > 0) {
            openSnackBar(NOTIFY_TYPE.ERROR, 'Tracking number is required');
            return;
          }
        }
      }

      // if (userInfo?.isSubInventoryOnhold) {
      //   openSnackBar(NOTIFY_TYPE.ERROR, 'Sub Inventory on hold. Please contact Ops');
      //   return;
      // }
      // const showError = showReturnResonWarning();

      // if (showError) return;

      const mutation = {
        orderId: inventoryReturnDetail.orderId,
        orderExternalId: inventoryReturnDetail.orderExternalId,
        accountId: inventoryReturnDetail.accountId,
        salesRepId: inventoryReturnDetail.salesRepId,
        fromSubInventoryCode: inventoryReturnDetail?.fromSubInventoryCode || '',
        salesOrganization: inventoryReturnDetail?.salesOrganization || '',
        returnType,
        returnStatus: inventoryReturnDetail.visualIndicator,
        isShippingLabelRequired: inventoryReturnDetail.isshippingLabelRequired,
        lineItems: formatLineItemToSubmit(inventoryReturnDetail.lineItems),
      };

      if (checkLotNotAvailable) {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'One or more parts are not available. Inventory needs to be adjusted before submitting.'
        );
        return;
      }
      setSubmitReturnInventory({
        variables: mutation,
      }).catch((error: any) => {
        if (error?.message?.toLowerCase().includes('permission denied')) {
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Submit is not allowed while user is on hold. Please contact Ops.'
          );
        } else openSnackBar(NOTIFY_TYPE.ERROR, `Error: ${error}.`);
        // eslint-disable-next-line no-console
        console.log('errorerror', error); // TODO change when some operation needs to be run on error.
      });
    }
  };

  const cancelReturnInventory = (): void => {
    const mutation = {
      externalId: externalId || inventoryReturnDetail.caseExternalId,
    };
    setCancelReturnInventory({
      variables: mutation,
    })
      .then(() => {
        setOpenInventoryReturnCancelModal(false);
        if (type === 'Completed' || type === 'Failed') {
          refetchProcessedEventDetails();
        } else {
          refetchInventoryReturnDetails();
        }
      })
      .catch(error => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory return cancellation error.');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const handleInventoryReturnActionDropdownSelect = (option: any): void => {
    if (option.value === 'cancel_request' && !option.disabled) {
      setOpenInventoryReturnCancelModal(!openInventoryReturnCancelModal);
    } else if (option.value === 'submit' && !option.disabled) {
      if (option.disabledReason === 'missing_line_items') {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Please add the parts and then submit');
      } else {
        submitInventoryReturn();
      }
    } else if (option.value === 'edit' && !option.disabled) {
      setIsEdit(true);
    } else if (option.value === 'discrepancy' && !option.disabled) {
      // eslint-disable-next-line no-unused-expressions
      userInfo?.businessRules.forEach((br: any) => {
        if (br.rules === 'Inventory Discrepancy Email Recipient') {
          window.open(
            `mailto: ${br.values}?subject=Inventory Return: ${
              inventoryReturnDetail.orderNumber ? inventoryReturnDetail.orderNumber : ''
            }&body=`
          );
        }
      });
    } else if (option.value === 'resubmit' && !option.disabled) {
      handleResubmit();
    }
  };

  const handleCancelInventoryReturnModal = (): void => {
    setOpenInventoryReturnCancelModal(!openInventoryReturnCancelModal);
  };

  const handleModalToggle = useCallback((): void => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    handleModalToggle();
  };

  const handleRequestClick = (
    parentEventType: any,
    parentCaseExternalId: any,
    parentEventId: any
  ): void => {
    if (window.location.pathname !== '/surgicalDetails') {
      history.push('/surgicalDetails', {
        eventType: parentEventType,
        externalId: parentCaseExternalId,
        id: parentEventId,
      });
    }
  };
  const orderNumber = inventoryReturnDetail ? inventoryReturnDetail.orderNumber : '--';
  const orderType = inventoryReturnDetail ? inventoryReturnDetail.type : '';
  const visualIndicator = inventoryReturnDetail ? inventoryReturnDetail.visualIndicator : '';
  const caseNumber = inventoryReturnDetail ? inventoryReturnDetail.caseNumber : '';

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {loading || submitReturnInventoryLoading || processedLoading || orderLoading ? (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      ) : (
        <>
          {
            <>
              <InventoryReturnDetailHeader
                inventoryReturnDetail={inventoryReturnDetail}
                handleInventoryReturnActionDropdownSelect={
                  handleInventoryReturnActionDropdownSelect
                }
                isOrder={(type === 'Completed' && orderId) || type === 'Failed'}
                isFailed={type === 'Failed'}
                handleRequestClick={handleRequestClick}
                detailsViewconfig={detailsViewconfig?.headers}
                viewOnly={viewOnly}
              />
              <InventoryReturnDetailCatalog
                inventoryReturnDetail={inventoryReturnDetail}
                refetch={refetchInventoryReturn}
                changeSelectedTab={changeSelectedTab}
                visualIndicator={visualIndicator}
                hasEventInventory
                detailsConfig={detailsViewconfig}
                type="pickup"
                externalId={type === 'Completed' || type === 'Failed' ? orderId : externalId}
                isOrder={type === 'Completed' /* || type === 'Failed'*/}
                returnType={type}
                viewOnly={viewOnly}
              />
              <Comments
                inventoryReturnDetails={inventoryReturnDetail}
                viewOnly={false}
                type={type}
              />
              {openInventoryReturnCancelModal && (
                <CancelReturnInventory
                  cancelInventoryModalVisible={openInventoryReturnCancelModal}
                  handleCancelInventoryModal={handleCancelInventoryReturnModal}
                  eventId={type === 'Failed' && isUserOpsCsr ? caseNumber : orderNumber}
                  eventType={type === 'Failed' && isUserOpsCsr ? 'Inventory Return' : orderType}
                  cancelInventory={cancelReturnInventory}
                  cancelInventoryLoading={cancelReturnInventoryLoading}
                />
              )}
              {isEdit && (
                <InventoryReturn
                  isOpen={isEdit}
                  toggleOpen={handleModalToggle}
                  formData={formdata}
                  inventoryReturnDetail={inventoryReturnDetail}
                  refetchInventoryReturn={
                    type === 'Failed' ? refetchProcessedEventDetails : refetchInventoryReturn
                  }
                  eventType="Return Inventory"
                  returnsType={returnType}
                  handleCancelButton={handleCancelButton}
                  type={type}
                  orderLoader={orderLoader}
                />
              )}
              {canceModalVisible && (
                <CancelReturnModal
                  isReturn
                  canceModalVisible={canceModalVisible}
                  handleCancelModal={handleCancelModal}
                  handleCancelModalConfirmPress={handleCancelModalConfirmPress}
                  edit={isEdit}
                />
              )}
            </>
          }
        </>
      )}
    </IconSettings>
  );
};

export default withRouter(InventoryReturnDetail);
