/**
 * Module for showing Inventory Details.
 * @module src/InventoryRequest/InventoryFavoriteDetail
 */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { unset } from 'lodash';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import InventoryFavoriteDetailHeader from './InventoryFavoriteDetailHeader';
import InventoryFavoriteDetailCatalog from './InventoryFavoriteDetailCatalog';
import InventoryFavoriteSaveAsModal from './InventoryFavoriteSaveAsModal';
import InventoryFavoriteEditModal from './InventoryFavoriteEditModal';
import InventoryRequest from '../InventoryRequest';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import CREATE_UPDATE_INVENTORY_FAVORITE from '../../../graphql/mutations/createUpdateInventoryFavorites';
import DELETE_INVENTORY_FAVORITE from '../../../graphql/mutations/deleteInventoryFavorite';
import { GET_INVENTORY_REQUEST_FAVORITE_ID } from '../../../graphql/getInventoryRequestFavoriteById';
import { NOTIFY_TYPE } from '../../../util/constants';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import { getUTCDateTime } from '../../../util/utilityFunctions';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';

export type Props = RouteComponentProps;

const InventoryFavoriteDetail: FC<Props> = ({ history, location }) => {
  const { state } = location;
  const { inventoryRequestFav } = state;
  const { nameOfFavorite, serviceLevel, comments } = inventoryRequestFav;
  const { open, notification, openSnackBar } = useSnackBar();
  const [openInventoryFlow, setOpenInventoryFlow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveAs, setIsSaveAs] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [FavName, setFavName] = useState(nameOfFavorite);
  const [inputValue, setInputValue] = useState('');
  const [selection, setSelection] = useState<any>([{ label: serviceLevel, value: serviceLevel }]);
  const [comment, setComment] = useState(
    comments && comments.length ? comments.map((i: any) => i.comment) : ''
  );
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [irFavoriteData, setIrFavoriteData] = useState(inventoryRequestFav);

  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);

  const [setInventoryFavorite] = useMutation(CREATE_UPDATE_INVENTORY_FAVORITE);

  const [setDeleteInventoryFavorite] = useMutation(DELETE_INVENTORY_FAVORITE);

  const { data: inventoryRequestFavorite } = useQuery(GET_INVENTORY_REQUEST_FAVORITE_ID, {
    fetchPolicy: 'no-cache',
    // eslint-disable-next-line no-underscore-dangle
    variables: { id: inventoryRequestFav._id },
  });

  const updateFavLineItems = (items: any): void => {
    const data = { ...irFavoriteData };
    data.lineItems = items;
    setIrFavoriteData(data);
  };

  useEffect(() => {
    getFormConfigurations();
    if (inventoryRequestFavorite && inventoryRequestFavorite?.getInventoryFavoriteById) {
      setIrFavoriteData(inventoryRequestFavorite?.getInventoryFavoriteById[0]);
      const favComment =
        inventoryRequestFavorite?.getInventoryFavoriteById[0]?.comments?.[0]?.comment;
      if (favComment) {
        setComment(favComment);
      }
    }
  }, [getFormConfigurations, inventoryRequestFavorite]);

  const handleInventoryFavoriteActionDropdownSelect = (option: any): void => {
    if (option.value === 'edit') {
      setIsEdit(!isEdit);
    } else if (option.value === 'save_as') {
      setIsSaveAs(!isSaveAs);
    } else if (option.value === 'delete_favorite') {
      setIsDelete(!isDelete);
      setDeleteInventoryFavorite({
        variables: {
          nameOfFavorite,
        },
      })
        .then(response => {
          if (response?.data?.deleteInventoryFavorite) {
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory favorite deleted.');
            setTimeout(() => {
              history.push('/Inventory/Requests/Favorites');
            }, 1000);
          }
        })
        .catch(error => {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory favourite delete error.');
          // eslint-disable-next-line no-console
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    }
  };

  const handleCreateNewInventoryRequest = (): void => {
    setOpenInventoryFlow(true);
  };

  const handlelSaveAsFavoriteModalConfirmPress = (): void => {
    setIsSaveAs(!isSaveAs);
    const finalSaveAsObject = irFavoriteData;
    finalSaveAsObject.nameOfFavorite = FavName;
    finalSaveAsObject.recordType = 'create';
    // eslint-disable-next-line no-underscore-dangle
    delete finalSaveAsObject._id;
    // eslint-disable-next-line no-underscore-dangle
    delete finalSaveAsObject.__typename;

    unset(finalSaveAsObject, 'account.__typename');
    unset(finalSaveAsObject, 'salesRep.__typename');
    unset(finalSaveAsObject, 'shipTo.__typename');
    unset(finalSaveAsObject, 'userDetails.__typename');

    finalSaveAsObject.lineItems.forEach(function deleteKey(v: any): void {
      // eslint-disable-next-line no-underscore-dangle
      delete v.part.__typename;
      // eslint-disable-next-line no-underscore-dangle
      delete v.__typename;
      delete v?.counterValue;
      delete v?.changed;
    });

    // eslint-disable no-param-reassign
    finalSaveAsObject.comments.forEach(function deleteKey(v: any) {
      // eslint-disable-next-line no-underscore-dangle
      delete v.__typename;
    });

    setInventoryFavorite({
      variables: finalSaveAsObject,
    })
      .then(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory favorite saved.');
      })
      .catch(error => {
        const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
        openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const handlelEditFavoriteModalConfirmPress = (): void => {
    setIsEdit(!isEdit);
    const finalSaveAsObject = irFavoriteData;
    finalSaveAsObject.nameOfFavorite = FavName;
    finalSaveAsObject.recordType = 'update';
    finalSaveAsObject.serviceLevel = selection && selection[0] && selection[0].value;
    // eslint-disable-next-line no-underscore-dangle
    delete finalSaveAsObject._id;
    // eslint-disable-next-line no-underscore-dangle
    delete finalSaveAsObject.__typename;

    unset(finalSaveAsObject, 'account.__typename');
    unset(finalSaveAsObject, 'salesRep.__typename');
    unset(finalSaveAsObject, 'shipTo.__typename');
    unset(finalSaveAsObject, 'userDetails.__typename');
    // unset(finalSaveAsObject, 'lineItems.__typename')

    // eslint-disable no-param-reassign
    finalSaveAsObject.lineItems.forEach(function deleteKey(v: any) {
      // eslint-disable-next-line no-underscore-dangle
      delete v.part.__typename;
      // eslint-disable-next-line no-underscore-dangle
      delete v.__typename;
    });
    delete finalSaveAsObject.favComments;
    delete finalSaveAsObject.comments;
    if (comment) {
      finalSaveAsObject.comments = [
        {
          comment,
          postTime: getUTCDateTime(new Date()),
          commentType: 'Internal',
        },
      ];
    }
    setInventoryFavorite({
      variables: finalSaveAsObject,
    })
      .then(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory favorite saved.');
      })
      .catch(error => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const handleSaveAsModalToggle = (): void => {
    setFavName(nameOfFavorite);
    setIsSaveAs(!isSaveAs);
  };

  const handleEditModalToggle = (): void => {
    setFavName(nameOfFavorite);
    setSelection('');
    setIsEdit(!isEdit);
  };

  const handleSaveAsName = (e: any): void => {
    setFavName(e);
  };

  const handleEditName = (e: any): void => {
    setFavName(e);
  };

  const handleDropDownSet = (event: any, data: any): void => {
    setInputValue(event);
    setSelection(data);
  };

  const toggleInventoryRequest = (): void => {
    setOpenInventoryFlow(false);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    toggleInventoryRequest();
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {false ? (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      ) : (
        <>
          <InventoryFavoriteDetailHeader
            inventoryRequestFav={irFavoriteData}
            handleInventoryFavoriteActionDropdownSelect={
              handleInventoryFavoriteActionDropdownSelect
            }
            handleCreateNewInventoryRequest={handleCreateNewInventoryRequest}
          />
          <InventoryFavoriteDetailCatalog
            inventoryRequestFav={irFavoriteData}
            updateFavLineItems={updateFavLineItems}
          />
        </>
      )}
      {isEdit && (
        <InventoryFavoriteEditModal
          handleDropDownSet={handleDropDownSet}
          selection={selection}
          comment={comment}
          setComment={setComment}
          inputValue={inputValue}
          favName={FavName}
          handleEditName={handleEditName}
          inventoryRequestFav={irFavoriteData}
          showEditConfirmationModal={isEdit}
          handleModalToggle={handleEditModalToggle}
          handlelEditFavoriteModalConfirmPress={handlelEditFavoriteModalConfirmPress}
        />
      )}
      {isSaveAs && (
        <InventoryFavoriteSaveAsModal
          favName={FavName}
          handleSaveAsName={handleSaveAsName}
          inventoryRequestFav={irFavoriteData}
          showEditConfirmationModal={isSaveAs}
          handleModalToggle={handleSaveAsModalToggle}
          handlelSaveAsFavoriteModalConfirmPress={handlelSaveAsFavoriteModalConfirmPress}
        />
      )}
      {openInventoryFlow && (
        <InventoryRequest
          isOpen={openInventoryFlow}
          toggleOpen={toggleInventoryRequest}
          formData={formdata}
          inventoryRequestFav={irFavoriteData}
          eventType="Request Inventory"
          handleCancelButton={handleCancelModal}
          isFavorite
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          isInventory
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
    </IconSettings>
  );
};

export default withRouter(InventoryFavoriteDetail);
