/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DetailsHeader from '../../InventoryRequestDetail/DetailsHeader';
// import LineItems from './LineItems';
// import Comments from './Comments';
import Comments from '../../InventoryRequestDetail/Comments';
import { GET_TNVENTORY_REQUEST_DETAILS } from '../../../../graphql/getInventoryRequestDetails';
import { GET_FORM_CONFIGURATIONS } from '../../../../graphql/getFormConfigurations';
import SUBMIT_INVENTORY_DETAILS from '../../../../graphql/mutations/submitInventoryRequestDetails';
import CREATE_UPDATE_USAGE from '../../../../graphql/mutations/createdUpdateUsage';
import { NOTIFY_TYPE, OPS_ACTION_TYPE, WORKFLOW_ACTION } from '../../../../util/constants';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import InventoryRequest from '../../InventoryRequest';
import { getUTCDateTime } from '../../../../util/utilityFunctions';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../../graphql/getDetailsViewConfigurations';
import CREATE_UPDATE_INVENTORY_FAVORITE from '../../../../graphql/mutations/createUpdateInventoryFavorites';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import InventoryFavoriteSaveModal from '../../InventoryRequestDetail/FavoriteSaveConfirmationModal';
import { getUserId } from '../../../../store/ducks/userId';
import '../index.scss';
import CancelEventModal from '../../../CreateEditSurgeryEvent/CancelEventCreationModal';
import SaveConfirmationModal from './SaveConfirmationModal';
import InventoryRequestDetailCatalog from '../../InventoryRequestDetail/InventoryRequestDetailCatalog';

interface InventoryDetails {
  salesRep: string;
  eventStatus: string;
}

interface LineItems {
  productId: string;
  quantity: string;
  productNumber: string;
  workflowAction?: string;
  usageId?: string;
  viewOnly?: boolean;
  usageExternalId?: string;
}

const InventoryDetails: FC<RouteComponentProps> = ({ location, history }) => {
  const { state } = location;
  const [loading, setLoader] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [createUpdateUsage] = useMutation(CREATE_UPDATE_USAGE);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const [getInventoryDetails, { data, refetch }] = useLazyQuery(GET_TNVENTORY_REQUEST_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [inventoryRequestDetails, setInventoryRequestDetails] = useState<any>();
  const [submitInventoryRequestDetails] = useMutation(SUBMIT_INVENTORY_DETAILS);
  const [shipMethod, setShipMethod] = useState('');
  // const [lineItems, setLineItems] = useState();
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [userComment, setUserComment] = useState<any[]>();
  const [viewOnly, setViewOnly] = useState(false);
  const userInfo = useSelector(getUserInfo);
  const userId = useSelector(getUserId);
  const [isClone, setIsClone] = useState(false);
  const [FavName, setFavName] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [isSavedModal, setSaveModal] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(true);
  const [reloadModal, setReloadModal] = useState(false);
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);
  const inventoryConfig =
    getDetailsViewConfigurations?.getDetailsViewConfigurations?.inventoryRequest;

  const [setInventoryFavorite] = useMutation(CREATE_UPDATE_INVENTORY_FAVORITE);
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);
  useEffect(() => {
    if (state) {
      const { caseExternalId, opsAction } = state;
      if (caseExternalId) {
        if ((opsAction && opsAction !== OPS_ACTION_TYPE.REVIEW) || opsAction === 'Assign') {
          setViewOnly(true);
        }
        getInventoryDetails({
          variables: {
            externalId: caseExternalId,
          },
        });
      } else {
        // No external id -- navigate to list
        history.push('./Inventory/Review');
      }
    }
  }, [getInventoryDetails, history, state]);
  useEffect(() => {
    if (data && data.getInventoryRequestDetails) {
      setInventoryRequestDetails(data.getInventoryRequestDetails);
      setShipMethod(data.getInventoryRequestDetails.shipMethod || shipMethod);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (!isDataSaved) {
        // Display your custom pop-up here
        setReloadModal(true);
        setSaveModal(true);
        const confirmationMessage = 'Changes that you made may not be saved.';

        // eslint-disable-next-line no-alert
        if (!window.confirm(confirmationMessage)) {
          // Prevent the page from unloading if the user cancels
          e.preventDefault();
          e.returnValue = '';
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDataSaved]);

  // const [finishstatus, setfinishstatus] = useState(false);

  // useEffect(() => {
  //   const onbackbuttonevent = (e: any) => {
  //     if (!isDataSaved) {
  //       e.preventDefault();
  //       if (!finishstatus) {
  //         setReloadModal(true);
  //         setSaveModal(true);
  //         // eslint-disable-next-line no-alert
  //         if (window.confirm('do you want to go back ?')) {
  //           setfinishstatus(true);
  //           history.goBack();
  //         } else {
  //           window.history.pushState(null, window.location.pathname);
  //           setfinishstatus(false);
  //         }
  //       }
  //     } else {
  //       history.goBack();
  //     }
  //   };
  //   if (!isDataSaved) {
  //     window.history.pushState(null, window.location.pathname);
  //   }
  //   window.addEventListener('popstate', onbackbuttonevent);
  //   return () => {
  //     window.removeEventListener('popstate', onbackbuttonevent);
  //   };
  // }, [isDataSaved]);

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const saveLineData = (items: any): void => {
    const { getInventoryRequestDetails: details } = data;
    const parts = items || details.lineItems;
    const lineItemsPayload = parts.map((item: any) => {
      const {
        productId,
        quantity,
        productNumber,
        workflowAction,
        userworkflowAction,
        usageId,
        usageExternalId,
        actualQuantity,
      } = item;
      let action =
        Array.isArray(userworkflowAction) && userworkflowAction.length > 0
          ? userworkflowAction[0].value
          : WORKFLOW_ACTION.NO_ACTION;

      if (userworkflowAction === null || userworkflowAction === undefined) {
        action = workflowAction;
      }
      return {
        productId,
        actualQuantity,
        quantity,
        productNumber,
        usageId,
        usageExternalId,
        workflowAction: action === WORKFLOW_ACTION.APPROVE ? WORKFLOW_ACTION.ACCEPT : action,
      };
    });
    const payload = {
      caseId: details.caseId,
      caseExternalId: details.caseExternalId,
      accountId: details.accountId,
      branchId: details.branchId,
      salesRepId: details.salesRepId,
      shipToId: details.shipToId,
      serviceLevel: details.serviceLevel,
      needByDate: details.needByDate,
      shipMethod: shipMethod?.split('-')?.[0] || details?.shipMethod?.split('-')?.[0] || '',
      usageParts: lineItemsPayload,
      deliveryContractEmail: details.deliveryContractEmail,
      deliveryContractInstructions: details.deliveryContractInstructions,
      deliveryContractName: details.deliveryContractName,
      deliveryContractPhone: details.deliveryContractPhone,
      dockAvailable: details.dockAvailable,
      isShippingDetails: details.isShippingDetails,
      liftGateNeeded: details.liftGateNeeded,
      shortTruckOnly: details.shortTruckOnly,
      requestedFrom: 'Inventory',
    };
    setLoader(true);
    createUpdateUsage({
      variables: {
        ...payload,
      },
    })
      .then((res: any) => {
        setLoader(false);
        if (res && res.data && res.data.createUpdateUsage.message === 'success') {
          setTimeout(() => {
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Line Items submitted successfully');
          }, 1000);
          refetch();
        }
      })
      .catch(() => {
        setLoader(false);
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
      });
  };

  const setLineItemsPayload = (items: any): void => {
    // setLineItems(items);
    saveLineData(items);
  };

  const setShipMethodValue = (item: string): void => {
    setIsDataSaved(false);
    setShipMethod(item);
    const { getInventoryRequestDetails: details } = data;

    if (details) {
      // const payload = {
      //   caseId: details.caseId,
      //   caseExternalId: details.caseExternalId,
      //   accountId: details.accountId,
      //   branchId: details.branchId,
      //   salesRepId: details.salesRepId,
      //   shipToId: details.shipToId,
      //   serviceLevel: details.serviceLevel,
      //   // needByDate: details.needByDate,
      //   shipMethod: item || details.shipMethod || '',
      //   deliveryContractEmail: details.deliveryContractEmail,
      //   deliveryContractInstructions: details.deliveryContractInstructions,
      //   deliveryContractName: details.deliveryContractName,
      //   deliveryContractPhone: details.deliveryContractPhone,
      //   dockAvailable: details.dockAvailable,
      //   isShippingDetails: details.isShippingDetails,
      //   liftGateNeeded: details.liftGateNeeded,
      //   requestedFrom: 'Inventory',
      // };
      // setLoader(true);
      // createUpdateUsage({
      //   variables: {
      //     ...payload,
      //   },
      // })
      //   .then((res: any) => {
      //     setLoader(false);
      //     if (res && res.data && res.data.createUpdateUsage.message === 'success') {
      //       setTimeout(() => {
      //         openSnackBar(NOTIFY_TYPE.SUCCESS, 'Shipping method updated successfully');
      //       }, 1000);
      //       refetch();
      //     }
      //   })
      //   .catch(() => {
      //     setLoader(false);
      //     openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
      //   });
    }
  };

  const handleBackClick = () => {
    if (!isDataSaved) {
      setSaveModal(true);
    } else {
      history.goBack();
    }
  };

  const setUserEnteredComment = (comment: any): void => {
    setUserComment(comment);
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleSavedModal = (): void => {
    setSaveModal(!isSavedModal);
    if (!reloadModal) {
      history.goBack();
    }
  };

  const handleCrossButtonModal = (): void => {
    setSaveModal(!isSavedModal);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setIsEdit(!isEdit);
  };

  const handleSavedModalConfirmPress = (): void => {
    const { getInventoryRequestDetails: details } = data;
    let commentsPayload;
    if (userComment) {
      const commentArray = userComment.map(v => ({
        comment: v.value,
        postTime: getUTCDateTime(new Date()),
        commentType: 'Internal',
        externalId: v.externalId || null,
      }));
      commentsPayload = commentArray;
    }
    if (details) {
      const payload = {
        caseId: details.caseId,
        caseExternalId: details.caseExternalId,
        accountId: details.accountId,
        branchId: details.branchId,
        salesRepId: details.salesRepId,
        shipToId: details.shipToId,
        serviceLevel: details.serviceLevel,
        // needByDate: details.needByDate,
        shipMethod: shipMethod?.split('-')?.[0] || details?.shipMethod?.split('-')?.[0] || '',
        deliveryContractEmail: details.deliveryContractEmail,
        deliveryContractInstructions: details.deliveryContractInstructions,
        deliveryContractName: details.deliveryContractName,
        deliveryContractPhone: details.deliveryContractPhone,
        dockAvailable: details.dockAvailable,
        isShippingDetails: details.isShippingDetails,
        liftGateNeeded: details.liftGateNeeded,
        shortTruckOnly: details.shortTruckOnly,
        comments: commentsPayload,
        requestedFrom: 'Inventory',
      };
      setLoader(true);
      createUpdateUsage({
        variables: {
          ...payload,
        },
      })
        .then((res: any) => {
          setLoader(false);
          if (res && res.data && res.data.createUpdateUsage.message === 'success') {
            setSaveModal(!isSavedModal);
            setTimeout(() => {
              openSnackBar(NOTIFY_TYPE.SUCCESS, 'Shipping method updated successfully');
            }, 1000);
            setIsDataSaved(true);
            if (!reloadModal) {
              history.goBack();
            }
          }
        })
        .catch(() => {
          setLoader(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        });
    }
  };

  const handleModalToggle = useCallback((): void => {
    setIsEdit(!isEdit);
    setLoader(true);
  }, [isEdit]);

  const submitIRDetails = (): void => {
    const { getInventoryRequestDetails: details } = data;
    const parts = details.lineItems;
    const actionableItems = parts.filter(
      (item: any) =>
        item.workflowAction === WORKFLOW_ACTION.NO_ACTION || item.workflowAction === 'NoAction'
    );
    if (actionableItems && actionableItems.length > 0) {
      const notAttempted = actionableItems.find((i: any) => !i.userworkflowAction);
      if (notAttempted) {
        openSnackBar(NOTIFY_TYPE.INFO, 'Please select an action for all line items');
        return;
      }
    }

    const allRejectedFlag = parts.find(
      (i: any) =>
        i.workflowAction &&
        (i.workflowAction === WORKFLOW_ACTION.ACCEPT ||
          i.workflowAction === WORKFLOW_ACTION.NO_ACTION)
    );
    const rejectedItemFound = parts.find(
      (i: any) => i.workflowAction && i.workflowAction === WORKFLOW_ACTION.REJECT
    );
    if (rejectedItemFound && !userComment) {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please provide comment for rejection');
      return;
    }
    // Validations
    if (!shipMethod && allRejectedFlag) {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please Select Ship Method');
      return;
    }
    // Proceed to Submit
    const lineItemsPayload = parts.map((item: any) => {
      const {
        productId,
        quantity,
        productNumber,
        workflowAction,
        userworkflowAction,
        usageId,
        usageExternalId,
      } = item;
      const action =
        Array.isArray(userworkflowAction) && userworkflowAction.length > 0
          ? userworkflowAction[0].value
          : workflowAction || WORKFLOW_ACTION.NO_ACTION;
      return {
        productId,
        quantity,
        productNumber,
        usageId,
        usageExternalId,
        workflowAction: action === WORKFLOW_ACTION.APPROVE ? WORKFLOW_ACTION.ACCEPT : action,
      };
    });
    // let commentsPayload;
    // if (userComment?.length) {
    //   const commentArray = userComment.map(v => ({
    //     comment: v.value,
    //     postTime: getUTCDateTime(new Date()),
    //     commentType: 'Customer',
    //     externalId: v.externalId || null,
    //   }));
    //   commentsPayload = commentArray;
    // }
    const payload = {
      caseId: details.caseId,
      caseExternalId: details.caseExternalId,
      parentEventType: details.parentEventType,
      parentEventSubType: details.parentEventSubType,
      accountId: details.accountId,
      branchId: details.branchId,
      salesRepId: details.salesRepId,
      shipToId: details.shipToId,
      serviceLevel: details.serviceLevel,
      needByDate: details.needByDate,
      shipMethod: shipMethod?.split('-')?.[0] || details?.shipMethod?.split('-')?.[0] || '',
      lineItems: lineItemsPayload,
      // comments: commentsPayload,
    };
    setLoader(true);
    submitInventoryRequestDetails({
      variables: {
        ...payload,
      },
    })
      .then((res: any) => {
        setLoader(false);
        if (res && res.data && res.data.reviewInventoryRequest.message === 'success') {
          setTimeout(() => {
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory details submitted successfully');
          }, 4000);
          setTimeout(() => {
            history.push('/Inventory/Review');
          }, 1000);
        }
      })
      .catch(error => {
        if (error?.message?.toLowerCase().includes('permission denied')) {
          setLoader(false);
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Submit is not allowed while user is on hold. Please contact Ops.'
          );
        } else {
          setLoader(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      });
  };

  const handleSaveName = (e: any): void => {
    setFavName(e);
  };

  const handleSaveModalToggle = (): void => {
    setFavName('');
    setIsSave(!isSave);
  };

  const handleCloneModalToggle = useCallback((): void => {
    setIsClone(!isClone);
  }, [isClone]);

  const handlelSaveAsFavoriteModalConfirmPress = (): void => {
    setIsSave(!isSave);
    const items: any = [];
    if (
      inventoryRequestDetails &&
      inventoryRequestDetails.lineItems &&
      inventoryRequestDetails.lineItems.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      inventoryRequestDetails.lineItems.map((el: any) => {
        const obj = {
          part: {
            id: el.productId,
            name: `${el.productNo} ${el.productdescription}`,
          },
          lotNumber: '',
          quantity: el.quantity,
        };
        items.push(obj);
      });
    }
    const commentsArray: any = [];
    if (
      inventoryRequestDetails &&
      inventoryRequestDetails.comments &&
      inventoryRequestDetails.comments.length > 0
    ) {
      inventoryRequestDetails.comments.map((el: any) => {
        const obj = {
          comment: el.value,
          postTime: el.postTime,
          commentType: el.type,
          externalId: el.externalId,
        };
        commentsArray.push(obj);
      });
    }
    const finalObject = {
      nameOfFavorite: FavName,
      recordType: 'create',
      userId,
      serviceLevel: inventoryRequestDetails.serviceLevel,
      salesRep: {
        name: inventoryRequestDetails.salesRep,
        id: inventoryRequestDetails.salesRepId,
      },
      comments: commentsArray,
      account: {
        id: inventoryRequestDetails.accountId,
        name: `${inventoryRequestDetails.accountName}-${inventoryRequestDetails.accountNumber}`,
      },
      shipTo: {
        id: inventoryRequestDetails.shippingId,
        name: `${inventoryRequestDetails.shippingAddessLine1},${
          inventoryRequestDetails.shippingAddessLine2
            ? inventoryRequestDetails.shippingAddessLine2
            : ''
        },${inventoryRequestDetails.shippingCity},${inventoryRequestDetails.shippingState},${
          inventoryRequestDetails.shippingPostalcode
        }`,
      },
      lineItems: items,
    };

    setInventoryFavorite({
      variables: finalObject,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
      // eslint-disable-next-line no-console
      console.log(error); // TODO change when some operation needs to be run on error.
    });
  };

  const handleInventoryActionDropdownSelect = (option: any): void => {
    if (option && option.value) {
      if (option.value.toLowerCase() === 'submit' && !option.disabled) {
        if (option.disabledReason === 'missing_line_items') {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Please add the parts for the Inventory and submit');
        } else {
          submitIRDetails();
        }
      } else if (option.value.toLowerCase() === 'edit' && !option.disabled) {
        setIsEdit(true);
      } else if (option.value.toLowerCase() === 'clone' && !option.disabled) {
        setIsClone(true);
      } else if (option.value.toLowerCase() === 'make_favorite' && !option.disabled) {
        setIsSave(!isSave);
      } else if (option.value.toLowerCase() === 'discrepancy') {
        // eslint-disable-next-line no-unused-expressions
        userInfo?.businessRules.forEach((br: any) => {
          if (br.rules === 'Inventory Discrepancy Email Recipient') {
            window.open(
              `mailto: ${br.values}?subject=Inventory Request: ${inventoryRequestDetails.eventId}&body=`
            );
          }
        });
      }
    }
  };

  const handleRequestClick = (
    parentEventType: any,
    parentCaseExternalId: any,
    parentEventId: any
  ): void => {
    if (window.location.pathname !== '/surgicalDetails') {
      history.push('/surgicalDetails', {
        eventType: parentEventType,
        externalId: parentCaseExternalId,
        id: parentEventId,
      });
    }
  };

  const { caseExternalId, opsAction } = state;
  const visualIndicator = inventoryRequestDetails ? inventoryRequestDetails.visualIndicator : '';
  const eventStatus = inventoryRequestDetails?.parentEventStatus || '';

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {loading && <Spinner />}
      {inventoryRequestDetails && (
        <>
          {/* <DetailsHeader
            inventoryDetails={inventoryRequestDetails}
            setShipMethodValue={setShipMethodValue}
            viewOnly={viewOnly}
            submitIRDetails={submitIRDetails}
            handleEditClick={handleEditClick}
          /> */}
          <DetailsHeader
            inventoryReturnDetails={inventoryRequestDetails}
            setShipMethodValue={setShipMethodValue}
            handleEditClick={handleEditClick}
            handleRequestClick={handleRequestClick}
            viewOnly={viewOnly}
            userInfo={userInfo}
            type={state?.opsAction}
            handleInventoryActionDropdownSelect={handleInventoryActionDropdownSelect}
            irHeaderCofig={inventoryConfig?.headers}
            handleBackClick={handleBackClick}
          />
          {/* <LineItems
            inventoryDetails={inventoryRequestDetails}
            handleLineItemActions={setLineItemsPayload}
            viewOnly={viewOnly}
            setShowSearchCatalogModal={setShowSearchCatalogModal}
          /> */}

          <InventoryRequestDetailCatalog
            refetch={refetch}
            inventoryDetail={inventoryRequestDetails}
            isEventInventoryFlow={false}
            visualIndicator={visualIndicator}
            parentEventStatus={eventStatus}
            type={opsAction}
            hasEventInventory={false}
            eventInventoryExternalId={inventoryRequestDetails?.parentCaseExternalId || ''}
            externalId={caseExternalId}
            changeSelectedTab={() => {}}
            tabConfig={inventoryConfig}
            viewOnly={!!(viewOnly || opsAction === 'Assign')}
            handleLineItemActions={setLineItemsPayload}
          />
          <Comments
            inventoryReturnDetails={inventoryRequestDetails}
            setUserEnteredComment={setUserEnteredComment}
            viewOnly={false}
            type={opsAction}
          />
        </>
      )}
      {isEdit && (
        <InventoryRequest
          isOpen={isEdit}
          inventoryDetail={inventoryRequestDetails}
          toggleOpen={handleModalToggle}
          formData={formdata}
          type="Open"
          refetch={refetch}
          eventType="Request Inventory"
          handleCancelButton={handleCancelButton}
        />
      )}
      {/* {canceModalVisible && (
        <CancelEventModal
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          edit={isEdit}
        />
      )} */}
      {isClone && (
        <InventoryRequest
          isClone={isClone}
          isOpen={isClone}
          inventoryDetail={inventoryRequestDetails}
          toggleOpen={handleCloneModalToggle}
          formData={formdata}
          refetch={refetch}
          eventType="Request Inventory"
        />
      )}
      {isSave && (
        <InventoryFavoriteSaveModal
          favName={FavName}
          handleSaveName={handleSaveName}
          showEditConfirmationModal={isSave}
          handleModalToggle={handleSaveModalToggle}
          handlelSaveFavoriteModalConfirmPress={handlelSaveAsFavoriteModalConfirmPress}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          edit={isEdit}
        />
      )}
      {isSavedModal && (
        <SaveConfirmationModal
          reloadModal={reloadModal}
          canceModalVisible={isSavedModal}
          handleCancelModal={handleSavedModal}
          handleCrossButtonModal={handleCrossButtonModal}
          handleCancelModalConfirmPress={handleSavedModalConfirmPress}
          edit={isEdit}
        />
      )}
    </IconSettings>
  );
};

export default withRouter(InventoryDetails);
