/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useEffect, useState } from 'react';
import Tabs from '@salesforce/design-system-react/components/tabs';
import Card from '@salesforce/design-system-react/components/card';
import Button from '@salesforce/design-system-react/components/button';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import DropdownTrigger from '@salesforce/design-system-react/components/menu-dropdown/button-trigger';
//  import { getUserInfo } from '../../../../../../store/ducks/userInfo';
import './index.scss';
import UsageComments from './UsageComments';
import AddCommentsModal from './AddCommentsModal';
import { getUTCDateTime } from '../../../../../../util/utilityFunctions';
import CREATE_COMMENT from '../../../../../../graphql/mutations/createComment';
import { GET_COMMENT_HISTORY } from '../../../../../../graphql/getCommentHistory';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../../../../graphql/getDetailsViewConfigurations';

interface DropdownButton {
  label: string;
  value: string;
}

interface Comment {
  id: string;
  comment: string;
  createdDate: string;
  commentType: string;
  commentExternalId: string;
  postTime: string;
  userId: string;
  sfid: string;
  userName: string;
}
interface Props {
  usageDetails?: any;
  viewOnly: boolean;
  getComments: (comm: any) => void;
}
interface Comments {
  id?: string;
  value: string;
  type?: string;
  postTime?: string;
  user?: string;
}

const Comments: FC<Props> = ({ usageDetails, viewOnly, getComments }) => {
  const [comments, setComments] = useState<Comments[]>([]);
  const [addCommentType, setAddCommentType] = useState('');
  // const [orderConfig, setOrderConfig] = useState({Internal:false,Customer:false});
  const [isInternal, setIsInternal] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [commentTypeOptions, setCommentTypeOptions] = useState([{ label: '', value: '' }]);
  // const userInfo = useSelector(getUserInfo);
  const [createComment, { loading: createCommentLoading }] = useMutation(CREATE_COMMENT);
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);
  const billonlyOrderConfig = getDetailsViewConfigurations?.getDetailsViewConfigurations;
  const [
    getCommentHistory,
    { data: commentHistory, refetch: refetchCommentHistory },
  ] = useLazyQuery(GET_COMMENT_HISTORY, { fetchPolicy: 'no-cache' });

  const checkConfigVal = (billType: string) => {
    const usageTab = billonlyOrderConfig?.[billType]?.usageSheet_tab;
    const optionsValues: any = [];
    const usageSheetTab = usageTab?.map((item: any) => {
      if (item.label === 'Customer Comments' && item.isShow) {
        setIsCustomer(item.isShow);
        optionsValues.push({ label: 'Customer Comment', value: 'customerComment' });
      } else if (item.label === 'Internal Comments' && item.isShow) {
        setIsInternal(item.isShow);
        optionsValues.push({ label: 'Internal Comment', value: 'internalComment' });
      }
    });
    setCommentTypeOptions(optionsValues);
    return usageSheetTab;
  };

  useEffect(() => {
    if (usageDetails?.orderType === 'Bill') {
      checkConfigVal('billOnly');
    } else {
      checkConfigVal('shipAndBill');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetailsViewConfigurations]);

  useEffect(() => {
    getCommentHistory({
      variables: {
        sfid: usageDetails.caseId,
        caseExternalId: usageDetails.caseExternalId,
        commentType: '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageDetails, getCommentHistory]);
  const handleDropdownButtonPress = (selectedItem: DropdownButton): void => {
    const { value } = selectedItem;
    setAddCommentType(value);
  };
  const closeModal = (): void => {
    setAddCommentType('');
  };
  const handleAdd = (input: string): void => {
    const type = addCommentType === 'internalComment' ? 'Internal' : 'Customer';
    // Create
    createComment({
      variables: {
        comment: input,
        caseExternalId: usageDetails.caseExternalId,
        postTime: getUTCDateTime(moment().toString()),
        commentType: type,
      },
    }).then(() => {
      getCommentHistory({
        variables: {
          sfid: usageDetails.caseId,
          caseExternalId: usageDetails.caseExternalId,
          commentType: '',
        },
      });
      closeModal();
    });
  };

  useEffect(() => {
    setComments(commentHistory?.getCommentHistory);
    getComments(commentHistory?.getCommentHistory);
  }, [commentHistory, getCommentHistory, getComments]);
  return (
    <>
      {(isInternal || isCustomer) && (
        <Card
          id="Comments"
          headerActions={
            <Dropdown
              disabled={viewOnly}
              assistiveText={{ icon: 'More Options' }}
              align="right"
              tabIndex="-1"
              width="x-small"
              onSelect={(value: DropdownButton): void => {
                handleDropdownButtonPress(value);
              }}
              options={commentTypeOptions?.filter(item => item?.value)}
            >
              <DropdownTrigger>
                <Button
                  iconCategory="utility"
                  iconName="down"
                  iconPosition="right"
                  className="color-black_button"
                  label="New"
                />
              </DropdownTrigger>
            </Dropdown>
          }
          heading="Comments"
        >
          <hr className="card-divider" />
          <Tabs id="tabs-example-default" className="comment-user_tabs">
            {isInternal && (
              <TabsPanel label="Internal" className="slds-p-horizontal_small">
                <UsageComments
                  refetchCommentHistory={refetchCommentHistory}
                  comments={comments}
                  type="Internal"
                  viewOnly={viewOnly || usageDetails?.eventStatus === 'Submitted'}
                />
              </TabsPanel>
            )}
            {isCustomer && (
              <TabsPanel label="Customer">
                <UsageComments
                  refetchCommentHistory={refetchCommentHistory}
                  comments={comments}
                  type="Customer"
                  viewOnly={viewOnly || usageDetails?.eventStatus === 'Submitted'}
                />
              </TabsPanel>
            )}
          </Tabs>
        </Card>
      )}
      {addCommentType && (
        <AddCommentsModal
          commentModalVisible={addCommentType !== ''}
          closeModal={closeModal}
          handleAdd={handleAdd}
          createCommentLoading={createCommentLoading}
        />
      )}
    </>
  );
};

export default Comments;
