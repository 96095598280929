/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import './index.scss';
import { useSelector } from 'react-redux';
import Button from '@salesforce/design-system-react/components/button';
import { useMutation } from '@apollo/react-hooks';
import Modal from '@salesforce/design-system-react/components/modal';
import EDIT_COMMENT from '../../../../../../graphql/mutations/editComment';
import DELETE_COMMENT from '../../../../../../graphql/mutations/deleteComment';
import TextArea from '../../../../../Shared/TextArea';
import { NOTIFY_TYPE } from '../../../../../../util/constants';
import SnackBar from '../../../../../Shared/SnackBar';
import useSnackBar from '../../../../../../util/customHooks/useSnackBar';
import { getUTCDateTime } from '../../../../../../util/utilityFunctions';
import { getUserInfo } from '../../../../../../store/ducks/userInfo';

interface Props {
  refetchCommentHistory: any;
  comments?: Comments[];
  type: string;
  viewOnly: any;
}

interface Comments {
  userId?: any;
  comment?: any;
  commentType?: string;
  postTime?: any;
  userName?: string;
  updatedBy?: string;
  commentExternalId?: any;
}

const UsageComments: FC<Props> = ({ refetchCommentHistory, comments, type, viewOnly }) => {
  const [isEdit, setIsEdit] = useState('');
  const userInfo = useSelector(getUserInfo);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [editFieldText, setEditFieldText] = useState('');
  const [deleteExternalId, setDeleteExternalId] = useState('');
  const [editComment] = useMutation(EDIT_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);
  const { open, notification, openSnackBar } = useSnackBar();

  const [commentsList, setCommentList] = useState<Comments[]>();
  useEffect(() => {
    if (comments) {
      const cmnts = comments
        .filter((item: Comments) => item.commentType === type)
        .sort((a, b) => {
          return b.postTime - a.postTime;
        });
      setCommentList(cmnts || []);
    }
  }, [comments, type]);

  const handleEdit = (commentData: any) => {
    if (editFieldText) {
      const obj = {
        externalId: isEdit,
        comment: editFieldText,
        postTime: getUTCDateTime(new Date()),
        // user: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
      editComment({
        variables: obj,
      })
        .then(() => {
          refetchCommentHistory();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please add your comment first');
    }
    setIsEdit('');
  };
  const handleDelete = (externalId: any): void => {
    if (externalId) {
      const obj = {
        externalId: deleteExternalId,
      };
      deleteComment({
        variables: obj,
      })
        .then(() => {
          refetchCommentHistory();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Seems like it is invalid comment.');
    }
    setDeleteConfirm(false);
  };

  const handleCancelModal = () => {
    setDeleteConfirm(false);
  };

  return (
    <div className="slds-m-around_small">
      <SnackBar open={open} notification={notification} />
      <div className="comments-container">
        {commentsList &&
          commentsList.map((comment: Comments) => (
            <div className="comment-body">
              <p className="comment-user-name">
                {comment.userName || ''}
                {(userInfo?.id === comment.userId || type === 'Customer') && (
                  <>
                    <Button
                      assistiveText={{ icon: 'Edit Comment' }}
                      iconCategory="utility"
                      iconName="edit"
                      iconSize="small"
                      iconVariant="bare"
                      onClick={() => {
                        setIsEdit(comment?.commentExternalId);
                        setEditFieldText(comment?.comment);
                      }}
                      variant="icon"
                      disabled={viewOnly}
                    />
                    <Button
                      assistiveText={{ icon: 'Delete Comment' }}
                      iconCategory="utility"
                      iconName="delete"
                      iconSize="small"
                      iconVariant="bare"
                      onClick={() => {
                        setDeleteConfirm(true);
                        setDeleteExternalId(comment.commentExternalId);
                      }}
                      variant="icon"
                      disabled={viewOnly}
                    />
                  </>
                )}
              </p>
              <h4 className="comment-timestamp">
                {`${moment(comment.postTime).format('L')} at ${moment(comment.postTime).format(
                  'LT'
                )}`}
                {comment.updatedBy &&
                  (type === 'Customer' ? ` - Edited by  ${comment.updatedBy}` : ' - Edited ')}
              </h4>
              {isEdit === comment?.commentExternalId ? (
                <>
                  <TextArea
                    label=""
                    tooltipText="Edit Comment"
                    showTooltip={false}
                    disabled={false}
                    handleChangeText={(text: string): void => setEditFieldText(text)}
                    value={editFieldText}
                    maxLength="200"
                  />
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button align="right" label="Cancel" onClick={() => setIsEdit('')} />
                      <Button
                        align="right"
                        label="Save"
                        className="yellow-btn"
                        onClick={() => handleEdit(comment)}
                        // disabled={
                        //   type === 'Processed' ||
                        //   (type === 'Failed' &&
                        //     !(
                        //       userInfo &&
                        //       PERSONA_CONDITION.some(el =>
                        //         userInfo?.personas?.toLowerCase()?.includes(el)
                        //       )
                        //     ))
                        // }
                        disabled={viewOnly || editFieldText === comment.comment}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <p className="comments_text">{comment.comment}</p>
              )}
            </div>
          ))}
        {deleteConfirm && (
          <Modal
            className="cancel-creation-modal default-modal_header default-modal_footer"
            isOpen={deleteConfirm}
            onRequestClose={handleCancelModal}
            ariaHideApp={false}
            footer={[
              <Button label="Yes" onClick={handleDelete} key="yes" />,
              <Button label="No" variant="brand" onClick={handleCancelModal} key="no" />,
            ]}
            heading="Delete Confirmation"
          >
            <div className="slds-text-align--center">
              <div>
                <h1 className="slds-m-around--large ">Are you sure to delete this comment?</h1>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default UsageComments;
