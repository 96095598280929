/*
 * Get Ship To Addresses GraphQL specification
 * @module src/graphql/getShipToAddresses
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SHIP_TO_ADDRESSES = gql`
  query getShipToAddresses(
    $accountId: String!
    $accountIds: [String]
    $type: String
    $filters: ShipToFilters
  ) {
    getShipToAddresses(
      accountId: $accountId
      accountIds: $accountIds
      type: $type
      filters: $filters
    ) {
      id
      name
      address1
      address2
      postalcode
      city
      state
      isPrimaryAddress
      isPrimaryAccount
    }
  }
`;
