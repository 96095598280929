/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
/* eslint-disable no-console */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Spinner from '@salesforce/design-system-react/components/spinner';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import TextArea from '@salesforce/design-system-react/components/textarea';
import Input from '@salesforce/design-system-react/components/input';
import Dropdown from '@salesforce/design-system-react/components/global-navigation-bar/dropdown';
import { Tab, Tabs, Paper } from '@material-ui/core';
import InputField from '../../Shared/Input';
import { NOTIFY_TYPE } from '../../../util/constants';
import { GET_ADMIN_FROM_CONFIG } from '../../../graphql/getAdminFormConfigurations';
import { GET_SCREENS } from '../../../graphql/getScreens';
import { GET_STATES } from '../../../graphql/getStates';
import CREATE_UPDATE_ADMIN_FORM_CONFIG from '../../../graphql/mutations/updateAdminFormConfigurations';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import '../index.scss';
import { GET_SERVICE_LEVELS } from '../../../graphql/getServiceLevels';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';

export interface Props extends RouteComponentProps {
  selectedTab: string;
}
interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
  moduleCode: string;
  moduleName: string;
  serialNumber: string;
  screenType: string;
  description: string;
}

// eslint-disable-next-line no-empty-pattern
const EventConfiguration: FC<Props> = ({ selectedTab, location }) => {
  const userInfo = useSelector(getUserInfo);
  const [inputValue, setInputValue] = useState('');
  const [inputFiledValue, setinputFiledValue] = useState('');
  const [selection, setSelection] = useState<any>([]);
  const { open, notification, openSnackBar } = useSnackBar();
  const [showInfo, setDesc] = useState(false);
  const [showPage, setShowPage] = useState<any>();
  const [showFields, setShowFields] = useState(true);
  const [
    getScreens,
    {
      data: getScreenConfigurationData,
      refetch: refetchScreenConfigurations,
      loading: getScreensLoader,
    },
  ] = useLazyQuery(GET_SCREENS, {
    fetchPolicy: 'no-cache',
    variables: { module: showPage },
  });
  const [
    getNotificationConfigurations,
    {
      data: getNotificationConfigurationData,
      refetch: refetchNotificationConfigurations,
      loading: getNotificationLoader,
    },
  ] = useLazyQuery(GET_ADMIN_FROM_CONFIG, {
    fetchPolicy: 'no-cache',
    variables: {
      businessunit: userInfo?.businessunit || 'Sports Medicine',
      screen: selection ? selection[0]?.value : 'Surgery Create',
    },
  });
  const [getServiceLevels, { data: serviceLevels }] = useLazyQuery(GET_SERVICE_LEVELS, {
    fetchPolicy: 'no-cache',
  });
  const [disableIds, setDisableIds] = useState<any>([]);
  const [businessRules, setbusinessRules] = useState<any>();
  const [notificationConfigurations, setNotificationconfigurations] = useState<any>();
  const [partsConfig, setPartsConfig] = useState<any>([]);
  const [options, setoptions] = useState<OpsDropdownSelect>();
  const [input, setInput] = useState<any>({});
  const [timeStamp, setTimeStamp] = useState<any>('');
  const [createUpdateConfig, { data: notificationConfigurationData, loading }] = useMutation(
    CREATE_UPDATE_ADMIN_FORM_CONFIG
  );
  const [getStatesData, { data: statesData }] = useLazyQuery(GET_STATES);
  const [selectState, setSelectState] = useState<any>();
  useEffect(() => {
    getScreens({
      variables: { module: showPage },
    });
    getStatesData();
  }, [showPage]);

  const [serviceLevelConfigs, setServiceLevelConfigs] = useState<any>();

  useEffect(() => {
    setServiceLevelConfigs(serviceLevels?.getServiceLevels);
  }, [serviceLevels]);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('/configurations/event')) {
      setShowPage('Events');
    } else if (pathname.includes('/configurations/order')) {
      setShowPage('Orders');
    } else if (pathname.includes('/configurations/inventories')) {
      setShowPage('Inventory');
    } else if (pathname.includes('/configurations/global')) {
      setShowPage('Global');
    } else if (pathname.includes('/configurations/cyclecount')) {
      setShowPage('CycleCount');
    } else if (pathname.includes('/configurations/notifications')) {
      setShowPage('Notifications');
    } else {
      setShowPage('Events');
    }
  }, [location]);

  useEffect(() => {
    if (
      notificationConfigurationData &&
      notificationConfigurationData.updateAdminFormConfigurations
    ) {
      if (notificationConfigurationData.updateAdminFormConfigurations.message === 'success') {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Configuration Settings Saved Successfully');
        refetchNotificationConfigurations();
      }
    }
  }, [notificationConfigurationData]);

  useEffect(() => {
    if (
      getNotificationConfigurationData &&
      getNotificationConfigurationData?.getAdminFormConfigurations[0]?.configurations
    ) {
      const data = getNotificationConfigurationData?.getAdminFormConfigurations[0]?.configurations;
      const businessRulesdata =
        getNotificationConfigurationData?.getAdminFormConfigurations[0]?.businessRules;
      // eslint-disable-next-line no-unused-expressions
      data &&
        data.forEach((item: any) => {
          if (item.fieldName === 'Replenishment Tab') {
            setShowFields(item.isShow);
          }
        });
      setNotificationconfigurations(data);
      setbusinessRules(businessRulesdata);
      businessRulesdata.forEach((br: any) => {
        if (br.isheader === false) input[br.rules] = br.values;
        if (br.isheader === true && br.rules === 'Email') input[br.rules] = br.values;
      });
      setDesc(getNotificationConfigurationData?.getAdminFormConfigurations[0]?.isBUFormRequired);
    } else {
      setNotificationconfigurations([]);
    }
    getServiceLevels({
      variables: { businessUnit: userInfo?.businessunit },
    });
  }, [getNotificationConfigurationData, refetchNotificationConfigurations]);

  useEffect(() => {
    let record = {};
    const updateDateTime = notificationConfigurations?.sort(
      (a: any, b: any) => new Date(a.updatedDate - b.updatedDate)
    );
    const updateDateTimeBR = businessRules?.sort(
      (a: any, b: any) => new Date(a.updatedDate - b.updatedDate)
    );
    const recordConfig = {
      updatedBy: updateDateTime?.length > 0 && updateDateTime[0]?.updatedBy,
      updatedDate: updateDateTime?.length > 0 && updateDateTime[0]?.updatedDate,
    };

    const recordBusinessRules = {
      updatedBy: updateDateTimeBR?.length > 0 && updateDateTimeBR[0]?.updatedBy,
      updatedDate: updateDateTimeBR?.length > 0 && updateDateTimeBR[0]?.updatedDate,
    };
    if (new Date(recordBusinessRules?.updatedDate) > new Date(recordConfig?.updatedDate)) {
      record = {
        updatedBy: recordBusinessRules?.updatedBy,
        updatedDate: recordBusinessRules?.updatedDate,
      };
    } else {
      record = {
        updatedBy: recordConfig?.updatedBy,
        updatedDate: recordConfig?.updatedDate,
      };
    }
    setTimeStamp(record);
  }, [notificationConfigurations]);

  useEffect(() => {
    if (
      getNotificationConfigurationData &&
      getNotificationConfigurationData?.getAdminFormConfigurations[0]?.parts
    ) {
      const partsConfigData =
        getNotificationConfigurationData?.getAdminFormConfigurations[0]?.parts;
      setPartsConfig(partsConfigData);
      setDesc(getNotificationConfigurationData?.getAdminFormConfigurations[0]?.isBUFormRequired);
    } else {
      setPartsConfig([]);
    }
  }, [getNotificationConfigurationData]);

  useEffect(() => {
    if (getScreenConfigurationData && getScreenConfigurationData.getScreens) {
      const data = getScreenConfigurationData.getScreens;
      const optionList = data.map((i: any) => {
        return {
          label: i.screenName,
          value: i.screenName,
          screenCode: i.screenCode,
          id: i.id,
          moduleCode: i.moduleCode,
          moduleName: i.moduleName,
          serialNumber: i.serialNumber,
          screenType: i.screenType,
          description: i.description,
        };
      });
      setoptions(optionList);
      setSelection(optionList[0] ? [optionList[0]] : []);
      getNotificationConfigurations({
        variables: {
          businessunit: userInfo?.businessunit || 'Sports Medicine',
          screen: optionList[0]?.value,
        },
      });
    } else {
      setNotificationconfigurations([]);
    }
  }, [getScreenConfigurationData, refetchScreenConfigurations]);

  const handleServiceLevelConfigChange = (event: any, serviceLevel: string): void => {
    const slConfigs = serviceLevelConfigs.map((sl: any) => {
      if (sl.code !== serviceLevel)
        return {
          mappingId: sl.mappingId,
          code: sl.code,
          name: sl.name,
          isapplicable: sl.isapplicable,
        };

      return {
        mappingId: sl.mappingId,
        code: sl.code,
        name: sl.name,
        isapplicable: !sl.isapplicable,
      };
    });

    setServiceLevelConfigs(slConfigs);
  };

  const handleCancel = (): void => {
    getNotificationConfigurations({
      variables: {
        businessunit: userInfo?.businessunit || 'Sports Medicine',
        screen: selection[0]?.value,
      },
    });
  };
  const handleBusinessRuleText = (item: any, text: any) => {
    setInput({ ...input, [item.rules]: text });
  };
  const handleSave = (): void => {
    let mutation: any = {};
    const payload: Array<any> = [];
    const bu: Array<any> = [];
    notificationConfigurations.forEach((e: any): void => {
      payload.push({
        formId: e.formId,
        fieldCode: e.fieldCode,
        fieldName: e.fieldName,
        isShow: e.isShow,
        isRequired: e.isRequired,
      });
    });
    businessRules.forEach((e: any): void => {
      if (e.rules === 'PO Document Threshold') {
        input[e.rules] = input[e.rules] ? input[e.rules] : '1';
      }
      if (e.isheader === false && e.rules === 'Footer') {
        input[e.rules] = input[e.rules]
          ? input[e.rules]
          : `Pricing Disclaimer: This is not an invoice. All prices are estimates and are subject to change without notice, and do not include applicable sales taxes. The pricing on the manufacturer's final invoice is the pricing for this transaction.`;
      }
      bu.push({
        formId: e.formId,
        rules: e.rules,
        isRequired: e.isRequired,
        values: input[e.rules] ? input[e.rules] : '',
      });
    });
    mutation = {
      isBUFormRequired: showInfo,
      businessrules: bu || [],
      screen: selection[0]?.value,
      configurations: showInfo ? payload : [],
      slMappingsToUpdate: serviceLevelConfigs.map((sl: any) => {
        return { mappingId: sl.mappingId, isapplicable: sl.isapplicable };
      }),
    };
    createUpdateConfig({
      variables: mutation,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
    });
  };

  const handleChange = (event: any, item: any, toggledProperty: string): void => {
    const updatedNotificationSettings = notificationConfigurations.map((element: any) => {
      if (item.formId) {
        if (item.formId === element.formId && item.fieldName === element.fieldName) {
          if (toggledProperty === 'required') {
            // eslint-disable-next-line no-unneeded-ternary
            return { ...element, isRequired: event.target.checked ? true : false };
          }
          if (toggledProperty === 'enabled') {
            if (event.target.checked === false) {
              if (disableIds.indexOf(item.formId) === -1) {
                setDisableIds([...disableIds, item.formId]);
              }
              return { ...element, isShow: false, isRequired: false };
            }
            setDisableIds(
              disableIds.filter((id: any) => {
                return id !== item.formId;
              })
            );
            return { ...element, isShow: true };
          }
        }
      }
      return element;
    });
    setNotificationconfigurations(updatedNotificationSettings);
  };

  const actions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <DisplayRecordIndicator recordInfo={timeStamp} screen="config" />
      </PageHeaderControl>
      <PageHeaderControl>
        <Button
          style={{ color: 'black', marginRight: '10px', fontWeight: 600 }}
          align="right"
          label="Cancel"
          onClick={handleCancel}
        />
        <Button className="yellow-btn" align="right" label="Save" onClick={handleSave} />
      </PageHeaderControl>
    </>
  );
  const handleDropDownSet = (event: any, data: any): void => {
    data = data.length ? data : [selection[0]];
    setSelection(data);
    setInputValue(event);
    getNotificationConfigurations({
      variables: {
        businessunit: userInfo?.businessunit || 'Sports Medicine',
        screen: data[0]?.value,
      },
    });
  };
  const handleDropDown = (event: any, data: { selection: any }) => {
    handleDropDownSet('', data.selection);
  };
  const handleStateDropDown = (event: any, data: { selection: any }) => {
    setSelectState(data.selection);
    setInput({ ...input, State: data.selection[0].label });
  };
  // const handleTabSelect = (value: number): void => {
  //   setSelectedTabIndex(value);
  // };

  const handleChangetoggel = (event: any): void => {
    const mutation: any = event.target.checked ? setDesc(true) : setDesc(false);
  };
  const handleChangetoggelDetail = (event: any, item: any, toggledProperty: string): void => {
    // const mutation: any = event.target.checked ? setshowInfoTrial(true) : setshowInfoTrial(false);
    const updatedbusinessRulesdata = businessRules.map((element: any) => {
      if (item.formId) {
        if (item.formId === element.formId && item.fieldName === element.fieldName) {
          if (toggledProperty === 'required') {
            // eslint-disable-next-line no-unneeded-ternary
            return { ...element, isRequired: event.target.checked ? true : false };
          }
          if (toggledProperty === 'minimumLeadTimeCheck') {
            // eslint-disable-next-line no-unneeded-ternary
            return { ...element, isRequired: event.target.checked ? true : false };
          }
          if (toggledProperty === 'minimumLeadTime') {
            // eslint-disable-next-line no-unneeded-ternary
            return { ...element, values: event };
          }
          if (toggledProperty === 'enabled') {
            if (event.target.checked === false) {
              if (disableIds.indexOf(item.formId) === -1) {
                setDisableIds([...disableIds, item.formId]);
              }
              return { ...element, isShow: false, isRequired: false };
            }
            setDisableIds(
              disableIds.filter((id: any) => {
                return id !== item.formId;
              })
            );
            return { ...element, isShow: true };
          }
        }
      }
      return element;
    });
    setbusinessRules(updatedbusinessRulesdata);
  };

  let label = selectedTab;

  if (selectedTab === 'Events' || selectedTab === 'Orders') {
    label = selectedTab?.slice(0, -1);
  }

  const getPlaceholderText = (item: any) => {
    if (
      item.rules === 'Street Address 1' ||
      item.rules === 'Street Address 2' ||
      item.rules === 'City'
    ) {
      return 'Enter Address';
    }
    if (item.rules === 'Zip Code') {
      return 'Enter Zip Code';
    }
    return '';
  };

  return (
    <div>
      <IconSettings iconPath="/icons">
        <SnackBar open={open} notification={notification} />
        {(loading || getNotificationLoader || getScreensLoader) && (
          <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
        )}
        <PageHeader
          className="slds-m-around_small slds-m-bottom_none"
          icon={
            <Icon
              assistiveText={{ label: 'Opportunity' }}
              category="standard"
              name="event"
              style={{ backgroundColor: '#FFB500' }}
            />
          }
          onRenderActions={actions}
          title={
            <div className="notification_header">
              <span className="slds-col event_id">
                {showPage !== 'CycleCount' ? showPage : 'Cycle Count'} Configurations
              </span>
            </div>
          }
          variant="record-home"
        />
        <div
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
          style={{ display: 'block' }}
        >
          <div className="notification-config-container">
            {selection?.length > 0 && selection[0] && selection[0]?.value !== 'Cycle Count' && (
              <section className="slds-p-around_small">
                <div className="slds-form-element slds-m-bottom_small" style={{ width: '477px' }}>
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: handleDropDown,
                    }}
                    labels={{
                      label: `${
                        selection[0]?.moduleCode === 'Events' ||
                        selection[0]?.moduleCode === 'Orders'
                          ? selection[0]?.moduleCode.slice(0, -1)
                          : selection[0]?.moduleCode
                      } Type`,
                      placeholder: `${
                        selection[0]?.moduleCode === 'Events' ||
                        selection[0]?.moduleCode === 'Orders'
                          ? selection[0]?.moduleCode.slice(0, -1)
                          : selection[0]?.moduleCode
                      } Type`,
                    }}
                    options={options || []}
                    selection={selection}
                    value={inputValue}
                    variant="readonly"
                  />
                </div>

                <b className="notificationfiled">
                  <h1>{selection ? selection[0]?.value : ''}</h1>
                </b>
                {selection[0] && selection[0]?.screenType === 'Create' && (
                  <div className="notification-cols-row options-table-row">
                    <span style={{ fontSize: 14, marginBottom: '10px' }}>BU Required Form</span>
                    <span style={{ fontSize: 14, marginBottom: '10px' }}>
                      <Checkbox
                        labels={{
                          toggleEnabled: '',
                          toggleDisabled: '',
                        }}
                        variant="toggle"
                        checked={showInfo === true}
                        onChange={(e: any): void => {
                          handleChangetoggel(e);
                        }}
                      />
                    </span>
                  </div>
                )}
              </section>
            )}
            {showInfo && selection[0]?.screenType !== 'Detail' ? (
              <>
                {notificationConfigurations.length &&
                selection[0].label !== 'Order-List View' &&
                selection[0].label !== 'Event-List View' ? (
                  <div className="notification-cols-header options-table-row">
                    <b>
                      <h1 className="notificationfiled">Fields</h1>
                    </b>
                    <p />
                  </div>
                ) : (
                  ''
                )}
                <div className="notification-cols-rowconfig options-table-row">
                  {notificationConfigurations.length ? (
                    <>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}>
                        {selection[0]?.screenType !== 'List View' && `Required`}
                      </span>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}>
                        {selection[0]?.screenType !== 'List View' && `Required`}
                      </span>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}>
                        {selection[0]?.screenType !== 'List View' && `Required`}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                  {notificationConfigurations &&
                    notificationConfigurations?.map((item: any) => (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {` ${item.fieldName}`}
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            disabled={
                              item.isEditable === false ||
                              ((item.screenCode === 'Surgery - Create' ||
                                item.screenCode === 'Standard Demo - Create' ||
                                item.screenCode === 'Trial - Create' ||
                                item.screenCode === 'Show-n-Go - Create') &&
                                item.mappingKey === 'coveringRep')
                            }
                            checked={item.isShow === true}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'enabled');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {((item.moduleCode === 'Inventory' &&
                            item.mappingKey !== 'specificShipingDetails' &&
                            item.mappingKey !== 'isshippingLabelRequired') ||
                            ((item.moduleCode === 'Events' || item.moduleCode === 'Orders') &&
                              item.moduleName !== 'List View')) && (
                            // eslint-disable-next-line react/jsx-indent
                            <Checkbox
                              labels={{
                                toggleEnabled: '',
                                toggleDisabled: '',
                              }}
                              variant="toggle"
                              disabled={
                                item.mappingKey === 'coveringRep' &&
                                item.screenCode === 'Mock OR - Create' &&
                                item.isEditable === false
                                  ? false
                                  : item.isEditable === false ||
                                    item.isShow === false ||
                                    !!disableIds.includes(item.formId)
                              }
                              checked={item.isRequired === true}
                              onChange={(e: any): void => {
                                handleChange(e, item, 'required');
                              }}
                            />
                          )}
                        </span>
                      </>
                    ))}
                </div>
                {partsConfig?.length ? (
                  <div className="notification-cols-header options-table-row">
                    <b>
                      <h1 className="notificationfiled">Parts</h1>
                    </b>
                    <p />
                  </div>
                ) : (
                  ''
                )}
                <div className="notification-cols-rowconfig options-table-row">
                  {partsConfig?.length ? (
                    <>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}>
                        {selection[0]?.screenType !== 'List View' && `Required`}
                      </span>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}>
                        {selection[0]?.screenType !== 'List View' && `Required`}
                      </span>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                    </>
                  ) : (
                    ''
                  )}
                  {partsConfig &&
                    partsConfig?.map((item: any) => (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {` ${item.fieldName}`}
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            disabled={item.isEditable === false}
                            checked={item.isShow === true}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'enabled');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {((item.moduleCode === 'Inventory' &&
                            item.mappingKey !== 'specificShipingDetails' &&
                            item.mappingKey !== 'isshippingLabelRequired') ||
                            ((item.moduleCode === 'Events' || item.moduleCode === 'Orders') &&
                              item.moduleName !== 'List View')) && (
                            // eslint-disable-next-line react/jsx-indent
                            <Checkbox
                              labels={{
                                toggleEnabled: '',
                                toggleDisabled: '',
                              }}
                              variant="toggle"
                              disabled={
                                item.isEditable === false ||
                                item.isShow === false ||
                                !!disableIds.includes(item.formId)
                              }
                              checked={item.isRequired === true}
                              onChange={(e: any): void => {
                                handleChange(e, item, 'required');
                              }}
                            />
                          )}
                        </span>
                      </>
                    ))}
                </div>
              </>
            ) : (
              ''
            )}
            {showInfo &&
              selection[0] &&
              selection[0]?.screenType === 'Detail' &&
              selection[0]?.screenCode === 'Inventory Request - Detail' && (
                <>
                  {selection[0].screenCode === 'Inventory Request - Detail' &&
                    (notificationConfigurations.length ? (
                      <>
                        <div className="notification-cols-header options-table-row">
                          <b>
                            <h1 className="notificationfiled">Header</h1>
                          </b>
                          <p />
                        </div>
                        <div className="padding-header">
                          Hide Show values should be inherited from the Create/New configurations,
                          for the user/BU.
                        </div>
                      </>
                    ) : (
                      ''
                    ))}
                  {notificationConfigurations.length ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Inventory Tab</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations.length ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      </>
                    ) : (
                      ''
                    )}
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false && item.header === 'Inventory Tab' && (
                            <>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {` ${item.fieldName}`}
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.isEditable === false}
                                  checked={item.isShow === true}
                                  onChange={(e: any): void => {
                                    handleChange(e, item, 'enabled');
                                  }}
                                />
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            </>
                          )}
                        </>
                      ))}
                  </div>
                  {notificationConfigurations.length ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Shipping Tab</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations.length ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      </>
                    ) : (
                      ''
                    )}
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false && item.header === 'Shipping Tab' && (
                            <>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {` ${item.fieldName}`}
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.isEditable === false}
                                  checked={item.isShow === true}
                                  onChange={(e: any): void => {
                                    handleChange(e, item, 'enabled');
                                  }}
                                />
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            </>
                          )}
                        </>
                      ))}
                  </div>
                </>
              )}

            {showInfo &&
              selection[0]?.screenType === 'Detail' &&
              selection[0]?.moduleCode === 'Events' && (
                <>
                  <div className="notification-cols-header options-table-row">
                    <b>
                      <h1 className="notificationfiled">Header</h1>
                    </b>
                    <p />
                  </div>
                  <div className="padding-header">
                    Hide Show values should be inherited from the Create/New configurations, for the
                    user/BU.
                  </div>
                </>
              )}

            {selection[0]?.screenCode === 'Standard Return - Detail' ||
            selection[0]?.screenCode === 'Pickup Return - Detail' ? (
              <>
                {notificationConfigurations.length ? (
                  <>
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Header</h1>
                      </b>
                      <p />
                    </div>
                    <div className="padding-header">
                      Hide Show values should be inherited from the Create/New configurations, for
                      the user/BU.
                    </div>
                  </>
                ) : (
                  ''
                )}
                {notificationConfigurations.length ? (
                  <div className="notification-cols-header options-table-row">
                    <b>
                      <h1 className="notificationfiled">Returning Tab</h1>
                    </b>
                    <p />
                  </div>
                ) : (
                  ''
                )}
                <div className="notification-cols-rowconfig options-table-row">
                  {notificationConfigurations.length ? (
                    <>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                    </>
                  ) : (
                    ''
                  )}
                  {notificationConfigurations &&
                    notificationConfigurations?.map((item: any) => (
                      <>
                        {item.isheader === false && item.header === 'Returning  Tab' && (
                          <>
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>
                              {` ${item.fieldName}`}
                            </span>
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>
                              <Checkbox
                                labels={{
                                  toggleEnabled: '',
                                  toggleDisabled: '',
                                }}
                                variant="toggle"
                                disabled={item.isEditable === false}
                                checked={item.isShow === true}
                                onChange={(e: any): void => {
                                  handleChange(e, item, 'enabled');
                                }}
                              />
                            </span>
                            <span style={{ fontSize: 14, marginBottom: '10px' }} />
                          </>
                        )}
                      </>
                    ))}
                </div>
                {notificationConfigurations.length ? (
                  <div className="notification-cols-header options-table-row">
                    <b>
                      <h1 className="notificationfiled">Received Tab</h1>
                    </b>
                    <p />
                  </div>
                ) : (
                  ''
                )}
                <div className="notification-cols-rowconfig options-table-row">
                  {notificationConfigurations.length ? (
                    <>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                    </>
                  ) : (
                    ''
                  )}
                  {notificationConfigurations &&
                    notificationConfigurations?.map((item: any) => (
                      <>
                        {item.isheader === false && item.header === 'Received Tab' && (
                          <>
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>
                              {` ${item.fieldName}`}
                            </span>
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>
                              <Checkbox
                                labels={{
                                  toggleEnabled: '',
                                  toggleDisabled: '',
                                }}
                                variant="toggle"
                                disabled={item.isEditable === false}
                                checked={item.isShow === true}
                                onChange={(e: any): void => {
                                  handleChange(e, item, 'enabled');
                                }}
                              />
                            </span>
                            <span style={{ fontSize: 14, marginBottom: '10px' }} />
                          </>
                        )}
                      </>
                    ))}
                </div>
              </>
            ) : (
              ''
            )}

            {selection[0]?.screenType === 'Detail' &&
            (selection[0]?.screenCode === 'Ship & Bill - Price Detail' ||
              selection[0]?.screenCode === 'Bill Only - Price Detail') ? (
              // eslint-disable-next-line react/jsx-indent
              <>
                <div className="notification-cols-rowconfig options-table-row">
                  {notificationConfigurations.length ? (
                    <>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                      <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                    </>
                  ) : (
                    ''
                  )}
                  {notificationConfigurations &&
                    notificationConfigurations?.map((item: any) => (
                      <>
                        {item.isheader === false && item.header === 'Price Detail Tab' && (
                          <>
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>
                              {` ${item.fieldName}`}
                            </span>
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>
                              <Checkbox
                                labels={{
                                  toggleEnabled: '',
                                  toggleDisabled: '',
                                }}
                                variant="toggle"
                                disabled={item.isEditable === false}
                                checked={item.isShow === true}
                                onChange={(e: any): void => {
                                  handleChange(e, item, 'enabled');
                                }}
                              />
                            </span>
                            <span style={{ fontSize: 14, marginBottom: '10px' }} />
                          </>
                        )}
                      </>
                    ))}
                </div>
              </>
            ) : (
              ''
            )}

            {showInfo &&
              selection[0]?.screenType === 'Detail' &&
              (selection[0]?.screenCode === 'Bill Only - Detail' ||
                selection[0]?.screenCode === 'Ship & Bill - Detail') && (
                <>
                  {(selection[0]?.screenCode === 'Bill Only - Detail' ||
                    selection[0]?.screenCode === 'Ship & Bill - Detail') &&
                    (notificationConfigurations.length ? (
                      <>
                        <div className="notification-cols-header options-table-row">
                          <b>
                            <h1 className="notificationfiled">Header</h1>
                          </b>
                          <p />
                        </div>
                        <div className="padding-header">
                          Hide Show values for the header are inherited from the Create/New Order
                          configurations except for the following.
                        </div>
                      </>
                    ) : (
                      ''
                    ))}
                  {notificationConfigurations.length ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Parts Tab</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations.length ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      </>
                    ) : (
                      ''
                    )}
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false && item.header === 'Parts Tab' && (
                            <>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {` ${item.fieldName}`}
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.isEditable === false}
                                  checked={item.isShow === true}
                                  onChange={(e: any): void => {
                                    handleChange(e, item, 'enabled');
                                  }}
                                />
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            </>
                          )}
                        </>
                      ))}
                  </div>
                  {notificationConfigurations.length &&
                  selection[0]?.screenCode === 'Bill Only - Detail' ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Replenishment Tab</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations.length &&
                    selection[0]?.screenCode === 'Bill Only - Detail' ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      </>
                    ) : (
                      ''
                    )}
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false &&
                            item.header === 'Replenishment Tab' &&
                            item.fieldName === 'Replenishment Tab' && (
                              <>
                                <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                  {` ${item.fieldName}`}
                                </span>
                                <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                  <Checkbox
                                    labels={{
                                      toggleEnabled: '',
                                      toggleDisabled: '',
                                    }}
                                    variant="toggle"
                                    disabled={item.isEditable === false}
                                    checked={item.isShow === true}
                                    onChange={(e: any): void => {
                                      handleChange(e, item, 'enabled');
                                      if (showFields) {
                                        setShowFields(false);
                                      } else {
                                        setShowFields(true);
                                      }
                                    }}
                                  />
                                </span>
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                              </>
                            )}
                        </>
                      ))}
                    {showFields !== false &&
                    notificationConfigurations.length &&
                    selection[0]?.screenCode === 'Bill Only - Detail' ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginTop: '20px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginTop: '20px' }}>
                          Hide/Show
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            marginBottom: '10px',
                            marginTop: '20px',
                            marginLeft: '10px',
                          }}
                        />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginTop: '20px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginTop: '20px' }}>
                          Hide/Show
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            marginBottom: '10px',
                            marginTop: '20px',
                            marginLeft: '10px',
                          }}
                        />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginTop: '20px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px', marginTop: '20px' }}>
                          Hide/Show
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            marginBottom: '10px',
                            marginTop: '20px',
                            marginLeft: '10px',
                          }}
                        />
                      </>
                    ) : (
                      ''
                    )}
                    {showFields !== false &&
                      notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false &&
                            item.header === 'Replenishment Tab' &&
                            item.fieldName !== 'Replenishment Tab' && (
                              <>
                                <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                  {` ${item.fieldName}`}
                                </span>
                                <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                  <Checkbox
                                    labels={{
                                      toggleEnabled: '',
                                      toggleDisabled: '',
                                    }}
                                    variant="toggle"
                                    disabled={item.isEditable === false}
                                    checked={item.isShow === true}
                                    onChange={(e: any): void => {
                                      handleChange(e, item, 'enabled');
                                    }}
                                  />
                                </span>
                                <span style={{ fontSize: 14, marginBottom: '10px' }} />
                              </>
                            )}
                        </>
                      ))}
                  </div>
                  {notificationConfigurations.length ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Pricing Tab</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations.length ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      </>
                    ) : (
                      ''
                    )}
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false && item.header === 'Pricing Tab' && (
                            <>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {` ${item.fieldName}`}
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.isEditable === false}
                                  checked={item.isShow === true}
                                  onChange={(e: any): void => {
                                    handleChange(e, item, 'enabled');
                                  }}
                                />
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            </>
                          )}
                        </>
                      ))}
                  </div>
                  {notificationConfigurations.length ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Price Detail</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations.length ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                        <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                      </>
                    ) : (
                      ''
                    )}
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false && item.header === 'Price Detail Tab' && (
                            <>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {` ${item.fieldName}`}
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.isEditable === false}
                                  checked={item.isShow === true}
                                  onChange={(e: any): void => {
                                    handleChange(e, item, 'enabled');
                                  }}
                                />
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            </>
                          )}
                        </>
                      ))}
                  </div>
                  {notificationConfigurations.length ? (
                    <div className="notification-cols-header options-table-row">
                      <b>
                        <h1 className="notificationfiled">Usage Sheet Tab</h1>
                      </b>
                      <p />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="notification-cols-rowconfig options-table-row">
                    {notificationConfigurations &&
                      notificationConfigurations?.map((item: any) => (
                        <>
                          {item.isheader === false && item.header === 'Usage Sheet Tab' && (
                            <>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {` ${item.fieldName}`}
                              </span>
                              <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                {item.fieldName === 'Print Pricing on Usage Sheet' ? (
                                  <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                    Default State
                                  </span>
                                ) : (
                                  <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                    Hide/Show
                                  </span>
                                )}
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.isEditable === false}
                                  checked={item.isShow === true}
                                  onChange={(e: any): void => {
                                    handleChange(e, item, 'enabled');
                                  }}
                                />
                              </span>
                              <span
                                style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}
                              >
                                {(item.fieldName === 'Internal Comments' ||
                                  item.fieldName === 'Customer Comments') && (
                                  <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                    Required
                                  </span>
                                )}
                                {(item.fieldName === 'Internal Comments' ||
                                  item.fieldName === 'Customer Comments') && (
                                  <Checkbox
                                    labels={{
                                      toggleEnabled: '',
                                      toggleDisabled: '',
                                    }}
                                    variant="toggle"
                                    disabled={
                                      item.isShow === false || !!disableIds.includes(item.id)
                                    }
                                    checked={item.isRequired === true}
                                    onChange={(e: any): void => {
                                      handleChange(e, item, 'required');
                                    }}
                                  />
                                )}
                              </span>
                            </>
                          )}
                        </>
                      ))}
                  </div>
                  {selection[0]?.screenCode === 'Ship & Bill - Detail' && (
                    <div>
                      {notificationConfigurations.length ? (
                        <div className="notification-cols-header options-table-row">
                          <b>
                            <h1 className="notificationfiled">Shipping Tab</h1>
                          </b>
                          <p />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="notification-cols-rowconfig options-table-row">
                        {notificationConfigurations.length ? (
                          <>
                            <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                            <span
                              style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}
                            />
                            <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                            <span
                              style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}
                            />
                            <span style={{ fontSize: 14, marginBottom: '10px' }} />
                            <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                            <span
                              style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }}
                            />
                          </>
                        ) : (
                          ''
                        )}
                        {notificationConfigurations &&
                          notificationConfigurations?.map((item: any) => (
                            <>
                              {item.isheader === false && item.header === 'Shipping Tab' && (
                                <>
                                  <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                    {` ${item.fieldName}`}
                                  </span>
                                  <span style={{ fontSize: 14, marginBottom: '10px' }}>
                                    <Checkbox
                                      labels={{
                                        toggleEnabled: '',
                                        toggleDisabled: '',
                                      }}
                                      variant="toggle"
                                      disabled={item.isEditable === false}
                                      checked={item.isShow === true}
                                      onChange={(e: any): void => {
                                        handleChange(e, item, 'enabled');
                                      }}
                                    />
                                  </span>
                                  <span style={{ fontSize: 14, marginBottom: '10px' }} />
                                </>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            {selection.length > 0 &&
              selection[0]?.screenType !== 'Template' &&
              selection[0]?.moduleName !== 'Events' &&
              businessRules &&
              businessRules?.map((item: any, index: any) => (
                <>
                  {index === 0 && (
                    <b className="notificationfiledBR">
                      <h1>Business Rules</h1>
                    </b>
                  )}
                  <div className="notification-cols-row-business-rules">
                    <span
                      style={{
                        fontSize: item.rules === 'Cycle Count Instructions' ? 13 : 14,
                        marginBottom: '10px',
                      }}
                    >
                      {item.rules}
                    </span>
                    <span id="tooltip">
                      <Icon
                        category="utility"
                        name="info"
                        size="x-small"
                        style={{
                          fill: 'rgb(133 125 125)',
                          marginBottom: '3px',
                          marginLeft: '10px',
                          marginRight: '25px',
                        }}
                      />
                      <span
                        className="tooltiptext"
                        style={{ textAlign: 'start', padding: '5px 5px' }}
                      >
                        {item.description}
                      </span>
                    </span>

                    {item.rules !== 'Cycle Count Instructions' && (
                      <span className="business-rule-checkbox">
                        <Checkbox
                          labels={{
                            toggleEnabled: '',
                            toggleDisabled: '',
                          }}
                          variant="toggle"
                          disabled={item.rules === 'PO Number' && item.iseditable === false}
                          checked={item.isRequired === true}
                          onChange={(e: any): void => {
                            handleChangetoggelDetail(e, item, 'required');
                          }}
                        />
                      </span>
                    )}
                    <span
                      style={{
                        fontSize: 14,
                        marginBottom: '10px',
                        width: '400px',
                        marginTop: '-32px',
                      }}
                    >
                      {item.value_type === 'textarea' && item.rules !== 'Cycle Count Instructions' && (
                        <Input
                          label={item.isRequired === true ? ` * ${item?.header}` : item?.header}
                          placeholder={`Enter ${item?.header}`}
                          id={item?.header === 'Minimum Lead Time (Days)' ? '' : 'bg-icon'}
                          onChange={
                            (event: any): void => handleBusinessRuleText(item, event.target.value)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          value={input[item.rules]}
                          maxLength="10"
                          disabled={item.isRequired === false}
                          type="number"
                        />
                      )}
                      {item.value_type === 'textarea' && item.rules === 'Cycle Count Instructions' && (
                        <Input
                          label={`${item.rules} URL`}
                          placeholder="https://stryker.sharepoint.com"
                          onChange={
                            (event: any): void => handleBusinessRuleText(item, event.target.value)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          value={input[item.rules]}
                          disabled={item.isRequired === false}
                          type="string"
                        />
                      )}
                    </span>
                  </div>
                </>
              ))}
            {showInfo && selection[0]?.screenCode === 'Inventory Request - Create' && (
              <>
                <div className="notification-cols-rowconfig options-table-row" />
                <b className="notificationfiledBR">
                  <h1>Service Level</h1>
                </b>
                <div className="notification-cols-rowconfig options-table-row">
                  <span style={{ fontSize: 14, marginBottom: '10px' }} />
                  <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                  <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                  <span style={{ fontSize: 14, marginBottom: '10px' }} />
                  <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                  <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                  <span style={{ fontSize: 14, marginBottom: '10px' }} />
                  <span style={{ fontSize: 14, marginBottom: '10px' }}>Hide/Show</span>
                  <span style={{ fontSize: 14, marginBottom: '10px', marginLeft: '10px' }} />
                  {serviceLevelConfigs?.map((level: any, index: any) => (
                    <>
                      <span
                        style={{
                          fontSize: 14,
                          marginBottom: '10px',
                        }}
                      >
                        {level.name}
                      </span>
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>
                        <Checkbox
                          variant="toggle"
                          labels={{
                            toggleEnabled: '',
                            toggleDisabled: '',
                          }}
                          checked={level.isapplicable}
                          onChange={(e: any): void => {
                            handleServiceLevelConfigChange(e, level.code);
                          }}
                        />
                      </span>
                      <span style={{ fontSize: 14, marginBottom: '10px' }} />
                    </>
                  ))}
                </div>
              </>
            )}
            {selection.length > 0 &&
              selection[0]?.screenType !== 'Template' &&
              selection[0]?.moduleName === 'Events' &&
              businessRules &&
              businessRules?.map((item: any, index: any) => (
                <>
                  {index === 0 && (
                    <div className="notificationfiledBR">
                      <h1>Business Rules</h1>
                    </div>
                  )}
                  <div
                    className={
                      item?.rules === 'Bill Only Dispositioning'
                        ? 'notification-cols-rowconfig-business_rules options-table-row'
                        : ''
                    }
                  >
                    {item?.rules === 'Bill Only Dispositioning' ? (
                      <>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {item?.rules}
                          <Icon
                            category="utility"
                            name="info"
                            size="x-small"
                            className="slds-tooltip-trigger"
                            style={{
                              fill: 'rgb(133 125 125)',
                              marginLeft: '12px',
                            }}
                            title="Controls whether a Bill Only disposition is enabled"
                          />
                        </span>
                        <span
                          style={{ fontSize: 14, marginBottom: '10px' }}
                          className="business-rule-checkbox"
                        >
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            checked={item.isRequired === true}
                            onChange={(e: any): void => {
                              handleChangetoggelDetail(e, item, 'required');
                            }}
                          />
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ))}
            {selection.length > 0 &&
              selection[0]?.screenType !== 'Template' &&
              selection[0]?.moduleName === 'Events' &&
              businessRules &&
              businessRules?.map((item: any, index: any) => (
                <>
                  {index === 0 && (
                    <>
                      <div className="padding-rules">
                        Controls what can be updated on a scheduled event once it has associated
                        records.
                      </div>
                      <div className="notification-cols-rowconfig-business_rules options-table-row">
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Disallow/Allow</span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Disallow/Allow</span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }} />
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>Disallow/Allow</span>
                      </div>
                    </>
                  )}
                </>
              ))}
            <div className="notification-cols-rowconfig-business_rules options-table-row">
              {selection.length > 0 &&
                selection[0]?.screenType !== 'Template' &&
                selection[0]?.moduleName === 'Events' &&
                businessRules &&
                businessRules?.map((item: any, index: any) =>
                  item?.rules !== 'Bill Only Dispositioning' ? (
                    <>
                      <span style={{ fontSize: 14, marginBottom: '10px' }}>{item.rules}</span>
                      <span
                        style={{ fontSize: 14, marginBottom: '10px' }}
                        className="business-rule-checkbox"
                      >
                        <Checkbox
                          labels={{
                            toggleEnabled: '',
                            toggleDisabled: '',
                          }}
                          variant="toggle"
                          checked={item.isRequired === true}
                          onChange={(e: any): void => {
                            handleChangetoggelDetail(e, item, 'required');
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    <></>
                  )
                )}
            </div>
            <div className="fields-container">
              {selection.length > 0 &&
                selection[0]?.screenType === 'Template' &&
                businessRules &&
                businessRules?.map((item: any) => {
                  if (item.header === 'Address') {
                    if (item.isheader === true) {
                      return (
                        <>
                          <div className="fields-rows-header">
                            <b>
                              <h1 className="">
                                {item.header}
                                <span id="tooltip">
                                  <Icon
                                    category="utility"
                                    name="info"
                                    size="x-small"
                                    style={{
                                      fill: 'rgb(133 125 125)',
                                      marginBottom: '3px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                  <span className="tooltiptext">{item.description}</span>
                                </span>
                              </h1>
                            </b>
                          </div>
                        </>
                      );
                    }
                    if (item.isheader === false) {
                      return (
                        <>
                          <div className="fields-rows">
                            <div style={{ fontSize: 14, marginBottom: '10px' }}>
                              {item.value_type === 'textarea' ? (
                                <Input
                                  label={item.rules}
                                  tooltipText={item.rules}
                                  showTooltip={false}
                                  placeholder={getPlaceholderText(item)}
                                  onChange={
                                    (event: any): void =>
                                      handleBusinessRuleText(item, event.target.value)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                  value={input[item.rules]}
                                  maxLength="200"
                                  disabled={false}
                                />
                              ) : (
                                <Combobox
                                  id={item.rules}
                                  disabled={item.rules === 'Country'}
                                  events={{
                                    onSelect: handleStateDropDown,
                                  }}
                                  labels={{
                                    label: `${item.rules}`,
                                    placeholder: `Select ${item.rules}`,
                                  }}
                                  options={
                                    item.rules !== 'Country'
                                      ? statesData.getStates.map((state: any) => {
                                          return { id: state.id, label: state.name };
                                        }) || []
                                      : [
                                          {
                                            id: 1,
                                            label: 'United States',
                                          },
                                        ]
                                  }
                                  selection={
                                    // eslint-disable-next-line no-nested-ternary
                                    item.rules !== 'Country'
                                      ? input.State
                                        ? [{ label: input.State }]
                                        : selectState || []
                                      : [
                                          {
                                            id: 1,
                                            label: 'United States',
                                          },
                                        ]
                                  }
                                  variant="readonly"
                                />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  return <></>;
                })}
            </div>
            <div className="fields-container" style={{ marginTop: '44px', marginBottom: '44px' }}>
              {selection.length > 0 &&
                selection[0]?.screenType === 'Template' &&
                businessRules &&
                businessRules?.map((item: any) => {
                  if (item.header === 'Contact Email') {
                    if (item.isheader === true) {
                      return (
                        <>
                          <div
                            className="notification-cols-row-business-rules"
                            style={{ width: '50%' }}
                          >
                            <h1 style={{ fontWeight: 'bold', width: '30%' }}>
                              {item.header}
                              <span id="tooltip">
                                <Icon
                                  category="utility"
                                  name="info"
                                  size="x-small"
                                  style={{
                                    fill: 'rgb(133 125 125)',
                                    marginBottom: '3px',
                                    marginLeft: '5px',
                                  }}
                                />
                                <span className="tooltiptext">{item.description}</span>
                              </span>
                            </h1>
                            <div style={{ display: 'grid', marginLeft: '50px', width: '20%' }}>
                              <span style={{ fontSize: 14, marginTop: '-23px' }}>Sales Rep/BU</span>
                              <span className="business-rule-checkbox">
                                <Checkbox
                                  labels={{
                                    toggleEnabled: '',
                                    toggleDisabled: '',
                                  }}
                                  variant="toggle"
                                  disabled={item.rules === 'Email' && item.iseditable === false}
                                  checked={item.isRequired === true}
                                  onChange={(e: any): void => {
                                    handleChangetoggelDetail(e, item, 'required');
                                  }}
                                />
                              </span>
                            </div>
                            <div style={{ marginLeft: '50px', width: '50%' }}>
                              {item.value_type === 'textarea' && item.rules === 'Email' && (
                                <Input
                                  label=""
                                  placeholder={
                                    item.isRequired === true
                                      ? 'Enter BU Email'
                                      : 'Sales Rep Email Defaults'
                                  }
                                  onChange={
                                    (event: any): void =>
                                      handleBusinessRuleText(item, event.target.value)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                  value={item.isRequired === true ? input[item.rules] : ''}
                                  maxLength="200"
                                  disabled={item.isRequired === false}
                                  type="text"
                                  size="10"
                                />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  return <></>;
                })}
            </div>
            <div className="fields-container">
              {selection.length > 0 &&
                selection[0]?.screenType === 'Template' &&
                businessRules &&
                businessRules?.map((item: any) => {
                  if (item.header === 'Document Name') {
                    if (item.isheader === true) {
                      return (
                        <>
                          <div className="fields-rows-header">
                            <b>
                              <h1 className="">
                                {item.header}
                                <span id="tooltip">
                                  <Icon
                                    category="utility"
                                    name="info"
                                    size="x-small"
                                    style={{
                                      fill: 'rgb(133 125 125)',
                                      marginBottom: '3px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                  <span className="tooltiptext">{item.description}</span>
                                </span>
                              </h1>
                            </b>
                          </div>
                        </>
                      );
                    }
                    if (item.isheader === false) {
                      return (
                        <>
                          <div className="fields-rows">
                            <div style={{ fontSize: 14, marginBottom: '10px' }}>
                              <Input
                                label={item.rules}
                                tooltipText={item.rules}
                                showTooltip={false}
                                onChange={
                                  (event: any): void =>
                                    handleBusinessRuleText(item, event.target.value)
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                value={input[item.rules]}
                                maxLength="200"
                                disabled={false}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  return <></>;
                })}
            </div>
            <div className="fields-container">
              {selection.length > 0 &&
                selection[0]?.screenType === 'Template' &&
                businessRules &&
                businessRules?.map((item: any) => {
                  if (item.header === 'Disclaimer') {
                    if (item.isheader === true) {
                      return (
                        <>
                          <div className="fields-rows-header">
                            <b>
                              <h1 className="">
                                {item.header}
                                <span id="tooltip">
                                  <Icon
                                    category="utility"
                                    name="info"
                                    size="x-small"
                                    style={{
                                      fill: 'rgb(133 125 125)',
                                      marginBottom: '3px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                  <span className="tooltiptext">{item.description}</span>
                                </span>
                              </h1>
                            </b>
                          </div>
                        </>
                      );
                    }
                    if (item.isheader === false) {
                      return (
                        <>
                          <div className="fields-rows">
                            <div style={{ fontSize: 14, marginBottom: '10px' }}>
                              <TextArea
                                label={item.rules}
                                tooltipText={item.rules}
                                showTooltip={false}
                                onChange={
                                  (event: any): void =>
                                    handleBusinessRuleText(item, event.target.value)
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                value={input[item.rules]}
                                maxLength="2000"
                                disabled={false}
                              />
                              {/* <Input
                                label={item.rules}
                                tooltipText={item.rules}
                                showTooltip={false}
                                onChange={
                                  (event: any): void =>
                                    handleBusinessRuleText(item, event.target.value)
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                value={input[item.rules]}
                                maxLength="200"
                                disabled={false}
                                style={{height:'50px'}}
                              /> */}
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  return <></>;
                })}
            </div>
            <div className="fields-container">
              {selection.length > 0 &&
                selection[0]?.screenType === 'Template' &&
                businessRules &&
                businessRules?.map((item: any) => {
                  if (item.header === 'Footer') {
                    if (item.isheader === true) {
                      return (
                        <>
                          <div className="fields-rows-header">
                            <b>
                              <h1 className="">
                                {item.header}
                                <span id="tooltip">
                                  <Icon
                                    category="utility"
                                    name="info"
                                    size="x-small"
                                    style={{
                                      fill: 'rgb(133 125 125)',
                                      marginBottom: '3px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                  <span className="tooltiptext">{item.description}</span>
                                </span>
                              </h1>
                            </b>
                          </div>
                        </>
                      );
                    }
                    if (item.isheader === false) {
                      return (
                        <>
                          <div className="fields-rows">
                            <div style={{ fontSize: 14, marginBottom: '10px' }}>
                              <TextArea
                                label={item.rules}
                                tooltipText={item.rules}
                                showTooltip={false}
                                onChange={
                                  (event: any): void =>
                                    handleBusinessRuleText(item, event.target.value)
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                value={input[item.rules]}
                                maxLength="2000"
                                disabled={false}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  return <></>;
                })}
            </div>
          </div>
        </div>
      </IconSettings>
    </div>
  );
};

export default withRouter(EventConfiguration);
