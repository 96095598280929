/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Module for header on Surgical Detail Page
 * @module src/SurgicalDetail/SurgicalDetailHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-lonely-if  */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Combobox from '@salesforce/design-system-react/components/combobox';
import CartComponent from '../InventoryRequestHeader/inventoryCart';
import Collapsible from '../../../../Usage/UsageDetails/UsageTabs/Parts/AddPartsModal/collapsible';
import debounced from '../../../../../util/debounced';
import { MAX_QUANTITY, NOTIFY_TYPE } from '../../../../../util/constants';
import { getDateDifferenceInDays, getHoldIcon } from '../../../../../util/utilityFunctions';
import { GET_USER_TERRITORIES } from '../../../../../graphql/getUserTerritories';
import { GET_PRODUCT_LIST } from '../../../../../graphql/getProductList';
import { GET_PRIMARY_ACCOUNT } from '../../../../../graphql/getPrimaryAccount';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../../../graphql/getFilteredProductGroups';
import './index.scss';
import { UPDATE_TRANSFER_INVENTORY } from '../../../../../graphql/mutations/updateTransferInventory';
import CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS from '../../../../../graphql/mutations/createUpdateInventoryReturnLineItems';
import { getUserInfo } from '../../../../../store/ducks/userInfo';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../../graphql/getSubInventoryWithLotDetails';
import RESUBMIT_ORDER_DETAILS from '../../../../../graphql/mutations/resubmitOrderDetails';
import SnackBar from '../../../../Shared/SnackBar';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import AutocompleteSingleSelectPicker from '../../../../Shared/AutocompleteMultiSelectPicker';

interface Props extends RouteComponentProps {
  isOpen?: boolean;
  handleClose: () => void;
  inventoryReturnDetail?: any;
  history: any;
  isEventInventoryFlow?: boolean;
  isEdit?: boolean;
  // handleFilter: (filter: DynamicType | null) => void;
  selectedFilter?: DynamicType | null;
  quantityCount?: number;
  refetch?: any;
  type?: any;
  returnType?: any;
  InventoryTotalcount?: any;
  handleSelectedLineItems?: (item: any, count: number) => void;
  refetchTransferDetails: () => void;
  selectedItemsValue?: any;
  selectedLineItemInventoryList?: any;
}
enum DetailTypes {
  ProductGroup = 'ProductGroup',
}
interface LineItem {
  id: string;
  externalId: string;
  partNumber?: string;
  lotNumber: string;
  productId: string;
  quantity: string;
  priceBookEntryId: string;
  unitPrice: string;
  status: string;
  approvalStatus: string;
  salesUnitOfMeasure: string;
  productNumber: string;
  isLotNumber: boolean;
  subInventoryCode: string;
}
interface DynamicType {
  [key: string]: any;
}

const InventoryReturnAddItems: FC<Props> = ({
  isOpen,
  handleClose,
  inventoryReturnDetail,
  isEdit = false,
  selectedFilter,
  refetchTransferDetails,
  selectedItemsValue,
  handleSelectedLineItems,
  // handleFilter,
  quantityCount,
  returnType,
  // history,
  // isEventInventoryFlow,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [subInvCount, setSubInvCount] = useState(0);
  const [userTerritories, setUserTerritories] = useState();
  const [subInventoryLotInfo, setSubInventoryLotInfo] = useState<any>([]);
  const [addPartsLocally, setAddPartsLocally] = useState<any[]>([]);
  const [addedSunInvPartLocal, setAddedSunInvPartLocal] = useState<any>([]);
  const [productList, setProductList] = useState([]);
  const [catalogProductList, setCatalogProductList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [inventoryList, setInventoryList] = useState<any>([]);
  const [catalogCount, setCatalogCount] = useState(0);
  const [updateTransfer] = useMutation(UPDATE_TRANSFER_INVENTORY);
  const [count, setCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const filterValues = selectedFilter?.filterValues;
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [searchTerm, setSearchText] = useState('');
  const [productListUpdated, setProductListUpdated] = useState<any[]>([]);
  const [isSearchEdItem, setIsSearchedItem] = useState(false);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const holdIconPath = getHoldIcon();
  // eslint-disable-next-line prefer-const
  let [cartCount, setCartCount] = useState(0);
  const [tabName, setTabName] = useState(
    inventoryReturnDetail?.type === 'Customer to Rep' ? 'Customer' : 'Repstock'
  );
  const updateProducts: Array<any> = productListUpdated;
  const [selectedGrps, setSelectedGrps] = useState<any>(
    selectedFilters?.productGroup?.map((item: any) => item.id) || []
  );
  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY
  const primaryAccountData = primaryAccountItems?.getPrimaryAccount;
  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS,
    {
      variables: {
        caseId: inventoryReturnDetail?.caseId || '',
        caseExternalId: inventoryReturnDetail?.externalId || '',
        salesRepId: inventoryReturnDetail?.salesRepId ? inventoryReturnDetail?.salesRepId : '',
        tabName,
      },
    }
  );

  const [productListItems, { data: productItems, loading: loadingCatalog }] = useLazyQuery(
    GET_PRODUCT_LIST
  );

  const [createUpdateInventoryReturn, { data: createUpdateInventoryReturnMutation }] = useMutation(
    UPDATE_TRANSFER_INVENTORY
  );

  const [getUserTerritoriesItems, { data: userTerritoriesItems }] = useLazyQuery(
    GET_USER_TERRITORIES,
    {
      variables: {
        userId: userInfo?.id,
      },
    }
  ); // USELAZYQUERY

  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY

  useEffect(() => {
    if (inventoryReturnDetail && inventoryReturnDetail.salesRepId) {
      getPrimaryAccountItems({
        variables: {
          salesRepId: inventoryReturnDetail.salesRepId,
        },
      });
    }
  }, [getPrimaryAccountItems, inventoryReturnDetail]);

  useEffect(() => {
    getUserTerritoriesItems();
    if (userTerritoriesItems?.getUserTerritories) {
      const userTerritoriesItemsList = userTerritoriesItems?.getUserTerritories;
      if (userTerritoriesItemsList && userTerritoriesItemsList.length > 0) {
        setUserTerritories(userTerritoriesItemsList[0].value);
      } else {
        setUserTerritories(inventoryReturnDetail.territoryNumber);
      }
    }
  }, [getUserTerritoriesItems, userTerritoriesItems]);

  const getProductRecord = (product: any): any => {
    let returnVal = null;
    if (
      inventoryReturnDetail &&
      inventoryReturnDetail.lineItems &&
      inventoryReturnDetail.lineItems.length
    ) {
      returnVal = inventoryReturnDetail.lineItems.find(
        (rec: any) =>
          rec.productId === product.productId &&
          rec.caseUsageSubInventoryCode === product.subInventoryCode
      );
    }
    return returnVal;
  };

  const getProductLotRecord = useCallback(
    (product: any): any => {
      let returnVal = null;
      if (
        inventoryReturnDetail &&
        inventoryReturnDetail.lineItems &&
        inventoryReturnDetail.lineItems.length
      ) {
        returnVal = inventoryReturnDetail.lineItems.find(
          (rec: any) => rec.productId === product.productId && rec.lotNumber === product.lotNumber
        );
      }
      return returnVal;
    },
    [inventoryReturnDetail]
  );

  useEffect(() => {
    let overallCount = count || 0;
    if (!isEdit) {
      if (productItems && productItems.getProductList && productItems.getProductList.length) {
        productItems.getProductList.forEach((list: any) => {
          const record = getProductRecord(list);
          const LotProduct = getProductLotRecord(list);
          /* eslint-disable no-param-reassign */
          list.counterValue = record ? Number(record.quantity) : 0;
          // list.maxQuantity = Number(record.availableQuantity);
          const changedIndex = addPartsLocally.find(
            (index: any) => index.productId === list.productId
          );
          if (changedIndex) {
            list.counterValue = changedIndex.counterValue;
          }
          if (record) {
            list.usageExternalId = record.usageExternalId;
          }
          if (LotProduct) {
            list.alreadyAdded = true;
          }
          list.changed = false;
          productListUpdated.forEach((data: any) => {
            if (data.productId === list.productId) {
              list.changed = false;
              list.counterValue = data.counterValue;
            }
          });
        });
        const counter = productItems.getProductList.filter((list: any) => list.counterValue).length;
        overallCount = counter;
        // setSubInvCount(subInvCount);
        setCatalogProductList(productItems.getProductList);
        setCartCount(subInvCount);
      } else {
        setCatalogProductList([]);
      }
    } else {
      inventoryReturnDetail.lineItems.forEach((record: any) => {
        record.changed = false;
        record.counterValue = Number(record.quantity);
        record.maxPermissibleValue = Number(record.availableQuantity);
      });
      setCatalogProductList(inventoryReturnDetail.lineItems);
      overallCount += inventoryReturnDetail.lineItems.length;
      setSubInvCount(inventoryReturnDetail.lineItems.length);
    }
    setCount(overallCount);
  }, [productItems]);

  useEffect(() => {
    // const output: any = ([] = [{ subInventoryName: userTerritories, lotInfo: [] }]);
    if (subInventoryWithLotDetails) {
      const searchedSubInventoryWithLot = subInventoryWithLotDetails?.getSubInventoryWithLotDetails;

      if (searchedSubInventoryWithLot.length) {
        const finalSubInvWithLotInfo: Array<any> = [];
        const finalSubInvWithLotAccountInfo: Array<any> = [];
        const productDescriptionLIst: Array<any> = [];
        const repAccount: Array<any> = [];
        searchedSubInventoryWithLot.forEach((record: any) => {
          /* eslint-disable no-param-reassign */
          const LotProduct = getProductLotRecord(record);
          record.alreadyAdded = !!LotProduct;
          record.counterValue = LotProduct ? Number(LotProduct.quantity) : 0;
          record.maxQuantity = Number(record.availableQuantity);
          const changedIndex = addedSunInvPartLocal.find(
            (index: any) =>
              index.lotNumber === record.lotNumber && index.lotNumber === record.lotNumber
          );
          if (changedIndex) {
            record.counterValue = changedIndex.counterValue;
          }

          const data = productDescriptionLIst?.filter(
            (item: any) =>
              item.prodNum === record.productNumber &&
              item.subInventoryCode === record.subInventoryCode
          );

          if (data && data.length > 0) {
            const index = finalSubInvWithLotInfo.findIndex(
              (entry: any) =>
                entry.productNumber === record.productNumber &&
                entry.subInventoryCode === record.subInventoryCode
            );

            finalSubInvWithLotInfo[index].value.push(record);
          } else {
            finalSubInvWithLotInfo.push({
              name: record.productDescription,
              productNumber: record.productNumber,
              productOnHold: record.productOnHold,
              serialRecalled: record.serialRecalled,
              productOnHoldType: record.productOnHoldType,
              subInventoryCode: record.subInventoryCode,
              subInventoryName: record.subInventoryName,
              value: [record],
            });
            productDescriptionLIst.push({
              prodNum: record.productNumber,
              prodName: record.productDescription,
              subInventoryCode: record.subInventoryCode,
            });
          }
        });
        finalSubInvWithLotInfo.forEach((subInv: any) => {
          if (repAccount.map(e => e.subInventoryCode).indexOf(subInv.subInventoryCode) >= 0) {
            const index = finalSubInvWithLotAccountInfo.findIndex(
              (entry: any) => entry.subInventoryCode === subInv.subInventoryCode
            );
            // eslint-disable-next-line no-unused-expressions
            finalSubInvWithLotAccountInfo[index]?.productDescription[0].value.push(subInv);
          } else {
            finalSubInvWithLotAccountInfo.push({
              subInventoryCode: subInv.subInventoryCode,
              subInventoryName: subInv.subInventoryName,
              name: subInv.name,
              productNumber: subInv.productNumber,
              productOnHold: subInv.productOnHold,
              productOnHoldType: subInv.productOnHoldType,
              isrepstock: subInv.isrepstock,
              productDescription: [
                {
                  name: subInv.name,
                  productNumber: subInv.productNumber,
                  productOnHold: subInv.productOnHold,
                  productOnHoldType: subInv.productOnHoldType,
                  subInventoryCode: subInv.subInventoryCode,
                  subInventoryName: subInv.subInventoryName,
                  isChecked: isSearchEdItem,
                  isrepstock: subInv.isrepstock,
                  value: [subInv],
                },
              ],
            });
            repAccount.push({
              subInventoryCode: subInv.subInventoryCode,
              subInventoryName: subInv.subInventoryName,
            });
          }
        });
        // setSubInventoryLotInfo(finalSubInvWithLotInfo);
        setSubInventoryLotInfo(finalSubInvWithLotAccountInfo);
        setIsSearchedItem(false);
      } else {
        setSubInventoryLotInfo([]);
      }
    }
  }, [subInventoryWithLotDetails, userTerritories, getProductLotRecord]);

  useEffect(() => {
    let counter = 0;
    const updateList = inventoryReturnDetail.lineItems.map((record: any) => {
      counter += Number(record.quantity);
      return {
        ...record,
        maxQuantity: Number(record.availableQuantity) + Number(record.quantity),
        changed: false,
        counterValue: Number(record.quantity),
      };
    });
    setProductList(updateList);
    setSubInvCount(counter);
  }, [inventoryReturnDetail]);

  useEffect(() => {
    if (
      createUpdateInventoryReturnMutation &&
      createUpdateInventoryReturnMutation.createUpdateInventoryReturnLineItems &&
      createUpdateInventoryReturnMutation.createUpdateInventoryReturnLineItems.message === 'success'
    ) {
      setLoader(false);
      handleClose();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [createUpdateInventoryReturnMutation]);

  useEffect(() => {
    setSelectedFilters({});
  }, [tabName]);

  const setSubInventoryQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryCode: inventoryReturnDetail.accountNumber,
          salesRepId: inventoryReturnDetail?.salesRepId,
          recordType: 'Transfer',
          tabName,
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryCode: inventoryReturnDetail.accountNumber,
          salesRepId: inventoryReturnDetail?.salesRepId,
          recordType: 'Transfer',
          tabName,
          filters: {
            searchText: '',
          },
        },
      });
    }
  };
  const handleFilterOpening = (title: any, code: any): void => {
    // eslint-disable-next-line no-unused-expressions
    subInventoryLotInfo?.forEach((subInv: any) => {
      // eslint-disable-next-line no-unused-expressions
      subInv?.productDescription.forEach((accounts: any) => {
        if (code === accounts.subInventoryCode) {
          accounts.isChecked = !accounts.isChecked;
        }
      });
    });
    setSubInventoryLotInfo(subInventoryLotInfo);
    setIsExpanded(prev => !prev);
  };
  const debouncedFetchSubInventoryItems = useCallback(debounced(500, setSubInventoryQuantity), [
    debounced,
    setSubInventoryQuantity,
  ]);

  // Get default Sub Inventories on load
  useEffect(() => {
    if (!isEdit) {
      getSubInventoryWithLotDetails({
        variables: {
          limit: 50,
          offset: 0,
          tabName,
          recordType: 'Transfer',
          salesRepId: inventoryReturnDetail?.salesRepId,
          subInventoryCode: inventoryReturnDetail.accountNumber,
          filters: {
            searchText: '',
          },
        },
      });
    }
  }, [getSubInventoryWithLotDetails, isEdit, userTerritories, tabName]);

  // Get default Parts on load
  useEffect(() => {
    productListItems({
      variables: {
        limit: 50,
        offset: 0,
        searchText: '',
        caseId: inventoryReturnDetail.orderId ? inventoryReturnDetail.orderId : null,
        caseExternalId: inventoryReturnDetail.orderExternalId
          ? inventoryReturnDetail.orderExternalId
          : inventoryReturnDetail?.caseExternalId,
        salesRepId: inventoryReturnDetail.salesRepId ? inventoryReturnDetail.salesRepId : null,
        filters: {
          productName: selectedGrps,
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId: userInfo?.id,
        },
      },
    });
    if (isEdit) setSubInvCount(inventoryReturnDetail.lineItems.length);
  }, [productListItems]);

  const setEventPartQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      productListItems({
        variables: {
          searchText: searchedItem,
          caseId: inventoryReturnDetail.orderId ? inventoryReturnDetail.orderId : null,
          caseExternalId: inventoryReturnDetail.orderExternalId
            ? inventoryReturnDetail.orderExternalId
            : null,
          salesRepId: inventoryReturnDetail.salesRepId ? inventoryReturnDetail.salesRepId : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    } else {
      productListItems({
        variables: {
          searchText: '',
          caseId: inventoryReturnDetail.orderId ? inventoryReturnDetail.orderId : null,
          caseExternalId: inventoryReturnDetail.orderExternalId
            ? inventoryReturnDetail.orderExternalId
            : null,
          salesRepId: inventoryReturnDetail.salesRepId ? inventoryReturnDetail.salesRepId : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    }
  };

  const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
    debounced,
    setEventPartQuantity,
  ]);

  const searchCatalog = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      setSearchText(searchedItem.value);
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSetItems(value);
      }
    },
    [debouncedFetchSetItems]
  );

  const searchSubInventory = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSubInventoryItems(value);
        setIsSearchedItem(true);
      }
    },
    [debouncedFetchSubInventoryItems]
  );

  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...productList];
    let counter = 0;
    updatedList.forEach((element: any) => {
      /** changing to lot number has its unique to handle update return */
      if (product.lotNumber) {
        if (product.productId === element.productId && product.externalId === element.externalId) {
          /* eslint-disable no-param-reassign */
          element.counterValue =
            data.number < Number(element.maxQuantity) ? data.number : Number(element.maxQuantity);
          element.changed = true;
        }
      } else {
        if (product.productId === element.productId && product.externalId === element.externalId) {
          /* eslint-disable no-param-reassign */
          element.counterValue =
            data.number < Number(MAX_QUANTITY) ? data.number : Number(MAX_QUANTITY);
          element.changed = true;
        }
      }

      counter += Number(element.counterValue);
    });
    setProductList(updatedList);
    setSubInvCount(counter);
  };

  const handleCatalogCount = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    const updatedList = [...catalogProductList];
    let counter = subInvCount;
    let decremenmtFlag = false;
    updatedList.forEach((element: any, index: number) => {
      if (product.productId === element.productId) {
        if (product.counterValue > data.number) cartCount -= product.counterValue - data.number;
        else {
          cartCount += data.number - product.counterValue;
        }
        setProductListUpdated(updateProducts);
        /* eslint-disable no-param-reassign */
        decremenmtFlag = data.number < element.counterValue;
        element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
        element.changed = true;
        counter += element.changed ? 1 : 0;
        const addedIndex = addPartsLocally.findIndex((e: any) => e.productId === element.productId);
        if (addedIndex > -1) {
          addPartsLocally[addedIndex] = updatedList[index];
        } else {
          addPartsLocally.push(updatedList[index]);
        }
      }
    });
    setCatalogProductList(updatedList);
    if (counter < catalogCount) {
      counter = counter === 1 && !decremenmtFlag ? catalogCount + 1 : counter;
    } else if (decremenmtFlag) {
      counter -= 2;
    }
    setAddPartsLocally(addPartsLocally);
    setSubInvCount(counter);
    // if (isEdit) setSubInvCount(counter);
    // else {
    //   setSubInvCount(cartCount);
    //   setCartCount(cartCount);
    // }
  };

  const handleCatalogCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any,
    sourceLocation: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCatalogCount(event, data, product);
  };

  const handleSubInvCount = (
    event: any,
    data: { value: string; number: number },
    subInv: any
  ): void => {
    const subInvWithLotInfo = [...subInventoryLotInfo];
    let counter = 0;
    let decremenmtFlag = false;
    subInvWithLotInfo.forEach((element: any) => {
      // eslint-disable-next-line no-unused-expressions
      element?.productDescription.forEach((item: any) => {
        // eslint-disable-next-line no-unused-expressions
        item?.value.forEach((elem: any) => {
          if (elem.name === subInv.productDescription) {
            elem.value.forEach((data1: any, index: any) => {
              if (
                data1.lotNumber === subInv.lotNumber &&
                data1.subInventoryCode === subInv.subInventoryCode
              ) {
                decremenmtFlag = data.number < data1.counterValue;
                data1.changed = true;
                data1.counterValue =
                  data.number < Number(subInv.maxQuantity)
                    ? data.number
                    : Number(subInv.maxQuantity);
                counter += data1.changed ? data1.counterValue : 0;
                const sunInvIndex = addedSunInvPartLocal.findIndex(
                  (e: any) => e.lotNumber === subInv.lotNumber
                );
                if (sunInvIndex > -1) {
                  addedSunInvPartLocal[sunInvIndex] = elem.value[index];
                } else {
                  addedSunInvPartLocal.push(elem.value[index]);
                }
                if (decremenmtFlag) {
                  setSubInvCount(subInvCount - 1);
                } else {
                  setSubInvCount(subInvCount + 1);
                }
              }
            });
          }
        });
      });
    });
    setAddedSunInvPartLocal(addedSunInvPartLocal);
    setSubInventoryLotInfo(subInvWithLotInfo);
    if (counter < subInvCount) {
      counter = counter === 1 && decremenmtFlag ? subInvCount + 1 : counter;
    } else if (decremenmtFlag) {
      counter -= 2;
    }
    // setSubInvCount(counter);
    // setCount(counter);
  };

  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...productList];
    listItems.forEach((i: any) => {
      if (i.productId === product.productId && i.externalId === product.externalId) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setSubInvCount(counter);
    setProductList(listItems || []);
  };

  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.ProductGroup) {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleProductList = (selectedItem: any, type: string): void => {
    const selectedOptions = selectedItem?.map((item: any) => item.id);
    setSelectedGrps(selectedOptions);
    if (type === 'product') {
      productListItems({
        variables: {
          filters: {
            productName: selectedOptions,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
          searchText: searchTerm,
          caseId: inventoryReturnDetail.orderId ? inventoryReturnDetail.orderId : null,
          caseExternalId: inventoryReturnDetail.orderExternalId
            ? inventoryReturnDetail.orderExternalId
            : inventoryReturnDetail.caseExternalId,
          salesRepId: inventoryReturnDetail.salesRepId ? inventoryReturnDetail.salesRepId : null,
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          limit: 50,
          offset: 0,
          tabName,
          recordType: 'Transfer',
          salesRepId: inventoryReturnDetail?.salesRepId,
          subInventoryCode: inventoryReturnDetail.accountNumber,
          filters: {
            productName: selectedOptions,
            searchText: '',
          },
        },
      });
    }
  };

  const handleSelect = useCallback(
    (data: any, name: string, type: string) => {
      // const { selection } = data;
      setFilterValue(name, data);
      handleProductList(data, type);
    },
    [handleProductList, setFilterValue]
  );
  const handleSaveCall = (selectedLineItemTransferList: any): void => {
    if (returnType === 'Failed') {
      // eslint-disable-next-line array-callback-return
      const updatedPickedItems = selectedLineItemTransferList.map((element: any) => {
        const existingItem = inventoryReturnDetail?.lineItems?.find(
          (item: any) =>
            item.productId === element.productId &&
            item.lotNumber === element.lotNumber &&
            item.externalId === element.externalId
        );
        // eslint-disable-next-line consistent-return
        if (existingItem) {
          return {
            // sfid: product.sfid,
            priceBookEntryId: element.priceBookEntryId,
            salesUnitOfMeasure: element.salesUnitOfMeasure,
            deliveredDate: null,
            externalId: existingItem?.orderItemId === null ? existingItem?.orderItemExternalId : '',
            sfid: existingItem?.orderItemId,
            caseUsageId: existingItem?.id,
            caseUsageExternalId: existingItem?.externalId,
            ...(existingItem.usageExternalId && {
              usageExternalId: existingItem.usageExternalId,
            }),
            subInventoryCode: element?.subInventoryCode || '',
            sourceLocation: element.sourceLocation,
            isLotNumber: element?.isLotNumber,
            // ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
            // ...(product.caseUsageExternalId && {
            //   caseUsageExternalId: product.caseUsageExternalId,
            // }),
            lotNumber: element.lotNumber,
            // externalId: element.externalId,
            productNumber: element.productNumber,
            productId: element.productId,
            quantity: element.counterValue.toString(),
          };
        }
        return {
          // sfid: product.sfid,
          priceBookEntryId: element.priceBookEntryId,
          salesUnitOfMeasure: element.salesUnitOfMeasure,
          deliveredDate: null,
          externalId: element?.orderItemId === null ? element?.orderItemExternalId : '',
          sfid: element?.orderItemId,
          caseUsageId: element?.id,
          caseUsageExternalId: element?.externalId,
          ...(element.usageExternalId && {
            usageExternalId: element.usageExternalId,
          }),
          // ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
          // ...(product.caseUsageExternalId && {
          //   caseUsageExternalId: product.caseUsageExternalId,
          // }),
          lotNumber: element.lotNumber,
          isLotNumber: element?.isLotNumber,
          // externalId: element.externalId,
          productNumber: element.productNumber,
          productId: element.productId,
          quantity: element.counterValue.toString(),
          sourceLocation: element.sourceLocation,
        };
      });
      const variables = {
        id: inventoryReturnDetail.orderId,
        caseId: inventoryReturnDetail.id,
        // externalId: inventoryReturnDetail.externalId,
        caseExternalId: inventoryReturnDetail.externalId,
        submitType: 'Update',
        salesRepId: inventoryReturnDetail.salesRepId,
        lineItems: updatedPickedItems || [],
      };
      reSubmitInventory({
        variables,
      })
        .then((res: any) => {
          if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
            handleClose();
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
            setTimeout(() => {
              refetchTransferDetails();
            }, 500);
          }
        })
        .catch(error => {
          if (error && error.message) {
            openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
          } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory Return error.');
          // eslint-disable-next-line no-console
          console.log('We had a trouble saving data to salesforce. Please try again later');
          // eslint-disable-next-line no-console
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      const updatedPickedItems = selectedLineItemTransferList.map(
        (element: any): LineItem => {
          const existingItem = inventoryReturnDetail?.lineItems?.find(
            (item: any) =>
              item.productId === element.productId &&
              item.lotNumber === element.lotNumber &&
              item.externalId === element.externalId
          );
          return {
            id: element?.id || '',
            externalId: element.externalId || '',
            lotNumber: element.lotNumber || '',
            productId: element.productId || '',
            quantity: String(element.counterValue) || '',
            priceBookEntryId: element.priceBookEntryId || '',
            unitPrice: element?.unitPrice || '',
            status: 'New',
            subInventoryCode: element?.subInventoryCode || '',
            approvalStatus:
              existingItem && existingItem.approvalStatus
                ? existingItem.approvalStatus
                : element.approvalStatus || '',
            salesUnitOfMeasure: element.salesUnitOfMeasure || '',
            productNumber: element.productNumber,
            isLotNumber: element.isLotNumber,
          };
        }
      );
      const updatedDetails = {
        orderId: inventoryReturnDetail.id || '', // null
        orderExternalId: inventoryReturnDetail.externalId || '',
        accountId: inventoryReturnDetail.accountId || '',
        requestedFrom: 'SalesRep',
        salesRepId: inventoryReturnDetail?.salesRepId,
        receivingRepId: inventoryReturnDetail?.receivingRepId,
        salesOrganization: 'F48',
        fromSubInventoryCode: inventoryReturnDetail.fromSubInventoryCode || '',
        toSubInventoryCode: inventoryReturnDetail.toSubInventoryCode || '',
        status: inventoryReturnDetail.status,
        lineItems: updatedPickedItems || [],
      };
      updateTransfer({
        variables: updatedDetails,
      })
        .then(response => {
          if (
            response &&
            response?.data &&
            response?.data?.updateTransferInventory &&
            response?.data?.updateTransferInventory?.message &&
            response?.data?.updateTransferInventory?.message === 'success'
          ) {
            handleClose();
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
            setTimeout(() => {
              refetchTransferDetails();
            }, 500);
          } else {
            console.log('We had a trouble saving data to salesforce. Please try again later');
          }
        })
        .catch(error => {
          setLoader(false);
          setTimeout(() => {
            if (error && error.message) {
              setLoader(false);
              openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
            } else {
              openSnackBar(
                NOTIFY_TYPE.ERROR,
                'Error: We had a trouble saving data to salesforce. Please try again later'
              );
            }
          }, 1000);
        });
    }
  };
  const onAddClick = (): void => {
    const selctedItems: Array<any> = [];
    addedSunInvPartLocal
      .filter((list: any) => list.counterValue)
      .forEach((product: any): void => {
        if (product.counterValue) {
          selctedItems.push({
            counterValue: product.counterValue,
            expirationDate: product.expirationDate,
            lotNumber: product.lotNumber,
            priceBookEntryId: product.priceBookEntryId,
            productDescription: product.productDescription,
            productId: product.productId,
            productNumber: product.productNumber,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            subInventoryName: product.subInventoryName,
            subInventoryCode: product?.subInventoryCode || '',
            unitPrice: product.unitPrice,
            externalId: product.externalId,
            isLotNumber: product.isLotNumber,
            sourceLocation: product.sourceLocation,
          });
        }
      });
    setInventoryList(selctedItems);
    selectedItemsValue = selctedItems;
    if (handleSelectedLineItems) {
      handleSelectedLineItems(selctedItems, count);
    }

    handleSaveCall(selctedItems);
  };
  // const onAddClick = (): void => {
  //   // handleFilter(selectedFilters);
  //   console.log(inventoryReturnDetail, 'inventoryReturnDetail');
  //   const mutation: any = {
  //     orderId: inventoryReturnDetail.orderId,
  //     orderExternalId: inventoryReturnDetail.orderExternalId,
  //     salesRepId: inventoryReturnDetail.salesRepId,
  //     formType: isEdit ? 'Update' : 'New',
  //   };

  //   const lineItems: any[] = [];
  //   // subInventoryLotInfo.forEach((subinvenTory: any) => {
  //   addedSunInvPartLocal.forEach((lotInfo: any) => {
  //     if (lotInfo.counterValue) {
  //       const obj: any = {
  //         lotNumber: lotInfo.lotNumber,
  //         productId: lotInfo.productId,
  //         quantity: lotInfo.counterValue.toString(),
  //         productNumber: lotInfo.productNumber,
  //         externalId: lotInfo.externalId,
  //         isLotNumber: lotInfo?.isLotNumber,
  //         // priceBookEntryId: lotInfo.priceBookEntryId,
  //         // unitPrice: lotInfo.unitPrice,
  //         // salesUnitOfMeasure: lotInfo.salesUnitOfMeasure,
  //         // deliveredDate: moment(lotInfo.deliveredDate).format('YYYY-MM-DD'),
  //       };
  //       lineItems.push(obj);
  //     }
  //   });
  //   if (!isEdit) {
  //     addPartsLocally.forEach((lotInfo: any): void => {
  //       if (lotInfo.changed) {
  //         const obj: any = {
  //           // externalId: lotInfo.externalId,
  //           // id: lotInfo.id,
  //           // lotNumber: lotInfo.lotNumber,
  //           // externalId: lotInfo.externalId,
  //           productNumber: lotInfo.productNumber,
  //           productId: lotInfo.productId,
  //           quantity: lotInfo.counterValue.toString(),
  //           isLotNumber: lotInfo?.isLotNumber,
  //           // priceBookEntryId: lotInfo.priceBookEntryId,
  //           // unitPrice: lotInfo.unitPrice,
  //           // salesUnitOfMeasure: lotInfo.salesUnitOfMeasure,
  //           // deliveredDate: moment(lotInfo.deliveredDate).format('YYYY-MM-DD'),
  //         };
  //         lineItems.push(obj);
  //       }
  //     });
  //   } else {
  //     catalogProductList.forEach((lotInfo: any): void => {
  //       if (lotInfo.changed) {
  //         const obj: any = {
  //           // externalId: lotInfo.externalId,
  //           // id: lotInfo.id,
  //           // lotNumber: lotInfo.lotNumber,
  //           // externalId: lotInfo.externalId,
  //           productNumber: lotInfo.productNumber,
  //           productId: lotInfo.productId,
  //           quantity: lotInfo.counterValue.toString(),
  //           isLotNumber: lotInfo?.isLotNumber,
  //           // priceBookEntryId: lotInfo.priceBookEntryId,
  //           // unitPrice: lotInfo.unitPrice,
  //           // salesUnitOfMeasure: lotInfo.salesUnitOfMeasure,
  //           // deliveredDate: moment(lotInfo.deliveredDate).format('YYYY-MM-DD'),
  //         };
  //         lineItems.push(obj);
  //       }
  //     });
  //   }
  //   productList.forEach((lotInfo: any): void => {
  //     if (lotInfo.changed) {
  //       const obj: any = {
  //         // externalId: lotInfo.externalId,
  //         // id: lotInfo.id,
  //         lotNumber: lotInfo.lotNumber,
  //         externalId: lotInfo.externalId,
  //         productNumber: lotInfo.productNumber,
  //         productId: lotInfo.productId,
  //         quantity: lotInfo.counterValue.toString(),
  //         isLotNumber: lotInfo?.isLotNumber,
  //         // priceBookEntryId: lotInfo.priceBookEntryId,
  //         // unitPrice: lotInfo.unitPrice,
  //         // salesUnitOfMeasure: lotInfo.salesUnitOfMeasure,
  //         // deliveredDate: moment(lotInfo.deliveredDate).format('YYYY-MM-DD'),
  //       };
  //       lineItems.push(obj);
  //     }
  //   });
  //   mutation.lineItems = lineItems;
  //   setLoader(true);
  //   if (returnType === 'Failed') {
  //     const variables = {
  //       id: inventoryReturnDetail.id,
  //       caseId: inventoryReturnDetail.caseId,
  //       caseExternalId: inventoryReturnDetail.caseExternalId,
  //       submitType: 'Update',
  //       lineItems,
  //     };
  //     reSubmitInventory({
  //       variables,
  //     })
  //       .then((res: any) => {
  //         if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
  //           setLoader(false);
  //           handleClose();
  //         }
  //       })
  //       .catch(error => {
  //         if (error && error.message) {
  //           openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
  //         } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory Return error.');
  //         // eslint-disable-next-line no-console
  //         console.log('We had a trouble saving data to salesforce. Please try again later');
  //         // eslint-disable-next-line no-console
  //         console.log(error); // TODO change when some operation needs to be run on error.
  //       });
  //   } else {
  //     createUpdateInventoryReturn({
  //       variables: mutation,
  //     });
  //   }
  // };

  const handleCompletedTab = (e: any): void => {
    if (inventoryReturnDetail?.type === 'Customer to Rep') {
      setTabName('Customer');
    } else {
      setTabName('Repstock');
    }
  };

  const filterAccount =
    primaryAccountData &&
    primaryAccountData.filter((el: any) => el.id === inventoryReturnDetail.accountId);
  const isRepstock: boolean = filterAccount && filterAccount[0]?.isRepStockAccount === true;
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        <Modal
          isOpen={isOpen}
          dismissOnClickOutside={false}
          ariaHideApp={false}
          footer={[
            <Button
              label="Cancel"
              onClick={() => {
                handleClose();
                //  handleFilter(selectedFilters);
              }}
            />,
            <Button label={isEdit ? 'Update' : 'Add'} variant="brand" onClick={onAddClick} />,
          ]}
          onRequestClose={() => {
            handleClose();
            //   handleFilter(selectedFilters);
          }}
        >
          {loading && <Spinner />}
          <div style={{ display: 'grid', padding: '0.75rem', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {isEdit ? 'Edit Parts' : 'Add Parts'}
            </div>
            <div style={{ textAlign: 'end' }}>
              <CartComponent count={subInvCount} color="black" textColor="white" />
            </div>
          </div>
          <div style={{ padding: '0rem 0.75rem 0rem 0.75rem' }}>
            <Tabs
              id="tabs-example-default"
              style={{ border: 'none' }}
              onSelect={(e: any): void => handleCompletedTab(e)}
            >
              {!isEdit && (
                <TabsPanel
                  label={
                    inventoryReturnDetail?.type === 'Customer to Rep' ? 'Customer' : 'Repstock'
                  }
                >
                  {/* <TabsPanel label="Repstock"> */}
                  <div>
                    <div className="full-width_button-product" id="product-catalog">
                      <AutocompleteSingleSelectPicker
                        items={productGroup}
                        setSelectedItems={(result: any): void => {
                          handleSelect(result, 'productGroup', 'subInventory');
                        }}
                        disabled={false}
                        selectedItems={
                          selectedFilters && selectedFilters?.productGroup
                            ? selectedFilters?.productGroup || []
                            : []
                        }
                        placeholder="Select Product Group"
                        label=""
                        withAddMenuButton={false}
                        // fetchItems={(searchText: string): string => searchText}
                        // filter
                        filterPicker
                        name="productGroup"
                      />
                    </div>
                    {!isEdit && (
                      <div className="catalog-input">
                        <Input
                          assistiveText={{ spinner: 'Field data is loading' }}
                          iconRight={
                            <InputIcon
                              assistiveText={{
                                icon: 'Search',
                              }}
                              name="search"
                              category="utility"
                            />
                          }
                          onChange={(_event: any, searchItem: string): void =>
                            searchSubInventory(_event, searchItem)
                          }
                          hasSpinner={loadingSubInventory}
                          id="unique-id-4"
                          placeholder="Search Part"
                        />
                      </div>
                    )}
                    <div
                      style={{ width: '100%', margin: 'auto', float: 'right', marginRight: '5px' }}
                    >
                      {/* <div
                        className="inventory-return-lot-info-name"
                        style={{ display: 'inline-block' }}
                      >
                        Legend:
                      </div> */}
                      <div style={{ display: 'inline-block', float: 'right' }}>
                        <span style={{ marginLeft: '50px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="notification"
                            size="x-small"
                            style={{ fill: '#000084', marginRight: '5px' }}
                          />
                          Expiring in 90 days
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="clock"
                            size="x-small"
                            style={{ fill: '#FFAE42', marginRight: '5px' }}
                          />
                          30 days
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="warning"
                            size="x-small"
                            style={{ fill: '#D2042D', marginRight: '5px' }}
                          />
                          Expired
                        </span>
                        <span style={{ marginLeft: '10px', marginRight: '5px' }}>
                          <img
                            src={holdIconPath}
                            className="logo-image"
                            alt="company"
                            style={{ marginLeft: '8px', marginRight: '8px' }}
                          />
                          On Hold
                        </span>
                      </div>
                    </div>
                    {inventoryReturnDetail?.type !== 'Customer to Rep' ? (
                      <section className="slds-p-around_large-1">
                        {!loadingSubInventory &&
                          subInventoryLotInfo.map((subInv: any) => (
                            <>
                              {subInv?.productDescription &&
                                subInv?.productDescription.map((subInvCode: any) => {
                                  return (
                                    <>
                                      <Collapsible
                                        open={subInvCode?.isChecked}
                                        handleFilterOpening={handleFilterOpening}
                                        title={`${subInvCode?.subInventoryCode} - ${subInvCode?.subInventoryName}`}
                                        code={subInvCode?.subInventoryCode}
                                      >
                                        {subInvCode?.value &&
                                          subInvCode?.value.map((item: any) => {
                                            return (
                                              <>
                                                <div className="inventory-return-lot-info-name">
                                                  <div style={{ marginTop: '5px' }}>
                                                    {`${item.productNumber} ${
                                                      item.name ? item.name.toUpperCase() : '--'
                                                    }`}
                                                    {item.productOnHoldType === 'ITEM' ? (
                                                      <img
                                                        src={holdIconPath}
                                                        className="logo-image"
                                                        alt="company"
                                                        style={{
                                                          marginLeft: '8px',
                                                          marginRight: '8px',
                                                        }}
                                                      />
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                  {item.value &&
                                                    item.value.map((value: any) => {
                                                      const diffDays = getDateDifferenceInDays(
                                                        new Date(value.expirationDate).toString(),
                                                        new Date().toString()
                                                      );
                                                      let icon: any;
                                                      let color: any;
                                                      if (diffDays < 90) {
                                                        icon = 'notification';
                                                        color = '#000084';
                                                      }
                                                      if (diffDays < 30) {
                                                        icon = 'clock';
                                                        color = '#FFAE42';
                                                      }
                                                      if (diffDays < 0) {
                                                        icon = 'warning';
                                                        color = '#D2042D';
                                                      }
                                                      return (
                                                        <div
                                                          className="inventory-return-lot-info"
                                                          style={{
                                                            color:
                                                              Number(value.maxQuantity) <= 0
                                                                ? 'lightgray'
                                                                : '',
                                                          }}
                                                        >
                                                          <span>
                                                            Lot #/Serial # -{' '}
                                                            {value.lotNumber || '--'}
                                                            {item?.productOnHoldType === 'ITEM' ||
                                                            value?.productOnHoldType === 'ITEM' ? (
                                                              <img
                                                                src={holdIconPath}
                                                                className="logo-image"
                                                                alt="company"
                                                                style={{
                                                                  marginLeft: '5px',
                                                                  marginRight: '5px',
                                                                  marginTop: '2px',
                                                                  position: 'absolute',
                                                                }}
                                                              />
                                                            ) : (
                                                              ''
                                                            )}
                                                            {(item?.serialRecalled &&
                                                              item?.serialRecalled.includes(
                                                                value?.lotNumber
                                                              )) ||
                                                            (value?.serialRecalled &&
                                                              value?.serialRecalled.includes(
                                                                value?.lotNumber
                                                              )) ? (
                                                              // eslint-disable-next-line react/jsx-indent
                                                              <>
                                                                <img
                                                                  src={holdIconPath}
                                                                  className="logo-image"
                                                                  alt="company"
                                                                  style={{
                                                                    marginLeft: '5px',
                                                                    marginRight: '5px',
                                                                    marginTop: '2px',
                                                                    position: 'absolute',
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              ''
                                                            )}{' '}
                                                            <br />
                                                            Expiration Date:{' '}
                                                            {value.expirationDate
                                                              ? moment(value.expirationDate).format(
                                                                  'MM/DD/YYYY'
                                                                )
                                                              : '--'}
                                                            {value?.expirationDate && icon && (
                                                              <Icon
                                                                // assistiveText={{ label: 'Announcement' }}
                                                                category="utility"
                                                                name={icon}
                                                                size="x-small"
                                                                style={{
                                                                  fill: color,
                                                                  marginLeft: '15px',
                                                                }}
                                                              />
                                                            )}
                                                          </span>
                                                          <Input
                                                            id="counter-input-3"
                                                            styleContainer={{ width: '110px' }}
                                                            styleInput={{ padding: '0px' }}
                                                            minValue={0}
                                                            maxValue={value.maxQuantity}
                                                            disabled={value.alreadyAdded}
                                                            onChange={(
                                                              event: any,
                                                              data: {
                                                                value: string;
                                                                number: number;
                                                              }
                                                            ): void => {
                                                              handleSubInvCount(event, data, value);
                                                            }}
                                                            value={value.counterValue}
                                                            variant="counter"
                                                          />
                                                        </div>
                                                      );
                                                    })}
                                                  <div />
                                                </div>
                                              </>
                                            );
                                          })}
                                      </Collapsible>
                                    </>
                                  );
                                })}
                            </>
                          ))}
                      </section>
                    ) : (
                      <section className="slds-p-around_large-1">
                        {!loadingSubInventory &&
                          subInventoryLotInfo.map((subInv: any) => {
                            return (
                              <>
                                {subInv?.productDescription &&
                                  subInv?.productDescription.map((item: any) => {
                                    return (
                                      <>
                                        {item?.value &&
                                          item?.value.map((part: any) => {
                                            return (
                                              <>
                                                <div className="inventory-return-lot-info-name">
                                                  <div style={{ marginTop: '5px' }}>
                                                    {`${part.productNumber} ${
                                                      part.name ? part.name.toUpperCase() : '--'
                                                    }`}
                                                    {part.productOnHoldType === 'ITEM' ? (
                                                      <img
                                                        src={holdIconPath}
                                                        className="logo-image"
                                                        alt="company"
                                                        style={{
                                                          marginLeft: '8px',
                                                          marginRight: '8px',
                                                        }}
                                                      />
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                  {part.value &&
                                                    part.value.map((value: any) => {
                                                      const diffDays = getDateDifferenceInDays(
                                                        new Date(value.expirationDate).toString(),
                                                        new Date().toString()
                                                      );
                                                      let icon: any;
                                                      let color: any;
                                                      if (diffDays < 90) {
                                                        icon = 'notification';
                                                        color = '#000084';
                                                      }
                                                      if (diffDays < 30) {
                                                        icon = 'clock';
                                                        color = '#FFAE42';
                                                      }
                                                      if (diffDays < 0) {
                                                        icon = 'warning';
                                                        color = '#D2042D';
                                                      }
                                                      return (
                                                        <div
                                                          className="inventory-return-lot-info"
                                                          style={{
                                                            color:
                                                              Number(value.maxQuantity) <= 0
                                                                ? 'lightgray'
                                                                : '',
                                                          }}
                                                        >
                                                          <span>
                                                            Lot #/Serial # -{' '}
                                                            {value.lotNumber || '--'}
                                                            {part?.productOnHoldType === 'ITEM' ||
                                                            value?.productOnHoldType === 'ITEM' ? (
                                                              <img
                                                                src={holdIconPath}
                                                                className="logo-image"
                                                                alt="company"
                                                                style={{
                                                                  marginLeft: '5px',
                                                                  marginRight: '5px',
                                                                  marginTop: '2px',
                                                                  position: 'absolute',
                                                                }}
                                                              />
                                                            ) : (
                                                              ''
                                                            )}
                                                            {(part?.serialRecalled &&
                                                              part?.serialRecalled.includes(
                                                                value?.lotNumber
                                                              )) ||
                                                            (value?.serialRecalled &&
                                                              value?.serialRecalled.includes(
                                                                value?.lotNumber
                                                              )) ? (
                                                              // eslint-disable-next-line react/jsx-indent
                                                              <>
                                                                <img
                                                                  src={holdIconPath}
                                                                  className="logo-image"
                                                                  alt="company"
                                                                  style={{
                                                                    marginLeft: '5px',
                                                                    marginRight: '5px',
                                                                    marginTop: '2px',
                                                                    position: 'absolute',
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              ''
                                                            )}{' '}
                                                            <br />
                                                            Expiration Date:{' '}
                                                            {value.expirationDate
                                                              ? moment(value.expirationDate).format(
                                                                  'MM/DD/YYYY'
                                                                )
                                                              : '--'}
                                                            {value?.expirationDate && icon && (
                                                              <Icon
                                                                // assistiveText={{ label: 'Announcement' }}
                                                                category="utility"
                                                                name={icon}
                                                                size="x-small"
                                                                style={{
                                                                  fill: color,
                                                                  marginLeft: '15px',
                                                                }}
                                                              />
                                                            )}
                                                          </span>
                                                          <Input
                                                            id="counter-input-3"
                                                            styleContainer={{ width: '110px' }}
                                                            styleInput={{ padding: '0px' }}
                                                            minValue={0}
                                                            maxValue={value.maxQuantity}
                                                            disabled={value.alreadyAdded}
                                                            onChange={(
                                                              event: any,
                                                              data: {
                                                                value: string;
                                                                number: number;
                                                              }
                                                            ): void => {
                                                              handleSubInvCount(event, data, value);
                                                            }}
                                                            value={value.counterValue}
                                                            variant="counter"
                                                          />
                                                        </div>
                                                      );
                                                    })}
                                                  <div />
                                                </div>
                                              </>
                                            );
                                          })}
                                      </>
                                    );
                                  })}
                              </>
                            );
                          })}
                      </section>
                    )}
                  </div>
                </TabsPanel>
              )}
            </Tabs>
            {isEdit &&
              productList &&
              productList.map((product: any) =>
                !product.deleted ? (
                  <div className="inventory-return-product-info">
                    <div style={{ marginTop: '5px' }}>
                      {`${
                        product.productNumber ? product.productNumber : product.productNo
                      } ${product.productdescription || product.productDescription}`}
                    </div>
                    <div>
                      <Input
                        id="counter-input-3"
                        minValue={0}
                        maxValue={product.lotNumber ? product.maxQuantity : MAX_QUANTITY}
                        onChange={(event: any, data: { value: string; number: number }): void => {
                          handleCount(event, data, product);
                        }}
                        disabled={
                          product.lineVisualIndicator === 'Approved' ||
                          product.lineVisualIndicator === 'Accept'
                        }
                        value={product.counterValue}
                        // value={product.quantity ? Number(product.quantity) : product.counterValue}
                        variant="counter"
                      />
                    </div>
                    {isEdit && (
                      <div style={{ textAlign: 'end', paddingTop: '5px' }}>
                        <Button
                          iconCategory="utility"
                          iconName="delete"
                          iconSize="large"
                          iconVariant="bare"
                          variant="icon"
                          disabled={
                            product.lineVisualIndicator === 'Approved' ||
                            product.lineVisualIndicator === 'Accept'
                          }
                          onClick={(): void => handleDelete(product)}
                        />
                      </div>
                    )}
                  </div>
                ) : null
              )}
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Custom Header on Inventory Add Items Page **/
export default withRouter(InventoryReturnAddItems);
