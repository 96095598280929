import gql from 'graphql-tag';

const SUBMIT_REVIEW_RETURN = gql`
  mutation reviewInventoryReturn(
    $orderId: String
    $orderExternalId: String!
    $accountId: String!
    $salesRepId: String!
    $fromSubInventoryCode: String!
    $salesOrganization: String!
    $shipDate: Date
    $trackingId: String
    $returnReason: String
    $lineItems: [UpdateReturnLineItems]
    $comments: [Comment]
  ) {
    reviewInventoryReturn(
      orderId: $orderId
      orderExternalId: $orderExternalId
      accountId: $accountId
      salesRepId: $salesRepId
      fromSubInventoryCode: $fromSubInventoryCode
      salesOrganization: $salesOrganization
      shipDate: $shipDate
      trackingId: $trackingId
      returnReason: $returnReason
      lineItems: $lineItems
      comments: $comments
    ) {
      message
      externalId
    }
  }
`;

export default SUBMIT_REVIEW_RETURN;
